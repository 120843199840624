import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import {
  createRegisterDocument,
  RegisterDocument,
  RegisterDocumentForm,
} from "../../../../services/registerDocument";
import { PRINT_FORM_FILE_TYPE } from "../../../../utils/const";
import notify from "../../../../utils/notify";

interface AddRegisterDocumentModalProps {
  registerDocuments: RegisterDocument[];
  printFormOptions: LabeledValue[];
  fileExtensionOptions: LabeledValue[];
  modalVisible: boolean;
  onDocumentAdded: (doc: RegisterDocument) => void;
  onModalClose: () => void;
}

const AddRegisterDocumentModal: React.FC<AddRegisterDocumentModalProps> = ({
  registerDocuments,
  printFormOptions,
  fileExtensionOptions,
  modalVisible,
  onDocumentAdded,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    doc_name: "",
    file_ord: registerDocuments.length + 1,
    file_ext: "",
    mandatory_flag: false,
  };

  const [editForm, setEditForm] =
    useState<RegisterDocumentForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const { t } = useTranslation();

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const doc = await createRegisterDocument(
        editForm,
        appContext.serviceConfig
      );
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onDocumentAdded(doc);
      onModalClose();
    } catch (ex) {
      appContext.errorHandler(ex, "create register document");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    doc_name: yup
      .string()
      .nullable()
      .required(t("app.common.NameRequired"))
      .test(
        "duplicated",
        t("AddRegisterDocument.DocumentNameAlreadyExisted"),
        function (val) {
          return !registerDocuments.find(
            (doc) =>
              doc.doc_name.toLowerCase().trim() ===
              editForm.doc_name.toLowerCase().trim()
          );
        }
      ),
    file_ext: yup.string().required(t("app.common.ValueRequired"))
  });

  const [validationError, , , saveWithValidate] =
    useValidate<RegisterDocumentForm>(
      editForm,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof RegisterDocument,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    if (fieldName === "file_ext") {
      setEditForm((prevState) => {
        const file_ext = value as string;
        if (
          value === PRINT_FORM_FILE_TYPE ||
          prevState.file_ext === PRINT_FORM_FILE_TYPE
        ) {
          return {
            ...prevState,
            file_ext,
            doc_name: "",
          };
        }
        return {
          ...prevState,
          file_ext,
        };
      });
    } else {
      setEditForm((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const formItems: NxpFormGridItemProps<RegisterDocumentForm>[] = [
    editForm.file_ext === PRINT_FORM_FILE_TYPE
      ? {
        controlType: "select",
        controlProps: {
          options: printFormOptions,
        },
        label: t("app.common.Name"),
        itemFieldName: "doc_name",
        required: true,
        span: 12,
      }
      : {
        controlType: "input",
        label: t("app.common.Name"),
        itemFieldName: "doc_name",
        required: true,
        span: 12,
      },
    {
      controlType: "select",
      controlProps: {
        allowClear: true,
        options: fileExtensionOptions,
      },
      label: t("app.common.AllowFileExtension"),
      itemFieldName: "file_ext",
      required: true,
      span: 12,
    },
    {
      controlType: "radioGroup",
      controlProps: {
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      label: t("app.common.Mandatory"),
      itemFieldName: "mandatory_flag",
      span: 12,
    },
    {
      controlType: "select",
      controlProps: {
        options: Array(registerDocuments.length + 1)
          .fill(null)
          .map((_, idx) => ({ value: idx + 1, label: idx + 1 })),
      },
      label: t("app.common.FileOrder"),
      itemFieldName: "file_ord",
      span: 12,
    },
  ];

  return (
    <NxpModal
      title={t("AddRegisterDocument.AddDocumentModal")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddRegisterDocumentModal;
