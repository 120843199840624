// @ts-nocheck

import { useCallback, useState } from "react";
import { SchemaOf } from "yup";

// import { getFormattedMessage } from './index';

// export const yupLocaleConfig: LocaleObject = {
//   mixed: {
//     required: ({ label }) => {
//       return getFormattedMessage('validation.field.isRequired', {
//         field: label || '',
//       });
//     },
//   },
//   array: {
//     min: ({ label, min }) => {
//       if (min === 1)
//         return getFormattedMessage('validation.field.isRequired', {
//           field: label || '',
//         });
//       return getFormattedMessage('validation.field.min', {
//         field: label || '',
//         min: min.toString(),
//       });
//     },
//   },
// };

interface Values {
  [field: string]: any;
}

export type ValidationResultNested<T> = {
  [P in keyof T]?: T[P] extends unknown[]
    ? string
    : T[P] extends Date
    ? string
    : T[P] extends object
    ? ValidationResult<T[P]>
    : string;
};

export type ValidationResult<T> = {
  [K in keyof T]?: string;
};

export function validateYup<T extends Values>(
  values: any,
  validationSchema: SchemaOf<T>
): ValidationResult<T> {
  try {
    validationSchema.validateSync(values, { abortEarly: false });
  } catch (error) {
    return yupToValidationErrors<T>(error);
  }

  return {};
}

export function isValidationResultError<T extends Values>(
  validationResult: ValidationResult<T>
): boolean {
  return (
    Boolean(validationResult) && Object.keys(validationResult).length !== 0
  );
}

export function useValidate<T extends Values, U = undefined>(
  values: any,
  validationSchema: SchemaOf<T>,
  onSubmitCallback?: (submissionContext: U) => any
): [
  ValidationResult<T>,
  (onBlur?: boolean) => ValidationResult<T>,
  () => void,
  (submissionContext: U) => ValidationResult<T>
] {
  const [error, setError] = useState<ValidationResult<T>>({});

  const validate = useCallback(
    (onBlur?: boolean): ValidationResult<T> => {
      const validationResult: ValidationResult<T> = validateYup(
        values,
        validationSchema
      );
      if (onBlur) {
        let re = { ...validationResult };
        for (let [key, value] of Object.entries(values)) {
          if (value === undefined)
            re = {
              ...re,
              [key]: value,
            } as ValidationResult<T>;
        }
        setError(re);
        return re;
      }
      setError(validationResult);
      return validationResult;
    },
    [values, validationSchema]
  );

  const clearError = useCallback(() => setError({}), []);

  const onSubmit = useCallback(
    (submissionContext: U) => {
      const validationResult: ValidationResult<T> = validate();
      if (onSubmitCallback && !isValidationResultError(validationResult)) {
        onSubmitCallback(submissionContext);
      }
      return validationResult;
    },
    [validate, onSubmitCallback]
  );

  return [error, validate, clearError, onSubmit];
}

/**
 * Transform Yup errors to a ValidationErrors object
 */
function yupToValidationErrors<T extends Values>(
  yupError: any
): ValidationResult<T> {
  const errors = {} as ValidationResult<T>;
  if (yupError.inner.length === 0) {
    updateIn(errors, yupError.path, yupError.message);
    return errors;
  }
  for (const err of yupError.inner) {
    updateIn(errors, err.path, err.message);
  }
  return errors;
}

function updateIn(obj: any, path: string, value: any): any {
  const pathArray = path
    .split(".")
    .map((item) => item.split(/\[(\d+)\]/g))
    .reduce((a, b) => a.concat(b, []))
    .filter((s) => s.length > 0);

  let destinationObject = obj;
  for (let i = 0; i < pathArray.length - 1; i++) {
    if (pathArray[i] in destinationObject === false) {
      destinationObject[pathArray[i]] = {};
    }
    destinationObject = destinationObject[pathArray[i]];
  }
  destinationObject[pathArray[pathArray.length - 1]] = value;
}
