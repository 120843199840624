import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import { getDockerHostRoot } from '../components/App/AppRouter/AppRouter';
import { ServiceConfig } from '../utils/backend';
import { STORAGE_X_ACCESS_TOKEN } from '../utils/const';

const useServiceConfig = (): ServiceConfig => {
  const { keycloak } = useKeycloak();
  const entityRoute = useRouteMatch<{ entityId: string }>(
    `${getDockerHostRoot()}/entities/:entityId`
  );

  const registerRoute = useRouteMatch<{ registerId: string }>(
    `${getDockerHostRoot()}/entities/:entityId/registers/:registerId`
  );

  const xAccessToken = localStorage.getItem(STORAGE_X_ACCESS_TOKEN);

  const serviceConfig = useMemo(
    () => ({
      entityId: entityRoute?.params.entityId,
      registerId: registerRoute?.params.registerId
        ? Number(registerRoute?.params.registerId)
        : undefined,
      keyCloakToken: keycloak?.token,
      apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
      qcServiceApiBaseUrl: process.env.REACT_APP_QC_API_BASE_URL || "",
      xAccessToken,
    }),
    [
      entityRoute?.params.entityId,
      registerRoute?.params.registerId,
      keycloak?.token,
      xAccessToken,
    ]
  );

  return serviceConfig;
};

export default useServiceConfig;
