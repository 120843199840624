import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { AppFileUpload } from "../../../../components/AppFileUpload";
import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import { UploadFile } from "../../../../services/file";
import {
  createFormEntryAttachment,
  FormAttachment,
  FormAttachmentForm,
} from "../../../../services/registerFormEntry";
import notify from "../../../../utils/notify";

interface AddAtachmentModalProps {
  formEntryId: number;
  modalVisible: boolean;
  onAttachmentAdded: (attachment: FormAttachment) => void;
  onModalClose: () => void;
}

const AddAtachmentModal: React.FC<AddAtachmentModalProps> = ({
  formEntryId,
  modalVisible,
  onAttachmentAdded,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    subject: "",
  };

  const [editForm, setEditForm] = useState<FormAttachmentForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const attachment = await createFormEntryAttachment(
        formEntryId,
        editForm,
        appContext.serviceConfig
      );

      onAttachmentAdded(attachment);
      //onAttachmentAdded(editForm as FormAttachment);
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onModalClose();
    } catch (ex) {
      appContext.errorHandler(ex, "create form entry attachment");
    } finally {
      setSaveInProgress(false);
    }
  };

  const { t } = useTranslation();

  const formSchema = yup.object({
    subject: yup.string().nullable().required(t("app.common.SubjectRequired")),
    file: yup.object().nullable().required(t("app.common.FileRequired")),
  });

  const [validationError, , , saveWithValidate] =
    useValidate<FormAttachmentForm>(editForm, formSchema, handleSaveValidated);

  const handleFormGridStateChange = (
    fieldName: keyof FormAttachmentForm,
    value: unknown
  ) => {
    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const handleFileChange = useCallback((files: UploadFile[]) => {
    setEditForm((prevState) => ({
      ...prevState,
      file: files.length ? files[0] : undefined,
    }));
  }, []);

  const formItems: NxpFormGridItemProps<FormAttachmentForm>[] = [
    {
      controlType: "input",
      label: t("app.common.Subject"),
      itemFieldName: "subject",
      required: true,
      span: 12,
    },
    {
      controlType: "custom",
      label: t("app.common.File"),
      itemFieldName: "file",
      required: true,
      span: 12,
      customContent: appContext.activeRegister ? (
        <AppFileUpload
          registerId={appContext.activeRegister.id}
          hasError={!!validationError.file}
          token={appContext.serviceConfig.keyCloakToken!}
          onChange={handleFileChange}
          fileLimit={1}
        />
      ) : null,
    },
  ];

  return (
    <NxpModal
      title={t("app.common.AddAttachment")}
      visible={modalVisible}
      onCancel={onModalClose}
      width="small"
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError as object}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddAtachmentModal;
