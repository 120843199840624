import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { RegisterRuleTemplate } from "../../../../services/registerRule";
import CreateTemplateModal from "./CreateTemplateModal";

interface CreateTemplateModalButtonProps {
  onTemplateCreated: (newTemplate: RegisterRuleTemplate) => void;
}

const useStyles = createUseStyles((theme) => ({
  addButton: {
    marginBottom: theme.spacing(1),
  },
}));

const CreateTemplateModalButton: React.FC<CreateTemplateModalButtonProps> = ({
  onTemplateCreated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleTemplateCreated = (newTemplate: RegisterRuleTemplate) => {
    onTemplateCreated(newTemplate);
    setModalVisible(false);
  };

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <NxpButton className={classes.addButton} onClick={handleClick}>
        {t("CreateTemplateModal.AddTemplate")}
      </NxpButton>
      <CreateTemplateModal
        modalVisible={modalVisible}
        onModalClose={handleModalClose}
        onTemplateCreated={handleTemplateCreated}
      />
    </>
  );
};

export default CreateTemplateModalButton;
