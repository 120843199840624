import { apiRequest, ServiceConfig } from "../utils/backend";
import { User } from "./app";

export interface RegisterFlowStep {
  id: number;
  stepName: string;
  approveStatusId: number;
  rejectStatusId: number;
  roleCodes: string[];
  order: number;
  editFields: string[];
  approvingUsers?: User[];
  parallelApprove: boolean;
}

export interface RegisterFlowStepForm extends Omit<RegisterFlowStep, "id"> {}

export const getFlowStepList = async (
  flowId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFlowStep[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows/${flowId}/steps`,
    serviceConfig
  );
};

export const getRegisterFlowStep = async (
  id: number,
  flowId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFlowStep>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows/${flowId}/steps/${id}`,
    serviceConfig
  );
};

export const createRegisterFlowStep = async (
  flowId: number,
  registerFlowStepForm: RegisterFlowStepForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFlowStep>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows/${flowId}/steps`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(registerFlowStepForm),
    }
  );
};

export const updateRegisterFlowStep = async (
  id: number,
  flowId: number,
  registerFlowStep: RegisterFlowStepForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFlowStep>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows/${flowId}/steps/${id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(registerFlowStep),
    }
  );
};

export const deleteRegisterFlowStep = async (
  id: number,
  flowId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows/${flowId}/steps/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export interface RegisterFlow {
  id: number;
  flowName: string;
  flowSteps: RegisterFlowStep[];
  updatedOn: Date;
  createdOn: Date;
}
export interface RegisterFlowForm extends Pick<RegisterFlow, "flowName"> {}

export const getRegisterFlowList = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<RegisterFlow[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows`,
    serviceConfig
  );
};

export const createRegisterFlow = async (
  registerFlowForm: RegisterFlowForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFlow>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(registerFlowForm),
    }
  );
};

export const updateRegisterFlow = async (
  id: number,
  registerFlowForm: RegisterFlowForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFlow>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows/${id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(registerFlowForm),
    }
  );
};

export const deleteRegisterFlow = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/flows/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
