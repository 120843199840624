import { EllipsisOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { LabeledValue } from "antd/lib/select";
import clsx from "clsx";
import React from "react";
import { useDrop, useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";

import { createUseStyles } from "react-jss";
import {
  RuleCondition,
  RuleConditionGroup,
  RuleConjunctive,
} from "../../../../services/registerRule";
import RuleConditionLayout from "./RuleConditionLayout";
import RuleGroupLayout from "./RuleGroupLayout";

const useStyles = createUseStyles((theme) => ({
  ruleGroupChild: {
    marginTop: theme.spacing(1),
    marginLeft: 12,
    "&.isOverCurrent": {
      backgroundColor: theme.palette.backgroundDark,
      boxShadow: `0 0 0 4px ${theme.palette.backgroundDark}`,
    },
    "&:first-of-type": {
      marginLeft: 98,
    },
  },
  dragPreview: {
    display: "flex",
    position: "relative",
    "& .anticon-ellipsis": {
      cursor: "move",
      position: "absolute",
      left: -17,
      top: 8,
    },
  },
  selectConjunctive: {
    width: 78,
    marginRight: theme.spacing(1),
  },
}));

interface RuleGroupChildProps {
  childIndex: number;
  groupChild: RuleConditionGroup | RuleCondition;
  parentGroup: RuleConditionGroup;
  fieldOptions: LabeledValue[];
  errorKeys: number[];
  onAddCondition: (conditionGroupKey: number) => void;
  onConjunctiveChange: (
    conjunctive: RuleConjunctive,
    conditionGroupKey: number
  ) => void;
  onConditionChange: (ruleCondition: RuleCondition) => void;
  onConditionDelete: (conditionKey: number, conditionGroupKey: number) => void;
  onConditionGroup: (ruleCondition: RuleCondition) => void;
  onRuleDrop: (
    draggingRule: RuleConditionGroup | RuleCondition,
    draggingRuleParent: RuleConditionGroup,
    dropToRule: RuleConditionGroup | RuleCondition | undefined,
    dropToRuleParent: RuleConditionGroup
  ) => void;
}

const RuleGroupChild: React.FC<RuleGroupChildProps> = ({
  childIndex,
  groupChild,
  parentGroup,
  fieldOptions,
  errorKeys,
  onAddCondition,
  onConjunctiveChange,
  onConditionChange,
  onConditionDelete,
  onConditionGroup,
  onRuleDrop,
}) => {
  const classes = useStyles();
  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: "rule",
      drop(
        item: {
          rule: RuleCondition | RuleConditionGroup;
          parentGroup: RuleConditionGroup;
        },
        monitor
      ) {
        if (monitor.didDrop()) {
          return;
        }
        if (item.rule.key !== groupChild.key) {
          onRuleDrop(item.rule, item.parentGroup, groupChild, parentGroup);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [parentGroup, groupChild]
  );
  const [, drag, preview] = useDrag(
    () => ({
      type: "rule",
      item: { rule: groupChild, parentGroup },
    }),
    [groupChild, parentGroup]
  );

  const { t } = useTranslation();

  return (
    <div
      ref={drop}
      className={clsx(classes.ruleGroupChild, isOverCurrent && "isOverCurrent")}
    >
      <div className={classes.dragPreview} ref={preview}>
        <EllipsisOutlined ref={drag} rotate={90} />
        {childIndex > 0 && (
          <div>
            <Select
              disabled={childIndex > 1}
              className={classes.selectConjunctive}
              options={[
                {
                  label: t("RuleGroupChild.AND"),
                  value: RuleConjunctive.And,
                },
                {
                  label: t("RuleGroupChild.OR"),
                  value: RuleConjunctive.Or,
                },
              ]}
              value={parentGroup.conjunctive}
              onChange={(val) => onConjunctiveChange(val, parentGroup.key)}
            />
          </div>
        )}
        {"conjunctive" in groupChild ? (
          <RuleGroupLayout
            ruleGroup={groupChild}
            fieldOptions={fieldOptions}
            errorKeys={errorKeys}
            onAddCondition={onAddCondition}
            onConjunctiveChange={onConjunctiveChange}
            onConditionChange={onConditionChange}
            onConditionDelete={onConditionDelete}
            onConditionGroup={onConditionGroup}
            onRuleDrop={onRuleDrop}
          />
        ) : (
          <RuleConditionLayout
            ruleCondition={groupChild}
            fieldOptions={fieldOptions}
            error={
              errorKeys.includes(groupChild.key)
                ? t("app.common.IncompleteCondition")
                : undefined
            }
            onConditionChange={onConditionChange}
            onConditionDelete={(conditionKey: number) =>
              onConditionDelete(conditionKey, parentGroup.key)
            }
            onConditionGroup={onConditionGroup}
          />
        )}
      </div>
    </div>
  );
};

export default RuleGroupChild;
