import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpButtonDeleteConfirm,
  NxpFormItem,
  NxpHeader,
  NxpTable,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";

import {
  SetupStatusEditMode,
  SetupStatusFormItem,
} from "./SetupStatusContainer";

const useStyles = createUseStyles((theme) => ({
  setupStatusLayout: {},
  tableFooter: {
    textAlign: "right",
  },
}));

interface SetupStatusLayoutProps {
  form: SetupStatusFormItem[];
  editMode: SetupStatusEditMode;
  onEditClick: () => void;
  onFormItemChange: (item: SetupStatusFormItem) => void;
  onAddStatusItem: () => void;
  onFormSave: () => void;
  onCancel: () => void;
}

const SetupStatusLayout: React.FC<SetupStatusLayoutProps> = ({
  form,
  editMode,
  onEditClick,
  onFormItemChange,
  onAddStatusItem,
  onFormSave,
  onCancel,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const columns: ColumnProps<SetupStatusFormItem>[] = [
    {
      title: t("SetupStatusLayout.StatusName"),
      dataIndex: "statusName",
      width: 150,
      fixed: "left",
      sorter: (a: SetupStatusFormItem, b: SetupStatusFormItem) =>
        sorterForString(a.statusName, b.statusName),
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="input"
            error={record.errorMsg}
            controlProps={{
              value: record.statusName,
              onChange: (e) =>
                onFormItemChange({ ...record, statusName: e.target.value }),
            }}
          />
        );
      },
    },
    {
      title: t("SetupStatusLayout.AllowResubmit"),
      dataIndex: "allowResubmit",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              checked: record.allowResubmit,
              useIconInReadMode: true,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  allowResubmit: e.target.checked,
                }),
            }}
          />
        );
      },
    },
    {
      title: t("SetupStatusLayout.AllowEdit"),
      dataIndex: "allowEdit",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.allowEdit,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  allowEdit: e.target.checked,
                }),
            }}
          />
        );
      },
    },
    {
      title: t("SetupStatusLayout.Outstanding"),
      dataIndex: "outstanding",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.outstanding,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  outstanding: e.target.checked,
                }),
            }}
          />
        );
      },
    },
    {
      title: t("SetupStatusLayout.DefaultStatus"),
      dataIndex: "defaultStatus",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.defaultStatus,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  defaultStatus: e.target.checked,
                }),
            }}
          />
        );
      },
    },
    {
      title: (
        <Tooltip
          title={t(
            "SetupStatusLayout.MustSpecifyIfYouWouldLikeToEnableThe ''ReleaseForAmendment''Feature"
          )}
        >
          {t("SetupStatusLayout.SubmitStatus")} <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "submittedStatus",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.submittedStatus,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  submittedStatus: e.target.checked,
                }),
            }}
          />
        );
      },
    },
    {
      title: (
        <Tooltip
          title={t(
            "SetupStatusLayout.SelectToEnableThe ''ReleaseForAmendment''Feature"
          )}
        >
          {t("SetupStatusLayout.ReleaseStatus")} <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "releaseStatus",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.releaseStatus,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  releaseStatus: e.target.checked,
                }),
            }}
          />
        );
      },
    },
    {
      title: (
        <Tooltip
          title={t(
            "SetupStatusLayout.TheSystemCanResetTheStatusAfterEveryEdit,ExceptUnder ''ReleaseStatus'''"
          )}
        >
          {t("SetupStatusLayout.ChangeStatus")} <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "changeStatus",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.changeStatus,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  changeStatus: e.target.checked,
                }),
            }}
          />
        );
      },
    },
    {
      title: t("app.common.CancelStatus"),
      dataIndex: "cancelStatus",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.cancelStatus,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  cancelStatus: e.target.checked,
                }),
            }}
          />
        );
      },
    },

    {
      title: t("SetupStatusLayout.WithdrawStatus"),
      dataIndex: "withdrawStatus",
      width: 100,
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpFormItem
            editing={editMode === "edit"}
            controlType="checkbox"
            controlProps={{
              useIconInReadMode: true,
              checked: record.withdrawStatus,
              onChange: (e) =>
                onFormItemChange({
                  ...record,
                  withdrawStatus: e.target.checked,
                }),
            }}
          />
        );
      },
    },

    {
      title: "",
      dataIndex: "delete",
      width: 100,
      align: "right",
      fixed: "right",
      render: (_, record: SetupStatusFormItem) => {
        return (
          <NxpButtonDeleteConfirm
            hidden={editMode === "read"}
            size="small"
            type="text"
            onConfirm={() => onFormItemChange({ ...record, isDeleted: true })}
            loading={editMode === "saving"}
          />
        );
      },
    },
  ];

  return (
    <div className={classes.setupStatusLayout}>
      <NxpHeader
        titleContent={t("SetupStatusLayout.SetupStatus")}
        actionContent={
          editMode === "read" ? (
            <NxpButton onClick={onEditClick}>{t("app.common.Edit")}</NxpButton>
          ) : (
            <>
              <NxpButton type="text" onClick={onCancel}>
                {t("app.common.Cancel")}
              </NxpButton>
              <NxpButton onClick={onFormSave}>{t("app.common.Save")}</NxpButton>
            </>
          )
        }
      />
      <NxpTable
        loading={editMode === "initializing"}
        dataSource={form.filter((item) => !item.isDeleted)}
        columns={columns}
        useDefaultHeight
        footer={() => (
          <div className={classes.tableFooter}>
            <NxpButton disabled={editMode !== "edit"} onClick={onAddStatusItem}>
              {t("SetupStatusLayout.AddStatus")}
            </NxpButton>
          </div>
        )}
      />
    </div>
  );
};

export default SetupStatusLayout;
