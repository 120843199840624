import { initReactI18next } from "react-i18next";

import {
  nxpUiTranslationDe,
  nxpUiTranslationEn,
  nxpUiTranslationEs,
  nxpUiTranslationZhHk,
} from "@nexploretechnology/nxp-ui";
import i18n from "i18next";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";

import de from "./de/translation.json";
// import LanguageDetector from 'i18next-browser-languagedetector';'
import en from "./en/translation.json";
import es from "./es/translation.json";
import zh_hk from "./zh-HK/translation.json";

const resources = {
  en: {
    translation: { ...nxpUiTranslationEn, ...en },
  },
  zh_hk: {
    translation: { ...nxpUiTranslationZhHk, ...zh_hk },
  },
  es: {
    translation: { ...nxpUiTranslationEs, ...es },
  },
  de: {
    translation: { ...nxpUiTranslationDe, ...de },
  },
};

const enableInContextProcessor = window.location.search.includes("phrase=true");
if (enableInContextProcessor) {
  i18n.use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: true,
      fullReparse: true,
      autoLowercase: false,
      projectId: "a934f6528d90063c55747c7a207df675",
    })
  );
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en", // default language
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    postProcess: enableInContextProcessor ? ["phraseInContextEditor"] : [],
  });
