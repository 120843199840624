import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpHeader, NxpTabs, TabPane } from "@nexploretechnology/nxp-ui";
import deepClone from "lodash/cloneDeep";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import {
  extractFormConfigFields,
  getRegisterCustomForm,
  RegisterCustomFormField,
} from "../../../services/registerCustomForm";
import {
  createRegisterListingConfig,
  getRegisterListingConfig,
  RegisterListingConfig,
} from "../../../services/registerListing";
import notify from "../../../utils/notify";
import AppListing from "./Tabs/AppListing";
import ConfidentialFields from "./Tabs/ConfidentialFields";
import KeyInfo from "./Tabs/KeyInfo";
import WebColumnWidth from "./Tabs/WebColumnWidth";
import WebListing from "./Tabs/WebListing";

interface SetupListingProps {}

const SetupListing: React.FC<SetupListingProps> = () => {
  const appContext = useAppContext();
  const [formSpecAsync] = useAsync(() =>
    getRegisterCustomForm(appContext.serviceConfig).then(
      (data) => data?.definitions || []
    )
  );

  const [listingConfigAsync] = useAsync(() =>
    getRegisterListingConfig(
      appContext.activeRegister!.id,
      appContext.serviceConfig
    )
  );

  const [listConfigRefined, setListConfigRefined] =
    useState<RegisterListingConfig>();

  const { t } = useTranslation();

  const getAvailableFields = (
    formSpec: RegisterCustomFormField[],
    excludeNonReadableFields: boolean
  ) => {
    return [
      {
        key: "seqNumber",
        name: t("app.common.Sequential"),
        isFormField: false,
      },
      {
        key: "formNumber",
        name: t("app.common.FormReference"),
        isFormField: false,
      },
      {
        key: "updatedOn",
        name: t("app.common.UpdatedOn"),
        isFormField: false,
      },
      {
        key: "createdOn",
        name: t("app.common.CreatedOn"),
        isFormField: false,
      },
      { key: "statusName", name: t("app.common.Status"), isFormField: false },
      ...extractFormConfigFields(
        formSpec,
        true,
        excludeNonReadableFields,
        []
      ).map((spec) => ({
        key: spec.key,
        name: spec.label,
        isFormField: true,
      })),
    ];
  };

  if (formSpecAsync.data && listingConfigAsync.data && !listConfigRefined) {
    const config = listingConfigAsync.data.layout //no layout means no config record before
      ? { layout: deepClone(listingConfigAsync.data.layout) }
      : { layout: [] };

    getAvailableFields(formSpecAsync.data, false).forEach((field) => {
      if (!config.layout.find((configItem) => configItem.key === field.key)) {
        // add available fields no yet included in config
        config.layout.push({
          key: field.key,
          webListing: {
            disabled: true,
            order: 0,
          },
          appListing: {
            disabled: true,
            order: 0,
          },
          approvedRequest: {
            disabled: true,
            order: 0,
          },
          confidential: {
            disabled: true,
          },
        });
      }
    });
    setListConfigRefined(config);
  }

  const [saveInProgress, setSaveInProgress] = useState(false);

  const [tabKey, setTabKey] = useState("1");

  const handleConfigSave = async (config: RegisterListingConfig) => {
    setSaveInProgress(true);
    try {
      // turns out update is for different use cases
      // if (listingConfigAsync.data?.layout) {
      //   await updateRegisterListing(config, appContext.serviceConfig);
      // } else {
      await createRegisterListingConfig(config, appContext.serviceConfig);
      //}
      setListConfigRefined(config);
      notify.actionCompleted();
    } catch (ex) {
      appContext.errorHandler(ex, "save listing config");
    } finally {
      setSaveInProgress(false);
    }
  };

  if (listConfigRefined && formSpecAsync.data) {
    return (
      <AppAccess rolesAllowed="adminOnly" showWarning>
        <NxpHeader titleContent={t("SetupListing.Setup -Listing")} />
        <NxpTabs activeKey={tabKey} onChange={setTabKey}>
          <TabPane tab={t("SetupListing.WebListing -ColumnSelection")} key="1">
            <WebListing
              availableFields={getAvailableFields(formSpecAsync.data, true)}
              listConfig={listConfigRefined}
              saveInProgress={saveInProgress}
              onSave={handleConfigSave}
            />
          </TabPane>{" "}
          <TabPane tab={t("SetupListing.WebListing -ColumnWidth")} key="2">
            {tabKey === "2" && (
              <WebColumnWidth
                availableFields={getAvailableFields(formSpecAsync.data, true)}
                listConfig={listConfigRefined}
                saveInProgress={saveInProgress}
                onSave={handleConfigSave}
              />
            )}
          </TabPane>
          <TabPane tab={t("SetupListing.AppListing")} key="3">
            <AppListing
              availableFields={getAvailableFields(formSpecAsync.data, true)}
              listConfig={listConfigRefined}
              saveInProgress={saveInProgress}
              onSave={handleConfigSave}
            />
          </TabPane>
          <TabPane tab={t("app.common.KeyInformation")} key="4">
            <KeyInfo
              availableFields={getAvailableFields(formSpecAsync.data, false)}
              listConfig={listConfigRefined}
              saveInProgress={saveInProgress}
              onSave={handleConfigSave}
            />
          </TabPane>
          <TabPane tab={t("app.common.Confidential")} key="5">
            <ConfidentialFields
              availableFields={getAvailableFields(formSpecAsync.data, false)}
              listConfig={listConfigRefined}
              saveInProgress={saveInProgress}
              onSave={handleConfigSave}
            />
          </TabPane>
        </NxpTabs>
      </AppAccess>
    );
  } else {
    return null;
  }
};

export default SetupListing;
