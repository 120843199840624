import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  LazyLoadStatus,
  NxpButton,
  NxpHeader,
  NxpTableLazy,
  NxpTableOnChange,
} from "@nexploretechnology/nxp-ui";
import { Switch } from "antd";
import { ColumnProps } from "antd/lib/table";

import useAppContext from "../../../hooks/useAppContext";
import {
  enableDisablePrintable,
  getPrintablesList,
  PrintableStatus,
  PrintForm,
} from "../../../services/registerPrintables";

//hardcoding query for now
const query = {
  limit: 0,
  offset: 0,
  filters: [],
};

interface SetupPrintablesListProps {}

const SetupPrintablesList: React.FC<SetupPrintablesListProps> = () => {
  const appContext = useAppContext();
  const history = useHistory();
  const [lazyLoadStatus, setLazyLoadStatus] = useState(LazyLoadStatus.ready);
  const [printForms, setPrintFroms] = useState<{
    data: PrintForm[];
    limit: number;
    page: number;
    total: number;
  }>({ data: [], limit: 0, page: 0, total: 0 });

  const fetchData = useCallback(
    (newQuery: any) => {
      setLazyLoadStatus(LazyLoadStatus.loading);
      //@ts-ignore
      if (appContext.activeRegister) {
        getPrintablesList(
          appContext.activeRegister.id,
          appContext.serviceConfig,
          newQuery
        )
          .then((data) => {
            console.log(data);
            setLazyLoadStatus(LazyLoadStatus.ready);
            setPrintFroms(data);
          })
          .catch((ex) => {
            console.log(ex);
          });
      }
    },
    [appContext.serviceConfig, appContext.activeRegister]
  );

  const handleLazyLoad = useCallback(() => {
    fetchData(query);
  }, [fetchData]);

  // fetch on start
  useEffect(() => {
    if (
      appContext.activeRegister?.id &&
      lazyLoadStatus === LazyLoadStatus.ready
    ) {
      fetchData(query);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = useCallback<NxpTableOnChange<any>>(
    (_, __, tableSorter) => {
      console.log(tableSorter);
      let newQuery;
      if (
        tableSorter &&
        !Array.isArray(tableSorter) &&
        tableSorter.field &&
        tableSorter.order
      ) {
        newQuery = {
          ...query,
          sortColumn: tableSorter.field,
          sortOrder: tableSorter.order.substring(0, 3), //this doesn't work, always returns 'ascend'
        };
      }
      fetchData(newQuery);
    },
    [fetchData]
  );

  const onClickEdit = (record: any) => {
    appContext.onAppContextCacheItemUpdate("printForm", record);
    history.push("./print-forms/edit-printable");
  };

  const { t } = useTranslation();

  const tableColumns: ColumnProps<PrintForm>[] = [
    {
      title: t("app.common.Name"),
      dataIndex: "printFormName",
      width: 150,
      fixed: "left",
      sorter: true,
    },
    {
      title: t("SetupPrintables.Version"),
      dataIndex: "version",
      width: 150,
      sorter: true,
    },
    {
      title: t("app.common.Status"),
      dataIndex: "status",
      width: 300,
      render: (_, record) => (
        <span
          className={
            record.status === PrintableStatus.draft
              ? "status-greytext"
              : record.status === "valid"
              ? "status-greentext"
              : record.status === "superseded"
              ? "status-redtext"
              : "status-greytext"
          }
        >
          {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
        </span>
      ),
    },
    {
      title: `${t("SetupPrintables.Enabled")}?`,
      dataIndex: "enabled",
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.enabled}
          onChange={(e) => {
            enableDisablePrintable(
              record.registerId,
              record.id,
              !record.enabled,
              appContext.serviceConfig
            ).then(() => fetchData(query));
          }}
        ></Switch>
      ),
    },
    {
      title: t("app.common.Edit"),
      width: 150,
      render: (_, record) => (
        <NxpButton
          onClick={() => {
            onClickEdit(record);
          }}
        >
          {t("app.common.EDIT")}
        </NxpButton>
      ),
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={t("SetupPrintables.PrintableFormListing")}
        actionContent={
          <>
            <NxpButton
              onClick={() => {
                appContext.onAppContextCacheItemUpdate("printForm", undefined);
                history.push("./print-forms/edit-printable");
              }}
            >
              {t("SetupPrintables.NewPrintable")}
            </NxpButton>
          </>
        }
      />
      {(lazyLoadStatus === LazyLoadStatus.ready ||
        lazyLoadStatus === LazyLoadStatus.loading) && (
        <NxpTableLazy
          lazyLoadStatus={lazyLoadStatus}
          onLazyLoad={handleLazyLoad}
          onChange={handleTableChange}
          columns={tableColumns}
          dataSource={printForms.data}
          useDefaultHeight
          pagination={false}
        />
      )}
    </>
  );
};

export default SetupPrintablesList;
