import React from "react";
import { useTranslation } from "react-i18next";

import { StopOutlined } from "@ant-design/icons";
import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { Col, Form, Row } from "antd";

import { RegisterCustomFormField } from "../../../services/registerCustomForm";
import {
  PrintablePageValue,
  PrintableStatus,
} from "../../../services/registerPrintables";
import FieldList from "./FieldList";
import Paginator from "./Paginator";
import PrintablePageBuilder from "./PrintablePageBuilder";

import "./SetupPrintables.css";

interface SetupPrintablesLayoutProps {
  editMode: boolean;
  page: number;
  printablePageValues: PrintablePageValue[];
  handlePageChanged: (page: number) => void;
  fields: RegisterCustomFormField[];
  usedFields: boolean[];
  handleUploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReloadImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasImages: boolean;
  imageUrls: {
    [name: string]: string | undefined;
  };
  onClickCreateEdit: () => void;
  onClickDiscardForm: () => void;
  handlePrintablePageChanged: (printablePage: PrintablePageValue) => void;
  printableName: string;
  setPrintableName: React.Dispatch<React.SetStateAction<string>>;
  printableStatus: PrintableStatus;
  setPrintableStatus: React.Dispatch<React.SetStateAction<PrintableStatus>>;
}

const SetupPrintablesLayout: React.FC<SetupPrintablesLayoutProps> = ({
  editMode,
  page,
  printablePageValues,
  handlePageChanged,
  fields,
  usedFields,
  handleUploadImage,
  handleReloadImage,
  hasImages,
  imageUrls,
  onClickCreateEdit,
  onClickDiscardForm,
  handlePrintablePageChanged,
  printableName,
  setPrintableName,
  printableStatus,
  setPrintableStatus,
}) => {
  React.useEffect(() => console.log(imageUrls), [imageUrls]);

  const { t } = useTranslation();

  return (
    <div className="form-printable-body">
      <div className="form-printable-page">
        <Paginator
          page={page}
          totalCount={Math.max(printablePageValues.length, 1)}
          onPageChange={handlePageChanged}
          onPageClick={handlePageChanged}
        />
        <div className="form-printable-page-details">
          <Form>
            <Row gutter={24}>
              <Col span={6}>
                <NxpFormItem
                  controlType="input"
                  label="Template Name"
                  controlProps={{
                    value: printableName,
                    onChange: (e) => {
                      setPrintableName(e.target.value);
                    },
                  }}
                />
              </Col>
              <Col span={6}>
                <NxpFormItem
                  controlType="select"
                  controlProps={{
                    value: printableStatus,
                    options: [
                      {
                        label: t("SetupPrintablesLayout.Draft"),
                        value: PrintableStatus.draft,
                      },
                      {
                        label: t("SetupPrintablesLayout.Valid"),
                        value: PrintableStatus.valid,
                      },
                    ],
                    onChange: (e) => {
                      if (e === PrintableStatus.draft) {
                        setPrintableStatus(PrintableStatus.draft);
                      } else {
                        setPrintableStatus(PrintableStatus.valid);
                      }
                    },
                  }}
                  label={t("app.common.Status")}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div className="form-printable-container">
        <FieldList fields={fields} useList={usedFields}></FieldList>
        <div className="form-printable-main">
          <div className="form-printable-action-bar">
            <div className="form-printable-action-button">
              <div className="form-printable-action-button-inner">
                {printablePageValues.length > 0 ? (
                  <button
                    className="form-printable-action-discard-btn"
                    onClick={onClickDiscardForm}
                  >
                    <StopOutlined />
                    {t("SetupPrintablesLayout.DiscardForm")}
                  </button>
                ) : null}
                {hasImages ? (
                  <label className="form-printable-action-reload-image">
                    <input
                      className="form-printable-action-reload-image-hidden-input"
                      type="file"
                      name="images"
                      accept="image/png, image/jpeg"
                      onChange={handleReloadImage}
                    />
                    {t("SetupPrintablesLayout.ReuploadImage")}
                  </label>
                ) : null}
                {printablePageValues.length > 0 ? (
                  <label className="form-printable-action-new-page">
                    <input
                      className="form-printable-action-new-page-hidden-input"
                      type="file"
                      multiple
                      name="images"
                      accept="image/png, image/jpeg"
                      onChange={handleUploadImage}
                    />
                    {t("SetupPrintablesLayout.AddPage")}
                  </label>
                ) : null}
              </div>
              <button
                className="form-printable-action-create"
                onClick={onClickCreateEdit}
              >
                {editMode
                  ? t("SetupPrintablesLayout.UpdatePrintableForm")
                  : t("app.common.CreatePrintableForm")}
              </button>
            </div>
          </div>
          {printablePageValues[page] &&
          imageUrls[printablePageValues[page].imageName] ? (
            <PrintablePageBuilder
              fields={fields}
              page={printablePageValues[page]}
              imageUrl={imageUrls[printablePageValues[page].imageName]}
              onPageChange={handlePrintablePageChanged}
            />
          ) : (
            <div className="form-upload-image">
              <label htmlFor="image-upload" className="form-upload-image-btn">
                +
              </label>
              <input
                id="image-upload"
                type="file"
                multiple
                name="images"
                accept="image/png, image/jpeg"
                onChange={handleUploadImage}
              />
              <div className="form-upload-image-text">
                {t("SetupPrintablesLayout.UploadImage")}
              </div>
            </div>
          )}
          {/* {isLoading && (
            <Overlay className="form-upload-image-overlay">
              <Spinner size={SpinnerSize.large} />
            </Overlay>
          )} */}
          {/* ^ cannot use this, need to use NXP version */}
        </div>
      </div>
    </div>
  );
};

export default SetupPrintablesLayout;
