import React from "react";
import { useTranslation } from "react-i18next";

import {
  DateFormatPattern,
  NxpDateDisplay,
  NxpHeader,
  NxpTableLazy,
  NxpTableOnChange,
  NxpTabs,
  TabPane,
  useDefaultTableScrollHeight,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";

import { User } from "../../services/app";
import {
  ApprovalListType,
  FormEntryApproval,
} from "../../services/registerFormEntryApproval";
import {
  ApprovalContainerStatusFilter,
  ApprovalsContainerState,
} from "./ApprovalsContainer";

interface ApprovalsLayoutProps {
  registerId?: number;
  listType: ApprovalListType;
  statusFilter: ApprovalContainerStatusFilter;
  approvalsState: ApprovalsContainerState;
  onTabChange: (key: string) => void;
  onLazyLoad: () => void;
  onTableChange: NxpTableOnChange<FormEntryApproval>;
  onRowClick: (record: FormEntryApproval) => void;
}

const ApprovalsLayout: React.FC<ApprovalsLayoutProps> = ({
  registerId,
  listType,
  statusFilter,
  approvalsState,
  onTabChange,
  onLazyLoad,
  onTableChange,
  onRowClick,
}) => {
  let headerTitle = "";
  let columns = [];

  const { t } = useTranslation();

  const allColumns: ColumnProps<FormEntryApproval>[] = [
    {
      title: t("ApprovalsLayout.RegisterId"),
      dataIndex: "registerId",
      width: 100,
      sorter: true,
    },
    {
      title: t("ApprovalsLayout.RequestOn"),
      dataIndex: "createdOn",
      width: 100,
      sorter: true,
      render: (val) => (
        <NxpDateDisplay date={val} format={DateFormatPattern.dateWithTime} />
      ),
    },
    {
      title: t("app.common.FormReference"),
      dataIndex: ["entry", "formNumber"],
      width: 100,
      sorter: true,
    },
    {
      title: t("ApprovalsLayout.Title"),
      dataIndex: ["entry", "formName"],
      width: 100,
      sorter: true,
    },
    {
      title: t("app.common.Status"),
      dataIndex: ["currentStatus", "statusName"],
      width: 100,
      sorter: true,
    },
    {
      title: t("ApprovalsLayout.NowPendingAt"),
      dataIndex: "pendingApprovers",
      width: 100,
      sorter: true,
      render: (val) =>
        val?.length &&
        val
          .map((user: User) => {
            return user.firstName + " " + user.lastName;
          })
          .join(", "),
    },
    {
      title: t("ApprovalsLayout.FinalApprover"),
      dataIndex: "finalApprovers",
      width: 100,
      sorter: true,
      render: (val) =>
        val?.length &&
        val
          .map((user: User) => {
            return user.firstName + " " + user.lastName;
          })
          .join(", "),
    },
    {
      title: t("ApprovalsLayout.TotalDaySpent"),
      dataIndex: "totalDaySpent",
      width: 100,
      sorter: true,
    },
  ];
  switch (listType) {
    case "allApprovals":
      headerTitle = t("app.common.Approvals");
      if (statusFilter === "pending") {
        columns = allColumns.filter((col) =>
          [
            "registerId",
            "createdOn",
            "entry,formNumber",
            "entry,formName",
            "currentStatus,statusName",
            "pendingApprovers",
            "finalApprovers",
          ].includes(col.dataIndex!.toString())
        );
      } else {
        columns = allColumns.filter((col) =>
          [
            "registerId",
            "createdOn",
            "entry,formNumber",
            "entry,formName",
            "currentStatus,statusName",
          ].includes(col.dataIndex!.toString())
        );
      }
      break;
    case "myApprovals":
      headerTitle = t("ApprovalsLayout.MyOutstandingApprovals");
      columns = allColumns.filter((col) =>
        [
          "registerId",
          "createdOn",
          "entry,formNumber",
          "entry,formName",
          "currentStatus,statusName",
        ].includes(col.dataIndex!.toString())
      );
      break;
    case "myRequests":
      headerTitle = t("app.common.MyRequests");
      if (statusFilter === "pending") {
        columns = allColumns.filter((col) =>
          [
            "registerId",
            "createdOn",
            "entry,formNumber",
            "entry,formName",
            "currentStatus,statusName",
            "pendingApprovers",
          ].includes(col.dataIndex!.toString())
        );
      } else {
        columns = allColumns.filter((col) =>
          [
            "registerId",
            "createdOn",
            "entry,formNumber",
            "entry,formName",
            "currentStatus,statusName",
            "totalDaySpent",
          ].includes(col.dataIndex!.toString())
        );
      }
      break;
  }

  if (registerId) {
    columns = columns.filter((col) => col.dataIndex !== "registerId");
  }

  const tableHeight = useDefaultTableScrollHeight(false) - 55;

  return (
    <>
      <NxpHeader titleContent={headerTitle} />
      {listType !== "myApprovals" && (
        <NxpTabs activeKey={statusFilter} onChange={onTabChange}>
          <TabPane tab={t("ApprovalsLayout.Pending")} key="pending" />
          <TabPane tab={t("ApprovalsLayout.Completed")} key="completed" />
        </NxpTabs>
      )}
      <NxpTableLazy
        rowKey="id"
        lazyLoadStatus={approvalsState.lazyloadStatus}
        onLazyLoad={onLazyLoad}
        onChange={onTableChange}
        columns={columns}
        dataSource={approvalsState.data}
        scroll={{ y: tableHeight }}
        onRow={(record) => ({
          onClick: () => {
            onRowClick(record);
          },
        })}
      />
    </>
  );
};

export default ApprovalsLayout;
