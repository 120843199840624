import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";

import { RegisterDocument } from "../../../../services/registerDocument";
import AddRegisterDocumentModal from "./AddRegisterDocumentModal";

interface AddRegisterDocumentButtonProps {
  registerDocuments: RegisterDocument[];
  printFormOptions: LabeledValue[];
  fileExtensionOptions: LabeledValue[];
  onDocumentAdded: (doc: RegisterDocument) => void;
}

const AddRegisterDocumentButton: React.FC<AddRegisterDocumentButtonProps> = ({
  registerDocuments,
  printFormOptions,
  fileExtensionOptions,
  onDocumentAdded,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <NxpButton onClick={handleClick}>
        {t("AddRegisterDocumentButton.AddDocument")}
      </NxpButton>
      {modalVisible && (
        <AddRegisterDocumentModal
          registerDocuments={registerDocuments}
          printFormOptions={printFormOptions}
          fileExtensionOptions={fileExtensionOptions}
          modalVisible={modalVisible}
          onDocumentAdded={onDocumentAdded}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AddRegisterDocumentButton;
