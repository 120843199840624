import React, { useCallback, useEffect, useState } from "react";
import deepClone from "lodash/cloneDeep";

import AppListBuilder from "../../../../components/AppListBuilder";
import { RegisterListingConfig } from "../../../../services/registerListing";
import { useTranslation } from "react-i18next";

interface WebListingProps {
  listConfig: RegisterListingConfig;
  availableFields: { key: string; name: string; isFormField: boolean }[];
  saveInProgress: boolean;
  onSave: (config: RegisterListingConfig) => void;
}

export interface BuilderItem {
  key: string;
  name: string;
  selected: boolean;
}

const WebListing: React.FC<WebListingProps> = ({
  listConfig,
  availableFields,
  saveInProgress,
  onSave,
}) => {
  const [builderItems, setBuilderItems] = useState<BuilderItem[]>([]);

  const initBuilderItems = useCallback(() => {
    setBuilderItems(
      (listConfig.layout || [])
        .filter((configItem) =>
          availableFields.find((field) => field.key === configItem.key)
        )
        .sort((a, b) => a.webListing.order - b.webListing.order)
        .map((configItem) => ({
          key: configItem.key,
          name:
            availableFields.find((field) => field.key === configItem.key)
              ?.name || "--",
          selected: !configItem.webListing.disabled,
        }))
    );
  }, [availableFields, listConfig]);

  useEffect(() => {
    initBuilderItems();
  }, [initBuilderItems, listConfig]);

  const handleSave = (builderItems: BuilderItem[]) => {
    const updateListConfig = deepClone(listConfig);

    builderItems
      .filter((item) => item.selected)
      .forEach((builderItem, idx) => {
        const configItem = (updateListConfig.layout || []).find(
          (configItem) => configItem.key === builderItem.key
        );
        if (configItem) {
          configItem.webListing.disabled = false;
          configItem.webListing.order = idx + 1;
        }
      });

    builderItems
      .filter((item) => !item.selected)
      .forEach((builderItem) => {
        const configItem = (updateListConfig.layout || []).find(
          (configItem) => configItem.key === builderItem.key
        );
        if (configItem) {
          configItem.webListing.disabled = true;
          configItem.webListing.order = 0;
        }
      });

    onSave(updateListConfig);
  };

  const { t } = useTranslation();

  return (
    <AppListBuilder
      leftHeader={t("app.common.HiddenFields")}
      rightHeader={t("app.common.Showing")}
      initBuilderItems={builderItems}
      onSave={handleSave}
      saveInProgress={saveInProgress}
    />
  );
};

export default WebListing;
