import * as React from "react";
import { useRef } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { Rnd, RndDragCallback, RndResizeCallback } from "react-rnd";

import deleteSVG from "../../../images/trash.svg";
import editSVG from "../../../images/gear.svg";
import cornerSVG from "../../../images/corner.svg";

import {
  ItemType,
  makePrintableComponent,
  PageBuilderWidth,
  PrintableComponentValue,
  PrintablePageValue,
} from "../../../services/registerPrintables";

import { EditComponentModal } from "./EditComponentModal";

export interface PrintablePageBuilderProps {
  fields: any[];
  page: PrintablePageValue;
  imageUrl: string | undefined;
  onPageChange: (page: PrintablePageValue) => void;
}

export const PrintablePageBuilder: React.FC<PrintablePageBuilderProps> = (
  props
) => {
  const { fields, page, imageUrl, onPageChange } = props;
  const imageRef = useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemType.component,
    drop: (component: any, monitor: DropTargetMonitor) => {
      const formComponent = findFormComponent(fields, component.id);
      if (formComponent == null) {
        console.warn(`Cannot find form component with key = ${component.id}`);

        return;
      }

      const position = {
        x:
          //@ts-ignore
          monitor.getSourceClientOffset().x -
          //@ts-ignore
          imageRef.current.getBoundingClientRect().x,
        y:
          //@ts-ignore
          monitor.getSourceClientOffset().y -
          //@ts-ignore
          imageRef.current.getBoundingClientRect().y,
      };

      onPageChange({
        ...page,
        components: [
          ...page.components,
          makePrintableComponent(formComponent, position),
        ],
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [editingComponentIndex, setEditingComponentIndex] = React.useState<
    number | undefined
  >(undefined);

  const handleComponentEditClick = React.useCallback(
    (index: number) => {
      setEditingComponentIndex(index);
    },
    [setEditingComponentIndex]
  );
  const handleEditComponentModalClose = React.useCallback(() => {
    setEditingComponentIndex(undefined);
  }, [setEditingComponentIndex]);

  const handleComponentChange = React.useCallback(
    (index: number, printableComponent: PrintableComponentValue) => {
      const printableComponents = [...page.components];
      printableComponents[index] = printableComponent;

      onPageChange({
        ...page,
        components: printableComponents,
      });
    },
    [page, onPageChange]
  );

  const handleComponentDeleteClick = React.useCallback(
    (index: number) => {
      const printableComponents = page.components ? [...page.components] : [];
      printableComponents.splice(index, 1);

      onPageChange({
        ...page,
        components: printableComponents,
      });
    },
    [page, onPageChange]
  );

  const handleComponentEdit = React.useCallback(
    (component: PrintableComponentValue) => {
      const printableComponents = page.components ? [...page.components] : [];
      const index = printableComponents.findIndex(
        (c) => c.subId === component.subId
      );
      if (index === -1) {
        console.warn("Failed to update edited component", component);
      }

      printableComponents[index] = component;
      onPageChange({
        ...page,
        components: printableComponents,
      });
      setEditingComponentIndex(undefined);
    },
    [page, onPageChange]
  );

  const printableComponents = page.components ? page.components : [];
  const opacity: number = isOver ? 0.8 : canDrop ? 0.5 : 1.0;

  return (
    <div
      ref={drop}
      style={{
        width: PageBuilderWidth,
        opacity: opacity,
      }}
      className="form-preview-image"
    >
      <img src={imageUrl} alt="preview" ref={imageRef} />
      {page.components
        ? page.components.map((c, index) => {
            return (
              <ResizablePrintableComponent
                key={c.subId}
                index={index}
                printableComponent={c}
                onComponentChange={handleComponentChange}
                onDeleteClick={handleComponentDeleteClick}
                onEditClick={handleComponentEditClick}
              />
            );
          })
        : null}
      {editingComponentIndex != null && (
        <EditComponentModal
          value={printableComponents[editingComponentIndex]}
          onClose={handleEditComponentModalClose}
          onSave={handleComponentEdit}
        />
      )}
    </div>
  );
};

function findFormComponent(
  formComponents: any[],
  key: string
): any | undefined {
  return formComponents.find((c) => c.key === key);
}

interface ResizablePrintableComponentProps {
  printableComponent: PrintableComponentValue;
  // index of printableComponent in the list
  index: number;
  onComponentChange: (
    index: number,
    printableComponent: PrintableComponentValue
  ) => void;
  onEditClick: (index: number) => void;
  onDeleteClick: (index: number) => void;
}

const ResizablePrintableComponent: React.FC<ResizablePrintableComponentProps> =
  (props) => {
    const {
      index,
      printableComponent,
      onComponentChange,
      onEditClick,
      onDeleteClick,
    } = props;

    const handleDropStop: RndDragCallback = React.useCallback(
      (e, data) => {
        onComponentChange(index, {
          ...printableComponent,
          x: data.x,
          y: data.y,
        });
      },
      [printableComponent, onComponentChange, index]
    );

    const handleResizeStop: RndResizeCallback = React.useCallback(
      (e, direction, ref, _position) => {
        onComponentChange(index, {
          ...printableComponent,
          sizeWidth: parseInt(ref.style.width, 10),
          sizeHeight: parseInt(ref.style.height, 10),
        });
      },
      [printableComponent, onComponentChange, index]
    );

    const handleEditClick = React.useCallback(() => {
      onEditClick(index);
    }, [index, onEditClick]);

    const handleDeleteClick = React.useCallback(() => {
      onDeleteClick(index);
    }, [index, onDeleteClick]);

    return (
      <Rnd
        className="form-printable-exist-component"
        size={{
          width: printableComponent.sizeWidth,
          height: printableComponent.sizeHeight,
        }}
        position={{ x: printableComponent.x, y: printableComponent.y }}
        bounds=".form-preview-image"
        onDragStop={handleDropStop}
        onResizeStop={handleResizeStop}
      >
        <div className="form-printable-component-container">
          <span className="form-printable-exist-component-name">
            {printableComponent.component.key}
          </span>
          <span className="form-printable-exist-component-type">
            {printableComponent.component.type}
          </span>
          <div className="form-printable-exist-component-corner">
            <img src={cornerSVG} alt="Corner" />
          </div>
          <div className="form-printable-component-tool">
            <button
              className="form-printable-component-edit"
              onClick={handleEditClick}
            >
              <img src={editSVG} alt="Edit" />
            </button>
            <button
              className="form-printable-component-delete"
              onClick={handleDeleteClick}
            >
              <img src={deleteSVG} alt="Delete" />
            </button>
          </div>
        </div>
      </Rnd>
    );
  };

export default PrintablePageBuilder;
