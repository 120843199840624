import React, { ReactNode, useRef, useState } from "react";
import { Form } from "react-formio";
import { useTranslation } from "react-i18next";

import { NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import {
  filterFormConfigInputByKeys,
  prepareFormContentForSubmit,
} from "../../../services/registerCustomForm";
import { getRegisterFormEntry } from "../../../services/registerFormEntry";
import {
  approveFormEntryApproval,
  FormEntryApproval,
  rejectFormEntryApproval,
} from "../../../services/registerFormEntryApproval";
import { FORMIO_DEFAULT_OPTIONS } from "../../../utils/const";
import notify from "../../../utils/notify";
import ApprovalUpdateModalLayout from "./ApprovalUpdateModalLayout";

interface ApprovalUpdateModalContainerProps {
  approval: FormEntryApproval;
  modalVisible: boolean;
  onApprovalUpdated: () => void;
  onModalClose: () => void;
}

export interface ApprovalUpdateModalForm {
  remarks: string;
  formioContent?: any; // for capturing changes in formio Form
}

const ApprovalUpdateModalContainer: React.FC<ApprovalUpdateModalContainerProps> =
  ({ approval, modalVisible, onApprovalUpdated, onModalClose }) => {
    const appContext = useAppContext();

    const initFormValues = {
      remarks: "",
    };

    const [formEntryAsync] = useAsync(
      () =>
        getRegisterFormEntry(
          approval.entryId,
          approval.registerId,
          appContext.serviceConfig,
          appContext.activeUser?.keycloakUserId
        ),
      undefined,
      "get form entry",
      true
    );

    const formNodeRef = useRef<ReactNode>(null);
    const formRef = useRef<any>(null);
    const [editForm, setEditForm] =
      useState<ApprovalUpdateModalForm>(initFormValues);

    const [saveInProgress, setSaveInProgress] = useState(false);

    const { t } = useTranslation();

    const handleSave = async (isReject: boolean) => {
      setSaveInProgress(true);

      try {
        const formioContent = prepareFormContentForSubmit(
          editForm.formioContent,
          formEntryAsync.data?.formConfig.definitions || []
        );

        if (isReject) {
          await rejectFormEntryApproval(
            approval.id,
            approval.entryId,
            editForm.remarks,
            formioContent,
            appContext.serviceConfig
          );
        } else {
          await approveFormEntryApproval(
            approval.id,
            approval.entryId,
            editForm.remarks,
            formioContent,
            appContext.serviceConfig,
            approval.registerId
          );
        }
        onApprovalUpdated();
        notify.success(
          t("ApprovalUpdateModalContainer.ApprovalUpdateSubmitted")
        );
      } catch (ex) {
        console.error(ex);
        appContext.errorHandler(ex, "update approval request");
      } finally {
        setSaveInProgress(false);
      }
    };

    const handleFormGridStateChange = (
      fieldName: keyof ApprovalUpdateModalForm,
      value: unknown
    ) => {
      setEditForm((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    };

    const formItems: NxpFormGridItemProps<ApprovalUpdateModalForm>[] = [
      {
        controlType: "textarea",
        label: t("app.common.Remarks"),
        itemFieldName: "remarks",
        span: 24,
      },
    ];

    if (
      formEntryAsync.data?.formConfig.definitions.length &&
      formNodeRef.current === null
    ) {
      const initFormioTriggerChange = () => {
        // need to call formio.triggerChange() to get the custom controls to work
        setTimeout(() => {
          if (formRef.current?.formio?.triggerChange) {
            formRef.current.formio.triggerChange();
          } else {
            initFormioTriggerChange();
          }
        }, 500);
      };

      const compos = filterFormConfigInputByKeys(
        { components: formEntryAsync.data.formConfig.definitions },
        approval.currentStep.editFields,
        []
      ).components;

      formNodeRef.current = (
        <Form
          ref={formRef}
          form={{
            components: compos,
          }}
          submission={{
            data: formEntryAsync.data.contentDetails,
          }}
          options={FORMIO_DEFAULT_OPTIONS}
          onChange={(form: any) => {
            setEditForm((prevState) => ({
              ...prevState,
              formioContent: form.data,
            }));
          }}
        />
      );

      initFormioTriggerChange();
    }

    return (
      <ApprovalUpdateModalLayout
        modalVisible={modalVisible}
        saveInProgress={saveInProgress}
        editFields={approval.currentStep.editFields}
        formEntry={formEntryAsync.data}
        formNodeRef={formNodeRef}
        formItems={formItems}
        editForm={editForm}
        onFormGridStateChange={handleFormGridStateChange}
        onSave={handleSave}
        onModalClose={onModalClose}
      />
    );
  };

export default ApprovalUpdateModalContainer;
