import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormTable,
  NxpFormTableColumnProps,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import mime from "mime-types";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useValidate } from "../../../hooks/useValidate";
import {
  deleteFormEntryAttachment,
  FormAttachment,
  FormAttachmentForm,
  getFormEntryAttachmentList,
  updateFormEntryAttachment,
} from "../../../services/registerFormEntry";
import notify from "../../../utils/notify";
import AddAttachmentModalButton from "./AddAttachmentModal/AddAttachmentModalButton";

const formSchema = yup.object().shape({
  subject: yup.string().nullable().required("Subject required."),
  file: yup.object(),
});

interface FormEntryAttachmentsProps {
  formEntryId: number;
}

const FormEntryAttachments: React.FC<FormEntryAttachmentsProps> = ({
  formEntryId,
}) => {
  const appContext = useAppContext();
  const [formAttachments, setFormAttachments] = useState<FormAttachment[]>([]);

  useEffect(() => {
    getFormEntryAttachmentList(formEntryId, appContext.serviceConfig)
      .then((data) => setFormAttachments(data))
      .catch((ex) => appContext.errorHandler(ex, "get form entry attachments"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editItem, setEditItem] = useState<FormAttachment>();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const { t } = useTranslation();

  const columns: NxpFormTableColumnProps<FormAttachment>[] = [
    {
      title: t("app.common.Subject"),
      dataIndex: "subject",
      width: 150,
      sorter: (a, b) => sorterForString(a.subject, b.subject),
      formItemProps: {
        controlType: "input",
      },
    },
    {
      title: t("app.common.FileName"),
      dataIndex: ["file", "fileName"],
      width: 100,
      sorter: (a, b) => sorterForString(a.file?.fileName, b.file?.fileName),
      render: (_, rec) => (
        <a href={rec.file?.url} rel="noopener noreferrer" target="_blank">
          {rec.file?.fileName}
        </a>
      ),
    },
    {
      title: t("app.common.FileType"),
      dataIndex: ["file", "mimeType"],
      width: 100,
      sorter: (a, b) => sorterForString(a.file?.mimeType, b.file?.mimeType),
      render: (val: string) => mime.extension(val).toString().toUpperCase(),
    },
    {
      title: t("FormEntryAttachments.FileSize"),
      dataIndex: "fileSize",
      width: 100,
      sorter: (a, b) => sorterForString(a.fileSize, b.fileSize),
    },
  ];

  const handleSaveValidated = async () => {
    if (editItem) {
      const attachment = await updateFormEntryAttachment(
        editItem.id,
        formEntryId,
        { subject: editItem.subject, file: editItem.file },
        appContext.serviceConfig
      );
      setSaveInProgress(true);
      setFormAttachments((prevState) =>
        prevState.map((item) => (item.id === attachment.id ? attachment : item))
      );
      try {
        setEditItem(undefined);
        notify.actionCompleted();
      } catch (ex) {
        appContext.errorHandler(ex, "update form entry attachments");
      } finally {
        setSaveInProgress(false);
      }
    }
  };

  const [validationError, , clearError, saveWithValidate] =
    useValidate<FormAttachmentForm>(editItem, formSchema, handleSaveValidated);

  const handleRowEdit = useCallback(
    (editItem: FormAttachment) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowDelete = useCallback(
    async (deleteItem: FormAttachment) => {
      setSaveInProgress(true);
      try {
        if (editItem) {
          await deleteFormEntryAttachment(
            editItem.id,
            formEntryId,
            appContext.serviceConfig
          );
          setFormAttachments((prevState) =>
            prevState.filter((item) => item.id !== editItem.id)
          );
          setEditItem(undefined);
          notify.actionCompleted();
        }
      } catch (ex) {
        appContext.errorHandler(ex, "delete form entry attachment");
      } finally {
        setSaveInProgress(false);
      }
    },
    [appContext, editItem, formEntryId]
  );

  const handleFormStateChange = useCallback(
    (fieldName: keyof FormAttachment, value: unknown) => {
      // console.log('ccccccccccccccc', fieldName, value);

      setEditItem((prevState) => ({
        ...prevState!,
        [fieldName]: value,
      }));
    },
    []
  );

  const handleAttachmentAdded = (attachment: FormAttachment) => {
    setFormAttachments((prevState) => [attachment, ...prevState]);
  };

  return (
    <div>
      {appContext.registerAccessList["register-entry:update"] &&
      appContext.hasAccess("writable") ? (
        <AddAttachmentModalButton
          formEntryId={formEntryId}
          onAttachmentAdded={handleAttachmentAdded}
        />
      ) : undefined}
      <NxpFormTable
        formState={editItem}
        onFormStateChange={handleFormStateChange}
        validationError={validationError as object}
        useDefaultHeight
        saveInProgress={saveInProgress}
        itemCompareKey="id"
        onRowDelete={handleRowDelete}
        onRowEdit={handleRowEdit}
        onRowSave={handleRowSave}
        onRowCancel={handleRowCancel}
        columns={columns}
        dataSource={formAttachments}
      />
    </div>
  );
};

export default FormEntryAttachments;
