import { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useReactToPrint } from "react-to-print";

import {
  NxpButton,
  NxpButtonWithConfirm,
  NxpButtonWithDropdown,
  NxpPanel,
} from "@nexploretechnology/nxp-ui";
import { Menu } from "antd";

import useAppContext from "../../../hooks/useAppContext";
import { PrintForm } from "../../../services/registerPrintables";
import { FormEntrySummaryForm } from "./FormEntrySummaryContainer";

const useStyles = createUseStyles((theme) => ({
  formPanel: {
    "& .form-control:disabled, .form-control[readonly]": {
      backgroundColor: theme.palette.dimLight,
    },
    "& .formio-disabled": {
      "& input[placeholder='yyyy-MM-dd hh:mm a']::placeholder, & input[placeholder='yyyy-MM-dd']::placeholder":
        {
          color: theme.palette.dimLight,
        },

      "&.formio-component.formio-component-datagrid": {
        pointerEvents: "auto",
        "& > .datagrid-table > tfoot,  & > .datagrid-table .formio-button-remove-row, & > .datagrid-table > thead > tr > th:last-child, & > .datagrid-table > tbody > tr > td:last-child":
          {
            display: "none",
          },
      },

      "&.formio-component-fileUpload": {
        "& .file-uploader-container .uploader": {
          backgroundColor: theme.palette.dimLight,
          color: theme.palette.textLabel,
          pointerEvents: "none",
        },
      },

      "&:not(.formio-component-fileUpload)": {
        pointerEvents: "none",
      },

      "& .signature-pad-refresh::after": {
        content: "'read-only'",
        color: theme.palette.text,
      },
      "& .signature-pad-footer": {
        display: "none",
      },
    },
  },
  topButtons: {
    position: "absolute",
    margin: "-55px 0 36px",
    right: 0,
  },
}));

interface FormEntrySummaryLayoutProps {
  formReference: string;
  printables: PrintForm[];
  editForm: FormEntrySummaryForm;
  statusAlert?: ReactNode;
  formNode?: ReactNode;
  onCopyClick: () => void;
  onSave: (e: React.MouseEvent) => void;
  onCancelConfirm: () => void;
  onPrintFormSelected: (printFormId: number) => void;
}

const FormEntrySummaryLayout: React.FC<FormEntrySummaryLayoutProps> = ({
  formReference,
  printables,
  editForm,
  statusAlert,
  formNode,
  onCopyClick,
  onSave,
  onCancelConfirm,
  onPrintFormSelected,
}) => {
  const classes = useStyles();
  const appContext = useAppContext();
  const defaultPrintoutRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const handleDefaultPrintout = useReactToPrint({
    documentTitle: `${formReference} - ${t(
      "FormEntrySummaryLayout.DefaultPrintout"
    )}`,
    content: () => defaultPrintoutRef.current,
    onBeforeGetContent: () => {},
    copyStyles: true,
    onAfterPrint: () => {},
    onPrintError: (ex) => appContext.errorHandler(ex, "Form default printout"),
    pageStyle: `
      @media print {
       .nxpPanel button{
        display: none
        }
      }
    `,
  });

  return (
    <>
      <div className={classes.topButtons}>
        {appContext.registerAccessList["register-entry:update"] &&
        appContext.hasAccess("writable") ? (
          <NxpButtonWithConfirm
            danger
            onConfirm={onCancelConfirm}
            modalContent={t(
              "FormEntrySummaryLayout.ProceedToCancelThisFormEntry?"
            )}
            disabled={editForm.cancelStatus}
          >
            {t("FormEntrySummaryLayout.CancelForm")}
          </NxpButtonWithConfirm>
        ) : undefined}
        {appContext.registerAccessList["register-entry:create"] &&
        appContext.hasAccess("writable") ? (
          <NxpButton onClick={onCopyClick}>
            {t("FormEntrySummaryLayout.CopyAsNew")}
          </NxpButton>
        ) : undefined}
        <NxpButtonWithDropdown
          menu={
            <Menu>
              <Menu.Item onClick={handleDefaultPrintout}>
                {t("FormEntrySummaryLayout.DefaultPrintout")}
              </Menu.Item>
              {printables.map((printForm, i) => {
                return (
                  <Menu.Item
                    key={i}
                    onClick={() => {
                      onPrintFormSelected(printForm.id);
                    }}
                  >
                    {printForm.printFormName}
                  </Menu.Item>
                );
              })}
            </Menu>
          }
        >
          {t("app.common.Print")}
        </NxpButtonWithDropdown>
      </div>
      {statusAlert}
      <div ref={defaultPrintoutRef}>
        <NxpPanel
          disableCollapse
          titleContent={`${t("app.common.Form")}: ${formReference}`}
          className={classes.formPanel}
          extra={
            appContext.registerAccessList["register-entry:update"] &&
            appContext.hasAccess("writable") && (
              <NxpButton
                disabled={
                  editForm.cancelStatus ||
                  (!editForm.defaultStatus && !editForm.allowEdit)
                }
                onClick={onSave}
              >
                {t("app.common.Save")}
              </NxpButton>
            )
          }
        >
          {formNode}
        </NxpPanel>
      </div>
    </>
  );
};

export default FormEntrySummaryLayout;
