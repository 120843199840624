import { FilterComparer } from "../components/AppFilter/AppFilter";
import { apiRequest, ServiceConfig } from "../utils/backend";
import { buildQueryString, QueryParams } from "../utils/backend/query";
import { User } from "./app";
import { UploadFile } from "./file";
import { RegisterFlowStep } from "./registerFlow";
import { RegisterFormEntry } from "./registerFormEntry";
import { RegisterStatus } from "./registerStatus";

export interface FormEntryApprovalStep {
  approvalInstanceId: number;
  approveStatus: RegisterStatus;
  approveStatusId: number;
  completedOn: Date;
  approvers: {
    id: number;
    approvalInstanceId: number;
    approvalStepId: number;
    approved: boolean;
    approver: User;
    rejected: boolean;
    remarks: string;
    updatedOn: Date;
  }[];
  delegates: User[];
  createdOn: Date;
  editFields: string[];
  id: number;
  order: number;
  parallelApprove: boolean;
  rejectStatus: RegisterStatus;
  rejectStatusId: number;
  remarks: string;
  rolecode: string;
  startedOn: Date;
  stepApproved: boolean;
  stepRejected: boolean;
  stepWithdrawn: boolean;
  stepReleased: boolean;
  stepName: string;
  stepStatus: RegisterStatus;
  stepStatusId: number;
  updatedOn: Date;
  approvingUsers: User[];
}

export interface FormEntryApproval {
  id: number;
  entityId: number;
  registerId: number;

  currentStepId: number;
  currentStep: FormEntryApprovalStep;
  approvalSteps?: FormEntryApprovalStep[];
  remarks: string;
  isCompleted: boolean;
  entryId: number;
  entry: RegisterFormEntry;

  deleted: boolean;
  rejected: boolean;
  withdrawn: boolean;
  currentStatusId: number;
  currentStatus: RegisterStatus;

  content: RegisterFormEntry["contentDetails"];

  submitUser: User;
  pendingApprovers: User[];
  notifyUsers: User[];
  approvalDocuments: {
    file: UploadFile;
    isPrintFormFile: boolean;
    registerDocumentId: number;
    updatedOn: Date;
  }[];

  totalDaySpent: number;
  createdOn: Date;
  updatedOn: Date;
}

export const getFormEntryApprovalList = async (
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  const approvals = await apiRequest<FormEntryApproval[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/approvals`,
    serviceConfig
  );

  approvals.forEach((appr) => {
    appr.submitUser.name = `${appr.submitUser.firstName} ${appr.submitUser.lastName}`;
    appr.notifyUsers.forEach((user) => {
      user.name = `${user.firstName} ${user.lastName}`;
    });
  });

  return approvals;
};

export type ApprovalListType = "myApprovals" | "myRequests" | "allApprovals";

// export const getMyApprovalList = async (
//   query: QueryParams,
//   serviceConfig: ServiceConfig
// ) => {
//   const refinedQuery = {
//     limit: query.limit,
//     offset: query.offset,
//   };

//   if (query.sorters.length) {
//     Object.assign(refinedQuery, {
//       sortColumn: query.sorters[0].column,
//       sortOrder: query.sorters[0].order,
//     });
//   }
//   return await apiRequest<{
//     results: FormEntryApproval[];
//     pagination: {
//       limit: number;
//       offset: number;
//       countAll: number;
//     };
//   }>(
//     `/powerflow/approvals/current_user${buildQueryString({
//       query: refinedQuery,
//     })}`,
//     serviceConfig
//   );
// };

export const getApprovalList = async (
  listType: ApprovalListType,
  query: QueryParams,
  serviceConfig: ServiceConfig
) => {
  const refinedQuery = {
    limit: query.limit,
    offset: query.offset,
  };

  if (query.filters.length) {
    Object.assign(refinedQuery, {
      filters: query.filters.map((filter) => {
        return {
          column: filter.filterColumn.fieldName,
          type: Object.entries(FilterComparer).find(
            (entry) => entry[1] === filter.filterComparer
          )?.[0],
          value: filter.filterValue,
        };
      }),
    });
  }

  if (query.sorters.length) {
    Object.assign(refinedQuery, {
      sortColumn: query.sorters[0].column,
      sortOrder: query.sorters[0].order,
    });
  }
  return await apiRequest<{
    data: FormEntryApproval[];
    pagination: {
      limit: number;
      offset: number;
      countAll: number;
    };
  }>(
    `/powerflow/approvals${
      listType === "allApprovals"
        ? ""
        : listType === "myApprovals"
        ? "/outstanding"
        : "/current_user" // listType === "myRequests"
    }${buildQueryString({
      query: refinedQuery,
    })}`,
    serviceConfig
  );
};

export const getFormEntryApproval = async (
  approvalId: number,
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormEntryApproval>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/approvals/${approvalId}`,
    serviceConfig
  );
};

export interface FormEntryApprovalForm {
  approvalDocuments: {
    file: UploadFile;
    isPrintFormFile: boolean;
    fileCategory: string;
    registerDocumentId: number;
  }[];
  remarks: string;
  notifyUsers: number[];
}

export const createFormEntryApproval = async (
  formEntryId: number,
  approvalCreateForm: FormEntryApprovalForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormEntryApproval>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/approvals`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        ...approvalCreateForm,
        approvalDocuments: approvalCreateForm.approvalDocuments.map((doc) => ({
          ...doc,
          registerDocumentId: undefined,
        })),
      }),
    }
  );
};

interface FormEntryApprovalFlow {
  createdOn: Date;
  flowName: string;
  id: number; // flow id
  registerId: number;
  steps: RegisterFlowStep[];
}

export const getFormEntryApprovalFlow = async (
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormEntryApprovalFlow>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/cal_flow`,
    serviceConfig
  );
};

interface ApprovalDocPreview {
  created: Date;
  doc_code: null;
  id: number;
  doc_name: string;
  entityId: number;
  file_ext: string;
  file_ord: number;
  mandatory_flag: true;
  previewFile: UploadFile;
  regId: number;
  updated: Date;
}

export const genApprovalDocPreview = async (
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<ApprovalDocPreview[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/docs/preview`,
    serviceConfig,
    {
      method: "POST",
    }
  );
};

export const approveFormEntryApproval = async (
  approvalId: number,
  formEntryId: number,
  remarks: string,
  formData: { [key: string]: any } | undefined,
  serviceConfig: ServiceConfig,
  registerId?: number
) => {
  return await apiRequest<FormEntryApproval>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${
      registerId ?? serviceConfig.registerId
    }/form/entries/${formEntryId}/approvals/${approvalId}/next/api`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        remarks,
        formData: formData || {},
      }),
    }
  );
};

export const rejectFormEntryApproval = async (
  approvalId: number,
  formEntryId: number,
  remarks: string,
  formData: { [key: string]: any } | undefined,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormEntryApproval>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/approvals/${approvalId}/reject/api`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        remarks,
        formData: formData || {},
      }),
    }
  );
};

// added for CPCS-680
export const releaseFormEntryApproval = async (
  registerId: number,
  entryId: number,
  approvalId: number,
  stepId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormEntryApproval>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/form/entries/${entryId}/approvals/${approvalId}/step/${stepId}/amendment_release`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};

export const relockFormEntryApproval = async (
  registerId: number,
  entryId: number,
  approvalId: number,
  stepId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormEntryApproval>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/form/entries/${entryId}/approval/${approvalId}/step/${stepId}/relock`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};

export const widthdrawFormEntryApproval = async (
  approvalId: number,
  formEntryId: number,
  remarks: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormEntryApproval>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/approvals/${approvalId}/withdraw/api`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        remarks,
      }),
    }
  );
};
