import { STORAGE_X_ACCESS_TOKEN } from '../const';
import httpRequest from './httpRequest';

export interface ServiceConfig {
  entityId?: string;
  registerId?: number;
  keyCloakToken?: string;
  xAccessToken?: string | null;
  apiBaseUrl: string;
  qcServiceApiBaseUrl: string;
}

export default async function apiRequest<R = any>(
  url: string,
  serviceConfig: ServiceConfig,
  options?: RequestInit,
  retryLimit = 0,
  lastRetryDelay = 2000
) {
  const xAccessToken = localStorage.getItem(STORAGE_X_ACCESS_TOKEN);

  const requestSettings: RequestInit = {
    ...options,
    headers: {
      Authorization: `Bearer ${serviceConfig.keyCloakToken}` || "",
      ...(xAccessToken ? { "x-access-token": xAccessToken } : undefined),
      ...options?.headers,
    },
  };

  return httpRequest<R>(
    url.startsWith("http://") || url.startsWith("https://")
      ? url
      : serviceConfig.apiBaseUrl + url,
    requestSettings,
    retryLimit,
    lastRetryDelay
  );
}
