import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import { Input, Select } from "antd";
import { LabeledValue } from "antd/lib/select";

import { RuleCondition, RuleOperator } from "../../../../services/registerRule";

const useStyles = createUseStyles((theme) => ({
  ruleConditionLayout: {
    whiteSpace: "nowrap",
  },
  selectField: {
    width: 160,
  },
  selectOperator: {
    marginLeft: theme.spacing(1),
    width: 68,
  },
  inputValue: {
    width: 160,
    marginLeft: theme.spacing(1),
    position: "relative",
    top: 1,
  },
  errorMsg: {
    color: theme.palette.warning,
    marginBottom: 0,
  },
}));

interface RuleConditionLayoutProps {
  isRoot?: boolean;
  ruleCondition: RuleCondition;
  fieldOptions: LabeledValue[];
  error?: string;
  onConditionChange: (ruleCondition: RuleCondition) => void;
  onConditionDelete: (conditionKey: number) => void;
  onConditionGroup: (ruleCondition: RuleCondition) => void;
}

const RuleConditionLayout: React.FC<RuleConditionLayoutProps> = ({
  isRoot,
  ruleCondition,
  fieldOptions,
  error,
  onConditionChange,
  onConditionDelete,
  onConditionGroup,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.ruleConditionLayout}>
      <Select
        className={classes.selectField}
        placeholder={t("RuleConditionLayout.selectField")}
        allowClear
        options={fieldOptions}
        value={ruleCondition.field || undefined}
        onChange={(val) => onConditionChange({ ...ruleCondition, field: val })}
        onClear={() => {
          // reset cleared field from undefined to empty string in next tick to avoid error in submission
          setTimeout(() => onConditionChange({ ...ruleCondition, field: "" }));
        }}
      />
      <Select
        className={classes.selectOperator}
        value={ruleCondition.operator}
        options={Object.entries(RuleOperator).map((entry) => ({
          value: entry[1],
        }))}
        onChange={(val) =>
          onConditionChange({ ...ruleCondition, operator: val })
        }
      />
      <Input
        className={classes.inputValue}
        placeholder={t("RuleConditionLayout.enterValue")}
        value={ruleCondition.value.toString()}
        onChange={(e) =>
          onConditionChange({ ...ruleCondition, value: e.target.value })
        }
      />
      <NxpButton
        type="text"
        size="small"
        onClick={() => onConditionGroup(ruleCondition)}
      >
        {t("RuleConditionLayout.BuildGroup")}
      </NxpButton>
      {!isRoot && (
        <NxpButton
          type="text"
          size="small"
          danger
          onClick={() => onConditionDelete(ruleCondition.key)}
        >
          {t("app.common.Delete")}
        </NxpButton>
      )}
      {error && <p className={classes.errorMsg}>{error}</p>}
    </div>
  );
};

export default RuleConditionLayout;
