import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useAppContext from "../../hooks/useAppContext";
import { useDebouncedValue } from "../../hooks/useDebounce";
import AddRegisterModal from "./AddRegisterModal";
import RegistersLayout from "./RegistersLayout";

interface RegistersContainerProps {}

const RegistersContainer: React.FC<RegistersContainerProps> = () => {
  const { registers } = useAppContext();
  const routerHistory = useHistory();
  const routerLocation = useLocation();
  const [modalVisible, setModalVisible] = useState(false);

  const handleCreateClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleCardClick = (registerId: number, e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }
    routerHistory.push({
      pathname: `registers/${registerId}/form-entries`,
      state: { from: routerLocation.pathname },
    });
  };

  const [searchValue, setSearchValue] = useState("");

  // replaced with useDebouncedValue hook that save the effort for defining an extra debounced state in client code
  // const [searchValueDebounced, setSearchValueDebounced] = useState("");
  // const handleSearch = (value: string) => {
  //setSearchValue(value);
  // };
  // const handleSearchDebounced = () => {
  //   setSearchValueDebounced(searchValue);
  // };
  // useDebounce(handleSearchDebounced, 500, [searchValue]);

  const searchValueDebounced = useDebouncedValue(searchValue, 500, [
    searchValue,
  ]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const searchMatch = (text: string | undefined) =>
    (text || "")
      .toLocaleLowerCase()
      .includes(searchValueDebounced.toLocaleLowerCase());

  return (
    <>
      <RegistersLayout
        registers={
          registers &&
          registers
            .filter((reg) => reg.permissions["register-entry:read"])
            .filter(
              (reg) =>
                !searchValueDebounced.trim() ||
                searchMatch(reg.register.register_name) ||
                searchMatch(reg.register.description) ||
                searchMatch(reg.register.owner.name) ||
                searchMatch(reg.register.entity?.name)
            )
        }
        searchValue={searchValue}
        searchValueDebounced={searchValueDebounced}
        onSearch={handleSearch}
        onCreateClick={handleCreateClick}
        onCardClick={handleCardClick}
      />
      <AddRegisterModal
        modalVisible={modalVisible}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default RegistersContainer;
