import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Form } from "react-formio";
import { useTranslation } from "react-i18next";

import { NxpButton, NxpModal, NxpModalProps } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import {
  getRegisterCustomForm,
  prepareFormContentForSubmit,
  RegisterCustomFormField,
} from "../../../services/registerCustomForm";
import { createRegisterFormEntry } from "../../../services/registerFormEntry";
import { FORMIO_DEFAULT_OPTIONS } from "../../../utils/const";
import notify from "../../../utils/notify";

type AddFormEntryModalEditMode =
  | "uninitialized"
  | "initializing"
  | "formUndefined"
  | "editing"
  | "saving";

interface AddFormEntryModalProps {
  modalVisible: boolean;
  modalWidth: NxpModalProps["width"];
  copyFormContent?: object;
  onModalClose: () => void;
  onFormEntryAdded: () => void;
}

const AddFormEntryModal: React.FC<AddFormEntryModalProps> = ({
  modalVisible,
  modalWidth,
  copyFormContent,
  onModalClose,
  onFormEntryAdded,
}) => {
  const [editMode, setEditMode] =
    useState<AddFormEntryModalEditMode>("uninitialized");

  const appContext = useAppContext();

  const initFormValues = {
    formConfigId: 0,
    formData: {},
    formDefinition: [],
  };

  const [editForm, setEditForm] = useState<{
    formConfigId: number;
    formDefinition: RegisterCustomFormField[];
    formData: any; // for capturing changes in formio Form
  }>(initFormValues);

  const { t } = useTranslation();

  const handleModalSave = async () => {
    if (editForm.formData.isValid) {
      try {
        setEditMode("saving");
        await createRegisterFormEntry(
          editForm.formConfigId,
          prepareFormContentForSubmit(
            editForm.formData.data,
            editForm.formDefinition
          ),
          appContext.serviceConfig
        );
        notify.actionCompleted();
        onFormEntryAdded();
        onModalClose(); // will unmount modal component
      } catch (ex) {
        appContext.errorHandler(ex, "create form entry");
      } finally {
        setEditMode("editing");
      }
    } else {
      // should find better way to trigger formio validation
      formRef.current.formio.submit();
      notify.warning(t("app.common.PleaseFixValidationErrorBeforeProceeding"));
    }
  };

  useEffect(() => {
    if (
      appContext.activeEntity &&
      appContext.activeRegister &&
      editMode === "uninitialized"
    ) {
      setEditMode("initializing");
      getRegisterCustomForm(appContext.serviceConfig).then((customForm) => {
        if (customForm) {
          // console.log('jjjjjjjjjj', JSON.parse(customForm.spec as any));
          setEditForm((prevState) => ({
            ...prevState,
            formConfigId: customForm.id,
            formDefinition: customForm.definitions,
          }));
          setEditMode("editing");
        } else {
          setEditMode("formUndefined");
        }
      });
    }
  }, [appContext, editMode]);

  const formNode = useRef<ReactNode>(null);
  const formRef = useRef<any>(null);

  if (editMode === "editing" && formNode.current === null) {
    formNode.current = (
      <Form
        ref={formRef}
        form={{
          components: editForm.formDefinition,
        }}
        submission={{
          data: copyFormContent ? copyFormContent : {},
        }}
        options={FORMIO_DEFAULT_OPTIONS}
        onChange={(data: any) => {
          setEditForm((prevState) => ({ ...prevState, formData: data }));
        }}
      />
    );

    // hack to ensure validation
    setTimeout(() => formRef.current.formio.triggerChange(), 800);
  }

  return (
    <NxpModal
      showMandatoryLabel
      title={t("AddFormEntryModal.AddFormEntry")}
      visible={modalVisible}
      onCancel={onModalClose}
      width={modalWidth}
      footer={
        <NxpButton disabled={editMode !== "editing"} onClick={handleModalSave}>
          {t("app.common.Save")}
        </NxpButton>
      }
    >
      {formNode.current as any}
    </NxpModal>
  );
};

export default AddFormEntryModal;
