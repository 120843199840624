import React, { ReactNode, useEffect, useState } from "react";

import AppListBuilderBase from "./AppListBuilderBase";

export interface AppListBuilderActiveItem {
  isLeftItem: boolean;
  itemIndex: number;
}

export interface BuilderItem {
  key: string;
  name: string;
  selected: boolean;
}

interface AppListBuilderProps {
  initBuilderItems: BuilderItem[];
  leftHeader: ReactNode;
  rightHeader: ReactNode;
  saveInProgress?: boolean;
  disableReorder?: boolean;
  onSave: (initBuilderItems: BuilderItem[]) => void;
}

const AppListBuilder: React.FC<AppListBuilderProps> = ({
  initBuilderItems,
  saveInProgress,
  disableReorder,
  leftHeader,
  rightHeader,
  onSave,
}) => {
  const [listBuilderActiveItem, setListBuilderActiveItem] =
    useState<AppListBuilderActiveItem>();

  const [builderItems, setBuilderItems] = useState<BuilderItem[]>([]);

  useEffect(() => {
    setBuilderItems(initBuilderItems);
  }, [initBuilderItems]);

  const handleMoveToLeft = (
    builderItem: BuilderItem,
    _: number,
    toIndex?: number
  ) => {
    const builderItemList = [...builderItems];
    const toItem =
      toIndex === undefined
        ? undefined
        : builderItemList.filter((item) => !item.selected)[toIndex];

    // remove from list
    builderItemList.splice(builderItemList.indexOf(builderItem), 1);

    // add back to list
    if (toItem) {
      builderItemList.splice(builderItemList.indexOf(toItem), 0, {
        ...builderItem,
        selected: false,
      });
    } else {
      builderItemList.push({ ...builderItem, selected: false });
    }

    setBuilderItems(builderItemList);
    // onSelectedUiDispatch({
    //   type: "dataTableChange",
    //   builderItemList,
    // });

    ///////// setListBuilderActiveItem({
    /////////   isLeftItem: true,
    /////////   itemIndex:
    /////////     toIndex === undefined
    /////////       ? builderItemList.filter(item => !item.display).length - 1
    /////////       : toIndex,
    ///////// });
  };

  const handleMoveToRight = (
    builderItem: BuilderItem,
    _: number,
    toIndex?: number
  ) => {
    const builderItemList = [...builderItems];
    const toItem =
      toIndex === undefined
        ? undefined
        : builderItemList.filter((item) => item.selected)[toIndex];

    // remove from list
    builderItemList.splice(builderItemList.indexOf(builderItem), 1);

    // add back to list
    if (toItem) {
      builderItemList.splice(builderItemList.indexOf(toItem), 0, {
        ...builderItem,
        selected: true,
      });
    } else {
      builderItemList.push({ ...builderItem, selected: true });
    }

    setBuilderItems(builderItemList);
    // onSelectedUiDispatch({
    //   type: "dataTableChange",
    //   builderItemList,
    // });
    setListBuilderActiveItem({
      isLeftItem: false,
      itemIndex:
        toIndex === undefined
          ? builderItemList.filter((item) => item.selected).length - 1
          : toIndex,
    });
  };

  const handleReorder = (
    builderItem: BuilderItem,
    fromIndex: number,
    toIndex?: number
  ) => {
    const builderItemList = [...builderItems];
    const toItem =
      toIndex === undefined
        ? undefined
        : builderItemList.filter((item) => item.selected)[toIndex];

    // remove from list
    builderItemList.splice(builderItemList.indexOf(builderItem), 1);

    // add back to list
    if (toItem) {
      builderItemList.splice(builderItemList.indexOf(toItem), 0, builderItem);
    } else {
      builderItemList.push(builderItem);
    }

    setBuilderItems(builderItemList);
    // onSelectedUiDispatch({
    //   type: "dataTableChange",
    //   builderItemList,
    // });

    setListBuilderActiveItem({
      isLeftItem: false,
      itemIndex:
        toIndex === undefined || toItem === undefined
          ? builderItemList.filter((item) => item.selected).length - 1 // move to end
          : fromIndex < toIndex
          ? toIndex - 1 // move down
          : fromIndex === toIndex + 1
          ? fromIndex - 1 //move up 1 position
          : builderItemList.indexOf(toItem) - 1, //drag up more than 1 position
    });
  };

  const handleSave = () => {
    onSave(builderItems);
  };

  const handleCancel = () => {
    setBuilderItems(initBuilderItems);
  };

  return (
    <AppListBuilderBase<BuilderItem>
      leftHeader={leftHeader}
      rightHeader={rightHeader}
      listBuilderActiveItem={listBuilderActiveItem}
      leftItems={builderItems.filter((item) => !item.selected)}
      rightItems={builderItems.filter((item) => item.selected)}
      saveInProgress={saveInProgress}
      disableReorder={disableReorder}
      onMoveToLeft={handleMoveToLeft}
      onMoveToRight={handleMoveToRight}
      onReorder={handleReorder}
      onItemPropsRender={(item) => ({ key: item.key, label: item.name })}
      onSortAscending={(a, b) => (a.name > b.name ? 1 : -1)}
      onSortDescending={(a, b) => (a.name > b.name ? -1 : 1)}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};

export default AppListBuilder;
