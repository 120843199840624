import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import { useValidate } from "../../../hooks/useValidate";
import {
  deleteRegisterDocument,
  getRegisterDocumentList,
  RegisterDocument,
  RegisterDocumentForm,
  updateRegisterDocument,
} from "../../../services/registerDocument";
import { getPrintablesList } from "../../../services/registerPrintables";
import { PRINT_FORM_FILE_TYPE } from "../../../utils/const";
import notify from "../../../utils/notify";
import SetupDocumentLayout from "./SetupDocumentLayout";

interface SetupDocumentProps {}

const SetupDocumentInternal: React.FC<SetupDocumentProps> = () => {
  const appContext = useAppContext();
  const [registerDocuments, setRegisterDocuments] = useState<
    RegisterDocument[]
  >([]);

  const fetchDocuments = useCallback(() => {
    getRegisterDocumentList(
      appContext.activeRegister!.id,
      appContext.serviceConfig
    )
      .then((data) => setRegisterDocuments(data))
      .catch((ex) => appContext.errorHandler(ex, "get register documents"));
  }, [appContext]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const [printFormOptions] = useAsync(
    () =>
      getPrintablesList(
        appContext.activeRegister!.id,
        appContext.serviceConfig,
        { limit: 0, offset: 0 }
      ).then(
        (response) =>
          response.data
            .filter((item) => item.status === "valid")
            .map((item) => ({
              label: item.printFormName,
              value: item.printFormName,
            })) || []
      ),
    [],
    "get register print forms"
  );

  const [editItem, setEditItem] = useState<RegisterDocument>();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const { t } = useTranslation();

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    if (editItem) {
      try {
        await updateRegisterDocument(
          editItem.id,
          {
            doc_name: editItem.doc_name,
            file_ord: editItem.file_ord,
            mandatory_flag: editItem.mandatory_flag,
            file_ext: editItem.file_ext,
          },
          appContext.serviceConfig
        );
        fetchDocuments();
        setEditItem(undefined);
        notify.actionCompleted();
      } catch (ex) {
        appContext.errorHandler(ex, "update register document");
      } finally {
        setSaveInProgress(false);
      }
    }
  };

  const formSchema = yup.object().shape({
    doc_name: yup
      .string()
      .nullable()
      .required(t("app.common.NameRequired"))
      .test("duplicated", "Document name already existed", function (val) {
        return !registerDocuments.find(
          (doc) =>
            doc.id !== editItem?.id &&
            doc.doc_name.toLowerCase().trim() ===
              editItem?.doc_name.toLowerCase().trim()
        );
      }),
    file_ext: yup
      .string()
      .required(t("app.common.ValueRequired"))
  });

  const [validationError, , clearError, saveWithValidate] =
    useValidate<RegisterDocumentForm>(
      editItem,
      formSchema,
      handleSaveValidated
    );

  const handleRowEdit = useCallback(
    (editItem: RegisterDocument) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      setEditItem(undefined);
      clearError();
    },
    [clearError]
  );

  const handleRowDelete = useCallback(
    async (deleteItem: RegisterDocument) => {
      setSaveInProgress(true);
      try {
        await deleteRegisterDocument(deleteItem.id, appContext.serviceConfig);
        fetchDocuments();
        setEditItem(undefined);
        notify.actionCompleted();
      } catch (ex) {
        appContext.errorHandler(ex, "delete register document");
      } finally {
        setSaveInProgress(false);
      }
    },
    [appContext, fetchDocuments]
  );

  const handleFormStateChange = useCallback(
    (fieldName: keyof RegisterDocument, value: unknown) => {
      if (fieldName === "file_ext") {
        setEditItem((prevState) => {
          const file_ext = value as string;
          if (
            value === PRINT_FORM_FILE_TYPE ||
            prevState?.file_ext === PRINT_FORM_FILE_TYPE
          ) {
            return {
              ...prevState!,
              file_ext,
              doc_name: "",
            };
          }
          return {
            ...prevState!,
            file_ext,
          };
        });
      } else {
        setEditItem((prevState) => ({
          ...prevState!,
          [fieldName]: value,
        }));
      }
    },
    []
  );

  const handleRegisterDocumentAdded = () => {
    fetchDocuments();
  };

  const fileExtensionOptions = [
    {
      label: t("SetupDocumentContainer.SystemPrintForm"),
      value: PRINT_FORM_FILE_TYPE,
    },
    {
      label: "DOCX",
      value: "DOCX",
    },
    {
      label: "JPG",
      value: "JPG",
    },
    {
      label: "PDF",
      value: "PDF",
    },
    {
      label: "PPTX",
      value: "PPTX",
    },
    {
      label: "XLSX",
      value: "XLSX",
    },
  ];

  return (
    <AppAccess rolesAllowed="adminOnly" showWarning>
      <SetupDocumentLayout
        registerDocuments={registerDocuments}
        editItem={editItem}
        saveInProgress={saveInProgress}
        validationError={validationError}
        fileExtensionOptions={fileExtensionOptions}
        printFormOptions={printFormOptions.data || []}
        onRowDelete={handleRowDelete}
        onRowEdit={handleRowEdit}
        onRowSave={handleRowSave}
        onRowCancel={handleRowCancel}
        onRegisterDocumentAdded={handleRegisterDocumentAdded}
        onFormStateChange={handleFormStateChange}
      />
    </AppAccess>
  );
};

const SetupDocumentContainer = () => {
  const appContext = useAppContext();
  return appContext.activeRegister ? <SetupDocumentInternal /> : null;
};

export default SetupDocumentContainer;
