import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpLabel,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import clsx from "clsx";

import { ValidationResult } from "../../../../hooks/useValidate";
import {
  RegisterRule,
  RegisterRuleForm,
  RuleCondition,
  RuleConditionGroup,
  ruleStringify,
} from "../../../../services/registerRule";
import RuleBody from "./RuleBody";
import { checkEmptyRule } from "./RuleFormModalContainer";

type PartialRegisterRuleForm = Partial<RegisterRuleForm>;

interface RuleFormModalLayoutProps {
  registerRule: Partial<RegisterRule>;
  templateRuleCount: number;
  editForm: Partial<RegisterRule>;
  flowOptions: LabeledValue[];
  fieldOptions: LabeledValue[];
  saveInProgress: boolean;
  validationError: ValidationResult<PartialRegisterRuleForm>;
  onModalSave: () => void;
  onFormGridStateChange: (
    fieldName: keyof RegisterRule,
    value: unknown
  ) => void;
  onRuleBodyChange: (rule: RuleCondition | RuleConditionGroup) => void;
  onModalClose: () => void;
}

const useStyles = createUseStyles((theme) => ({
  ruleBodyHeader: {
    display: "flex",
    margin: theme.spacing(1, 0),
    width: 938,
    borderBottom: `1px solid ${theme.palette.border}`,
    alignItems: "center",
    "& > p": {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
    },
    "& > p:first-child": {
      whiteSpace: "pre",
      marginLeft: 0,
    },
    "& > p + p": {
      "&::before": {
        content: "'-'",
        marginLeft: -14,
        position: "absolute",
      },
    },
  },
  ruleBody: { width: 968, overflow: "auto", paddingBottom: theme.spacing(1) },
  ruleBodyError: { color: theme.palette.warning },
}));

const RuleFormModalLayout: React.FC<RuleFormModalLayoutProps> = ({
  registerRule,
  templateRuleCount,
  editForm,
  flowOptions,
  fieldOptions,
  saveInProgress,
  validationError,
  onModalSave,
  onFormGridStateChange,
  onRuleBodyChange,
  onModalClose,
}) => {
  const { t } = useTranslation();

  const formItems: NxpFormGridItemProps<PartialRegisterRuleForm>[] = [
    {
      label: t("app.common.Order"),
      itemFieldName: "order",
      controlType: "select",
      controlProps: {
        options: Array(templateRuleCount + (registerRule.id ? 0 : 1))
          .fill(null)
          .map((_, idx) => ({
            label: idx + 1,
            value: idx + 1,
          })),
      },
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: t("app.common.Name"),
      itemFieldName: "ruleName",
      required: true,
      span: 12,
    },
    {
      controlType: "select",
      label: t("RuleFormModalLayout.WorkflowToRun"),
      itemFieldName: "workflowId",
      controlProps: {
        options: flowOptions,
      },
      required: true,
      span: 12,
    },
  ];

  const classes = useStyles();

  return (
    <NxpModal
      width="large"
      title={`${
        registerRule.id
          ? t("RuleFormModalLayout.UpdateRule")
          : t("RuleFormModalLayout.CreateRule")
      }`}
      visible={!!registerRule}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={onModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={
          validationError as ValidationResult<PartialRegisterRuleForm>
        }
        formItems={formItems}
        formState={editForm}
        onFormStateChange={onFormGridStateChange}
      />
      <div className={classes.ruleBodyHeader}>
        <NxpLabel>{t("RuleFormModalLayout.RuleConditions")}</NxpLabel>
        {editForm.rule && (
          <NxpLabel
            className={clsx(validationError.rule && classes.ruleBodyError)}
          >
            {checkEmptyRule(editForm.rule)
              ? t("RuleFormModalLayout.matchAll")
              : ruleStringify(editForm.rule)}
          </NxpLabel>
        )}
      </div>
      <div className={classes.ruleBody}>
        <RuleBody
          errorKeys={
            validationError.rule
              ? validationError.rule.split("_").map((key) => Number(key))
              : []
          }
          fieldOptions={fieldOptions}
          ruleBody={editForm.rule || undefined}
          onRuleBodyChange={onRuleBodyChange}
        />
      </div>
    </NxpModal>
  );
};

export default RuleFormModalLayout;
