import React, { ReactNode, useCallback, useState } from "react";

import { AccessType } from "../../../services/access";
import AppContext, { AppContextCache } from "./AppContext";

interface AppContextProviderProps {
  initAppContextCache?: AppContextCache;
  children: ReactNode;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  initAppContextCache,
  children,
}) => {
  const [appContextCache, setAppContextCache] = useState<AppContextCache>(
    initAppContextCache || {
      users: [],
      registerTypes: [],
    }
  );

  const handleAppContextCacheItemUpdate = useCallback(
    <K extends keyof AppContextCache>(
      cacheItem: K,
      itemValue: AppContextCache[K]
    ) => {
      setAppContextCache((prevState) => ({
        ...prevState,
        [cacheItem]: itemValue,
      }));
    },
    []
  );

  const hasAccess = useCallback(
    (accessType: AccessType) => {
      if (
        appContextCache.activeUser?.id !== undefined &&
        appContextCache.activeRegister?.owner.id ===
          appContextCache.activeUser?.id
      ) {
        // same as RES_OWNER, thus full access
        // this condition is for handling situation where registerRolesOfCurrentUser is empty in sub entity even for the owners themselves
        return true;
      }

      if (appContextCache.registerRolesOfCurrentUser === undefined) {
        return false;
      }

      if (accessType === "adminOnly") {
        return appContextCache.registerRolesOfCurrentUser.some((r) =>
          ["RES_OWNER", "RES_ADMIN"].includes(r)
        );
      }

      if (accessType === "writable") {
        return appContextCache.registerRolesOfCurrentUser.some((r) =>
          ["RES_OWNER", "RES_ADMIN", "RES_CONTRIBUTOR"].includes(r)
        );
      }

      if (accessType === "readable") {
        return appContextCache.registerRolesOfCurrentUser.some((r) =>
          [
            "RES_OWNER",
            "RES_ADMIN",
            "RES_CONTRIBUTOR",
            "RES_PRIV_READER",
            "RES_READER",
          ].includes(r)
        );
      }

      return false;
    },
    [
      appContextCache.activeRegister?.owner.id,
      appContextCache.activeUser?.id,
      appContextCache.registerRolesOfCurrentUser,
    ]
  );

  return (
    <AppContext.Provider
      value={{
        ...appContextCache,
        hasAccess,
        onAppContextCacheUpdate: setAppContextCache,
        onAppContextCacheItemUpdate: handleAppContextCacheItemUpdate,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
