import { apiRequest, ServiceConfig } from "../utils/backend";

export interface RegisterStatus {
  value: string;
  outstanding: boolean;
  id: number;
  version: number;
  registerId: number;
  createdOn: Date;
  allowEdit: boolean;
  deleted: boolean;
  statusName: string;
  allowResubmit: boolean;
  updatedOn: Date;
  defaultStatus: boolean;
  submittedStatus: boolean;
  releaseStatus: boolean;
  cancelStatus: boolean;
  changeStatus: boolean;
  withdrawStatus: boolean;
}

export interface RegisterStatusPatchItem
  extends Pick<
    RegisterStatus,
    | "statusName"
    | "allowEdit"
    | "allowResubmit"
    | "outstanding"
    | "defaultStatus"
    | "submittedStatus"
    | "releaseStatus"
    | "cancelStatus"
    | "changeStatus"
    | "withdrawStatus"
  > {}

export const batchPatchStatus = async (
  id: number,
  statusItems: RegisterStatusPatchItem[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterStatus[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${id}/status/batch`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(statusItems),
    }
  );
};
