import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpFormSurvey,
  NxpFormSurveyItemProps,
  NxpHeader,
  NxpHolder,
} from "@nexploretechnology/nxp-ui";

import { ValidationResult } from "../../../hooks/useValidate";
import { RegisterType, User } from "../../../services/app";
import { RegisterSetupForm } from "../../../services/register";
import { RegisterRuleTemplate } from "../../../services/registerRule";

const useStyles = createUseStyles((theme) => ({
  setupGeneralLayout: {},
}));

interface SetupGeneralLayoutProps {
  form: RegisterSetupForm;
  isInheritedRegister: boolean;
  registerHasRecord: boolean;
  saveInProgress: boolean;
  userOptions: User[];
  registerTypeOptions: RegisterType[];
  ruleTemplateOptions: RegisterRuleTemplate[];
  validationError: ValidationResult<RegisterSetupForm>;
  onFormStateChange: (
    fieldName: keyof RegisterSetupForm,
    value: unknown
  ) => void;
  onFormSave: () => void;
}

const SetupGeneralLayout: React.FC<SetupGeneralLayoutProps> = ({
  form,
  isInheritedRegister,
  registerHasRecord,
  saveInProgress,
  userOptions,
  registerTypeOptions,
  ruleTemplateOptions,
  validationError,
  onFormStateChange,
  onFormSave,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const formItems: NxpFormSurveyItemProps<RegisterSetupForm>[] = [
    {
      controlType: "input",
      label: t("app.common.Name"),
      itemFieldName: "register_name",
      editing: !isInheritedRegister,
      required: true,
      span: 2,
    },
    {
      controlType: "textarea",
      label: t("app.common.Description"),
      itemFieldName: "description",
      editing: !isInheritedRegister,
      span: 2,
    },
    {
      controlType: "select",
      controlProps: {
        options: userOptions.map((opt) => ({
          label: opt.name,
          value: opt.id,
        })),
      },
      label: t("app.common.Owner"),
      itemFieldName: "owner",
      editing: !isInheritedRegister,
      required: true,
      span: 1,
    },
    {
      controlType: "select",
      controlProps: {
        options: registerTypeOptions,
      },
      label: t("app.common.RegisterType"),
      itemFieldName: "type",
      editing: !isInheritedRegister,
      required: true,
      span: 1,
    },
    {
      controlType: "radioGroup",
      controlProps: {
        options: [
          {
            label: t("app.common.Yes"),
            value: true,
          },
          {
            label: t("app.common.No"),
            value: false,
          },
        ],
      },
      label: t("app.common.Disabled"),
      itemFieldName: "disabled",
      editing: !isInheritedRegister,
      span: 1,
    },
    {
      controlType: "inputNumber",
      itemFieldName: "last_sequential_num",
      controlProps: {
        decimalPlace: 0,
        disabled:
          registerHasRecord ||
          (isInheritedRegister && !form.flag_seperate_queue),
      },
      label: t("app.common.LastSequentialNumber"),
      span: 1,
    },
    // {
    //   controlType: "select",
    //   controlProps: {
    //     options: statusOptions.map((opt) => ({
    //       label: opt.statusName,
    //       value: opt.id,
    //     })),
    //   },
    //   label: "Default Status",
    //   itemFieldName: "defaultStatusId",
    //   span: 1,
    // },
    // {
    //   controlType: "select",
    //   controlProps: {
    //     options: statusOptions.map((opt) => ({
    //       label: opt.statusName,
    //       value: opt.id,
    //     })),
    //   },
    //   tooltip: {
    //     title:
    //       'Must specify if you would like to enable the "Release for Amendment" feature.',
    //   },
    //   label: "Submit Status",
    //   itemFieldName: "submittedStatusId",
    //   span: 1,
    // },
    // {
    //   controlType: "select",
    //   controlProps: {
    //     options: statusOptions.map((opt) => ({
    //       label: opt.statusName,
    //       value: opt.id,
    //     })),
    //   },
    //   tooltip: {
    //     title: 'Select to enable the "Release for Amendment" feature.',
    //   },
    //   label: "Release Status",
    //   itemFieldName: "releaseStatusId",
    //   span: 1,
    // },
    // {
    //   controlType: "select",
    //   controlProps: {
    //     options: statusOptions.map((opt) => ({
    //       label: opt.statusName,
    //       value: opt.id,
    //     })),
    //   },
    //   tooltip: {
    //     title:
    //       'The system can reset the status after every edit, except under "Release Status"',
    //   },
    //   label: "Change Status After Edit",
    //   itemFieldName: "changeStatusId",
    //   span: 1,
    // },
    {
      controlType: "select",
      controlProps: {
        options: ruleTemplateOptions.map((item) => ({
          label: item.ruleTemplateName,
          value: item.id,
        })),
      },
      tooltip: {
        title: t("SetupGeneral.ItIsTheRuleTemplate"),
      },
      label: t("SetupGeneral.DefaultRuleTemplate"),
      itemFieldName: "defaultRuleTemplateId",
      editing: !isInheritedRegister,
      span: 2,
    },
    {
      controlType: "radioGroup",
      label: t("app.common.AddToWorkspace"),
      itemFieldName: "flag_workspace",
      controlProps: {
        options: [
          {
            label: t("app.common.Yes"),
            value: true,
          },
          {
            label: t("app.common.No"),
            value: false,
          },
        ],
      },
      editing: !isInheritedRegister,
      span: 1,
    },
    {
      controlType: "radioGroup",
      controlProps: {
        options: [
          {
            label: t("app.common.Yes"),
            value: true,
          },
          {
            label: t("app.common.No"),
            value: false,
          },
        ],
      },
      label: t("SetupGeneral.ConsolidatedView (forProjectRegistersOnly)"),
      itemFieldName: "flag_consolidated",
      editing: !isInheritedRegister,
      span: 1,
    },
    {
      controlType: "radioGroup",
      controlProps: {
        options: [
          {
            label: t("app.common.Yes"),
            value: true,
          },
          {
            label: t("app.common.No"),
            value: false,
          },
        ],
      },
      label: t("app.common.SeparateQueue"),
      itemFieldName: "flag_seperate_queue",
      editing: !isInheritedRegister,
      span: 1,
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={t("SetupGeneral.SetupGeneral")}
        actionContent={
          <NxpButton
            loading={saveInProgress}
            disabled={
              isInheritedRegister &&
              !registerHasRecord &&
              !form.flag_seperate_queue
            }
            onClick={onFormSave}
          >
            {t("app.common.Save")}
          </NxpButton>
        }
      />
      <NxpHolder className={classes.setupGeneralLayout}>
        <NxpFormSurvey
          validationError={validationError}
          formItems={formItems}
          formState={form}
          onFormStateChange={onFormStateChange}
        />
      </NxpHolder>
    </>
  );
};

export default SetupGeneralLayout;
