import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EditOutlined } from "@ant-design/icons";
import {
  NxpButton,
  NxpHeader,
  NxpTable,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";

import useAppContext from "../../../hooks/useAppContext";
import {
  getRegisterRuleList,
  RegisterRule,
  RegisterRuleTemplate,
} from "../../../services/registerRule";
import RuleFormModal from "./RuleFormModal";

interface RuleTemplateDetailsProps {
  ruleTemplate: RegisterRuleTemplate;
  className: string;
}

const RuleTemplateDetails: React.FC<RuleTemplateDetailsProps> = ({
  ruleTemplate,
  className,
}) => {
  const appContext = useAppContext();
  const [templateRules, setTemplateRules] = useState<RegisterRule[]>([]);
  const [modalRuleData, setModalRuleData] = useState<Partial<RegisterRule>>();
  const [listLoading, setListLoading] = useState(false);

  const fetchList = useCallback(async () => {
    setListLoading(true);
    try {
      setTemplateRules(
        await getRegisterRuleList(ruleTemplate.id, appContext.serviceConfig)
      );
    } catch (ex) {
      appContext.errorHandler(ex, "get register rules");
    } finally {
      setListLoading(false);
    }
  }, [appContext, ruleTemplate.id]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const handleEditClick = useCallback(
    (editItem: RegisterRule) => setModalRuleData({ ...editItem }),
    []
  );

  const handleRefreshList = useCallback(() => {
    fetchList();
    setModalRuleData(undefined);
  }, [fetchList]);

  const handleAddRuleClick = () => {
    setModalRuleData({});
  };

  const handleRuleModalClose = useCallback(() => {
    setModalRuleData(undefined);
  }, []);

  const { t } = useTranslation();

  const columns: ColumnProps<RegisterRule>[] = [
    {
      title: t("app.common.Order"),
      dataIndex: "order",
      width: 100,
      fixed: "left",
      sorter: (a, b) => sorterForNumber(a.order, b.order),
    },
    {
      title: t("app.common.name"),
      dataIndex: "ruleName",
      width: 100,
      sorter: (a, b) => sorterForString(a.ruleName, b.ruleName),
    },
    {
      title: t("RuleTemplateDetails.Workflow"),
      dataIndex: ["workflow", "flowName"],
      width: 100,
      sorter: (a, b) =>
        sorterForString(a.workflow.flowName, b.workflow.flowName),
    },
    {
      fixed: "right",
      align: "right",
      title: t("RuleTemplateDetails.action"),
      width: 100,
      render: (_, record) => (
        <NxpButton type="text" onClick={() => handleEditClick(record)}>
          <EditOutlined /> {t("app.common.Edit")}
        </NxpButton>
      ),
    },
  ];

  return (
    <div className={className}>
      <NxpHeader
        titleContent={`${t("RuleTemplateDetails.RuleTempate")} - ${
          ruleTemplate ? ruleTemplate.ruleTemplateName : ""
        }`}
        actionContent={
          <NxpButton onClick={handleAddRuleClick}>
            {t("RuleTemplateDetails.AddRule")}
          </NxpButton>
        }
      />
      <NxpTable
        useDefaultHeight
        loading={listLoading}
        columns={columns}
        dataSource={templateRules}
      />
      {modalRuleData && (
        <RuleFormModal
          registerRule={modalRuleData}
          templateId={ruleTemplate.id}
          templateRuleCount={templateRules.length}
          onModalClose={handleRuleModalClose}
          onRefreshList={handleRefreshList}
        />
      )}
    </div>
  );
};

export default RuleTemplateDetails;
