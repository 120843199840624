import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";

import {
  DateFormatPattern,
  NxpAlert,
  NxpButton,
  NxpDateDisplay,
  NxpFormTableColumnProps,
  NxpTable,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import { RegisterFlowStep } from "../../../services/registerFlow";
import {
  FormEntryApproval,
  getFormEntryApprovalFlow,
} from "../../../services/registerFormEntryApproval";
import AddApprovalModal from "./AddApprovalModalContainer";

const useStyles = createUseStyles((theme) => ({
  addApprovalButtonWrap: {
    right: 0,
    top: -55,
    position: "absolute",
    display: "inline-block",
    marginBottom: theme.spacing(3),
  },
}));

interface FormEntryApprovalsProps {
  formEntryId: number;
  formApprovals: FormEntryApproval[];
  cancelled?: boolean;
  onFormEntryStatusChanged: () => void;
}

const FormEntryApprovals: React.FC<FormEntryApprovalsProps> = ({
  formEntryId,
  formApprovals,
  cancelled,
  onFormEntryStatusChanged,
}) => {
  const appContext = useAppContext();
  const routerHistory = useHistory();

  const [formApprovalFlowsAsync] = useAsync<RegisterFlowStep[]>(
    () =>
      getFormEntryApprovalFlow(formEntryId, appContext.serviceConfig).then(
        (flow) => flow.steps
      ),
    [],
    "get form entry approval flow",
    true
  );

  const [approvalModalVisible, setApprovalModalVisible] = useState(false);

  const { t } = useTranslation();

  const columns: NxpFormTableColumnProps<FormEntryApproval>[] = [
    {
      title: t("FormEntryApprovals.RequestDate"),
      dataIndex: "createdOn",
      width: 100,
      sorter: (a, b) => sorterForDate(a.createdOn, b.createdOn),
      render: (val) => (
        <NxpDateDisplay date={val} format={DateFormatPattern.dateWithTime} />
      ),
    },
    {
      title: t("FormEntryApprovals.Originator"),
      dataIndex: ["submitUser", "name"],
      width: 100,
      fixed: "left",
      sorter: (a, b) => sorterForString(a.submitUser.name, b.submitUser.name),
    },
    {
      title: t("app.common.Status"),
      dataIndex: ["currentStatus", "statusName"],
      width: 100,
      sorter: (a, b) =>
        sorterForString(a.currentStatus.statusName, b.currentStatus.statusName),
    },
    {
      title: t("FormEntryApprovals.CC"),
      dataIndex: "notifyUsers",
      width: 150,
      sorter: (a, b) =>
        sorterForString(
          a.notifyUsers.map((user) => user.name.trim()).join(", "),
          b.notifyUsers.map((user) => user.name.trim()).join(", ")
        ),
      render: (_, rec) =>
        rec.notifyUsers.map((user) => user.name.trim()).join(", "),
    },
  ];

  const handleApprovalCreated = (approval: FormEntryApproval) => {
    onFormEntryStatusChanged();
    setApprovalModalVisible(false);
  };

  const classes = useStyles();

  return (
    <div>
      {appContext.registerAccessList["register-entry:update"] &&
      appContext.hasAccess("writable") ? (
        <div className={classes.addApprovalButtonWrap}>
          <NxpButton
            onClick={() => setApprovalModalVisible(true)}
            disabled={
              !appContext.activeRegister?.defaultRuleTemplateId ||
              !formApprovalFlowsAsync.data?.length ||
              formApprovals.filter(
                (item) =>
                  (item.currentStatus.submittedStatus ||
                    !item.currentStatus.outstanding) &&
                  !item.currentStatus.cancelStatus &&
                  !item.currentStatus.withdrawStatus
              ).length > 0 ||
              cancelled
            }
          >
            {t("app.common.RequestApproval")}
          </NxpButton>
        </div>
      ) : undefined}

      {approvalModalVisible && (
        <AddApprovalModal
          formApprovalFlows={formApprovalFlowsAsync.data || []}
          formEntryId={formEntryId}
          modalVisible={approvalModalVisible}
          onModalClose={() => setApprovalModalVisible(false)}
          onApprovalCreated={handleApprovalCreated}
        />
      )}
      {!appContext.activeRegister?.defaultRuleTemplateId && (
        <NxpAlert
          type="warning"
          message={t("FormEntryApprovals.DefaultRuleTemplateHasNotBeenSet")}
        />
      )}
      {!formApprovalFlowsAsync.loading &&
        !formApprovalFlowsAsync.data?.length && (
          <NxpAlert
            type="warning"
            message={t("FormEntryApprovals.CannotFindSuitableWorkflow")}
          />
        )}
      <NxpTable
        useDefaultHeight
        rowKey="id"
        columns={columns}
        dataSource={formApprovals}
        onRow={(record) => ({
          onClick: () => {
            routerHistory.push(
              `../form-entries/${formEntryId}/approvals/${record.id}`
            );
          },
        })}
      />
    </div>
  );
};

export default FormEntryApprovals;
