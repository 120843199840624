import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { NxpButton, NxpFormItem } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";

import useAppContext from "../../../hooks/useAppContext";
import { useFileService } from "../../../services/file";
import {
  getRegisterCustomForm,
  RegisterCustomFormField,
} from "../../../services/registerCustomForm";
import { RegisterFormEntry } from "../../../services/registerFormEntry";
import {
  PrintableComponent,
  PrintablePage,
  PrintForm,
} from "../../../services/registerPrintables";

import "./PrintableEntry.css";

// enum SignatureDateKey {
//   DateReview = "dateReview",
//   DateApprove = "dateApprove",
// }

interface Props {
  formEntry: RegisterFormEntry;
  printForm: PrintForm;
}

export const PrintableEntry: React.FC<Props> = (props) => {
  const { formEntry, printForm } = props;
  //console.log("pppppppppp", printForm);

  const appContext = useAppContext();
  const history = useHistory();
  const [printablePages, setPrintablePages] = React.useState<PrintablePage[]>(
    []
  );
  const [page, setPage] = React.useState<number>(0);
  const [pageOptions, setPageOptions] = React.useState<LabeledValue[]>([]);
  const [fields, setFields] = useState<RegisterCustomFormField[]>([]);
  const fileService = useFileService(
    appContext.serviceConfig.keyCloakToken || ""
  );
  const [formEntryContent, setFormEntryContent] = React.useState<any>();
  const [formSpec, setFormSpec] = React.useState<any>();
  const [imageNameToImageUrl, setImageNameToImageUrl] = React.useState<{
    [key: string]: string | undefined;
  }>({});

  useEffect(() => {
    if (appContext.activeEntity && appContext.activeRegister) {
      getRegisterCustomForm(appContext.serviceConfig).then((response) => {
        if (response) {
          setFields(response.definitions);
        }
      });
    }
  }, [
    appContext.activeEntity,
    appContext.activeRegister,
    appContext.serviceConfig,
  ]);

  useEffect(() => {
    if (printForm && formEntry.contentDetails) {
      setPrintablePages(printForm.printFormConfig);
      fileService
        .sign(printForm.registerId, {
          assetIds: printForm.printFormConfig.map((p: any) => p.imageName),
        })
        .then((r) => {
          const imageUrls: {
            [key: string]: string | undefined;
          } = {};
          for (const a of r.assets) {
            imageUrls[a.assetId] = a.url;
          }

          // added for CPCS-976
          let contents = formEntry.contentDetails;
          contents["entityName"] = Reflect.get(
            appContext.activeEntity ?? {},
            "name_english"
          );
          contents["entityCode"] = appContext.activeEntity?.code;
          contents["entryAuthor"] = formEntry.creatorUserId?.name;
          setFormEntryContent(contents);

          setFormSpec(formEntry.formConfig.definitions);
          setImageNameToImageUrl(imageUrls);
          let pageOptions: LabeledValue[] = [
            {
              value: 0,
              label: "1",
            },
          ];
          for (
            let index = 1;
            //@ts-ignore
            index < printForm.printFormConfig.length;
            index++
          ) {
            pageOptions.push({ value: index, label: (index + 1).toString() });
          }
          setPageOptions(pageOptions);
        });
    }
  }, [
    fields,
    printForm,
    formEntry.contentDetails,
    formEntry.formConfig.definitions,
    fileService,
    appContext.activeEntity,
    formEntry.creatorUserId?.name,
  ]);

  const { t } = useTranslation();

  const handlePageChange = (page: any) => {
    setPage(page);
  };

  if (!formEntryContent) {
    return null;
  }

  const entryKeyValue: { [key: string]: any } = formEntryContent;

  return (
    <>
      <div className="printable-entry-title">
        <div className="printable-entry-preview-control">
          <span className="printable-entry-title-span">
            {t("app.common.Preview")}
          </span>
          <NxpFormItem
            controlType="select"
            controlProps={{
              value: page,
              options: pageOptions,
              onChange: handlePageChange,
            }}
            label={t("app.common.Page")}
          />
        </div>

        <div className="printable-entry-controls">
          <NxpButton onClick={() => window.print()}>
            {t("app.common.Print")}
          </NxpButton>
          <NxpButton onClick={() => history.goBack()}>
            {t("PrintableEntry.GoBack")}
          </NxpButton>
        </div>
      </div>
      {formSpec ? (
        <div id="printable-entry" className="PrintableEntry">
          {printablePages.map((printableImage: any, i) => {
            return (
              <div
                key={printableImage.imageName}
                className={"PrintableImagePage" + (i !== page ? " hidden" : "")}
              >
                <img
                  alt={printableImage.imageName}
                  src={imageNameToImageUrl[printableImage.imageName]}
                  style={{ width: "100%" }}
                />
                {printableImage.components.map((component: any) => {
                  return (
                    <PrintableValue
                      key={component.componentId}
                      printableComponent={component}
                      entryKeyValue={entryKeyValue}
                      spec={formSpec}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

const PrintableValue: React.FC<{
  printableComponent: PrintableComponent;
  entryKeyValue: { [key: string]: any };
  spec: any[];
}> = (props) => {
  //commenting out in case we need it later
  // const appFormSignatureField = ["signatureReview", "signatureApprove"];
  // const appFormDateField: string[] = Object.values(SignatureDateKey);
  const { printableComponent, entryKeyValue, spec } = props;
  const style: React.CSSProperties = {
    ...scaleComponentRect(printableComponent, 1),
    fontSize: printableComponent.fontSize,
    fontWeight: printableComponent.appearance?.isBold ? "bold" : "normal",
    color: printableComponent.appearance?.fontColor ?? "#000",
    fontStyle: printableComponent.appearance?.isItalic ? "italic" : "none",
  };
  const systemField: string[] = useMemo(
    () => ["entityCode", "entityName", "entryAuthor"],
    []
  );
  const discover = useCallback((component: string, spec: any[]): any => {
    console.log(`discover()->$spec: ${spec}`);
    let result: any = undefined;

    for (let i = 0; i < spec.length; i++) {
      const element: any = spec[i];
      if (element["key"] === component) {
        result = element;
        break;
      }
      if (Reflect.has(element, "components")) {
        result = discover(component, element["components"]);
        if (result !== undefined) break;
      }
    }

    return result;
  }, []);
  const entrySpec: { [key: string]: any } = useMemo(
    () =>
      systemField.includes(printableComponent.componentId)
        ? { type: "system" }
        : discover(printableComponent.componentId, spec),
    [discover, printableComponent.componentId, spec, systemField]
  );

  const { t } = useTranslation();

  const entryValue = React.useMemo<string>(() => {
    const value = entryKeyValue[printableComponent.componentId];
    // console.log(`V: ${JSON.stringify(value)}`);
    // console.log(`S: ${JSON.stringify(entrySpec)}`);

    //commenting out in case we need it later
    // if (appFormSignatureField.includes(printableComponent.componentId)) {
    //   return value;
    // }

    // if (appFormDateField.includes(printableComponent.componentId)) {
    //   return getFormattedDateTime(value, false);
    // }

    if (entrySpec == null) {
      console.log(
        `sorry but did not find anything matching for ${printableComponent.componentId}`
      );
      return "";
    }

    if (entrySpec.type === "checkbox") {
      return value ? "Yes" : "No";
    }

    if (entrySpec.type === "datetime") {
      return getFormattedDateTime(value, entrySpec.enableTime);
    }

    if (entrySpec.type === "radio") {
      console.log(`radio value: ${JSON.stringify(entrySpec.values)}`);
      console.log(`actual value: ${JSON.stringify(value)}`);
      let data = entrySpec.values.find((v: any) => v.value === value);
      console.log(`matched data: ${JSON.stringify(data)}`);
      return entrySpec.values.find((v: any) => v.value === value).label;
    }

    if (entrySpec.type === "survey") {
      return JSON.stringify(value);
    }

    if (entrySpec.type === "selectboxes") {
      return (entrySpec.values as any[])
        .filter((v: any) => value[v.value])
        .map<string>((v) => v.label)
        .join(", ");
    }

    if (entrySpec.type === "selectDictionary") {
      if (typeof value === "object") {
        const key = Object.keys(value)[0];
        return value[key];
      } else {
        return "";
      }
    }

    if (entrySpec.type === "selectCPCSDictionary") {
      if (typeof value === "object") {
        return Reflect.get(value, "name");
      } else {
        return "";
      }
    }

    if (entrySpec.type === "selectUser") {
      if (typeof value === "object") {
        return Reflect.get(value, "name");
      } else {
        return "";
      }
    }
    //this is an inspection app custom formio component, add later?
    // if (formFieldSpec.type === 'userDropdown') {
    //   const users = value as User[];
    //   return users.map(user => user.name).join(', \n');
    // }
    return value;
  }, [entryKeyValue, printableComponent.componentId, entrySpec]);

  const getSurveyData = (source: string): string[] => {
    let result: string[] = [];
    const buffer: object = JSON.parse(source);

    if (buffer) {
      for (let item in buffer) {
        result.push(`${item}: ${Reflect.get(buffer, item)}`);
      }
    }

    return result;
  };

  function getPrintableNode(type: string, value: any): any {
    switch (type) {
      case "signature":
        return (
          <img
            className="PrintableComponent"
            alt="printable-component"
            src={entryValue}
            style={style}
          />
        );
      case "survey":
        return (
          <span className="PrintableComponent" style={style}>
            <div>
              <strong>
                <u>{t("PrintableEntry.Survey")}</u>
              </strong>
            </div>
            {getSurveyData(entryValue).map((value: string) => (
              <div>{value}</div>
            ))}
          </span>
        );
      default:
        return (
          <span className="PrintableComponent" style={style}>
            {entryValue}
          </span>
        );
    }
  }

  return entryValue ? getPrintableNode(entrySpec.type, entryValue) : null;
};

function scaleComponentRect(c: PrintableComponent, scale: number) {
  return {
    left: c.x * scale,
    top: c.y * scale,
    height: c.sizeHeight * scale,
    width: c.sizeWidth * scale,
  };
}

function getFormattedDateTime(
  d: string | undefined,
  includeTime: boolean
): string {
  if (d == null) {
    return "";
  }

  const datetime = new Date(d);
  if (isNaN(datetime.getTime())) {
    console.warn("Found invalid date:", d);

    return "";
  }

  const dtf = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second },
    ,
    { value: dayPeriod },
  ] = dtf.formatToParts(datetime);

  if (includeTime) {
    return `${year}-${month}-${day} ${hour}:${minute}:${second} ${dayPeriod}`;
  }
  return `${year}-${month}-${day}`;
}

export default PrintableEntry;
