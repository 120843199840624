import { useRef } from 'react';
import { useRouteMatch } from 'react-router';

import { useKeycloak } from '@react-keycloak/web';

import useAppContext from '../../../hooks/useAppContext';
import { getRegisterRolesOfCurrentUser } from '../../../services/access';
import {
  createMyUserPreference,
  getEntityInfo,
  getMe,
  getMyUserPreference,
  getRegisterTypes,
  getUsers,
} from '../../../services/app';
import {
  getRegister,
  getRegisterList,
} from '../../../services/register';
import { getRegisterDictionaryList } from '../../../services/registerDictionary';
import { getDockerHostRoot } from './AppRouter';

// for setting cache data in app context based on route param
const useRouteAppContextUpdate = () => {
  const { keycloak } = useKeycloak();

  const appContext = useAppContext();
  const loadingEntityIdRef = useRef("");
  const loadingRegisterIdRef = useRef(0);
  const loadingLanguage = useRef(false);

  const entityId = useRouteMatch<{ entityId: string }>(
    `${getDockerHostRoot()}/entities/:entityId`
  )?.params.entityId;
  const registerIdStr = useRouteMatch<{ registerId: string }>(
    `${getDockerHostRoot()}/entities/:entityId/registers/:registerId`
  )?.params.registerId;
  const registerId = Number.isFinite(Number(registerIdStr))
    ? Number(registerIdStr)
    : undefined;

  if (!appContext.serviceConfig.keyCloakToken) return;

  if (entityId && keycloak) {
    if (appContext.activeUser && !loadingLanguage.current) {
      loadingLanguage.current = true;
      getMyUserPreference(appContext.serviceConfig).then((userPreference) => {
        const languagePreference = userPreference.find(
          (u) => u.application === "entity" && u.parameter === "language"
        );
        if (languagePreference) {
          appContext.onAppContextCacheItemUpdate(
            "language",
            languagePreference.value
          );
        } else {
          createMyUserPreference(appContext.serviceConfig, {
            application: "entity",
            parameter: "language",
            value: "en",
          }).then((newUserPreference) => {
            appContext.onAppContextCacheItemUpdate(
              "language",
              newUserPreference.value
            );
          });
        }
      });
    }

    if (
      appContext.activeEntity?.id !== entityId &&
      loadingEntityIdRef.current !== entityId
    ) {
      loadingEntityIdRef.current = entityId;

      getRegisterList(appContext.serviceConfig).then((registers) => {
        appContext.onAppContextCacheItemUpdate("registers", registers);
      });

      getEntityInfo(entityId, appContext.serviceConfig).then((entity) => {
        appContext.onAppContextCacheItemUpdate("activeEntity", entity);
      });

      getMe(appContext.serviceConfig).then((user) => {
        appContext.onAppContextCacheItemUpdate("activeUser", user);
      });

      getUsers(entityId, appContext.serviceConfig).then((users) => {
        appContext.onAppContextCacheItemUpdate("users", users);
      });

      getRegisterTypes(appContext.serviceConfig).then((registerTypes) => {
        appContext.onAppContextCacheItemUpdate("registerTypes", registerTypes);
      });
    }

    if (
      appContext.activeRegister?.id !== registerId &&
      loadingRegisterIdRef.current !== registerId
    ) {
      if (registerId) {
        loadingRegisterIdRef.current = registerId;
        getRegister(registerId, appContext.serviceConfig).then((register) => {
          appContext.onAppContextCacheItemUpdate("activeRegister", register);
        });

        getRegisterRolesOfCurrentUser(appContext.serviceConfig).then(
          (roles) => {
            appContext.onAppContextCacheItemUpdate(
              "registerRolesOfCurrentUser",
              roles.map((r) => r.roleCode)
            );
          }
        );

        getRegisterDictionaryList(appContext.serviceConfig).then(
          (dictionaries) => {
            appContext.onAppContextCacheItemUpdate(
              "registerDictionaries",
              dictionaries
            );
          }
        );
      } else {
        appContext.onAppContextCacheItemUpdate("activeRegister", undefined);
        appContext.onAppContextCacheItemUpdate(
          "registerDictionaries",
          undefined
        );
        loadingRegisterIdRef.current = 0;
      }
    }
  }
};

export default useRouteAppContextUpdate;
