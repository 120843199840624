import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { RoleAssignmentForm } from "../../../../services/roleAssignment";
import AddRoleAssignmentModal from "./AddRoleAssignmentModal";

interface AddRoleAssignmentModalButtonProps {
  registerId?: number;
  existingRoleAssignments: RoleAssignmentForm[];
  disabled?: boolean;
  onRoleAssignmentAdded: (roleAssignment: RoleAssignmentForm) => void;
}

const AddRoleAssignmentButton: React.FC<AddRoleAssignmentModalButtonProps> = ({
  registerId,
  existingRoleAssignments,
  disabled,
  onRoleAssignmentAdded,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleRoleAssignmentAdded = (roleAssignment: RoleAssignmentForm) => {
    handleModalClose();
    onRoleAssignmentAdded(roleAssignment);
  };

  const { t } = useTranslation();
  return (
    <>
      <NxpButton disabled={disabled} onClick={handleClick}>
        {t("app.common.Add")}
      </NxpButton>
      <AddRoleAssignmentModal
        registerId={registerId}
        existingRoleAssignments={existingRoleAssignments}
        modalVisible={modalVisible}
        onModalClose={handleModalClose}
        onRoleAssignmentAdded={handleRoleAssignmentAdded}
      />
    </>
  );
};

export default AddRoleAssignmentButton;
