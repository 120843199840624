import React, { useState } from "react";
import {
  SketchPicker,
  SketchPickerProps,
  Color,
  ColorResult,
} from "react-color";
import { createUseStyles } from "react-jss";
import clsx from "clsx";

export interface NxpColorPickerProps extends SketchPickerProps {
  onColorChange: (color: string) => void;
}

const useStyles = createUseStyles((theme) => ({
  swatch: (props: any) => ({
    width: "40px",
    height: "40px",
    visibility: "visible",
    background: props.backgroundColor,
    marginLeft: 16,
  }),
  dialog: {
    paddingTop: 5,
    position: "absolute",
    zIndex: 1001,
  },
}));

const NxpColorPicker: React.FC<NxpColorPickerProps> = ({
  color = "#000",
  onColorChange,
}) => {
  const [currentColor, setCurrentColor] = useState<Color>(color);
  const [isDisplay, setDisplay] = useState<boolean>(false);
  const classes = useStyles({ backgroundColor: currentColor });

  const handleColorChange = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setCurrentColor(color.hex);
    onColorChange(color.hex);
    setDisplay(!isDisplay);
  };

  const handleSwatchClick = () => {
    setDisplay(!isDisplay);
  };

  return (
    <>
      <button
        onClick={handleSwatchClick}
        className={clsx("nxpButton", "swatch", classes.swatch)}
      >
        <br />
      </button>
      {isDisplay ? (
        <div className={clsx("dialog", classes.dialog)}>
          <SketchPicker
            color={currentColor}
            onChangeComplete={handleColorChange}
          />
        </div>
      ) : null}
    </>
  );
};

export default NxpColorPicker;
