import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { range } from "lodash";

import { NxpFontFormatter } from "../../../components/NxpFontFormatter";
import { PrintableComponentValue } from "../../../services/registerPrintables";

export interface EditComponentModalProp {
  value: PrintableComponentValue;
  onSave: (value: PrintableComponentValue) => void;
  onClose: () => void;
}

export const EditComponentModal: React.FC<EditComponentModalProp> = ({
  value,
  onClose,
  onSave,
}) => {
  const [formValue, setFormValue] =
    React.useState<PrintableComponentValue>(value);

  const onSaveComponent = () => {
    onSave(formValue);
  };

  const getFontSizeOptions = (): any[] =>
    range(6, 61).map((value: number) => {
      return { label: value.toString(), value: value };
    });

  const handleFormStateChange = (
    fieldName: keyof PrintableComponentValue,
    value: unknown
  ) => {
    setFormValue((prevState) => {
      let result = {
        ...prevState,
        [fieldName]: value,
      };
      return result;
    });
  };

  const handleFontElementChange = (
    bold: boolean,
    italic: boolean,
    color: string
  ) => {
    setFormValue((prevState) => ({
      ...prevState,
      appearance: { isBold: bold, isItalic: italic, fontColor: color },
    }));
  };

  const { t } = useTranslation();

  const fieldItems: NxpFormGridItemProps<PrintableComponentValue>[] = [
    {
      label: t("EditComponentModal.Width"),
      itemFieldName: "sizeWidth",
      controlType: "inputNumber",
      span: 12,
    },
    {
      label: t("EditComponentModal.Height"),
      itemFieldName: "sizeHeight",
      controlType: "inputNumber",
      span: 12,
    },
    {
      label: t("EditComponentModal.X-Position"),
      itemFieldName: "x",
      controlType: "inputNumber",
      span: 12,
    },
    {
      label: t("EditComponentModal.Y-Position"),
      itemFieldName: "y",
      controlType: "inputNumber",
      span: 12,
    },
    {
      label: t("EditComponentModal.FontSize"),
      itemFieldName: "fontSize",
      controlType: "select",
      controlProps: {
        options: getFontSizeOptions(),
      },
      span: 12,
    },
    {
      label: t("EditComponentModal.Appearance"),
      itemFieldName: "custom",
      controlType: "custom",
      customContent: (
        <NxpFontFormatter
          isBold={formValue.appearance?.isBold ?? false}
          isItalic={formValue.appearance?.isItalic ?? false}
          fontColor={formValue.appearance?.fontColor ?? "#000"}
          onFontElementsChanged={handleFontElementChange}
        />
      ),
      span: 12,
    },
  ];

  return (
    <NxpModal
      className="form-printable-edit-title"
      visible={true}
      title={value.component.key}
      onCancel={() => {
        onClose();
      }}
      footer={
        <>
          <NxpButton type="text" onClick={onSaveComponent}>
            {t("EditComponentModal.Continue")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        formState={formValue}
        formItems={fieldItems}
        onFormStateChange={handleFormStateChange}
      />
    </NxpModal>
  );
};

export default EditComponentModal;
