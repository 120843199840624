import { FilterComparer } from "../components/AppFilter/AppFilter";
import { apiRequest, ServiceConfig } from "../utils/backend";
import { buildQueryString, QueryParams } from "../utils/backend/query";
import { User } from "./app";
import { UploadFile } from "./file";
import {
  extractFormConfigFields,
  RegisterCustomForm,
  RegisterCustomFormField,
} from "./registerCustomForm";
import { RegisterDictionary } from "./registerDictionary";
import { RegisterStatus } from "./registerStatus";

export interface RegisterFormEntryState {
  entryId: number;
  updatedOn: Date;
  id: number;
  authUserIds: number[];
  createdOn: Date;
  status: RegisterStatus;
  statusId: number;
}

export interface RegisterFormEntry {
  id: number;
  registerId: number;
  formNumber: string;
  seqNumber: number;

  createdOn: Date;
  updatedOn: Date;
  formConfigId: number;
  formConfig: RegisterCustomForm;

  entryState: RegisterFormEntryState;

  contentDetails: {
    [key: string]: any;
  };

  // added for CPCS-976
  creatorUserId?: {
    [key: string]: any;
  };
}

const getFilterFieldName = (
  fieldName: string,
  fieldValue: string,
  formioFieldDef: RegisterCustomFormField,
  registerDictionaries: RegisterDictionary[]
) => {
  switch (formioFieldDef?.type) {
    case "selectDictionary":
      const dict = registerDictionaries.find(
        (dict) => dict.id === formioFieldDef.dictionaryId
      )?.dict_pair;
      if (dict) {
        const dictEntry = Object.entries(dict).find(
          (entry) =>
            entry[1].toLocaleLowerCase() === fieldValue.toLocaleLowerCase()
        );
        if (dictEntry) {
          return `${fieldName}:/${dictEntry[1]}`;
        }
      }
      return `${fieldName}:/${fieldValue}`;
    case "selectUser":
      return `${fieldName}:/name`;
    default:
      return fieldName;
  }
};

const getFilterFieldValue = (
  fieldValue: string,
  formioFieldDef: RegisterCustomFormField,
  registerDictionaries: RegisterDictionary[]
) => {
  switch (formioFieldDef?.type) {
    case "selectDictionary":
      const dict = registerDictionaries.find(
        (dict) => dict.id === formioFieldDef.dictionaryId
      )?.dict_pair;
      if (dict) {
        const dictEntry = Object.entries(dict).find(
          (entry) =>
            entry[1].toLocaleLowerCase() === fieldValue.toLocaleLowerCase()
        );
        if (dictEntry) {
          return dictEntry[0];
        }
      }
      return "";
    default:
      return fieldValue;
  }
};

const getSorterFieldName = (
  fieldName: string,
  formioFieldDef: RegisterCustomFormField
) => {
  switch (formioFieldDef?.type) {
    case "selectUser":
      return `${fieldName}:/name`;
    default:
      return fieldName;
  }
};

export const getRegisterFormEntryList = async (
  serviceConfig: ServiceConfig,
  query: QueryParams,
  formConfigSpec: RegisterCustomFormField[], // need for building filter
  registerDictionaries: RegisterDictionary[] // need for building dictionary filter
) => {
  const queryLocal = {
    limit: query.limit,
    offset: query.offset,
  };

  if (query.sorters.length) {
    const formioFieldDef = extractFormConfigFields(
      formConfigSpec,
      false,
      false,
      []
    ).find(
      (field) =>
        field.key === query.sorters[0].column.replace(/^contentDetails:\//g, "")
    );
    Object.assign(queryLocal, {
      sortColumn: getSorterFieldName(query.sorters[0].column, formioFieldDef),
      sortOrder: query.sorters[0].order,
    });
  }

  if (query.filters.length) {
    // handle field from formio content

    Object.assign(queryLocal, {
      filters: query.filters.map((filter) => {
        if (filter.filterColumn.fieldName.startsWith("contentDetails.")) {
          const fieldName = filter.filterColumn.fieldName.replace(
            /^contentDetails\./g,
            ""
          );
          const formioFieldDef = extractFormConfigFields(
            formConfigSpec,
            false,
            false,
            []
          ).find((field) => field.key === fieldName);
          return {
            column: getFilterFieldName(
              fieldName,
              filter.filterValue,
              formioFieldDef,
              registerDictionaries
            ),
            type:
              "Json" +
              Object.entries(FilterComparer).find(
                (entry) => entry[1] === filter.filterComparer
              )?.[0],
            value: getFilterFieldValue(
              filter.filterValue,
              formioFieldDef,
              registerDictionaries
            ),
          };
        }
        return {
          column: filter.filterColumn.fieldName,
          type: Object.entries(FilterComparer).find(
            (entry) => entry[1] === filter.filterComparer
          )?.[0],
          value: filter.filterValue,
        };
      }),
    });
  }

  return await apiRequest<{
    data: RegisterFormEntry[];
    form_config: RegisterCustomForm;
    pagination: {
      limit: number;
      offset: number;
      countAll: number;
    };
  }>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${
      serviceConfig.registerId
    }/form/entries${buildQueryString({
      query: queryLocal,
    })}`,
    serviceConfig
  );
};

export const getRegisterFormEntry = async (
  formEntryId: number,
  registerId: number,
  serviceConfig: ServiceConfig,
  override?: string
) => {
  return await apiRequest<RegisterFormEntry>(
    `/powerflow/entities/${
      serviceConfig.entityId
    }/registers/${registerId}/form/entries/${formEntryId}${
      override ? "?o=" + override : ""
    }`,
    serviceConfig
  );
};

export const createRegisterFormEntry = async (
  formConfigId: number,
  entryContent: object,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFormEntry>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entry`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        formConfigId: formConfigId,

        // updated for CPCS-680
        contentDetails: entryContent,
      }),
    }
  );
};

export const updateRegisterFormEntry = async (
  formEntryId: number,
  formContent: object,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFormEntry>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({ contentDetails: formContent }),
    }
  );
};

export const cancelRegisterFormEntry = async (
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterFormEntry>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/cancel`,
    serviceConfig,
    {
      method: "PATCH",
    }
  );
};

export interface FormAttachment {
  id: number;
  formid: number;
  regid: number;
  file?: UploadFile;
  fileSize: string;
  subject: string;
  createdOn: Date;
  updatedOn: Date;
}

export interface FormAttachmentForm
  extends Pick<FormAttachment, "file" | "subject"> {}

export const getFormEntryAttachmentList = async (
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormAttachment[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/form_attachments`,
    serviceConfig
  );
};
export const getFormEntryAttachment = async (
  id: number,
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormAttachment>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/form_attachment/${id}`,
    serviceConfig
  );
};

export const createFormEntryAttachment = async (
  formEntryId: number,
  attachmentCreateForm: FormAttachmentForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormAttachment>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/form_attachments`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(attachmentCreateForm),
    }
  );
};

export const updateFormEntryAttachment = async (
  id: number,
  formEntryId: number,
  attachmentUpdateForm: FormAttachmentForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FormAttachment>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/form_attachment/${id}`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(attachmentUpdateForm),
    }
  );
};

export const deleteFormEntryAttachment = async (
  id: number,
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/form_attachment/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export interface FormEntryLog {
  id: number;
  editor?: User;
  changedFields: {
    [key: string]: {
      old: any;
      new: any;
    };
  };
  action: string;
  parentType: string;
  sourceType: string;
  createdOn: Date;
}

export const getFormEntryLogs = async (
  formEntryId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<{ results: FormEntryLog[] }>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/form/entries/${formEntryId}/logs`,
    serviceConfig
  ).then((data) => data.results);
};
