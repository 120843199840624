import React, {
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import useAppContext from '../../../hooks/useAppContext';
import {
  ChatMessage,
  ChatPost,
  getChatFiles,
  getChats,
  postChat,
} from '../../../services/approvalsChat';
import { useFileService } from '../../../services/file';
import {
  FormEntryApproval,
  releaseFormEntryApproval,
  relockFormEntryApproval,
} from '../../../services/registerFormEntryApproval';
import notify from '../../../utils/notify';
import ApprovalChatsLayout from './ApprovalChatsLayout';

interface ApprovalChatsContainerProps {
  approval: FormEntryApproval;
  onApprovalLockChange: () => void;
}

function concatMessages(messages: ChatMessage[]) {
  const messagesCopy = [...messages];
  const concatenated: ChatMessage[] = [];
  console.log(messagesCopy);
  messagesCopy
    .sort((a, b) => { return b.createdOn.valueOf() - a.createdOn.valueOf() })
    .forEach((message, i) => {
      if (i === 0) {
        concatenated.push(message);
      } else if (
        concatenated[concatenated.length - 1].attachedFile != null ||
        message.attachedFile != null
      ) {
        concatenated.push(message);
      } else if (
        concatenated[concatenated.length - 1].author.id !== message.author.id ||
        Math.abs(
          concatenated[concatenated.length - 1].createdOn.getTime() -
          message.createdOn.getTime()
        ) > 600000
      ) {
        concatenated.push(message);
      } else if (
        concatenated[concatenated.length - 1].attachedFile == null &&
        message.attachedFile == null &&
        concatenated[concatenated.length - 1].author.id === message.author.id &&
        Math.abs(
          concatenated[concatenated.length - 1].createdOn.getTime() -
          message.createdOn.getTime()
        ) < 600000
      ) {
        const last = concatenated.pop();
        const mutant = {
          ...last,
          message: message.message + "\n" + last?.message,
        };
        //@ts-ignore
        concatenated.push(mutant);
      }
    });
  return concatenated.sort((a, b) => { return b.createdOn.valueOf() - a.createdOn.valueOf() });
}

const ApprovalChatsContainer: React.FC<ApprovalChatsContainerProps> = (
  props
) => {
  let { approval, onApprovalLockChange } = props;

  const appContext = useAppContext();

  const fileService = useFileService(
    appContext.serviceConfig.keyCloakToken || ""
  );
  //   const history = useHistory();

  const [readySignFiles, setReadySignFiles] = React.useState<boolean>(false);

  const [loaded, setLoaded] = React.useState<boolean>(true);
  const [form, setForm] = React.useState<ChatPost>();
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);
  const [posters, setPosters] = React.useState<string[]>([]);
  const [isReleased, setIsRelease] = React.useState<boolean>(
    approval.entry.entryState.status.releaseStatus
  );

  const { t } = useTranslation();

  const callGetChats = useCallback(() => {
    getChats(
      //@ts-ignore
      approval.registerId,
      appContext.serviceConfig,
      approval.entryId,
      approval.id
    ).then((data) => {
      const reversedData = [...data].reverse();
      const reducePosters = reversedData.reduce(
        (acc: string[], curr: ChatMessage) => {
          if (!acc.includes(curr.author.id)) {
            acc.push(curr.author.id);
          }
          return acc;
        },
        []
      );
      setPosters(reducePosters);
      getChatFiles(approval.id, appContext.serviceConfig).then((fdata) => {
        fdata.forEach((f) => {
          data.forEach((d) => {
            if (f.chatId === d.id) {
              d.attachedFile = f;
            }
          });
        });
        //THE FILES SHOULD BE SIGNED FROM THE BACKEND ALREADY!!!
        setMessages([...concatMessages(data)]);
        setReadySignFiles(true);
        setLoaded(true);
      });
    });
  }, [
    appContext.serviceConfig,
    approval.entryId,
    approval.id,
    approval.registerId,
  ]);

  // added for CPCS-807 - release for amendment
  const enableAmmend = useCallback(() => {
    releaseFormEntryApproval(
      approval.registerId,
      approval.entryId,
      approval.id,
      approval.currentStepId,
      appContext.serviceConfig
    )
      .then((value: FormEntryApproval) => {
        notify.info(
          t("ApprovalChatsContainer.ApprovalRequestIsNowReleasedForChanges")
        );
        setIsRelease(true);
        onApprovalLockChange();
      })
      .catch((ex: Error) => {
        notify.error(ex.message);
      });
  }, [
    appContext.serviceConfig,
    approval.currentStepId,
    approval.entryId,
    approval.id,
    approval.registerId,
    onApprovalLockChange,
    t,
  ]);

  function enableRelock() {
    relockFormEntryApproval(
      approval.registerId,
      approval.entryId,
      approval.id,
      approval.currentStepId,
      appContext.serviceConfig
    )
      .then((value: FormEntryApproval) => {
        notify.info(
          t("ApprovalChatsContainer.ApprovalRequestIsNowLockedFromChanges")
        );
        setIsRelease(false);
        onApprovalLockChange();
      })
      .catch((ex: Error) => {
        notify.error(ex.message);
      });
  }

  const signFile = useCallback(
    async (assetIds: { assetIds: string[] }) => {
      let response;
      await fileService.sign(approval.registerId, assetIds).then((res) => {
        response = res;
      });
      //@ts-ignore
      return response.assets[0].url;
    },
    [approval.registerId, fileService]
  );

  const handleSignFiles = useCallback(() => {
    messages.forEach((m) => {
      if (m.attachedFile?.file.id) {
        signFile({ assetIds: [m.attachedFile.file.assetId!] }).then((res) => {
          //@ts-ignore
          m.attachedFile.file.url = res;
        });
      }
    });
  }, [messages, signFile]);

  useEffect(() => {
    if (readySignFiles) {
      handleSignFiles();
    }
  }, [readySignFiles, handleSignFiles]);

  useEffect(() => {
    callGetChats();
  }, [appContext.activeEntity, appContext.serviceConfig, callGetChats]);

  const handlePostChat = React.useCallback(() => {
    postChat(
      //@ts-ignore
      approval.registerId,
      appContext.serviceConfig,
      approval.entryId,
      approval.id,
      //@ts-ignore
      form
    ).then((data) => {
      console.log(data);
      callGetChats();
    });
  }, [form, appContext.serviceConfig, approval, callGetChats]);

  React.useEffect(() => {
    if (loaded && form) {
      handlePostChat();
    }
  }, [loaded, form, handlePostChat]);

  return loaded ? (
    <ApprovalChatsLayout
      //@ts-ignore
      userId={appContext.activeUser.id}
      messages={messages}
      posters={posters}
      //@ts-ignore
      setForm={setForm}
      formEntry={approval.entry}
      approval={approval}
      isRelease={isReleased}
      callGetChats={callGetChats}
      setAmmendApproval={enableAmmend}
      setRelockApproval={enableRelock}
    ></ApprovalChatsLayout>
  ) : null;
};

export default ApprovalChatsContainer;
