import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";

import { LabeledValue } from "antd/lib/select";
import deepClone from "lodash/cloneDeep";

import {
  RuleCondition,
  RuleConditionGroup,
  RuleConjunctive,
  RuleOperator,
} from "../../../../services/registerRule";
import AddConditionButton from "./AddConditionButton";
import RuleConditionLayout from "./RuleConditionLayout";
import RuleGroupLayout from "./RuleGroupLayout";

interface RuleBodyProps {
  ruleBody?: RuleConditionGroup | RuleCondition;
  fieldOptions: LabeledValue[];
  errorKeys: number[];
  onRuleBodyChange: (ruleBody: RuleConditionGroup | RuleCondition) => void;
}

// this component is created primarily for handling drag and drop

const RuleBody: React.FC<RuleBodyProps> = ({
  ruleBody = {
    key: new Date().getTime(),
    field: "",
    operator: RuleOperator.Equal,
    value: "",
  },
  fieldOptions,
  errorKeys,
  onRuleBodyChange,
}) => {
  const findCondition = (
    findKey: number,
    conditionGroup: RuleConditionGroup
  ) => {
    let conditionFound = undefined;
    conditionGroup.children.forEach((groupChild) => {
      if ("conjunctive" in groupChild) {
        const groupChildCondition = findCondition(findKey, groupChild);
        if (groupChildCondition) {
          conditionFound = groupChildCondition;
        }
      } else {
        if (groupChild.key === findKey) {
          conditionFound = groupChild;
        }
      }
    });
    return conditionFound;
  };

  const findConditionGroup = (
    findKey: number,
    conditionGroup: RuleConditionGroup
  ) => {
    if (conditionGroup.key === findKey) {
      return conditionGroup;
    }

    let groupFound = undefined;
    conditionGroup.children.forEach((groupChild) => {
      if ("conjunctive" in groupChild) {
        if (groupChild.key === findKey) {
          groupFound = groupChild;
        } else {
          const groupChildGroup = findConditionGroup(findKey, groupChild);
          if (groupChildGroup) {
            groupFound = groupChildGroup;
          }
        }
      }
    });
    return groupFound;
  };

  const handleConditionChange = (ruleCondition: RuleCondition) => {
    if ("conjunctive" in ruleBody) {
      const conditionFound = findCondition(ruleCondition.key, ruleBody);
      Object.assign(conditionFound || {}, { ...ruleCondition });
      onRuleBodyChange(ruleBody);
    } else {
      onRuleBodyChange(ruleCondition);
    }
  };

  const deleteGroup = (deleteKey: number, fromGroup: RuleConditionGroup) => {
    fromGroup.children = fromGroup.children.filter(
      (group) =>
        !("conjunctive" in group) ||
        ("conjunctive" in group && group.key !== deleteKey)
    );

    // delete recursively
    fromGroup.children.forEach((groupChild) => {
      if ("conjunctive" in groupChild) {
        deleteGroup(deleteKey, groupChild);
      }
    });
  };

  const ungroupCondition = (fromGroup: RuleConditionGroup) => {
    // console.log("fffffffffffffff", JSON.stringify(fromGroup));

    if (!fromGroup.children) {
      return false;
    }
    if (fromGroup.children.length === 1) {
      if (!("conjunctive" in fromGroup.children[0])) {
        Object.assign(fromGroup, {
          field: fromGroup.children[0].field,
          fieldType: fromGroup.children[0].fieldType,
          operator: fromGroup.children[0].operator,
          value: fromGroup.children[0].value,
        });
        //@ts-ignore
        delete fromGroup.conjunctive;
        //@ts-ignore
        delete fromGroup.children;
      }
      return true;
    } else {
      // upgroup recursively
      let upgrouped = false;
      fromGroup.children.forEach((groupChild) => {
        if (!upgrouped) {
          if ("conjunctive" in groupChild) {
            upgrouped = ungroupCondition(groupChild);
          }
        }
      });
      return upgrouped;
    }
  };

  const handleConditionDelete = (
    conditionKey: number,
    conditionGroupKey: number
  ) => {
    const groupFound = findConditionGroup(
      conditionGroupKey,
      ruleBody as RuleConditionGroup
    );
    if (groupFound) {
      groupFound.children = groupFound.children.filter(
        (groupChild) => groupChild.key !== conditionKey
      );
      if (groupFound.children.length === 1) {
        while (ungroupCondition(ruleBody as RuleConditionGroup)) {
          // nothing to do
        }
      }
      // shouldn't happen as group with only one condition should be ungrouped already
      if (groupFound.children?.length === 0) {
        deleteGroup(groupFound.key, ruleBody as RuleConditionGroup);
      }
    }
    onRuleBodyChange(deepClone(ruleBody));
  };

  const handleConditionGroup = (ruleCondition: RuleCondition) => {
    const conditionFound =
      "conjunctive" in ruleBody
        ? findCondition(ruleCondition.key, ruleBody)
        : ruleCondition;
    if (conditionFound) {
      Object.assign(conditionFound, {
        key: conditionFound.key,
        conjunctive: "and",
        children: [
          {
            key: new Date().getTime() - 1,
            field: conditionFound.field,
            fieldType: conditionFound.fieldType,
            operator: conditionFound.operator,
            value: conditionFound.value,
          },
          {
            key: new Date().getTime(),
            field: "",
            fieldType: undefined,
            operator: RuleOperator.Equal,
            value: "",
          },
        ],
        field: undefined,
        fieldType: undefined,
        operator: undefined,
        value: undefined,
      });
    }
    onRuleBodyChange(deepClone(ruleBody));
  };

  const handleAddCondition = (conditionGroupKey: number) => {
    const groupFound = findConditionGroup(
      conditionGroupKey,
      ruleBody as RuleConditionGroup
    );
    if (groupFound) {
      groupFound.children = [
        ...groupFound.children,
        {
          key: new Date().getTime(),
          field: "",
          fieldType: undefined,
          operator: RuleOperator.Equal,
          value: "",
        },
      ];
      onRuleBodyChange(deepClone(ruleBody));
    }
  };

  const handleConjunctiveChange = (
    conjunctive: RuleConjunctive,
    conditionGroupKey: number
  ) => {
    const groupFound = findConditionGroup(
      conditionGroupKey,
      ruleBody as RuleConditionGroup
    );
    if (groupFound) {
      groupFound.conjunctive = conjunctive;
    }
    onRuleBodyChange(deepClone(ruleBody));
  };

  const handleRuleDrop = (
    draggingRule: RuleConditionGroup | RuleCondition,
    draggingRuleParent: RuleConditionGroup,
    dropToRule: RuleConditionGroup | RuleCondition | undefined,
    dropToRuleParent: RuleConditionGroup
  ) => {
    if (draggingRuleParent.key === dropToRuleParent.key) {
      dropToRuleParent.children = dropToRuleParent.children.filter(
        (child) => child.key !== draggingRule.key
      );
    } else {
      draggingRuleParent.children = draggingRuleParent.children.filter(
        (child) => child.key !== draggingRule.key
      );
    }

    if (dropToRule) {
      dropToRuleParent.children.splice(
        dropToRuleParent.children.indexOf(dropToRule),
        0,
        draggingRule
      );
    } else {
      dropToRuleParent.children.push(draggingRule);
    }

    if (draggingRuleParent.children.length === 1) {
      while (ungroupCondition(ruleBody as RuleConditionGroup)) {
        // nothing to do
      }
    }
    onRuleBodyChange(deepClone(ruleBody));
  };

  const { t } = useTranslation();

  return (
    <DndProvider backend={HTML5Backend}>
      {"conjunctive" in ruleBody ? (
        <RuleGroupLayout
          fieldOptions={fieldOptions}
          isRoot
          ruleGroup={ruleBody}
          errorKeys={errorKeys}
          onAddCondition={handleAddCondition}
          onConjunctiveChange={handleConjunctiveChange}
          onConditionChange={handleConditionChange}
          onConditionDelete={handleConditionDelete}
          onConditionGroup={handleConditionGroup}
          onRuleDrop={handleRuleDrop}
        />
      ) : (
        <>
          <RuleConditionLayout
            isRoot
            fieldOptions={fieldOptions}
            ruleCondition={ruleBody}
            error={
              errorKeys.includes(ruleBody.key)
                ? t("app.common.IncompleteCondition")
                : undefined
            }
            onConditionChange={handleConditionChange}
            onConditionDelete={() => {}} // no delete if only one rule
            onConditionGroup={handleConditionGroup}
          />
          <AddConditionButton
            // add condition at the root level have the same effect as "build group"
            onClick={() => handleConditionGroup(ruleBody)}
            onRuleDrop={handleRuleDrop}
          />
        </>
      )}
    </DndProvider>
  );
};

export default RuleBody;
