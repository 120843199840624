import { apiRequest, ServiceConfig } from "../utils/backend";

export interface RegisterDictionaryItemForm {
  dictKey: string;
  dictLabel: string;
}

// export interface RegisterDictionaryItemForm
//   extends Omit<RegisterDictionaryItem, "id"> {}

// export const getDictionaryItemList = async (
//   dictionaryId: number,
//   serviceConfig: ServiceConfig
// ) => {
//   return await apiRequest<RegisterDictionaryItem[]>(
//     `/powerflow/registers/${serviceConfig.registerId}/dicts/${dictionaryId}/items`,
//     serviceConfig
//   );
// };

// export const getRegisterDictionaryItem = async (
//   id: number,
//   dictionaryId: number,
//   serviceConfig: ServiceConfig
// ) => {
//   return await apiRequest<RegisterDictionaryItem>(
//     `/powerflow/registers/${serviceConfig.registerId}/dicts/${dictionaryId}/items/${id}`,
//     serviceConfig
//   );
// };

// export const createRegisterDictionaryItem = async (
//   dictionaryId: number,
//   registerDictionaryItemForm: RegisterDictionaryItemForm,
//   serviceConfig: ServiceConfig
// ) => {
//   return await apiRequest<RegisterDictionaryItem>(
//     `/powerflow/registers/${serviceConfig.registerId}/dicts/${dictionaryId}/items`,
//     serviceConfig,
//     {
//       method: "POST",
//       body: JSON.stringify(registerDictionaryItemForm),
//     }
//   );
// };

// export const updateRegisterDictionaryItem = async (
//   id: number,
//   dictionaryId: number,
//   registerDictionaryItem: RegisterDictionaryItemForm,
//   serviceConfig: ServiceConfig
// ) => {
//   return await apiRequest<RegisterDictionaryItem>(
//     `/powerflow/dicts/${dictionaryId}/items/${id}`,
//     serviceConfig,
//     {
//       method: "PATCH",
//       body: JSON.stringify(registerDictionaryItem),
//     }
//   );
// };

// export const deleteRegisterDictionaryItem = async (
//   id: number,
//   dictionaryId: number,
//   serviceConfig: ServiceConfig
// ) => {
//   return await apiRequest(
//     `/powerflow/dicts/${dictionaryId}/items/${id}`,
//     serviceConfig,
//     {
//       method: "DELETE",
//     }
//   );
// };

export interface RegisterDictionary {
  id: number;
  dict_name: string;
  dict_pair: {
    [key in string]: string;
  };
  updatedOn: Date;
  createdOn: Date;
}

export interface RegisterDictionaryForm
  extends Pick<RegisterDictionary, "dict_name" | "dict_pair"> {}

export const getRegisterDictionaryList = async (
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterDictionary[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/dicts`,
    serviceConfig
  );
};

export const getRegisterDictionary = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterDictionary[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/dicts/${id}`,
    serviceConfig
  );
  // .then((data) => (data.length ? data[0] : undefined));
};

export const createRegisterDictionary = async (
  dictionaryForm: RegisterDictionaryForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterDictionary>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/dicts`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(dictionaryForm),
    }
  );
};

export const updateRegisterDictionary = async (
  id: number,
  dictionaryForm: RegisterDictionaryForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterDictionary>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/dicts/${id}`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(dictionaryForm),
    }
  );
};

export const deleteRegisterDictionary = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/dicts/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
