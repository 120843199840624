import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpDateDisplay,
  NxpFormSurvey,
  NxpFormSurveyItemProps,
  NxpPanel,
} from "@nexploretechnology/nxp-ui";
import { Table } from "antd";

import FileLink from "../../components/AppFileUpload/FileLink";
import useAppContext from "../../hooks/useAppContext";
import { useAsync } from "../../hooks/useAsync";
import { UploadFile } from "../../services/file";
import { extractFormConfigFields } from "../../services/registerCustomForm";
import { getRegisterFormEntry } from "../../services/registerFormEntry";
import { getRegisterListingConfig } from "../../services/registerListing";
import { DateFormatPattern } from "../../utils/date/formatDate";

const useStyles = createUseStyles((theme) => ({
  keyInfo: {},
  signatureImg: {
    width: 180,
  },
}));

interface FormEntryKeyInformationProps {
  formEntryId: number;
  registerId: number;
  isApprovalForm?: boolean;
}

const FormEntryKeyInformation: React.FC<FormEntryKeyInformationProps> = ({
  formEntryId,
  registerId,
  isApprovalForm,
}) => {
  const appContext = useAppContext();

  const classes = useStyles();

  const [formEntryAsync] = useAsync(
    () =>
      getRegisterFormEntry(
        formEntryId,
        registerId,
        appContext.serviceConfig,
        isApprovalForm ? appContext.activeUser?.keycloakUserId : undefined
      ),
    undefined,
    "get form entry",
    true // as requested -  avoid showing 405 error in case when the approver has not access to the register
  );

  const [listConfigAsync] = useAsync(
    () =>
      getRegisterListingConfig(
        registerId,
        appContext.serviceConfig,
        isApprovalForm ? appContext.activeUser?.keycloakUserId : undefined
      ),
    undefined,
    "get register listing config",
    true // as requested -  avoid showing 405 error in case when the approver has not access to the register
  );

  const { t } = useTranslation();

  function getComponentRender(
    type: string,
    value: any,
    source?: any
  ): ReactNode | undefined {
    let result: ReactNode = undefined;
    switch (type) {
      case "selectDictionary":
        result = value && value[Object.keys(value)[0]];
        break;
      case "selectCPCSDictionary":
        result = value && value.name;
        break;
      case "selectUser":
        result = value && value.name;
        break;
      case "fileUpload":
        result = <FileLink files={value} />;
        break;
      case "signature":
        result = (
          <img src={value} className={classes.signatureImg} alt="signature" />
        );
        break;
      case "datetime":
        result = (
          <NxpDateDisplay
            date={value}
            format={DateFormatPattern.dateWithTime}
          />
        );
        break;
      case "survey":
        result = (
          <div>
            <div>
              <strong>
                <u>Survey</u>
              </strong>
            </div>
            {Object.keys(value).map((key: string) => (
              <ul>
                <li>
                  {key}: {value[key]}
                </li>
              </ul>
            ))}
          </div>
        );
        break;
      case "datagrid":
        const components: { [key: string]: any }[] = Reflect.get(
          source,
          "components"
        );
        const columns: {
          title: string;
          dataIndex: string;
          key: string;
        }[] = components.map((component: { [key: string]: any }) => {
          return {
            title: component["label"],
            dataIndex: component["key"],
            key: component["key"],
            render:
              component["type"] === "fileUpload" ||
              component["type"] === "datetime"
                ? (text: UploadFile[], record: any, index: any) => {
                    return getComponentRender(component["type"], text);
                  }
                : null,
          };
        });
        result = (
          <Table
            pagination={false}
            columns={columns}
            dataSource={value}
            size="small"
          />
        );
        break;
    }
    return result;
  }

  if (formEntryAsync.data && listConfigAsync.data) {
    const configItems = listConfigAsync.data?.layout || [];
    const formItems: NxpFormSurveyItemProps<{ [key: string]: any }>[] =
      extractFormConfigFields(
        formEntryAsync.data.formConfig.definitions,
        true,
        false,
        []
      )
        .filter(
          //apply list config filter
          (spec) => {
            const configItem = configItems.find(
              (item) => item.key === spec.key
            );
            return (
              configItem &&
              configItem.approvedRequest.disabled === false &&
              (configItem.confidential.disabled ||
                appContext.registerAccessList["register-entry:special"])
            );
          }
        )
        .sort(
          //apply list config order
          (a, b) =>
            (configItems.find((item) => item.key === a.key)?.approvedRequest
              .order || 0) -
            (configItems.find((item) => item.key === b.key)?.approvedRequest
              .order || 0)
        )
        .map((field) => {
          const formData = formEntryAsync.data;
          const fieldValue = formData!.contentDetails[field.key];

          let customContent: ReactNode =
            getComponentRender(field.type, fieldValue, field) ?? fieldValue;
          // switch (field.type) {
          //   case "selectDictionary":
          //     customContent =
          //       fieldValue && fieldValue[Object.keys(fieldValue)[0]];
          //     break;
          //   case "selectUser":
          //     customContent = fieldValue && fieldValue.name;
          //     break;
          //   case "fileUpload":
          //     customContent = <FileLink files={fieldValue} />;
          //     break;
          //   case "signature":
          //     customContent = (
          //       <img
          //         src={fieldValue}
          //         className={classes.signatureImg}
          //         alt="signature"
          //       />
          //     );
          //     break;
          //   case "datetime":
          //     customContent = (
          //       <NxpDateDisplay
          //         date={fieldValue}
          //         format={DateFormatPattern.dateWithTime}
          //       />
          //     );
          //     break;
          //   case "survey":
          //     customContent = (
          //       <div>
          //         <div>Survey</div>
          //         {Object.keys(fieldValue).map((key: string) => (
          //           <div>
          //             <span>{key}:</span>
          //             <span>{fieldValue[key]}</span>
          //           </div>
          //         ))}
          //       </div>
          //     );
          //     break;
          //   case "datagrid":
          //     const components: { [key: string]: any }[] = Reflect.get(
          //       field,
          //       "components"
          //     );
          //     const columns: {
          //       title: string;
          //       dataIndex: string;
          //       key: string;
          //     }[] = components.map((component: { [key: string]: any }) => {
          //       return {
          //         title: component["label"],
          //         dataIndex: component["key"],
          //         key: component["key"],
          //         render:
          //           component["type"] === "fileUpload"
          //             ? (text: UploadFile[], record: any, index: any) => {
          //                 return text.length > 0 ? (
          //                   text.map((file) => (
          //                     <>
          //                       <PlaceholderPreviewer
          //                         url={file.url}
          //                         remove={undefined}
          //                       />
          //                       <div>{file.fileName}</div>
          //                     </>
          //                   ))
          //                 ) : (
          //                   <div />
          //                 );
          //               }
          //             : null,
          //       };
          //     });
          //     customContent = (
          //       <Table columns={columns} dataSource={fieldValue} size="small" />
          //     );
          //     break;
          // }

          return {
            editing: false,
            controlType: "custom",
            itemFieldName: "custom",
            customContent,
            label: field.label,
            span: field.type === "datagrid" ? 2 : 1,
          };
        }) || [];
    return formItems.length ? (
      <NxpPanel titleContent={t("app.common.KeyInformation")}>
        <NxpFormSurvey className={classes.keyInfo} formItems={formItems} />
      </NxpPanel>
    ) : null;
  }
  return null;
};

export default FormEntryKeyInformation;
