import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormTable,
  NxpFormTableColumnProps,
  NxpHeader,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Input } from "antd";
import Select, { LabeledValue } from "antd/lib/select";

import { ValidationResult } from "../../../../hooks/useValidate";
import {
  extractFormConfigFields,
  RegisterCustomForm,
} from "../../../../services/registerCustomForm";
import {
  RegisterFlow,
  RegisterFlowStep,
} from "../../../../services/registerFlow";
import { RegisterStatus } from "../../../../services/registerStatus";
import { RoleAssignment } from "../../../../services/roleAssignment";

interface FlowDetailsLayoutProps {
  className?: string;
  flow: RegisterFlow;
  statuses: RegisterStatus[];
  flowSteps: RegisterFlowStep[];
  editItem?: RegisterFlowStep;
  validationError: ValidationResult<RegisterFlowStep>;
  saveInProgress: boolean;
  roles: RoleAssignment[];
  registerCustomForm?: RegisterCustomForm;
  headerActionContent: ReactNode;

  onRowDelete: (deleteItem: RegisterFlowStep) => void;
  onRowEdit: (editItem: RegisterFlowStep) => void;
  onRowSave: () => void;
  onRowCancel: () => void;
  onFormStateChange: (
    fieldName: keyof RegisterFlowStep,
    value: unknown
  ) => void;
}

const FlowDetailsLayout: React.FC<FlowDetailsLayoutProps> = ({
  className,
  flow,
  statuses,
  flowSteps,
  editItem,
  validationError,
  saveInProgress,
  registerCustomForm,
  headerActionContent,
  onRowDelete,
  onRowEdit,
  onRowSave,
  onRowCancel,
  onFormStateChange,
}) => {
  const { t } = useTranslation();

  const getStatusOptions = () =>
    statuses
      ? statuses.map((status) => ({
          label: status.statusName,
          value: status.id,
        }))
      : [];
  const formUserSelectFields: LabeledValue[] = extractFormConfigFields(
    registerCustomForm?.definitions || [],
    true,
    false,
    []
  )
    .filter((field) => field.type === "selectUser")
    .map((field) => ({ label: field.label, value: field.key }));

  const checkRoleFromForm = (roleCodes: string[]) => {
    return roleCodes.length > 0 && roleCodes[0].startsWith("$");
  };

  const columns: NxpFormTableColumnProps<RegisterFlowStep>[] = [
    {
      title: t("app.common.Order"),
      dataIndex: "order",
      width: 100,
      fixed: "left",
      sorter: (a, b) => sorterForNumber(a.order, b.order),
      formItemProps: {
        controlType: "select",
        controlProps: {
          options: Array(flowSteps.length)
            .fill(null)
            .map((_, idx) => ({
              label: idx + 1,
              value: idx + 1,
            })),
        },
      },
    },
    {
      title: t("app.common.name"),
      dataIndex: "stepName",
      width: 150,
      sorter: (a, b) => sorterForString(a.stepName, b.stepName),
      formItemProps: {
        controlType: "input",
      },
    },
    {
      title: t("app.common.RoleFromForm"),
      dataIndex: "rolecodeByFormValue",
      width: 150,
      // sorter: (a, b) => sorterForString(a.rolecode, b.rolecode),
      render: (_, record) => {
        if (editItem?.id === record.id) {
          const roleFromForm = checkRoleFromForm(editItem.roleCodes)
            ? editItem.roleCodes[0].replace("$", "")
            : "";

          return (
            <Select
              style={{ width: "100%" }}
              value={roleFromForm}
              allowClear
              options={formUserSelectFields}
              onChange={(val) =>
                onFormStateChange("roleCodes", val ? [`$${val}`] : [])
              }
            />
          );
        }

        return checkRoleFromForm(record.roleCodes)
          ? formUserSelectFields.find(
              (opt) => opt.value === record.roleCodes[0].replace("$", "")
            )?.label
          : "";
      },
    },
    {
      title: t("app.common.RoleFromCode"),
      dataIndex: "rolecodeByInput",
      width: 150,
      // sorter: (a, b) => sorterForString(a.rolecode, b.rolecode),
      render: (_, record) => {
        if (editItem?.id === record.id) {
          return (
            <Input
              disabled={checkRoleFromForm(editItem.roleCodes)}
              value={
                checkRoleFromForm(editItem.roleCodes)
                  ? ""
                  : editItem.roleCodes.length
                  ? editItem.roleCodes[0]
                  : ""
              }
              onChange={(e) =>
                onFormStateChange(
                  "roleCodes",
                  e.target.value ? [e.target.value] : []
                )
              }
              onKeyPress={(e) => {
                if (e.key === "$") {
                  e.preventDefault();
                }
              }}
            />
          );
        }
        return checkRoleFromForm(record.roleCodes) || !record.roleCodes.length
          ? ""
          : record.roleCodes[0];
      },
    },
    {
      title: t("app.common.EditFields"),
      dataIndex: "editFields",
      width: 150,
      formItemProps: {
        controlType: "selectMultiple",
        controlProps: {
          options: registerCustomForm
            ? extractFormConfigFields(
                registerCustomForm.definitions,
                true,
                false,
                []
              ).map((field) => ({
                label: field.label,
                value: field.key,
              }))
            : [],
        },
      },
    },
    {
      title: t("app.common.ApproveStatus"),
      dataIndex: "approveStatusId",
      width: 150,
      sorter: (a, b) => sorterForNumber(a.approveStatusId, b.approveStatusId),
      formItemProps: {
        controlType: "select",
        controlProps: {
          options: getStatusOptions(),
        },
      },
    },
    {
      title: t("app.common.RejectStatus"),
      dataIndex: "rejectStatusId",
      width: 150,
      sorter: (a, b) => sorterForNumber(a.rejectStatusId, b.rejectStatusId),
      formItemProps: {
        controlType: "select",
        controlProps: {
          options: getStatusOptions(),
        },
      },
    },
  ];

  return (
    <div className={className}>
      <NxpHeader
        titleContent={`${t("app.common.SetupFlow")} - ${flow.flowName}`}
        actionContent={headerActionContent}
      />
      <NxpFormTable
        formState={editItem}
        onFormStateChange={onFormStateChange}
        validationError={validationError}
        useDefaultHeight
        saveInProgress={saveInProgress}
        itemCompareKey="id"
        onRowDelete={onRowDelete}
        onRowEdit={onRowEdit}
        onRowSave={onRowSave}
        onRowCancel={onRowCancel}
        columns={columns}
        dataSource={flowSteps}
      />
    </div>
  );
};

export default FlowDetailsLayout;
