// @ts-nocheck

///////////////////////////////////////////////// https://codesandbox.io/s/81qb6?file=/src/Custom/Toggle/index.js:1919-1931

import { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./FileUpload.form";

// import { getRegisterDictionary } from "../../../services/registerDictionary";
// import useAppContext from "../../../hooks/useAppContext";
import { UploadFile } from "../../../services/file";
import AppContext from "../../App/AppContext";
import keycloakInstance from "../../App/keycloak";
import { AppFileUpload } from "../../AppFileUpload";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const FileUploadInternal = class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  onFilesChange = (files: UploadFile[]) => {
    // if (
    //   // new file added
    //   (files.length &&
    //     (!this.state.value?.length || files[0].url !== this.state.value.url)) ||
    //   // existing file removed
    //   (!files.length && this.state.value?.url)
    // ) {
    this.setState({ value: files.length ? files : null }, () =>
      this.props.onChange(null, { changed: true })
    );
    // }
  };

  render() {
    return (
      <AppFileUpload
        token={this.props.token}
        disabled={this.props.disabled}
        fileLimit={this.props.fileLimit ? this.props.fileLimit : 1}
        onChange={this.onFilesChange}
        uploadedFiles={this.state.value ? this.state.value : []}
        registerId={this.props.registerId}
      />
    );
  }
};

// functional component do not work in formio, reason unknown
//
// const FileUploadInternal: React.FC<{
//...........
// };

export default class FileUpload extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "File Upload",
      icon: "upload",
      group: "Data",
      documentation: "",
      weight: 10,
      schema: FileUpload.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "fileUpload",
      label: "", //default label, if any
      multiple: true,
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    const [, entityId] = window.location.href.match(/entities\/([\w-]+)/);
    const [, registerId] = window.location.href.match(/registers\/([\d-]+)/);

    return ReactDOM.render(
      <FileUploadInternal
        dictionaryId={this.component.dictionaryId}
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        entityId={entityId}
        registerId={registerId}
        token={keycloakInstance.token}
        disabled={this.component.disabled}
        fileLimit={this.component.fileLimit}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  static contextType = AppContext;
}
