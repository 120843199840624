import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton, NxpModalProps } from "@nexploretechnology/nxp-ui";

import AddFormEntryModal from "./AddFormEntryModal";

interface AddFormEntryModalButtonProps {
  disabled: boolean;
  modalWidth: NxpModalProps["width"];
  onFormEntryAdded: () => void;
}

const AddFormEntryModalButton: React.FC<AddFormEntryModalButtonProps> = ({
  disabled,
  modalWidth,
  onFormEntryAdded,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleFormEntryAdded = () => {
    onFormEntryAdded();
    setModalVisible(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <NxpButton disabled={disabled} onClick={handleClick}>
        {t("AddFormEntryButton.AddEntry")}
      </NxpButton>
      {modalVisible && (
        <AddFormEntryModal
          modalVisible={modalVisible}
          modalWidth={modalWidth}
          onModalClose={handleModalClose}
          onFormEntryAdded={handleFormEntryAdded}
        />
      )}
    </>
  );
};

export default AddFormEntryModalButton;
