import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useAsync } from "../../../../hooks/useAsync";
import { useValidate } from "../../../../hooks/useValidate";
import {
  extractFormConfigFields,
  getRegisterCustomForm,
  RegisterCustomForm,
} from "../../../../services/registerCustomForm";
import { getRegisterFlowList } from "../../../../services/registerFlow";
import {
  createRegisterRule,
  RegisterRule,
  RegisterRuleForm,
  RuleCondition,
  RuleConditionGroup,
  RuleOperator,
  updateRegisterRule,
} from "../../../../services/registerRule";
import notify from "../../../../utils/notify";
import RuleFormModalLayout from "./RuleFormModalLayout";

type PartialRegisterRuleForm = Partial<RegisterRuleForm>;

export const checkEmptyRule = (
  rule: RuleConditionGroup | RuleCondition | undefined | null
) => {
  return (
    !(rule as RuleConditionGroup).children &&
    (rule as RuleCondition)?.field?.trim() === "" &&
    (rule as RuleCondition)?.value?.toString().trim() === ""
  );
};

interface RuleFormModalContainerProps {
  registerRule: Partial<RegisterRule>;
  templateId: number;
  templateRuleCount: number;
  onRefreshList: () => void;
  onModalClose: () => void;
}

const RuleFormModalContainer: React.FC<RuleFormModalContainerProps> = ({
  registerRule,
  templateId,
  templateRuleCount,
  onRefreshList,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    ruleName: registerRule.ruleName,
    order: registerRule.order,
    workflowId: registerRule.workflowId,
    rule: registerRule.rule || {
      key: new Date().getTime(),
      field: "",
      operator: RuleOperator.Equal,
      value: "",
    },
  };

  const [flows] = useAsync(
    () => getRegisterFlowList(appContext.serviceConfig),
    [],
    "get flow options"
  );

  const [editForm, setEditForm] = useState<PartialRegisterRuleForm>(
    initFormValues as PartialRegisterRuleForm
  );

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    if (checkEmptyRule(editForm.rule)) {
      editForm.rule = null;
    }
    try {
      registerRule.id
        ? await updateRegisterRule(
            registerRule.id,
            templateId,
            editForm as RegisterRuleForm,
            appContext.serviceConfig
          )
        : await createRegisterRule(
            templateId,
            editForm as RegisterRuleForm,
            appContext.serviceConfig
          );
      setSaveInProgress(false);
      notify.actionCompleted();
      onRefreshList();
    } catch (ex) {
      appContext.errorHandler(ex, "update template rule");
    } finally {
      setSaveInProgress(false);
    }
  };

  const ruleConditionCompleteCheck = (condition: RuleCondition) => {
    if (
      !condition.field.trim() ||
      !condition.key.toString().trim() ||
      condition.value === undefined ||
      !condition.value.toString().trim()
    ) {
      return false;
    }
    return true;
  };

  const genRuleError = (rule: RuleConditionGroup | RuleCondition) => {
    const errorKeys: number[] = [];
    if ("conjunctive" in rule) {
      rule.children.forEach((groupChild) => {
        if ("conjunctive" in groupChild) {
          errorKeys.push(...genRuleError(groupChild));
        } else {
          if (!ruleConditionCompleteCheck(groupChild)) {
            errorKeys.push(groupChild.key);
          }
        }
      });
    } else {
      if (!ruleConditionCompleteCheck(rule)) {
        errorKeys.push(rule.key);
      }
    }
    return errorKeys;
  };

  const { t } = useTranslation();

  const formSchema = yup.object({
    ruleName: yup.string().nullable().required(t("app.common.NameRequired")),
    order: yup.number().nullable().required(t("app.common.OrderRequired")),
    workflowId: yup
      .number()
      .nullable()
      .required(t("RuleFormModalContainer.WorkflowRequired")),
    rule: yup.object().test({
      name: t("RuleFormModalContainer.incompleteRule"),
      test: function (value) {
        if (checkEmptyRule(value)) {
          return true;
        }

        const errorKeys = genRuleError(value);

        return errorKeys.length
          ? this.createError({
              message: errorKeys.join("_"),
              path: "rule", // Fieldname
            })
          : true;
      },
    }),
  });

  const [validationError, , , saveWithValidate] = useValidate<
    Omit<RegisterRuleForm, "rule"> & { ruleBody: string }
  >(editForm, formSchema, handleSaveValidated);

  const [{ data: registerCustomForm }] = useAsync<
    RegisterCustomForm | undefined
  >(() => getRegisterCustomForm(appContext.serviceConfig));

  const handleFormGridStateChange = (
    fieldName: keyof RegisterRule,
    value: unknown
  ) => {
    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const handleRuleBodyChange = (
    ruleBody: RuleConditionGroup | RuleCondition
  ) => {
    setEditForm((prevState) => ({ ...prevState, rule: ruleBody }));
  };

  return (
    <RuleFormModalLayout
      registerRule={registerRule}
      templateRuleCount={templateRuleCount}
      editForm={editForm}
      saveInProgress={saveInProgress}
      validationError={validationError}
      onModalSave={handleModalSave}
      onFormGridStateChange={handleFormGridStateChange}
      onRuleBodyChange={handleRuleBodyChange}
      onModalClose={onModalClose}
      flowOptions={
        flows.data
          ? flows.data.map((flow) => ({
              label: flow.flowName,
              value: flow.id,
            }))
          : []
      }
      fieldOptions={
        registerCustomForm
          ? extractFormConfigFields(
              registerCustomForm.definitions,
              true,
              true,
              []
            ).map((field) => ({
              label: field.label,
              value: field.key,
            }))
          : []
      }
    />
  );
};

export default RuleFormModalContainer;
