import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpFormItem } from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";
import { ValidateStatus } from "antd/lib/form/FormItem";
import { LabeledValue } from "antd/lib/select";
import moment from "moment";

import {
  FilterColumn,
  FilterComparer,
  FilterDataType,
  FilterStateItem,
} from "./AppFilter";

const useStyles = createUseStyles((theme) => ({
  appFilterRow: {
    "&:first-child > .ant-col:last-child": {
      marginTop: 28,
    },
  },
}));
interface AppFilterRowProps {
  isFormValid: boolean;
  formFilterItem: Partial<FilterStateItem>;
  rowIndex: number;
  filterColumns: FilterColumn[];
  showClearButton: boolean;
  onFormFilterChange: (
    orignalFormFilterItem: Partial<FilterStateItem>,
    updatedFormFilterItem?: Partial<FilterStateItem>
  ) => void;
}

const AppFilterRow: React.FC<AppFilterRowProps> = ({
  isFormValid,
  formFilterItem,
  rowIndex,
  filterColumns,
  showClearButton,
  onFormFilterChange,
}) => {
  const { t } = useTranslation();

  const getValueInput = (
    formFilterItem: Partial<FilterStateItem>,
    showLabel: boolean = true,
    validateStatus?: ValidateStatus
  ) => {
    const label = showLabel ? t("AppFilterRow.FilterValue") : undefined;
    switch (formFilterItem?.filterColumn?.filterDataType) {
      case FilterDataType.Select:
        if (formFilterItem.filterColumn.selectOptions) {
          return (
            <NxpFormItem
              validateStatus={validateStatus}
              label={label}
              controlType="select"
              controlProps={{
                options: formFilterItem.filterColumn.selectOptions,
                value: formFilterItem.filterValue || undefined,
                onChange: (val) => {
                  const updatedFormFilterItem = { ...formFilterItem };
                  updatedFormFilterItem.filterValue = val
                    ? val.toString()
                    : undefined;
                  onFormFilterChange(formFilterItem, updatedFormFilterItem);
                },
              }}
            />
          );
        } else {
          return (
            <NxpFormItem
              validateStatus={validateStatus}
              label={label}
              controlType="input"
              controlProps={{
                value: formFilterItem.filterValue,
                onChange: (e) => {
                  const updatedFormFilterItem = { ...formFilterItem };
                  updatedFormFilterItem.filterValue = e.target.value;
                  onFormFilterChange(formFilterItem, updatedFormFilterItem);
                },
              }}
            />
          );
        }
      case FilterDataType.Boolean:
        return (
          <NxpFormItem
            validateStatus={validateStatus}
            label={label}
            controlType="select"
            controlProps={{
              options: [
                { value: "true", label: "Yes" },
                { value: "false", label: "No" },
              ],
              disabled:
                formFilterItem.filterComparer === FilterComparer.IsEmpty,
              value: formFilterItem.filterValue || undefined,
              onChange: (val) => {
                const updatedFormFilterItem = { ...formFilterItem };
                updatedFormFilterItem.filterValue = val.toString();
                onFormFilterChange(formFilterItem, updatedFormFilterItem);
              },
            }}
          />
        );
      case FilterDataType.Date:
        return (
          <NxpFormItem
            validateStatus={validateStatus}
            label={label}
            controlType="datePicker"
            controlProps={{
              value: formFilterItem.filterValue
                ? moment(formFilterItem.filterValue).toDate()
                : undefined,
              onChange: (date) => {
                const updatedFormFilterItem = { ...formFilterItem };
                updatedFormFilterItem.filterValue = date
                  ? date.toJSON()
                  : undefined;
                onFormFilterChange(formFilterItem, updatedFormFilterItem);
              },
            }}
          />
        );
      //case FilterDataType.Money:
      case FilterDataType.Number:
        return (
          <NxpFormItem
            validateStatus={validateStatus}
            label={label}
            controlType="inputNumber"
            controlProps={{
              value: Number.isFinite(Number(formFilterItem.filterValue))
                ? Number(formFilterItem.filterValue)
                : undefined,
              onChange: (value) => {
                const updatedFormFilterItem = { ...formFilterItem };
                updatedFormFilterItem.filterValue =
                  // FIX for CPCS-802
                  value !== undefined &&
                  Number.isInteger(value) &&
                  Number.isFinite(value)
                    ? value.toString()
                    : undefined;
                // console.log(
                //   `After Change: ${updatedFormFilterItem.filterValue}`
                // );
                onFormFilterChange(formFilterItem, updatedFormFilterItem);
              },
            }}
          />
        );
      // case FilterDataType.Percent:
      //   return (
      //     <NxpFormItem
      //       validateStatus={validateStatus}
      //       label={label}
      //       controlType="inputPercent"
      //       controlProps={{
      //         value: Number.isFinite(Number(formFilterItem.filterValue))
      //           ? Number(formFilterItem.filterValue)
      //           : undefined,
      //         onChange: (value) => {
      //             const updatedFormFilterItem = { ...formFilterItem };
      //             updatedFormFilterItem.filterValue = value ? value.toString() : "";
      //             onFormFilterChange(formFilterItem, updatedFormFilterItem);
      //         },
      //       }}
      //     />
      default:
        //includes case FilterDataType.String and other (if any)
        return (
          <NxpFormItem
            validateStatus={validateStatus}
            controlType="input"
            label={label}
            controlProps={{
              value: formFilterItem.filterValue,
              onChange: (e) => {
                const updatedFormFilterItem = { ...formFilterItem };
                updatedFormFilterItem.filterValue = e.target.value;
                onFormFilterChange(formFilterItem, updatedFormFilterItem);
              },
            }}
          />
        );
    }
  };

  const getComparerOptions = (
    filterDataType?: FilterDataType
  ): LabeledValue[] => {
    let options: LabeledValue[];
    switch (filterDataType) {
      case undefined:
        options = [
          // {
          //   value: FilterComparer.Contains,
          //   label: FilterComparer.Contains,
          // },
          {
            value: FilterComparer.Equal,
            label: FilterComparer.Equal,
          },
          // {
          //   key: FilterComparer.NotEqual,
          //   text: FilterComparer.NotEqual,
          // },
        ];
        break;
      case FilterDataType.Select:
        options = [
          {
            value: FilterComparer.Equal,
            label: FilterComparer.Equal,
          },
          // {
          //   key: FilterComparer.NotEqual,
          //   text: FilterComparer.NotEqual,
          // },
        ];
        break;
      case FilterDataType.Boolean:
        options = [
          {
            value: FilterComparer.Equal,
            label: FilterComparer.Equal,
          },
          {
            value: FilterComparer.IsEmpty,
            label: FilterComparer.IsEmpty,
          },
        ];
        break;
      // case FilterDataType.Money:
      // case FilterDataType.Percent:
      case FilterDataType.Number:
      case FilterDataType.Date:
        options = [
          {
            value: FilterComparer.Equal,
            label: FilterComparer.Equal,
          },
          // {
          //   value: FilterComparer.NotEqual,
          //   label: FilterComparer.NotEqual,
          // },
          {
            value: FilterComparer.GreaterThan,
            label: FilterComparer.GreaterThan,
          },
          {
            value: FilterComparer.LessThan,
            label: FilterComparer.LessThan,
          },
        ];
        break;
      case FilterDataType.String:
        options = [
          // {
          //   value: FilterComparer.Contains,
          //   label: FilterComparer.Contains,
          // },
          {
            value: FilterComparer.Equal,
            label: FilterComparer.Equal,
          },
          // {
          //   value: FilterComparer.NotEqual,
          //   label: FilterComparer.NotEqual,
          // },
        ];
        break;
    }
    return options;
  };

  const fieldOptions = filterColumns.map((column) => ({
    value: column.fieldName,
    label: column.displayName,
  }));

  const findColumn = (fieldName: string): FilterColumn => {
    return filterColumns.find((column) => column.fieldName === fieldName)!;
  };

  const classes = useStyles();

  return (
    <>
      <Row gutter={[8, 8]} className={classes.appFilterRow}>
        <Col span={7}>
          <NxpFormItem
            validateStatus={
              !isFormValid && !formFilterItem.filterColumn ? "error" : undefined
            }
            label={rowIndex === 0 ? t("app.common.Field") : undefined}
            controlType="select"
            controlProps={{
              options: fieldOptions || [],
              value: formFilterItem.filterColumn?.fieldName,
              onChange: (val) => {
                const updatedFormFilterItem = { ...formFilterItem };
                updatedFormFilterItem.filterColumn = findColumn(val.toString());
                onFormFilterChange(formFilterItem, updatedFormFilterItem);
              },
            }}
          />
        </Col>
        <Col span={7}>
          <NxpFormItem
            validateStatus={
              !isFormValid && !formFilterItem.filterComparer
                ? "error"
                : undefined
            }
            label={rowIndex === 0 ? "Operator" : undefined}
            controlType="select"
            controlProps={{
              options: getComparerOptions(
                formFilterItem.filterColumn?.filterDataType
              ),
              value: formFilterItem.filterComparer,
              onChange: (val) => {
                const updatedFormFilterItem = { ...formFilterItem };
                updatedFormFilterItem.filterComparer = val as FilterComparer;
                onFormFilterChange(formFilterItem, updatedFormFilterItem);
              },
            }}
          />
        </Col>
        <Col span={7}>
          {getValueInput(
            formFilterItem,
            rowIndex === 0,
            !isFormValid &&
              !formFilterItem.filterValue &&
              !(formFilterItem.filterComparer === FilterComparer.IsEmpty)
              ? "error"
              : undefined
          )}
        </Col>
        <Col span={3}>
          {showClearButton ? (
            <NxpButton
              type="text"
              danger
              onClick={() => {
                onFormFilterChange(formFilterItem, {});
              }}
            >
              {t("AppFilterRow.Clear")}
            </NxpButton>
          ) : (
            <NxpButton
              type="text"
              danger
              onClick={() => {
                onFormFilterChange(formFilterItem, undefined);
              }}
            >
              {t("app.common.Delete")}
            </NxpButton>
          )}
        </Col>
      </Row>
    </>
  );
};

export default AppFilterRow;
