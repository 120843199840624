// import ToggleCustomComp from "./ToggleCustomComp";
import FileUpload from "./FileUpload";
import SectionHeader from "./SectionHeader";
import SelectCPCSDictionary from "./SelectCPCSDictionary/SelectCPCSDictionary";
import SelectDictionary from "./SelectDictionary";
import SelectUser from "./SelectUser";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // toggleCustomComp: ToggleCustomComp, // sample component for testing only
  sectionHeader: SectionHeader,
  selectUser: SelectUser,
  selectDictionary: SelectDictionary,
  selectCPCSDictionary: SelectCPCSDictionary,
  fileUpload: FileUpload,
};
