import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import * as yup from "yup";

import useAppContext from "../../../../../hooks/useAppContext";
import { useAsync } from "../../../../../hooks/useAsync";
import { useValidate } from "../../../../../hooks/useValidate";
import {
  extractFormConfigFields,
  getRegisterCustomForm,
  RegisterCustomForm,
} from "../../../../../services/registerCustomForm";
import {
  createRegisterFlowStep,
  RegisterFlowStep,
  RegisterFlowStepForm,
} from "../../../../../services/registerFlow";
// import { getRoleAssignmentList } from "../../../../../services/roleAssignment";
import notify from "../../../../../utils/notify";

interface AddFlowStepModalProps {
  flowId: number;
  flowStepsCount: number;
  formUserSelectFields: LabeledValue[];
  modalVisible: boolean;
  onModalClose: () => void;
  onFlowStepAdded: (newFlowStep: RegisterFlowStep) => void;
}

const AddFlowStepModal: React.FC<AddFlowStepModalProps> = ({
  flowId,
  flowStepsCount,
  formUserSelectFields,
  modalVisible,
  onFlowStepAdded,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    order: flowStepsCount + 1,
    editFields: [],
    parallelApprove: false,
  };

  const [editForm, setEditForm] =
    useState<Partial<RegisterFlowStepForm>>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const { t } = useTranslation();

  // const [roles] = useAsync(
  //   () =>
  //     getRoleAssignmentList(
  //       appContext.serviceConfig,
  //       appContext.activeRegister?.registerId
  //     ),
  //   [],
  //   "get role options"
  // );

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const flowStep = await createRegisterFlowStep(
        flowId,
        editForm as RegisterFlowStep,
        appContext.serviceConfig
      );
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onFlowStepAdded(flowStep);
      onModalClose();
    } catch (ex) {
      appContext.errorHandler(ex, "create flow step");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    order: yup.number().nullable().required(t("app.common.OrderRequired")),
    approveStatusId: yup
      .number()
      .nullable()
      .required(t("app.common.ApproveStatusRequired")),
    rejectStatusId: yup
      .number()
      .nullable()
      .required(t("app.common.RejectStatusRequired")),
    roleCodes: yup
      .array(yup.string())
      .nullable()
      .required(t("AddFlowStepModal.RoleRequired"))
      .min(1, t("AddFlowStepModal.RoleRequired")),
    stepName: yup.string().nullable().required(t("app.common.NameRequired")),
    editFields: yup.array(),
  });

  const [validationError, , , saveWithValidate] = useValidate<RegisterFlowStep>(
    editForm,
    formSchema,
    handleSaveValidated
  );

  const handleFormGridStateChange = (
    fieldName: keyof RegisterFlowStepForm,
    value: unknown
  ) => {
    // console.log("ccccccccccccccc", fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const getStatusOptions = () =>
    appContext.activeRegister?.statuses
      ? appContext.activeRegister.statuses.map((status) => ({
          label: status.statusName,
          value: status.id,
        }))
      : [];

  const [{ data: registerCustomForm }] = useAsync<
    RegisterCustomForm | undefined
  >(() => getRegisterCustomForm(appContext.serviceConfig));

  const roleFromForm =
    editForm.roleCodes?.length && editForm.roleCodes[0].startsWith("$")
      ? editForm.roleCodes[0].replace("$", "")
      : "";

  const formItems: NxpFormGridItemProps<Partial<RegisterFlowStepForm>>[] = [
    {
      label: t("app.common.Order"),
      itemFieldName: "order",
      controlType: "select",
      controlProps: {
        options: Array(flowStepsCount + 1)
          .fill(null)
          .map((_, idx) => ({
            label: idx + 1,
            value: idx + 1,
          })),
      },
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: t("app.common.Name"),
      itemFieldName: "stepName",
      required: true,
      span: 12,
    },
    {
      controlType: "select",
      label: t("app.common.RoleFromForm"),
      itemFieldName: "roleCodes",
      controlProps: {
        style: { width: "100%" },
        value: roleFromForm,
        allowClear: true,
        options: formUserSelectFields,
        onChange: (val) =>
          handleFormGridStateChange("roleCodes", val ? [`$${val}`] : []),
      },
      span: 12,
    },
    {
      controlType: "input",
      label: t("app.common.RoleFromCode"),
      itemFieldName: "roleCodes",
      controlProps: {
        disabled: !!roleFromForm, // disable if role from form is set
        value:
          !!roleFromForm || !editForm.roleCodes?.length // empty if role from form is set
            ? ""
            : editForm.roleCodes[0],
        onChange: (e) =>
          handleFormGridStateChange("roleCodes", [e.target.value]),
        onKeyPress: (e) => {
          if (e.key === "$") {
            e.preventDefault();
          }
        },
      },
      required: true,
      span: 12,
    },
    {
      controlType: "selectMultiple",
      label: t("app.common.EditFields"),
      itemFieldName: "editFields",
      controlProps: {
        options: registerCustomForm
          ? extractFormConfigFields(
              registerCustomForm.definitions,
              true,
              false,
              []
            ).map((field) => ({
              label: field.label,
              value: field.key,
            }))
          : [],
      },
      span: 24,
    },
    {
      controlType: "select",
      label: t("app.common.ApproveStatus"),
      itemFieldName: "approveStatusId",
      controlProps: {
        options: getStatusOptions(),
      },
      required: true,
      span: 12,
    },
    {
      controlType: "select",
      label: t("app.common.RejectStatus"),
      itemFieldName: "rejectStatusId",
      controlProps: {
        options: getStatusOptions(),
      },
      required: true,
      span: 12,
    },
    // {
    //   controlType: "checkbox",
    //   label: t("app.common.ParallelApproval"),
    //   itemFieldName: "parallelApprove",
    //   controlProps: {
    //     options: getStatusOptions(),
    //   },
    //   span: 12,
    // },
  ];

  return (
    <NxpModal
      title={t("AddFlowStepModal.AddFlowStep")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddFlowStepModal;
