import { LazyLoadStatus, NxpDateDisplay } from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";

import FileLink from "../../components/AppFileUpload/FileLink";
import {
  extractFormConfigFields,
  RegisterCustomForm,
  RegisterCustomFormField,
} from "../../services/registerCustomForm";
import { RegisterFormEntry } from "../../services/registerFormEntry";
import { RegisterListingConfig } from "../../services/registerListing";
import { QueryParams } from "../../utils/backend/query";
import { DEFAULT_COLUMN_WIDTH } from "../../utils/const";
import { DateFormatPattern } from "../../utils/date/formatDate";

export interface RegisterFormEntriesState {
  initializing: boolean;
  refetchMode?:
    | "lazyload"
    | "dataReload"
    | "dataSorterChange"
    | "dataFilterChange";
  lazyloadStatus: LazyLoadStatus;
  data: RegisterFormEntry[];
  listConfig?: RegisterListingConfig;
  tableColumns: ColumnProps<any>[];
  formConfigSpec: RegisterCustomFormField[];
  query: QueryParams;
  translation: (key: string) => string;
}

type RegisterFormEntriesAction =
  | {
      type: "dataLoading";
    }
  | {
      type: "listConfigLoaded";
      listConfig: RegisterListingConfig;
    }
  | {
      type: "dataLoaded";
      data: RegisterFormEntry[];
      formConfig: RegisterCustomForm;
      pageSize: number;
    }
  | {
      type: "lazyload";
    }
  | {
      type: "dataError";
    }
  | {
      type: "dataReload";
    }
  | {
      type: "dataSorterChange";
      sorters: QueryParams["sorters"];
    }
  | {
      type: "dataFilterChange";
      filters: QueryParams["filters"];
    };

const getColumnWidth = (key: string, listConfig: RegisterListingConfig) => {
  return (
    listConfig.layout?.find((config) => config.key === key)?.webListing.width ||
    DEFAULT_COLUMN_WIDTH
  );
};

export default function registerFormEntriesReducer(
  state: RegisterFormEntriesState,
  action: RegisterFormEntriesAction
): RegisterFormEntriesState {
  switch (action.type) {
    case "dataLoading":
      return {
        ...state,
        lazyloadStatus: LazyLoadStatus.loading,
      };
    case "listConfigLoaded":
      return {
        ...state,
        listConfig: action.listConfig,
        tableColumns: [],
      };
    case "dataLoaded":
      let tableColumns: ColumnProps<any>[] = state.tableColumns;

      if (!state.tableColumns.length && action.formConfig && state.listConfig) {
        tableColumns = [
          {
            title: state.translation("app.common.Sequential"),
            dataIndex: "seqNumber",
            sorter: true,
            width: getColumnWidth("seqNumber", state.listConfig),
          },
          {
            title: state.translation("app.common.FormReference"),
            dataIndex: "formNumber",
            sorter: true,
            width: getColumnWidth("formNumber", state.listConfig),
          },
          {
            title: state.translation("app.common.Status"),
            dataIndex: ["entryState", "status", "statusName"],
            sorter: true,
            width: getColumnWidth("status", state.listConfig),
          },
          {
            title: state.translation("app.common.CreatedOn"),
            dataIndex: "createdOn",
            sorter: true,
            width: getColumnWidth("createdOn", state.listConfig),
            render: (_, record) => (
              <NxpDateDisplay
                date={record.createdOn}
                format={DateFormatPattern.date}
              />
            ),
          },
          {
            title: state.translation("app.common.UpdatedOn"),
            dataIndex: "updatedOn",
            sorter: true,
            width: getColumnWidth("updatedOn", state.listConfig),
            render: (_, record) => (
              <NxpDateDisplay
                date={record.updatedOn}
                format={DateFormatPattern.date}
              />
            ),
          },
          ...extractFormConfigFields(
            action.formConfig.definitions,
            true,
            true,
            []
          ).map((field) => {
            const fieldProps: ColumnProps<any> = {};
            switch (field.type) {
              case "toggleCustomComp":
              case "checkbox":
                fieldProps.render = (value) => (value ? "Yes" : "No");
                break;
              case "day":
                fieldProps.render = (value) => {
                  if (value) {
                    const valueArr = value.split("/");
                    return (
                      <NxpDateDisplay
                        date={
                          new Date(
                            Number(valueArr[2]),
                            Number(valueArr[0] - 1),
                            Number(valueArr[1])
                          )
                        }
                        format={DateFormatPattern.date}
                      />
                    );
                  }
                  return null;
                };
                break;
              case "datetime":
                fieldProps.render = (value) => (
                  <NxpDateDisplay
                    date={value}
                    format={DateFormatPattern.dateWithTime}
                  />
                );
                break;
              case "selectDictionary":
                fieldProps.render = (dict) => dict && Object.keys(dict)[0];
                break;
              case "selectCPCSDictionary":
                fieldProps.render = (dict) => dict && dict.name;
                break;
              case "selectUser":
                fieldProps.render = (user) => user && user.name;
                break;
              case "fileUpload":
                fieldProps.ellipsis = true;
                fieldProps.filtered = false;
                fieldProps.render = (files) => <FileLink files={files} />;
                break;
            }

            return {
              title: field.label,
              dataIndex: ["contentDetails", field.key],
              sorter: true,
              width: getColumnWidth(field.key, state.listConfig!),
              ...fieldProps,
            };
          }),
        ];

        if (state.listConfig.layout) {
          const layout = state.listConfig.layout;

          //apply list config
          if (
            state.listConfig.layout &&
            state.listConfig.layout.find(
              // no filter if all disabled
              (configItem) => !configItem.webListing.disabled
            )
          ) {
            // for web listing config
            tableColumns = tableColumns
              .filter((col) =>
                layout.find(
                  (configItem) =>
                    (configItem.key === col.dataIndex ||
                      (Array.isArray(col.dataIndex) &&
                        configItem.key ===
                          col.dataIndex[col.dataIndex.length - 1])) &&
                    configItem.webListing.disabled === false
                )
              )
              .sort(
                (a, b) =>
                  (layout.find(
                    (configItem) =>
                      configItem.key === a.dataIndex ||
                      (Array.isArray(a.dataIndex) &&
                        configItem.key === a.dataIndex[a.dataIndex.length - 1])
                  )?.webListing.order || 0) -
                  (layout.find(
                    (configItem) =>
                      configItem.key === b.dataIndex ||
                      (Array.isArray(b.dataIndex) &&
                        configItem.key === b.dataIndex[b.dataIndex.length - 1])
                  )?.webListing.order || 0)
              );
          }

          // for confidential config
          if (state.listConfig.layout) {
            tableColumns = tableColumns.filter((col) =>
              (state.listConfig?.layout || []).find(
                (configItem) =>
                  (configItem.key === col.dataIndex ||
                    (Array.isArray(col.dataIndex) &&
                      configItem.key ===
                        col.dataIndex[col.dataIndex.length - 1])) &&
                  configItem.confidential.disabled
              )
            );
          }
        }
      }

      return {
        ...state,
        formConfigSpec: state.formConfigSpec?.length
          ? state.formConfigSpec
          : action.formConfig?.definitions,
        initializing: false,
        refetchMode: undefined,
        data:
          state.refetchMode !== undefined &&
          ["dataReload", "dataFilterChange", "dataSorterChange"].includes(
            state.refetchMode
          )
            ? [...action.data] // clear previous data
            : [...state.data, ...action.data],
        query: {
          ...state.query,
          offset: state.query.offset + action.pageSize,
        },
        lazyloadStatus:
          action.data.length < action.pageSize
            ? LazyLoadStatus.noMoreItem
            : LazyLoadStatus.ready,
        tableColumns,
      };
    case "lazyload":
      // update query to trigger fetching
      return {
        ...state,
        refetchMode: action.type,
      };
    case "dataError":
      return {
        ...state,
        lazyloadStatus: LazyLoadStatus.noMoreItem,
        refetchMode: undefined,
      };
    case "dataReload":
      return {
        ...state,
        refetchMode: action.type,
        query: {
          ...state.query,
          offset: 0,
        },
      };
    case "dataSorterChange":
      return {
        ...state,
        refetchMode: action.type,
        query: {
          ...state.query,
          offset: 0,
          sorters: action.sorters,
        },
      };
    case "dataFilterChange":
      return {
        ...state,
        refetchMode: action.type,
        query: {
          ...state.query,
          offset: 0,
          filters: action.filters,
        },
      };
    default:
      return state;
  }
}
