import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { FormAttachment } from "../../../../services/registerFormEntry";
import AddAtachmentModal from "./AddAtachmentModal";

const useStyles = createUseStyles((theme) => ({
  addButton: {
    position: "absolute",
    right: 0,
    marginTop: -55,
  },
}));

interface AddAtachmentModalButtonProps {
  formEntryId: number;
  onAttachmentAdded: (attachment: FormAttachment) => void;
}

const AddAtachmentModalButton: React.FC<AddAtachmentModalButtonProps> = ({
  formEntryId,
  onAttachmentAdded,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleAttachmentAdded = (attachment: FormAttachment) => {
    onAttachmentAdded(attachment);
    handleModalClose();
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <NxpButton className={classes.addButton} onClick={handleClick}>
        {t("app.common.AddAttachment")}
      </NxpButton>
      {modalVisible && (
        <AddAtachmentModal
          formEntryId={formEntryId}
          modalVisible={modalVisible}
          onModalClose={handleModalClose}
          onAttachmentAdded={handleAttachmentAdded}
        />
      )}
    </>
  );
};

export default AddAtachmentModalButton;
