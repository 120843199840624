import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { RegisterDictionary } from "../../../../services/registerDictionary";
import AddDictionaryItemModal from "./AddDictionaryItemModal";

interface AddDictionaryItemButtonProps {
  dictionary: RegisterDictionary;
  disabled?: boolean;
  onDictionaryItemAdded: (updateDict: RegisterDictionary) => void;
}

const AddDictionaryItemButton: React.FC<AddDictionaryItemButtonProps> = ({
  dictionary,
  disabled,
  onDictionaryItemAdded,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleDictionaryItemAdded = (updateDict: RegisterDictionary) => {
    onDictionaryItemAdded(updateDict);
    handleModalClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <NxpButton disabled={disabled} onClick={handleClick}>
        {t("AddDictionaryItemButton.AddItem")}
      </NxpButton>
      <AddDictionaryItemModal
        dictionary={dictionary}
        modalVisible={modalVisible}
        onModalClose={handleModalClose}
        onDictionaryItemAdded={handleDictionaryItemAdded}
      />
    </>
  );
};

export default AddDictionaryItemButton;
