import baseEditForm from "formiojs/components/_classes/component/Component.form";

// function will be re-created in SetupFormLayout as dictionary key options need to be retrieved in run-time
export default function SelectUserFormioConfig(...extend: any) {
  return baseEditForm(
    [
      {
        key: "display",
        components: [],
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
}
