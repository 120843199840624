import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Route, RouteProps } from "react-router-dom";

import { NxpAuthSpinner, NxpLayoutGlobal } from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";

import useAppContext from "../../../hooks/useAppContext";
import { RegisterAccessList } from "../../../services/access";
import notify from "../../../utils/notify";
import AppLayout from "../AppLayout/AppLayout";
import useRouteAppContextUpdate from "./useRouteAppContextUpdate";

interface AppProtectedRouteProps extends RouteProps {
  requireActiveEntity?: boolean;
  requireActiveRegister?: boolean;
  requireRegisters?: boolean;
}

const AppProtectedRoute: React.FC<AppProtectedRouteProps> = ({
  requireActiveEntity,
  requireActiveRegister,
  requireRegisters,
  children,
  ...restProps
}) => {
  const { keycloak } = useKeycloak();
  const appContext = useAppContext();
  const { t } = useTranslation();
  useRouteAppContextUpdate();

  const checkRegisterReadAccess = () => {
    const allow = !!(appContext.registers?.find(
      (reg) => reg.register.id === appContext.activeRegister?.id
    )?.permissions || ({} as RegisterAccessList))["register-entry:read"];
    if (!allow) {
      notify.error(t("AppProtectedRoute.RegisterAccessDenied"));
    }
    return allow;
  };

  if (
    keycloak?.authenticated &&
    (!requireActiveEntity || appContext.activeEntity) &&
    (!requireActiveRegister ||
      (appContext.activeRegister &&
        appContext.registers &&
        checkRegisterReadAccess())) &&
    (!requireRegisters ||
      (appContext.registers && appContext.registers.length > 0))
  ) {
    return (
      <Route {...restProps}>
        <AppLayout>{children as ReactNode}</AppLayout>
      </Route>
    );
  }

  setTimeout(() => {
    if (!keycloak?.authenticated) {
      notify.error(
        t(
          "AppProtectedRoute.CannotConnectToAuthenticationServer.PleaseTryRefreshingYourBrowserOrContactSupport."
        )
      );
    }
  }, 15000);
  return (
    <NxpLayoutGlobal>
      <NxpAuthSpinner />
    </NxpLayoutGlobal>
  );
};

export default AppProtectedRoute;
