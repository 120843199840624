import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { notify } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import { useValidate } from "../../../hooks/useValidate";
import {
  RegisterQueue,
  RegisterSetupForm,
  updateRegister,
  updateRegisterQueue,
} from "../../../services/register";
import { getRuleTemplateList } from "../../../services/registerRule";
import SetupGeneralLayout from "./SetupGeneralLayout";

interface SetupGeneralFormProps {
  initForm: RegisterSetupForm;
  registerId: number;
  queue?: RegisterQueue;
  registerHasRecord: boolean;
  isInheritedRegister: boolean;
}

const SetupGeneralForm: React.FC<SetupGeneralFormProps> = ({
  initForm,
  registerId,
  queue,
  registerHasRecord,
  isInheritedRegister,
}) => {
  const appContext = useAppContext();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [form, setForm] = useState<RegisterSetupForm>(() => ({
    ...initForm,
  }));

  const { t } = useTranslation();

  const formSchema = yup.object({
    register_name: yup
      .string()
      .nullable()
      .required(t("app.common.NameRequired")),
    owner: yup
      .string()
      .nullable()
      .required(t("SetupGeneralContainer.OwnerRequired")),
    type: yup
      .string()
      .nullable()
      .required(t("SetupGeneralContainer.RegisterTypeRequired")),
    flag_workspace: yup
      .boolean()
      .nullable()
      .required(t("SetupGeneralContainer.AddToWorkspaceRequired")),
  });

  const handleSaveValidated = async () => {
    setSaveInProgress(true);
    try {
      if (!isInheritedRegister) {
        const register = await updateRegister(
          registerId,
          { ...form, last_sequential_num: undefined },
          appContext.serviceConfig
        );

        if (!registerHasRecord && queue !== undefined) {
          await updateRegisterQueue(
            registerId,
            queue.id,
            form.last_sequential_num || 0,
            appContext.serviceConfig
          );
        }

        appContext.onAppContextCacheUpdate((prevState) => ({
          ...prevState,
          activeRegister: register,
          registers: (prevState.registers || []).map((item) =>
            item.register.id === register.id
              ? {
                  register: {
                    id: register.id,
                    register_name: register.register_name,
                    owner: { ...register.owner },
                    description: register.description,
                  },
                  permissions: item.permissions,
                }
              : item
          ),
        }));
      } else if (!registerHasRecord && form.flag_seperate_queue && queue) {
        await updateRegisterQueue(
          registerId,
          queue?.id,
          form.last_sequential_num || 0,
          appContext.serviceConfig
        );
      }
      notify.actionCompleted();
    } catch (ex) {
      appContext.errorHandler(ex, "update register setup general");
    } finally {
      setSaveInProgress(false);
    }
  };

  const [ruleTemplateOptions] = useAsync(
    () => getRuleTemplateList(appContext.serviceConfig),
    [],
    "get rule template list"
  );

  const [validationError, , , saveWithValidate] = useValidate<
    Pick<RegisterSetupForm, "register_name">
  >(form, formSchema, handleSaveValidated);

  const handleFormStateChange = (
    fieldName: keyof RegisterSetupForm,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);
    setForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleFormSave = () => {
    saveWithValidate(undefined);
  };

  return (
    form && (
      <SetupGeneralLayout
        form={form}
        saveInProgress={saveInProgress}
        isInheritedRegister={isInheritedRegister}
        registerHasRecord={registerHasRecord}
        ruleTemplateOptions={ruleTemplateOptions.data || []}
        userOptions={appContext.users}
        registerTypeOptions={appContext.registerTypes}
        validationError={validationError}
        onFormSave={handleFormSave}
        onFormStateChange={handleFormStateChange}
      />
    )
  );
};

export default SetupGeneralForm;
