import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Form } from "react-formio";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { NxpAlert } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import {
  prepareFormContentForSubmit,
  RegisterCustomFormField,
} from "../../../services/registerCustomForm";
import {
  cancelRegisterFormEntry,
  RegisterFormEntry,
  updateRegisterFormEntry,
} from "../../../services/registerFormEntry";
import { FormEntryApproval } from "../../../services/registerFormEntryApproval";
import { RegisterListingConfig } from "../../../services/registerListing";
import { PrintForm } from "../../../services/registerPrintables";
import { FORMIO_DEFAULT_OPTIONS } from "../../../utils/const";
import formatDate, { DateFormatPattern } from "../../../utils/date/formatDate";
import notify from "../../../utils/notify";
import AddFormEntryModal from "../../RegisterFormEntries/AddFormEntry/AddFormEntryModal";
import getAddFormEntryModalWidth from "../../RegisterFormEntries/AddFormEntry/getAddFormEntryModalWidth";
import FormEntrySummaryLayout from "./FormEntrySummaryLayout";

type FormEntrySummaryEditMode =
  | "uninitialized"
  | "initializing"
  | "editing"
  | "saving";

export interface FormEntrySummaryForm {
  formConfigId?: number;
  cancelStatus?: boolean;
  defaultStatus?: boolean;
  releaseStatus?: boolean;
  allowEdit?: boolean;
  updatedOn?: Date;
  formDefinition: RegisterCustomFormField[];
  formContent: RegisterFormEntry["contentDetails"];
  formData: any; // for capturing changes in formio Form
}

interface FormEntrySummaryContainerProps {
  formEntry: RegisterFormEntry;
  printables: PrintForm[];
  activeApproval?: FormEntryApproval;
  listingConfig: RegisterListingConfig;
  onFormEntryStatusChanged: () => void;
}

const FormEntrySummaryContainer: React.FC<FormEntrySummaryContainerProps> = ({
  formEntry,
  printables,
  listingConfig,
}) => {
  const routerHistory = useHistory();
  const appContext = useAppContext();
  const formNodeRef = useRef<ReactNode>(null);
  const formRef = useRef<any>(null);

  const [editMode, setEditMode] =
    useState<FormEntrySummaryEditMode>("uninitialized");
  const [editForm, setEditForm] = useState<FormEntrySummaryForm>({
    formDefinition: [],
    formContent: [],
    formData: {},
  });

  useEffect(() => {
    if (
      appContext.activeEntity &&
      appContext.activeRegister &&
      editMode === "uninitialized"
    ) {
      //setEditMode("initializing");
      // getRegisterFormEntry(formEntryId, appContext.serviceConfig).then(
      //   (formEntry) => {
      setEditForm((prevState) => ({
        ...prevState,
        cancelStatus: formEntry.entryState.status.cancelStatus,
        allowEdit: formEntry.entryState.status.allowEdit,
        defaultStatus: formEntry.entryState.status.defaultStatus,
        releaseStatus: formEntry.entryState.status.releaseStatus,
        updatedOn: formEntry.entryState.updatedOn,
        formConfigId: formEntry.formConfigId,
        formDefinition: formEntry.formConfig.definitions,
        formContent: formEntry.contentDetails,
      }));
      setEditMode("editing");
      // }
      //);
    }
  }, [appContext, editMode, formEntry]);

  const { t } = useTranslation();

  const getFieldsWithoutConfidential = (
    spec: RegisterCustomFormField[],
    listingConfig: RegisterListingConfig
  ) => {
    const fields: RegisterCustomFormField[] = spec
      .filter((field) => {
        const configItem = (listingConfig.layout || []).find(
          (item) => item.key === field.key
        );
        return (
          // not configItem mean not confidential
          !configItem ||
          // marked as not confidential
          configItem.confidential.disabled ||
          // users with special right
          appContext.registerAccessList["register-entry:special"]
        );
      })
      .map((field) => {
        if (field.label && field.key && field.input) {
          return field;
        } else {
          return {
            ...field,
            components: getFieldsWithoutConfidential(
              field.components || [],
              listingConfig
            ),
          };
        }
      });

    return fields;
  };

  const handleSave = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (editForm.formData.isValid) {
      try {
        setEditMode("saving");
        await updateRegisterFormEntry(
          formEntry.id,
          prepareFormContentForSubmit(
            editForm.formData.data,
            formEntry.formConfig.definitions
          ),
          appContext.serviceConfig
        );
        notify.actionCompleted();
      } catch (ex) {
        appContext.errorHandler(ex, "update form entry");
      } finally {
        setEditMode("editing");
      }
    } else {
      // should find better way to trigger formio validation
      formRef.current.formio.submit();
      notify.warning(t("app.common.PleaseFixValidationErrorBeforeProceeding"));
    }
  };

  if (editForm.formDefinition.length && formNodeRef.current === null) {
    editForm.formDefinition = getFieldsWithoutConfidential(
      editForm.formDefinition,
      listingConfig
    );

    const initFormioTriggerChange = () => {
      // need to call formio.triggerChange() to get the custom controls to work
      setTimeout(() => {
        if (formRef.current?.formio?.triggerChange) {
          formRef.current.formio.triggerChange();
        } else {
          initFormioTriggerChange();
        }
      }, 500);
    };

    const disableFormioFields = (
      def: RegisterCustomFormField,
      releasedFields: string[]
    ) => {
      if (def.input) {
        // disable only fields released for amendment
        const disabled = !releasedFields.find((field) => field === def.key);

        if (def.type === "datagrid") {
          // datagrid cannot be disabled as a whole
          return {
            ...def,
            components: disabled
              ? def.components.map((child: RegisterCustomFormField) =>
                  disableFormioFields(child, [])
                )
              : def.components,
            customClass: disabled ? "formio-disabled" : undefined,
          };
        }
        return {
          ...def,
          disabled: def.type !== "signature" ? disabled : false, // signature cannot be shown if disabled
          customClass: disabled ? "formio-disabled" : undefined,
        };
      }
      return {
        ...def,
        components: def.components.map((child: RegisterCustomFormField) =>
          disableFormioFields(child, releasedFields)
        ),
        customClass: "formio-disabled",
      };
    };

    formNodeRef.current = (
      <Form
        ref={formRef}
        form={{
          components:
            editForm.defaultStatus || editForm.allowEdit
              ? // before approval or released for amendment
                editForm.formDefinition
              : // approval in progress, no amendment
                disableFormioFields(
                  {
                    components: editForm.formDefinition,
                  },
                  []
                ).components,
        }}
        submission={{
          data: editForm.formContent,
        }}
        options={FORMIO_DEFAULT_OPTIONS}
        onChange={(data: any) => {
          setEditForm((prevState) => ({ ...prevState, formData: data }));
        }}
      />
    );

    initFormioTriggerChange();
  }

  const handleCancelConfirm = async () => {
    try {
      setEditMode("saving");
      const entry = await cancelRegisterFormEntry(
        formEntry.id,
        appContext.serviceConfig
      );
      setEditForm((prevState) => ({
        ...prevState,
        isCancelled: true,
        updatedOn: entry.updatedOn,
      }));
      // delay to ensure backend update completion as cancelRegisterFormEntry is queued
      await new Promise<void>((resolve) =>
        setTimeout(() => {
          routerHistory.push("../");
          notify.actionCompleted();
          resolve();
        }, 1000)
      );
    } catch (ex) {
      appContext.errorHandler(ex, "cancel form entry");
    } finally {
      setEditMode("editing");
    }
  };

  const [copyModalVisible, setCopyModalVisible] = useState(false);

  const handleFormEntryCopied = () => {
    routerHistory.push("../");
  };

  const getStatusAlert = () => {
    if (editForm.defaultStatus) {
      return null;
    }
    if (editForm.cancelStatus) {
      return (
        <NxpAlert
          type="warning"
          message={t("FormEntrySummaryContainer.ThisFormIsCancelledOn", {
            date: formatDate(
              formEntry.updatedOn,
              DateFormatPattern.dateWithTime
            ),
          })}
        />
      );
    }
    if (!editForm.allowEdit) {
      return (
        <NxpAlert
          type="info"
          message={t("FormEntrySummaryContainer.FormApprovalIsInProgress")}
        />
      );
    }
    if (editForm.allowEdit) {
      return (
        <NxpAlert
          type="info"
          message={t("FormEntrySummaryContainer.FormReleasedForAmendment")}
        />
      );
    }
  };

  const handlePrintFormSelected = (printFormId: number) => {
    routerHistory.push(`./${formEntry.id}/printable/${printFormId}`);
  };

  return (
    <>
      {copyModalVisible && (
        <AddFormEntryModal
          modalVisible={copyModalVisible}
          modalWidth={getAddFormEntryModalWidth(
            formEntry.formConfig.definitions
          )}
          copyFormContent={editForm.formData.data || editForm.formContent}
          onFormEntryAdded={handleFormEntryCopied}
          onModalClose={() => setCopyModalVisible(false)}
        />
      )}
      <FormEntrySummaryLayout
        formReference={formEntry.formNumber}
        printables={printables}
        editForm={editForm}
        statusAlert={getStatusAlert()}
        formNode={formNodeRef.current}
        onCopyClick={() => setCopyModalVisible(true)}
        onSave={handleSave}
        onCancelConfirm={handleCancelConfirm}
        onPrintFormSelected={handlePrintFormSelected}
      />
    </>
  );
};

export default FormEntrySummaryContainer;
