import { CloseCircleFilled, FileOutlined } from "@ant-design/icons";
import * as React from "react";
import "./Previewer.css";

interface PlaceholderPreviewerProps {
  url: string;
  remove?: () => void;
}

export const PlaceholderPreviewer: React.FC<PlaceholderPreviewerProps> = ({
  url,
  remove,
}) => {
  const handleThumbnailClick = React.useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  return (
    <div className="previewer-container">
      <div onClick={handleThumbnailClick} className="placeholder-container">
        <FileOutlined />
      </div>
      {remove && (
        <div className="previewer__close-button" onClick={remove}>
          <CloseCircleFilled />
        </div>
      )}
    </div>
  );
};
