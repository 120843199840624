import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import {
  getRuleTemplateList,
  RegisterRuleTemplate,
} from "../../../services/registerRule";
import SetupRuleLayout from "./SetupRuleLayout";

interface SetupRuleContainerProps {}

const SetupRuleContainer: React.FC<SetupRuleContainerProps> = () => {
  const history = useHistory();
  const appContext = useAppContext();
  const { ruleTemplateId: templateIdParam } = useParams<{
    ruleTemplateId: string;
  }>();
  const activeTemplateId = templateIdParam
    ? Number(templateIdParam)
    : undefined;

  const [ruleTemplates, setRuleTemplates] = useState<RegisterRuleTemplate[]>();

  useEffect(() => {
    if (!activeTemplateId && appContext.activeRegister?.defaultRuleTemplateId) {
      history.push(
        `rule-templates/${appContext.activeRegister?.defaultRuleTemplateId}`
      );
    }
    getRuleTemplateList(appContext.serviceConfig).then((data) =>
      setRuleTemplates(data)
    );
  }, [
    appContext.activeRegister?.defaultRuleTemplateId,
    history,
    activeTemplateId,
    appContext.serviceConfig,
  ]);

  const handleTemplateCreated = (newTemplate: RegisterRuleTemplate) => {
    setRuleTemplates((prevState) => [newTemplate, ...(prevState || [])]);
    history.push(
      `${activeTemplateId ? "" : "rule-templates/"}${newTemplate.id}`
    );
  };

  return (
    <AppAccess rolesAllowed="adminOnly" showWarning>
      <SetupRuleLayout
        activeTemplateId={activeTemplateId}
        ruleTemplates={ruleTemplates}
        onTemplateCreated={handleTemplateCreated}
      />
    </AppAccess>
  );
};

export default SetupRuleContainer;
