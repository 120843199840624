import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  DateFormatPattern,
  NxpLabel,
  NxpPanel,
} from "@nexploretechnology/nxp-ui";
import { Col, Row } from "antd";

import { FormEntryLog } from "../../../services/registerFormEntry";
import formatDate from "../../../utils/date/formatDate";

const useStyles = createUseStyles((theme) => ({
  logItem: {
    marginTop: theme.spacing(2),
    wordWrap: "break-word",
    "& .ant-col + .ant-col": {
      paddingLeft: theme.spacing(1),
    },
  },
  panelTitle: {
    display: "inline-block",
    margin: 0,
    "& .action": {
      fontWeight: "normal",
    },
  },
}));

const renderField = (field: ReactNode) => {
  return typeof field === "object" ? JSON.stringify(field) : field;
};

interface FormEntryLogsLayoutProps {
  logs: FormEntryLog[];
}

const LogItem: React.FC<{ log: FormEntryLog }> = ({ log }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.logItem}>
      <h3>{formatDate(log.createdOn, DateFormatPattern.dateWithTime)}</h3>
      <NxpPanel
        titleContent={
          <p className={classes.panelTitle}>
            {log.editor?.firstName} {log.editor?.lastName}{" "}
            <span className="action">{log.action.toLowerCase()}</span>{" "}
            {log.sourceType}
          </p>
        }
        disableCollapse
      >
        <Row>
          <Col span={8}>
            <NxpLabel>{t("app.common.Field")}</NxpLabel>
          </Col>
          <Col span={8}>
            <NxpLabel>{t("FormEntryLogsLayout.Previous")}</NxpLabel>
          </Col>
          <Col span={8}>
            <NxpLabel>{t("FormEntryLogsLayout.UpdatedTo")}</NxpLabel>
          </Col>
        </Row>
        {Object.keys(log.changedFields).map((fieldKey) => (
          <Row key={fieldKey}>
            <Col span={8}>
              <p>{fieldKey}</p>
            </Col>
            <Col span={8}>
              <p>{renderField(log.changedFields[fieldKey].old)}</p>
            </Col>
            <Col span={8}>
              <p>{renderField(log.changedFields[fieldKey].new)}</p>
            </Col>
          </Row>
        ))}
      </NxpPanel>
    </div>
  );
};

const FormEntryLogsLayout: React.FC<FormEntryLogsLayoutProps> = ({ logs }) => {
  return (
    <div>
      {logs.map((log) => (
        <LogItem key={log.id} log={log} />
      ))}
    </div>
  );
};

export default FormEntryLogsLayout;
