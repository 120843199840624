import React from "react";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { SearchOutlined } from "@ant-design/icons";
import { NxpButton, NxpCardList, NxpHeader } from "@nexploretechnology/nxp-ui";
import { Input } from "antd";

import cardCover from "../../images/card-cover.png";
import { RegisterListItem } from "../../services/register";

const useStyles = createUseStyles((theme) => ({
  highlight: {
    padding: 0,
    backgroundColor: theme.palette.marker,
  },
}));

interface RegistersLayoutProps {
  registers?: RegisterListItem[];
  searchValue: string;
  searchValueDebounced: string;
  onSearch: (val: string) => void;
  onCreateClick: () => void;
  onCardClick: (registerId: number, e?: React.MouseEvent) => void;
}

const RegistersLayout: React.FC<RegistersLayoutProps> = ({
  registers,
  searchValue,
  searchValueDebounced,
  onSearch,
  onCreateClick,
  onCardClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <NxpHeader
        titleContent={t("RegistersLayout.Registers")}
        actionContent={
          <Input
            disabled={!registers}
            prefix={<SearchOutlined />}
            value={searchValue}
            allowClear
            onChange={(e) => onSearch(e.target.value)}
          />
        }
      />
      <NxpCardList
        isLoading={!registers}
        onCreateClick={onCreateClick}
        createText={t("RegistersLayout.NewRegister")}
        cards={(registers || []).map((reg) => ({
          id: reg.register.id?.toString(),
          onClick: () => onCardClick(reg.register.id),
          cover: (
            <img
              src={cardCover}
              alt={`Register - ${reg.register.register_name}`}
            />
          ),
          children: (
            <>
              <h3>
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchValueDebounced]}
                  autoEscape={true}
                  textToHighlight={reg.register.register_name}
                />
                {}
              </h3>
              <p>
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchValueDebounced]}
                  autoEscape={true}
                  textToHighlight={reg.register.description || ""}
                />
              </p>
              <p>
                {t("RegistersLayout.By")}{" "}
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchValueDebounced]}
                  autoEscape={true}
                  textToHighlight={reg.register.owner.name}
                />
              </p>
              <p>
                <Highlighter
                  highlightClassName={classes.highlight}
                  searchWords={[searchValueDebounced]}
                  autoEscape={true}
                  textToHighlight={reg.register.entity?.name || ""}
                />
              </p>
            </>
          ),
          actions: [
            <NxpButton
              type="text"
              onClick={(e) => onCardClick(reg.register.id, e)}
            >
              {t("RegistersLayout.Select")}
            </NxpButton>,
          ],
        }))}
      />
    </>
  );
};

export default RegistersLayout;
