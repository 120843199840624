import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";

import { RegisterFlowStep } from "../../../../../services/registerFlow";
import AddFlowStepModal from "./AddFlowStepModal";

interface AddFlowStepButtonProps {
  flowId: number;
  flowStepsCount: number;
  formUserSelectFields: LabeledValue[];
  onFlowStepAdded: (newFlowStep: RegisterFlowStep) => void;
}

const AddFlowStepButton: React.FC<AddFlowStepButtonProps> = ({
  flowId,
  flowStepsCount,
  formUserSelectFields,
  onFlowStepAdded,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleFlowStepAdded = (newFlowStep: RegisterFlowStep) => {
    onFlowStepAdded(newFlowStep);
    handleModalClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <NxpButton onClick={handleClick}>
        {t("AddFlowStepButton.AddStep")}
      </NxpButton>
      {modalVisible && (
        <AddFlowStepModal
          flowId={flowId}
          flowStepsCount={flowStepsCount}
          formUserSelectFields={formUserSelectFields}
          modalVisible={modalVisible}
          onModalClose={handleModalClose}
          onFlowStepAdded={handleFlowStepAdded}
        />
      )}
    </>
  );
};

export default AddFlowStepButton;
