import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  NxpHeader,
  NxpSpin,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { useAsync } from "../../hooks/useAsync";
import { getRegisterFormEntry } from "../../services/registerFormEntry";
import {
  FormEntryApproval,
  getFormEntryApprovalList,
} from "../../services/registerFormEntryApproval";
import { getRegisterListingConfig } from "../../services/registerListing";
import { getPrintablesList } from "../../services/registerPrintables";
import FormEntryApprovals from "./FormEntryApprovals";
import FormEntryAttachments from "./FormEntryAttachments/FormEntryAttachments";
import FormEntryLogs from "./FormEntryLogs";
import FormEntrySummary from "./FormEntrySummary";
import PrintableEntry from "./FormEntrySummary/PrintableEntry";

interface RegisterFormEntryDetailsProps {}

const RegisterFormEntryDetails: React.FC<RegisterFormEntryDetailsProps> =
  () => {
    const appContext = useAppContext();
    const routerHistory = useHistory();
    const routerLocation = useLocation<{ from: string }>();
    const [tabsActiveKey, setTabsActiveKey] = useState<string>("summary");

    const {
      formEntryId: formEntryIdParam,
      printFormId,
      registerId: registerIdParam,
    } = useParams<{
      formEntryId: string;
      printFormId: string;
      registerId: string;
    }>();

    const formEntryId = Number(formEntryIdParam);
    const registerId = Number(registerIdParam);

    const [formEntryAsync, setFormEntryRequest] = useAsync(
      () =>
        getRegisterFormEntry(formEntryId, registerId, appContext.serviceConfig),
      undefined,
      "get form entry"
    );

    const [listingConfigAsync] = useAsync(
      () => getRegisterListingConfig(registerId, appContext.serviceConfig),
      undefined,
      "get listing config"
    );

    const [printablesAsync] = useAsync(
      () =>
        getPrintablesList(
          appContext.activeRegister!.id,
          appContext.serviceConfig,
          {
            limit: 0,
            offset: 0,
            filters: [
              { column: "enabled", type: "Equal", value: true },
              { column: "status", type: "Equal", value: "valid" },
            ],
            sorters: [],
          }
        ).then((res) => res.data),
      undefined,
      "get form entry print forms"
    );

    const [formApprovalsAsync, setFormApprovalsRequest] = useAsync<
      FormEntryApproval[]
    >(
      () => getFormEntryApprovalList(formEntryId, appContext.serviceConfig),
      []
    );

    const { t } = useTranslation();

    const handleFormEntryStatusChanged = () => {
      setFormEntryRequest(() =>
        getRegisterFormEntry(formEntryId, registerId, appContext.serviceConfig)
      );
      setFormApprovalsRequest(() =>
        getFormEntryApprovalList(formEntryId, appContext.serviceConfig)
      );
    };

    if (printFormId) {
      if (formEntryAsync.data && printablesAsync.data) {
        return (
          <PrintableEntry
            printForm={
              printablesAsync.data.find(
                (printForm) => printForm.id === Number(printFormId)
              )!
            }
            formEntry={formEntryAsync.data}
          />
        );
      }
      return null;
    }

    const dataReady =
      appContext.activeRegister &&
      formEntryAsync.data &&
      !formEntryAsync.loading &&
      formApprovalsAsync.data &&
      !formApprovalsAsync.loading &&
      listingConfigAsync.data;

    return (
      <>
        <NxpHeader
          titleContent={t("RegisterFormEntryDetails.FormEntry")}
          onBackClick={() => {
            if (routerLocation.state?.from) {
              routerHistory.goBack();
            } else {
              routerHistory.push("../");
            }
          }}
        />
        <NxpTabs activeKey={tabsActiveKey} onChange={setTabsActiveKey}>
          <TabPane tab={t("RegisterFormEntryDetails.Summary")} key="summary">
            {dataReady ? (
              <FormEntrySummary
                listingConfig={listingConfigAsync.data!}
                printables={printablesAsync.data || []}
                activeApproval={
                  formApprovalsAsync.data!.length
                    ? formApprovalsAsync.data!.reduce((approval, aggr) =>
                        approval.id > aggr.id ? approval : aggr
                      )
                    : undefined
                }
                formEntry={formEntryAsync.data!}
                onFormEntryStatusChanged={handleFormEntryStatusChanged}
              />
            ) : (
              <NxpSpin size="large" expand />
            )}
          </TabPane>
          <TabPane tab={t("app.common.Approvals")} key="approvals">
            {/* remoount logs tab to make sure approval flow is update to date */}
            {tabsActiveKey === "approvals" && dataReady ? (
              <FormEntryApprovals
                formEntryId={formEntryId}
                formApprovals={formApprovalsAsync.data!.map((approval) => {
                  if (approval.submitUser) {
                    approval.submitUser.name = `${approval.submitUser?.firstName} ${approval.submitUser?.lastName}`;
                  }
                  return approval;
                })}
                onFormEntryStatusChanged={handleFormEntryStatusChanged}
                cancelled={formEntryAsync.data?.entryState.status.cancelStatus}
              />
            ) : null}
          </TabPane>
          <TabPane tab={t("app.common.Attachments")} key="attachments">
            <FormEntryAttachments formEntryId={formEntryId} />
          </TabPane>
          {
            // appContext.registerAccessList["register:admin"] || appContext.registerAccessList["register:setup"]
            appContext.hasAccess("adminOnly") ? (
              <TabPane tab={t("RegisterFormEntryDetails.Logs")} key="logs">
                {/* remoount logs tab to make sure log data is always the latest */}
                {tabsActiveKey === "logs" ? (
                  <FormEntryLogs formEntryId={formEntryId} />
                ) : null}
              </TabPane>
            ) : null
          }
        </NxpTabs>
      </>
    );
  };

export default RegisterFormEntryDetails;
