import { NxpModalProps } from "@nexploretechnology/nxp-ui";

import {
  extractFormConfigFields,
  RegisterCustomFormField,
} from "../../../services/registerCustomForm";

export default function getAddFormEntryModalWidth(
  formSpec: RegisterCustomFormField[]
): NxpModalProps["width"] {
  if (
    extractFormConfigFields(formSpec, false, false, []).find(
      (field) => field.type === "datagrid" || field.type === "survey"
    )
  ) {
    return "large";
  }
  return "medium";
}
