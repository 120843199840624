import { FileOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
  fileLink: {
    "&::after": {
      content: "'\\A'",
      whiteSpace: "pre",
    },
  },
}));

interface FileLinkProps {
  files?: UploadFile[];
}

const FileLink: React.FC<FileLinkProps> = ({ files }) => {
  const classes = useStyles();
  return files && files.length ? (
    <>
      {files.map((file) => (
        <a
          className={classes.fileLink}
          onClick={(e) => e.stopPropagation()}
          href={file.url}
          target="_blank"
          rel="noreferrer"
        >
          <FileOutlined /> {file.fileName}
        </a>
      ))}
    </>
  ) : null;
};

export default FileLink;
