import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { NavLink } from "react-router-dom";

import {
  NxpHeader,
  NxpMainSplitter,
  NxpSpin,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { RegisterRuleTemplate } from "../../../services/registerRule";
import CreateTemplateModalButton from "./CreateTemplateModal/CreateTemplateModalButton";
import RuleTemplateDetails from "./RuleTemplateDetails";

const useStyles = createUseStyles((theme) => ({
  setupRuleLayout: {},
  selectTemplates: {
    padding: theme.spacing(4),
    "& > ul": {
      listStyle: "none",
      padding: 0,
      margin: theme.spacing(1, 0),
    },
  },
  templateList: {
    "& a": {
      color: theme.palette.text,
      "&.active": {
        color: theme.palette.primary,
        fontWeight: theme.fontWeight.bold,
      },
    },
  },
  templateDetails: {
    margin: theme.spacing(3, 0, 0, 5),
  },
}));

interface SetupRuleLayoutProps {
  activeTemplateId?: number;
  ruleTemplates?: RegisterRuleTemplate[];
  onTemplateCreated: (newTemplate: RegisterRuleTemplate) => void;
}

const SetupRuleLayout: React.FC<SetupRuleLayoutProps> = ({
  activeTemplateId,
  ruleTemplates,
  onTemplateCreated,
}) => {
  const appContext = useAppContext();
  const classes = useStyles();

  const definedRuleTemplates = ruleTemplates || [];

  const { t } = useTranslation();

  return (
    <NxpMainSplitter className={classes.setupRuleLayout}>
      <div className={classes.selectTemplates}>
        <CreateTemplateModalButton onTemplateCreated={onTemplateCreated} />
        <ul className={classes.templateList}>
          {ruleTemplates ? (
            ruleTemplates.map((template) => (
              <li key={template.id}>
                <NavLink
                  to={
                    activeTemplateId
                      ? `./${template.id}`
                      : `./rule-templates/${template.id}`
                  }
                >
                  {template.ruleTemplateName}
                </NavLink>
              </li>
            ))
          ) : (
            <NxpSpin size="large" />
          )}
        </ul>
      </div>
      {activeTemplateId &&
      definedRuleTemplates.some((item) => item.id === activeTemplateId) &&
      appContext.activeRegister ? (
        <RuleTemplateDetails
          className={classes.templateDetails}
          ruleTemplate={
            definedRuleTemplates.find((item) => item.id === activeTemplateId)!
          }
        />
      ) : (
        <div>
          <NxpHeader
            className={classes.templateDetails}
            titleContent={t("RuleTemplateDetails.SetupRule")}
          />
        </div>
      )}
    </NxpMainSplitter>
  );
};

export default SetupRuleLayout;
