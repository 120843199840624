// @ts-nocheck

///////////////////////////////////////////////// https://codesandbox.io/s/81qb6?file=/src/Custom/Toggle/index.js:1919-1931

import { Select } from 'antd';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent } from 'react-formio';

import { CaretDownOutlined } from '@ant-design/icons';

// import { getRegisterDictionary } from "../../../services/registerDictionary";
// import useAppContext from "../../../hooks/useAppContext";
import {
  CPCSDictionaryItem,
  getCPCSDictionaryData,
} from '../../../services/app';
import AppContext from '../../App/AppContext';
import keycloakInstance from '../../App/keycloak';
import { registerIdMatch } from '../registerIdMatch';
import settingsForm from './SelectCPCSDictionary.form';

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const mapSelectedDictionaryPath = (
  dictionaryKey: string,
  dictionaryItems: CPCSDictionaryItem[],
  parentKey?: string
) => {
  if (dictionaryItems.find((item) => item.key === dictionaryKey)) {
    return parentKey ? [parentKey, dictionaryKey] : [dictionaryKey];
  } else {
    // find path for dictionaryCode recursively
    return dictionaryItems
      .map((item) =>
        mapSelectedDictionaryPath(dictionaryKey, item.members || [], item.key)
      )
      .filter((item) => item.length > 0) // filter out unmatched as mapSelectedDictionaryPath return [] when unmatched
      .map((item) => (parentKey ? [parentKey, item] : item)) // send back matched
      .flat(Infinity); // keeps array at one dimension
  }
};

const findDictionaryItem = (
  key: string,
  dictionaryItems: CPCSDictionaryItem[]
) => {
  const foundItem = dictionaryItems.find((item) => item.key === key);
  if (foundItem) {
    return foundItem;
  } else {
    return dictionaryItems
      .map((childItem) =>
        childItem.members?.length
          ? findDictionaryItem(key, childItem.members)
          : undefined
      )
      .find((item) => !!item);
  }
};

// functional component do not work in formio, reason unknown
const SelectDictionaryInternal = class extends Component {
  constructor(props) {
    super(props);

    if (props.cpcsDictionaryKey && props.serviceConfig.registerId) {
      getCPCSDictionaryData(props.cpcsDictionaryKey, props.serviceConfig).then(
        (dict) => {
          this.setState({
            dictionaryItems: dict?.members || [],
          });
        }
      );
    }

    this.state = {
      value: props.value,
      options: [],
    };
  }

  buildSelectComponent = (
    selectedValue: string,
    dictionaryItems: CPCSDictionaryItem[],
    parentCode?: string // empty for root
  ) => {
    const parentItem = parentCode
      ? findDictionaryItem(parentCode, dictionaryItems) // for child select
      : { code: "", children: dictionaryItems }; // for root select

    return parentItem.children?.length ? (
      <Select
        disabled={this.props.disabled}
        style={{ width: "100%" }}
        suffixIcon={<CaretDownOutlined />}
        className="appFormioSelectDictionary"
        options={[
          {
            label: "",
            value: "",
          },
          ...parentItem.children.map((item) => ({
            label: item.label,
            value: item.key,
          })),
        ]}
        value={selectedValue}
        onChange={(_, opt) =>
          this.setState(
            {
              value: opt.value // child select empty set value to parent
                ? { key: opt.value, label: opt.label }
                : parentCode // root select empty set value to null
                ? { key: parentCode, label: parentItem.label }
                : null,
            },
            () => this.props.onChange(null, { changed: true })
          )
        }
      />
    ) : null;
  };

  render() {
    const dictionaryPathArr = mapSelectedDictionaryPath(
      this.state.value?.key,
      this.state.dictionaryItems || []
    );

    return (
      <>
        {dictionaryPathArr.length
          ? // value selected
            [
              ...dictionaryPathArr.map((code, idx) =>
                this.buildSelectComponent(
                  code,
                  this.state.dictionaryItems,
                  idx > 0 ? dictionaryPathArr[idx - 1] : undefined
                )
              ),
              // add select for the next level
              this.buildSelectComponent(
                "",
                this.state.dictionaryItems,
                dictionaryPathArr[dictionaryPathArr.length - 1]
              ),
            ]
          : // nothing selected show only root items
            this.buildSelectComponent("", this.state.dictionaryItems || [])}
      </>
    );
  }
};

export default class SelectDictionary extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Select CPCS Dictionary",
      icon: "list",
      group: "Data",
      documentation: "",
      weight: 10,
      schema: SelectDictionary.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "selectCPCSDictionary",
      label: "", //default label, if any
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    const [, entityId] = window.location.href.match(/entities\/([\w-]+)/);

    const registerId = registerIdMatch();

    return ReactDOM.render(
      <SelectDictionaryInternal
        disabled={this.component.disabled}
        cpcsDictionaryKey={this.component.cpcsDictionaryKey}
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        serviceConfig={{
          entityId: entityId,
          registerId: Number(registerId),
          keyCloakToken: keycloakInstance.token,
          apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
          qcServiceApiBaseUrl: process.env.REACT_APP_QC_API_BASE_URL || "",
        }}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  static contextType = AppContext;
}
