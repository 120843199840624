import * as React from 'react';
import * as yup from 'yup';

import { CustomApiErrorServerResponseData } from '../utils/backend';
import CustomApiError from '../utils/backend/customApiError';
import fetchWithTimeout from '../utils/backend/extendFetchApiTime';

export interface UploadFile {
  id?: string;
  createdAt?: Date;
  remarkId?: number;
  assetId?: string;
  fileName?: string;
  mimeType?: string;
  url: string;
}

export const signResponseSchema = yup
  .object()
  .camelCase()
  .shape({
    assets: yup.array(
      yup.object().camelCase().shape({
        assetId: yup.string().required(),
        url: yup.string().required(),
      })
    ),
  });

export interface PresignUploadResponse {
  assetId: string;
  url: string;
}

export interface SignResponse {
  assets: { assetId: string; url: string }[];
}

export class FileService {
  private endpoint: string;
  private token: string;

  public constructor(endpoint: string, token: string) {
    this.endpoint = endpoint;
    this.token = token;
  }

  private async request<R = {}>(options: {
    path: string;
    method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
    jsonBody?: object;
  }): Promise<R> {
    const { path, method, jsonBody } = options;
    const url = new URL(`${this.endpoint}${path}`);

    const resp = await fetch(url.href, {
      method,
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${this.token}` || "",
      },
      body: jsonBody ? JSON.stringify({ ...jsonBody }) : undefined,
    });
    if (resp.ok) {
      return resp.json();
    }

    throw new Error(await resp.text());
  }

  public async fileUpload(registerId: number, file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const uploadData = {
      headers: {
        Authorization: `Bearer ${this.token}` || "",
      },
      method: "POST",
      body: formData,
    };

    const url = `${this.endpoint}/powerflow/registers/${registerId}/files/upload`;

    const response = await fetchWithTimeout(url, {
      ...uploadData,
      timeout: 3600000, // one hour
    });

    if (!response.ok) {
      let errorJson: CustomApiErrorServerResponseData | undefined = undefined;
      try {
        errorJson = await response.json();
      } catch {
        // nothing to do
      }
      throw new CustomApiError(
        url,
        uploadData,
        response.status,
        response.statusText,
        errorJson,
        undefined,
        0,
        undefined,
        true // stop retry for defined api server error
      );
    } else {
      const respText = await response.text();
      const result = JSON.parse(respText);
      return result as unknown as PresignUploadResponse;
    }
  }

  public async sign(
    registerId: number,
    options: {
      assetIds: string[];
    }
  ): Promise<SignResponse> {
    const resp = await this.request({
      path: `/powerflow/registers/${registerId}/files/sign`,
      method: "POST",
      jsonBody: {
        assets: options.assetIds.map((assetId) => ({ assetId: assetId })),
      },
    });
    return signResponseSchema.validateSync(resp);
  }
}

export function useFileService(token: string) {
  const [service] = React.useState(
    () =>
      new FileService(
        process.env.REACT_APP_API_BASE_URL || "",
        //"http://coreapi.cpcs.localhost/file",
        token
      )
  );
  return service;
}
