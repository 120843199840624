import { useKeycloak } from "@react-keycloak/web";
import { useCallback } from "react";
import CustomApiError from "../utils/backend/customApiError";
import notify from "../utils/notify";
export const useErrorHandler = () => {
  const { keycloak } = useKeycloak();

  return useCallback(
    (
      error: Error | unknown,
      taskDescription: string, // task in format such as 'getting locations', 'updating photo detail', 'deleting album'
      errorReference?: string | number | object, // information that could be useful for tracing error
      hideNotification?: boolean
    ) => {
      // const currentUser = useCurrentUser();
      // const location = useLocation()
      // should add logic for logging error here...

      if ((error as CustomApiError).status === 401) {
        keycloak.logout();
      }

      if (!hideNotification) {
        notify.error(
          `Error occurred${
            taskDescription ? ` when ${taskDescription}` : ""
          } - ${error instanceof Error ? error.message : error}`
        );
      }
    },
    [keycloak]
  );
};

export default useErrorHandler;
