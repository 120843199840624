import { LabeledValue } from "antd/lib/select";
import clsx from "clsx";
import React from "react";

import { createUseStyles } from "react-jss";
import {
  RuleCondition,
  RuleConditionGroup,
  RuleConjunctive,
} from "../../../../services/registerRule";
import AddConditionButton from "./AddConditionButton";
import RuleGroupChild from "./RuleGroupChild";

const useStyles = createUseStyles((theme) => ({
  ruleGroupLayout: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.borderRadius.main,
    padding: theme.spacing(0, 1, 1, 1),

    "&.isRoot": {
      border: "none",
      padding: 0,
      marginTop: theme.spacing(-1),
      marginRight: theme.spacing(4),
    },
  },
}));

interface RuleGroupLayoutProps {
  isRoot?: boolean;
  ruleGroup: RuleConditionGroup;
  fieldOptions: LabeledValue[];
  errorKeys: number[];
  onAddCondition: (conditionGroupKey: number) => void;
  onConjunctiveChange: (
    conjunctive: RuleConjunctive,
    conditionGroupKey: number
  ) => void;
  onConditionChange: (ruleCondition: RuleCondition) => void;
  onConditionDelete: (conditionKey: number, conditionGroupKey: number) => void;
  onConditionGroup: (ruleCondition: RuleCondition) => void;
  onRuleDrop: (
    draggingRule: RuleConditionGroup | RuleCondition,
    draggingRuleParent: RuleConditionGroup,
    dropToRule: RuleConditionGroup | RuleCondition | undefined,
    dropToRuleParent: RuleConditionGroup
  ) => void;
}

const RuleGroupLayout: React.FC<RuleGroupLayoutProps> = ({
  isRoot,
  ruleGroup,
  fieldOptions,
  errorKeys,
  onAddCondition,
  onConjunctiveChange,
  onConditionGroup,
  onConditionChange,
  onConditionDelete,
  onRuleDrop,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(isRoot && "isRoot", classes.ruleGroupLayout)}>
      <div>
        {ruleGroup.children.map((groupChild, idx) => (
          <RuleGroupChild
            key={groupChild.key}
            groupChild={groupChild}
            childIndex={idx}
            parentGroup={ruleGroup}
            fieldOptions={fieldOptions}
            errorKeys={errorKeys}
            onAddCondition={onAddCondition}
            onConjunctiveChange={onConjunctiveChange}
            onConditionGroup={onConditionGroup}
            onConditionChange={onConditionChange}
            onConditionDelete={onConditionDelete}
            onRuleDrop={onRuleDrop}
          />
        ))}
        <AddConditionButton
          ruleGroup={ruleGroup}
          onClick={() => onAddCondition(ruleGroup.key)}
          onRuleDrop={onRuleDrop}
        />
      </div>
    </div>
  );
};

export default RuleGroupLayout;
