import { apiRequest, ServiceConfig } from "../utils/backend";
import { User } from "./app";

export interface Delegation {
  id: number;
  delegates: User[];
  expiryDate?: Date;
  createdOn: Date;
  updatedOn: Date;
}
export interface DelegationForm extends Pick<Delegation, "expiryDate"> {
  delegates: string[];
}

export const getDelegation = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<Delegation>(
    `/powerflow/entities/${serviceConfig.entityId}${
      serviceConfig.registerId ? `/registers/${serviceConfig.registerId}` : ""
    }/delegates`,
    serviceConfig
  );
};

export const updateDelegation = async (
  delegation: DelegationForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<Delegation>(
    `/powerflow/entities/${serviceConfig.entityId}${
      serviceConfig.registerId ? `/registers/${serviceConfig.registerId}` : ""
    }/delegates`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(delegation),
    }
  );
};
