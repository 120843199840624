import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { RegisterDictionary } from "../../../../services/registerDictionary";
import AddDictionaryModal from "./AddDictionaryModal";

interface AddDictionaryButtonProps {
  registerDictionaries: RegisterDictionary[];
  onDictionaryCreated: (newDictionary: RegisterDictionary) => void;
}

const AddDictionaryButton: React.FC<AddDictionaryButtonProps> = ({
  registerDictionaries,
  onDictionaryCreated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleDictionaryCreated = (newDictionary: RegisterDictionary) => {
    onDictionaryCreated(newDictionary);
    setModalVisible(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <NxpButton onClick={handleClick}>
        {t("AddDictionaryButton.AddDictionary")}
      </NxpButton>
      <AddDictionaryModal
        registerDictionaries={registerDictionaries}
        modalVisible={modalVisible}
        onModalClose={handleModalClose}
        onDictionaryCreated={handleDictionaryCreated}
      />
    </>
  );
};

export default AddDictionaryButton;
