import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import {
  useValidate,
  ValidationResultNested,
} from "../../../hooks/useValidate";
import { getCPCSDictionaryTypes } from "../../../services/app";
import {
  createRegisterCustomForm,
  getRegisterCustomForm,
  RegisterCustomFormField,
  RegisterCustomFormForCreate,
  updateRegisterCustomForm,
} from "../../../services/registerCustomForm";
import { getRegisterDictionaryList } from "../../../services/registerDictionary";
import {
  FORM_SERVICE_APPLICATION,
  FORM_SERVICE_IDENTIFIER_COLUMN,
} from "../../../utils/const";
import notify from "../../../utils/notify";
import SetupFormLayout from "./SetupFormLayout";

export type SetupFormEditMode =
  | "unInitialized"
  | "initializing"
  | "initializeFailed"
  | "editing"
  | "saving"
  | "unInitialized";

// export interface SetupFormCustomInfo {
//   name: string;
//   code: string;
//   numberFormat: string;
//   type: string;
// }

interface SetupFormContainerProps {}

const SetupFormContainer: React.FC<SetupFormContainerProps> = () => {
  const appContext = useAppContext();

  const [editMode, setEditMode] = useState<SetupFormEditMode>("unInitialized");

  const [form, setForm] = useState<
    RegisterCustomFormForCreate & { formId?: number }
  >(() => ({
    entityId: "",
    type: "",
    code: "",
    numberFormat: "",
    name: "",
    application: FORM_SERVICE_APPLICATION,
    identifierColumn: FORM_SERVICE_IDENTIFIER_COLUMN,
    identifierId: 0,
    definitions: [],
  }));

  const [dictionaries] = useAsync(
    () => getRegisterDictionaryList(appContext.serviceConfig),
    [],
    "get dictionary list"
  );

  const [cpcsDictionaries] = useAsync(
    () => getCPCSDictionaryTypes(appContext.serviceConfig),
    [],
    "get cpcs dictionary list"
  );

  useEffect(() => {
    if (
      appContext.activeEntity &&
      appContext.activeRegister &&
      editMode === "unInitialized"
    ) {
      setEditMode("initializing");
      getRegisterCustomForm(appContext.serviceConfig)
        .then((customForm) => {
          if (customForm) {
            // console.log(
            //   "jjjjjjjjjj",
            //   JSON.parse(customForm.definitions as any)
            // );
            setForm({
              formId: customForm.id,
              entityId: customForm.entityId,
              type: customForm.type,
              code: customForm.code,
              numberFormat: customForm.numberFormat,
              name: customForm.name,
              application: FORM_SERVICE_APPLICATION,
              identifierColumn: FORM_SERVICE_IDENTIFIER_COLUMN,
              identifierId: appContext.activeRegister!.id,
              definitions: customForm.definitions,
            });
          } else {
            setForm({
              entityId: appContext.activeEntity!.id,
              type: "",
              code: "",
              numberFormat:
                "{​​​​​​​%PN%}​​​​​​​-​​​​{​​​​​​​%FC%}​​​​​​​-{​​​​​​​%NUM%}​​​​​​​",
              name: "",
              application: FORM_SERVICE_APPLICATION,
              identifierColumn: FORM_SERVICE_IDENTIFIER_COLUMN,
              identifierId: appContext.activeRegister!.id,
              definitions: [],
            });
          }
          setEditMode("editing");
        })
        .catch((ex) => {
          appContext.errorHandler(ex, "fetch register custom form");
          setEditMode("initializeFailed");
        });
    }
  }, [appContext, editMode]);

  const { t } = useTranslation();

  const formSchema = yup.object({
    definitions: yup
      .array()
      .min(1, t("SetupFormContainer.PleaseAddAtLeastOneField")),
    name: yup.string().nullable().required(t("app.common.NameRequired")),
    code: yup
      .string()
      .nullable()
      .required(t("SetupFormContainer.FormCodeRequired")),
    type: yup
      .string()
      .nullable()
      .required(t("SetupFormContainer.FormTypeRequired")),
    numberFormat: yup
      .string()
      .nullable()
      .required("Reference Format required."),
  });

  const handleSaveValidated = async () => {
    setEditMode("saving");
    try {
      if (form.formId) {
        const {
          formId,
          identifierColumn,
          identifierId,
          application,
          ...updateForm
        } = form;
        await updateRegisterCustomForm(
          formId,
          {
            ...updateForm,
            status: "valid",
          },
          appContext.serviceConfig
        );
      } else {
        await createRegisterCustomForm(form, appContext.serviceConfig);
      }
      setEditMode("unInitialized");
      notify.actionCompleted();
    } catch (ex) {
      appContext.errorHandler(ex, "create register custom form");
    } finally {
      setEditMode("editing");
    }
  };

  const [validationError, , , saveWithValidate] = useValidate<
    Pick<
      RegisterCustomFormForCreate,
      "code" | "name" | "type" | "numberFormat" | "definitions"
    >
  >(form, formSchema, handleSaveValidated);

  const handleFormSave = () => {
    saveWithValidate(undefined);
  };

  const handleFormInfoChange = (form: Partial<RegisterCustomFormForCreate>) => {
    setForm((prevState) => ({
      ...prevState,
      ...form,
    }));
  };

  const handleFormDefinitionChange = (
    definitions: RegisterCustomFormField[]
  ) => {
    setForm((prevState) => ({
      ...prevState,
      // remove submit button
      definitions: definitions.filter(
        (def) =>
          def.type !== "button" ||
          def.label !== "Submit" ||
          def.key !== "submit"
      ),
    }));
  };

  if (
    !dictionaries.loading &&
    dictionaries.data &&
    !cpcsDictionaries.loading &&
    cpcsDictionaries.data
  ) {
    return (
      <AppAccess rolesAllowed="adminOnly" showWarning>
        <SetupFormLayout
          form={form}
          validationError={
            validationError as ValidationResultNested<RegisterCustomFormForCreate>
          }
          dictionaryOptions={dictionaries.data.map((dict) => ({
            label: dict.dict_name,
            value: dict.id,
          }))}
          cpcsDictionaryOptions={cpcsDictionaries.data.map((dict) => ({
            label: dict.name,
            value: dict.description,
          }))}
          onFormInfoChange={handleFormInfoChange}
          onFormDefinitionChange={handleFormDefinitionChange}
          onFormSave={handleFormSave}
          editMode={editMode}
        />
      </AppAccess>
    );
  }
  return null;
};

export default SetupFormContainer;
