import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { FormEntryApproval } from "../../../services/registerFormEntryApproval";
import ApprovalUpdateModal from "./ApprovalUpdateModalContainer";

interface ApprovalUpdateButtonProps {
  approval: FormEntryApproval;
  onApprovalUpdated: () => void;
}

const ApprovalUpdateButton: React.FC<ApprovalUpdateButtonProps> = ({
  approval,
  onApprovalUpdated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleApprovalUpdated = () => {
    handleModalClose();
    onApprovalUpdated();
  };

  const { t } = useTranslation();

  return (
    <>
      <NxpButton onClick={handleClick}>{t("app.common.Waiting")}...</NxpButton>
      {modalVisible && (
        <ApprovalUpdateModal
          approval={approval}
          modalVisible={modalVisible}
          onModalClose={handleModalClose}
          onApprovalUpdated={handleApprovalUpdated}
        />
      )}
    </>
  );
};

export default ApprovalUpdateButton;
