import React from "react";
import { useTranslation } from "react-i18next";

import { NxpAlert, NxpButton } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { ChatMessage, ChatPost } from "../../../services/approvalsChat";
import { RegisterFormEntry } from "../../../services/registerFormEntry";
import { FormEntryApproval } from "../../../services/registerFormEntryApproval";
import ApprovalChatFileModal from "./ApprovalChatFileModal";

import "./ApprovalChats.css";

interface ApprovalChatsLayoutProps {
  userId: string;
  messages: ChatMessage[];
  posters: string[];
  approval: FormEntryApproval;
  formEntry: RegisterFormEntry;
  setForm: React.Dispatch<React.SetStateAction<ChatPost>>;
  callGetChats: () => void;
  // added for CPCS-680
  isRelease?: boolean;
  setAmmendApproval: () => void;
  setRelockApproval: () => void;
}

const ApprovalChatsLayout: React.FC<ApprovalChatsLayoutProps> = (props) => {
  const {
    userId,
    messages,
    posters,
    approval,
    formEntry,
    setForm,
    callGetChats,
    // added for CPCS-680
    isRelease,
    setAmmendApproval,
    setRelockApproval,
  } = props;

  const appContext = useAppContext();
  const [message, setMessage] = React.useState<string>("");
  const [showPostFileModal, setShowPostFileModal] =
    React.useState<boolean>(false);

  const { t } = useTranslation();

  function handleEditMessage(event: any) {
    setMessage(event.target.value);
  }

  const sendChat = React.useCallback(() => {
    setForm({
      author: userId,
      message: message,
    });
    setMessage("");
    // @ts-ignore
    // CPCS-807: adding to clear up the control after submit
    document.getElementById("chat-input").value = "";
  }, [message, setForm, userId]);

  // added for CPCS-680
  function release() {
    try {
      setAmmendApproval();
    } catch (ex) {
      console.log("Get to this area");
    }
  }

  function lock() {
    try {
      setRelockApproval();
    } catch (ex) {
      console.error("Unableto relock the approval");
    }
  }

  const messagesRef = React.useRef(null);

  React.useEffect(() => {
    if (messagesRef.current) {
      //@ts-ignore
      messagesRef.current.scrollIntoView();
    }
  }, [messages]);

  return (
    <div className="chats-tab-container">
      {isRelease ? (
        <NxpAlert
          type="info"
          message={t(
            "ApprovalChatsLayout.ApprovalIsCurrentlyReleasedForAmendments"
          )}
        />
      ) : null}
      <div className="messages">
        {messages.map((msg, i) => (
          <div
            key={i}
            className={
              "message" + (msg.author.id === userId ? " user" : " not-user")
            }
          >
            <div className="profile-wrapper">
              <div
                className={
                  "profile " +
                  "color" +
                  posters.indexOf(msg.author.id).toString().split("").pop()
                }
              >
                {msg.author.firstName.slice(0, 1) +
                  msg.author.lastName.slice(0, 1)}
              </div>
            </div>
            <div className="bubble">
              <div className="meta">
                <div
                  className={"author color" + posters.indexOf(msg.author.id)}
                >
                  {msg.author.firstName}
                </div>
                <div className="timestamp">
                  {/* !! format */}
                  {msg.createdOn.toString()}
                </div>
              </div>
              {!msg.attachedFile ? (
                <div className="text">
                  {msg.message.split("\n").map((s, i: number) => {
                    return (
                      <span key={i}>
                        {s}
                        <br />
                      </span>
                    );
                  })}
                  <br />
                </div>
              ) : (
                <div className="text">
                  {msg.attachedFile.file.mimeType?.substring(0, 5) ===
                  "image" ? (
                    <img src={msg.attachedFile.file.url} alt="" />
                  ) : null}
                  <br />
                  <a href={msg.attachedFile.file.url}>{msg.message}</a>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="messages-bottom" ref={messagesRef}></div>
      <div className="new-message-wrapper">
        <form
          className="new-message"
          onSubmit={(e) => {
            e.preventDefault();
            sendChat();
          }}
        >
          <input
            id="chat-input"
            className="message-input"
            onChange={handleEditMessage}
          />
          {!isRelease ? (
            <NxpButton
              className="post-file-modal-button"
              onClick={() => {
                release();
              }}
              disabled={
                approval.currentStep.approvingUsers.filter(
                  (u) => u.id === userId
                ).length === 0
              }
            >
              R
            </NxpButton>
          ) : (
            <NxpButton
              className="post-file-modal-button"
              onClick={() => {
                lock();
              }}
              disabled={
                !(
                  approval.approvalSteps &&
                  approval.approvalSteps[0].approvers.find(
                    (approverStep) =>
                      approverStep.approver.id === appContext.activeUser?.id
                  )
                )
              }
            >
              L
            </NxpButton>
          )}
          <NxpButton
            className="post-file-modal-button"
            onClick={() => {
              setShowPostFileModal(true);
            }}
          >
            +
          </NxpButton>
          <button
            type="submit"
            className="send-button"
            disabled={message.length < 1}
          >
            {t("ApprovalChatsLayout.SEND")}
          </button>
        </form>
      </div>
      <ApprovalChatFileModal
        formEntry={formEntry}
        userId={userId}
        modalVisible={showPostFileModal}
        setModalVisible={setShowPostFileModal}
        approval={approval}
        callGetChats={callGetChats}
      />
    </div>
  );
};

export default ApprovalChatsLayout;
