import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { getRegister } from "../../../services/register";
import {
  batchPatchStatus,
  RegisterStatusPatchItem,
} from "../../../services/registerStatus";
import notify from "../../../utils/notify";
import SetupStatusLayout from "./SetupStatusLayout";

export interface SetupStatusFormItem extends RegisterStatusPatchItem {
  id: number;
  isDeleted: boolean;
  isNew: boolean;
  errorMsg: string;
}

export type SetupStatusEditMode = "initializing" | "read" | "edit" | "saving";

interface SetupStatusContainerProps {}

const SetupStatusContainer: React.FC<SetupStatusContainerProps> = () => {
  const [form, setForm] = useState<SetupStatusFormItem[]>([]);
  const [editMode, setEditMode] = useState<SetupStatusEditMode>("initializing");
  const appContext = useAppContext();

  const initForm = useCallback(() => {
    if (appContext.activeRegister) {
      setForm(
        (appContext.activeRegister.statuses || []).map((status) => ({
          id: status.id,
          statusName: status.statusName,
          allowEdit: !!status.allowEdit,
          allowResubmit: !!status.allowResubmit,
          outstanding: !!status.outstanding,
          isDeleted: false,
          isNew: false,
          errorMsg: "",
          defaultStatus: !!status.defaultStatus,
          submittedStatus: !!status.submittedStatus,
          releaseStatus: !!status.releaseStatus,
          changeStatus: !!status.changeStatus,
          cancelStatus: !!status.cancelStatus,
          withdrawStatus: !!status.withdrawStatus,
        }))
      );
      setEditMode("read");
    }
  }, [setForm, appContext.activeRegister]);

  useEffect(() => {
    initForm();
  }, [appContext.activeRegister, initForm]);

  const handleEditClick = () => {
    setEditMode("edit");
  };

  const handleFormItemChange = (changeItem: SetupStatusFormItem) => {
    setForm((prevState) => {
      return prevState.map((status) =>
        status.id === changeItem.id
          ? changeItem
          : {
              ...status, // follow status can only be true in one record
              defaultStatus: changeItem.defaultStatus
                ? false
                : status.defaultStatus,
              releaseStatus: changeItem.releaseStatus
                ? false
                : status.releaseStatus,
              submittedStatus: changeItem.submittedStatus
                ? false
                : status.submittedStatus,
              changeStatus: changeItem.changeStatus
                ? false
                : status.changeStatus,
              cancelStatus: changeItem.cancelStatus
                ? false
                : status.cancelStatus,
              withdrawStatus: changeItem.withdrawStatus
                ? false
                : status.withdrawStatus,
            }
      );
    });
  };

  const handleAddStatusItem = () => {
    setForm((prevState) => [
      ...prevState,
      {
        id: Math.max(...prevState.map((status) => status.id)) + 1,
        statusName: "",
        allowEdit: false,
        allowResubmit: false,
        outstanding: false,
        isDeleted: false,
        isNew: false,
        errorMsg: "",
        defaultStatus: false,
        submittedStatus: false,
        releaseStatus: false,
        changeStatus: false,
        cancelStatus: false,
        withdrawStatus: false,
      },
    ]);
  };

  const { t } = useTranslation();

  const validateForm = () => {
    let valid = true;

    setForm((prevState) =>
      prevState.map((item) => {
        const statusName = item.statusName.toLocaleLowerCase().trim();
        if (!item.isDeleted) {
          if (!statusName) {
            item.errorMsg = t("app.common.NameRequired");
            valid = false;
          } else if (
            prevState.find(
              (otherItem) =>
                !otherItem.isDeleted &&
                otherItem.id !== item.id &&
                otherItem.statusName.toLocaleLowerCase().trim() === statusName
            )
          ) {
            item.errorMsg = t("SetupStatusContainer.NameDuplicated");
            valid = false;
          } else {
            item.errorMsg = "";
          }
        }
        return item;
      })
    );

    return valid;
  };

  const handleFormSave = async () => {
    const registerId = appContext.activeRegister!.id;
    if (validateForm()) {
      try {
        setEditMode("saving");
        await batchPatchStatus(
          registerId,
          form
            .filter((item) => !item.isDeleted)
            .map((item) => ({
              statusName: item.statusName,
              allowResubmit: item.allowResubmit,
              allowEdit: item.allowEdit,
              outstanding: item.outstanding,
              defaultStatus: item.defaultStatus,
              submittedStatus: item.submittedStatus,
              releaseStatus: item.releaseStatus,
              changeStatus: item.changeStatus,
              cancelStatus: item.cancelStatus,
              withdrawStatus: item.withdrawStatus,
            })),
          appContext.serviceConfig
        );
        setEditMode("read");
        notify.actionCompleted();
        // update cached register to reflect changes
        appContext.onAppContextCacheItemUpdate(
          "activeRegister",
          await getRegister(registerId, appContext.serviceConfig)
        );
      } catch (ex) {
        appContext.errorHandler(ex, "update register status");
        setEditMode("edit");
      } finally {
      }
    }
  };

  const handleCancel = () => {
    initForm();
    setEditMode("read");
  };
  return (
    <AppAccess rolesAllowed="adminOnly" showWarning>
      <SetupStatusLayout
        form={form}
        onFormItemChange={handleFormItemChange}
        onAddStatusItem={handleAddStatusItem}
        onFormSave={handleFormSave}
        editMode={editMode}
        onEditClick={handleEditClick}
        onCancel={handleCancel}
      />
    </AppAccess>
  );
};

export default SetupStatusContainer;
