import React, { ReactNode, useRef, useState } from "react";
import { FormBuilder } from "react-formio";
import { useTranslation } from "react-i18next";
import { createUseStyles, useTheme } from "react-jss";

import {
  NxpButton,
  NxpFormSurvey,
  NxpHeader,
  NxpHolder,
} from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import baseEditForm from "formiojs/components/_classes/component/Component.form";

import { AppTheme } from "../../../components/App/appTheme";
import { ValidationResultNested } from "../../../hooks/useValidate";
import {
  RegisterCustomFormField,
  RegisterCustomFormForCreate,
} from "../../../services/registerCustomForm";
import { SetupFormEditMode } from "./SetupFormContainer";
import SetupFormPreview from "./SetupFormPreview";

const useStyles = createUseStyles((theme) => ({
  setupFormLayout: {
    // "& .formio-component-button.formio-component-submit": {
    //   display: 'none'
    // }
  },
  noField: {
    color: theme.palette.warning,
  },
  applyButton: {
    backgroundColor: theme.palette.primary,
  },
}));

interface SetupFormLayoutProps {
  form: RegisterCustomFormForCreate & {
    formId?: number;
  };
  editMode: SetupFormEditMode;
  validationError: ValidationResultNested<RegisterCustomFormForCreate>;
  dictionaryOptions: LabeledValue[];
  cpcsDictionaryOptions: LabeledValue[];
  onFormInfoChange: (info: Partial<RegisterCustomFormForCreate>) => void;
  onFormDefinitionChange: (definition: RegisterCustomFormField[]) => void;
  onFormSave: () => void;
}

const SetupFormLayout: React.FC<SetupFormLayoutProps> = ({
  form,
  editMode,
  validationError,
  dictionaryOptions,
  cpcsDictionaryOptions,
  onFormInfoChange,
  onFormDefinitionChange,
  onFormSave,
}) => {
  const classes = useStyles();
  const theme = useTheme<AppTheme>();
  const formBuilder = useRef<ReactNode>(null);

  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const { t } = useTranslation();

  if (editMode === "editing" && formBuilder.current === null) {
    const signatureEditFormFunc =
      Formio.Components.components.signature.editForm;

    Formio.Components.components.signature.editForm = (...extend: any) => {
      const signatureEditForm = signatureEditFormFunc(...extend);
      signatureEditForm.components
        .find((item: any) => item.key === "tabs")
        .components.find((item: any) => item.key === "display")
        .components.find((item: any) => item.key === "label").label =
        "Signature ID";
      return signatureEditForm;
    };

    Formio.Components.components.selectDictionary.editForm = (
      ...extend: any
    ) => {
      return baseEditForm(
        [
          {
            key: "display",
            components: [
              {
                tooltip: "Select a dictionary defined in Setup - Dictionaries",
                type: "select",
                label: "Dictionary",
                key: "dictionaryId",
                weight: 12,
                data: {
                  values: dictionaryOptions,
                },
                multiple: false,
                input: true,
                // remove below because not able to get client side validation styling to work
                // validate: {
                //   required: true,
                // },
              },
            ],
          },
          {
            key: "data",
            ignore: true,
          },
          {
            key: "validation",
            components: [],
          },
          {
            key: "api",
            components: [],
          },
          {
            key: "conditional",
            components: [],
          },
          {
            key: "logic",
            components: [],
          },
        ],
        ...extend
      );
    };
    Formio.Components.components.selectCPCSDictionary.editForm = (
      ...extend: any
    ) => {
      return baseEditForm(
        [
          {
            key: "display",
            components: [
              {
                tooltip: "Select a dictionary defined in Setup - Dictionaries",
                type: "select",
                label: "CPCS Dictionary",
                key: "cpcsDictionaryKey",
                weight: 12,
                data: {
                  values: cpcsDictionaryOptions,
                },
                multiple: false,
                input: true,
                // remove below because not able to get client side validation styling to work
                // validate: {
                //   required: true,
                // },
              },
            ],
          },
          {
            key: "data",
            ignore: true,
          },
          {
            key: "validation",
            components: [],
          },
          {
            key: "api",
            components: [],
          },
          {
            key: "conditional",
            components: [],
          },
          {
            key: "logic",
            components: [],
          },
        ],
        ...extend
      );
    };
    formBuilder.current = (
      <FormBuilder
        onChange={(form: any) => onFormDefinitionChange(form.components)}
        // onUpdateComponent={(form: object) => console.log('uuuuu', form)}
        //       onDeleteComponent={(form: object) => console.log('dddddd', form)}
        //       onCancelComponent={(form: object) => console.log('cccccccc', form)}
        onEditComponent={(form: object) => {
          const btn = document.querySelector(
            "button.btn.btn-success[ref='saveButton']"
          ) as HTMLElement;
          if (btn) {
            btn.innerHTML = "Apply";
            btn.style.backgroundColor = theme.palette.primary;
            btn.style.borderColor = theme.palette.primary;
          }
        }}
        form={{
          components: form.definitions,
        }}
        options={{
          builder: {
            basic: {
              title: "Common",
              components: {
                content: true,
                email: true,
                url: true,
                selectDictionary: true,
                selectCPCSDictionary: true,
                selectUser: true,
                fileUpload: true,
                signature: false,
                signatureNew: {
                  title: "Signature+",
                  key: "signature",
                  icon: "pencil",
                  schema: {
                    type: "signature",
                    backgroundColor: "rgba(255,255,255,0)",
                    input: true,
                  },
                },
                datagrid: true,
                datetime: true,
                day: true,
                time: true,
                survey: true,
                tags: true,
              },
            },
            premium: false,
            layout: {
              components: {
                HtmlElement: false,
                sectionHeader: true,
                content: false,
                fieldSet: false,
                table: false,
                tabs: false,
                well: false,
              },
            },
            advanced: false,
            data: false,
          },
        }}
      />
    );
  }

  return (
    <div className={classes.setupFormLayout}>
      <NxpHeader
        titleContent={t("SetupForm.SetupForm")}
        actionContent={
          <>
            <NxpButton
              type="default"
              onClick={() => setPreviewModalVisible(true)}
              disabled={form.definitions.length === 0}
            >
              {t("app.common.Preview")}
            </NxpButton>
            <NxpButton disabled={editMode !== "editing"} onClick={onFormSave}>
              {t("app.common.Save")}
            </NxpButton>
          </>
        }
      />
      <NxpHolder>
        <NxpFormSurvey
          formItems={[
            {
              controlType: "input",
              controlProps: {
                value: form.name,
                onChange: (e) =>
                  onFormInfoChange({
                    name: e.target.value,
                  }),
              },
              error: validationError?.name,
              label: t("SetupForm.FormName"),
              itemFieldName: "custom",
              required: true,
              span: 1,
            },
            {
              controlType: "input",
              controlProps: {
                disabled: !!form.formId,
                value: form.code,
                onChange: (e) =>
                  onFormInfoChange({ ...form, code: e.target.value }),
              },
              error: validationError?.code,
              label: t("SetupForm.FormCode"),
              itemFieldName: "custom",
              span: 1,
              required: true,
            },
            {
              controlType: "input",
              controlProps: {
                value: form.type,
                onChange: (e) =>
                  onFormInfoChange({ ...form, type: e.target.value }),
              },
              error: validationError?.type,
              label: t("SetupForm.FormType"),
              itemFieldName: "custom",
              span: 1,
              required: true,
            },
            {
              controlType: "input",
              controlProps: {
                value: form.numberFormat,
                onChange: (e) =>
                  onFormInfoChange({
                    ...form,
                    numberFormat: e.target.value,
                  }),
              },
              error: validationError?.numberFormat,
              label: t("SetupForm.NumberFormat"),
              itemFieldName: "custom",
              span: 1,
              required: true,
            },
          ]}
        />
      </NxpHolder>
      <NxpHolder>
        {validationError.definitions && (
          <p className={classes.noField}>{validationError.definitions}</p>
        )}
        {formBuilder.current}
      </NxpHolder>
      {previewModalVisible && (
        <SetupFormPreview
          formDefinitions={form.definitions}
          modalVisible={previewModalVisible}
          onModalClose={() => setPreviewModalVisible(false)}
        />
      )}
    </div>
  );
};

export default SetupFormLayout;
