import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useAsync } from "../../../../hooks/useAsync";
import { useValidate } from "../../../../hooks/useValidate";
import {
  extractFormConfigFields,
  getRegisterCustomForm,
  RegisterCustomForm,
} from "../../../../services/registerCustomForm";
import {
  createRegisterFlowStep,
  deleteRegisterFlowStep,
  getFlowStepList,
  RegisterFlow,
  RegisterFlowStep,
  updateRegisterFlowStep,
} from "../../../../services/registerFlow";
import { getRoleAssignmentList } from "../../../../services/roleAssignment";
import notify from "../../../../utils/notify";
import AddFlowStepButton from "./AddFlowStep/AddFlowStepButton";
import FlowDetailsLayout from "./FlowDetailsLayout";

interface FlowDetailsCopyObject {
  action: "copyFlowSteps";
  registerId: number;
  steps: RegisterFlowStep[];
  flowId: number;
}

interface FlowDetailsContainerProps {
  flow: RegisterFlow;
  className: string;
}

const FlowDetailsContainer: React.FC<FlowDetailsContainerProps> = ({
  flow,
  className,
}) => {
  const appContext = useAppContext();
  const [flowSteps, setFlowSteps] = useState<RegisterFlowStep[]>([]);

  const { t } = useTranslation();

  const formSchema = yup.object().shape({
    stepName: yup.string().nullable().required(t("app.common.NameRequired")),
  });

  const fetchList = useCallback(async () => {
    await getFlowStepList(flow.id, appContext.serviceConfig)
      .then((data) => setFlowSteps(data))
      .catch((ex) => appContext.errorHandler(ex, "get flows"));
  }, [appContext, flow.id]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const [editItem, setEditItem] = useState<RegisterFlowStep>();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [pasteInProgress, setPasteInProgress] = useState(false);

  const [roles] = useAsync(
    () =>
      getRoleAssignmentList(
        appContext.serviceConfig,
        appContext.activeRegister?.id
      ),
    [],
    "get role options"
  );

  const [{ data: registerCustomForm }] = useAsync<
    RegisterCustomForm | undefined
  >(() => getRegisterCustomForm(appContext.serviceConfig));

  const handleSaveValidated = async () => {
    if (editItem) {
      setSaveInProgress(true);
      try {
        await updateRegisterFlowStep(
          editItem.id,
          flow.id,
          {
            stepName: editItem.stepName,
            approveStatusId: editItem.approveStatusId,
            rejectStatusId: editItem.rejectStatusId,
            roleCodes: editItem.roleCodes,
            order: editItem.order,
            editFields: editItem.editFields,
            parallelApprove: false,
          },
          appContext.serviceConfig
        );
        await fetchList();
        setEditItem(undefined);
        notify.actionCompleted();
      } catch (ex) {
        appContext.errorHandler(ex, "update flow");
      } finally {
        setSaveInProgress(false);
      }
    }
  };

  const [validationError, , clearError, saveWithValidate] = useValidate<
    Pick<RegisterFlowStep, "stepName">
  >(editItem, formSchema, handleSaveValidated);

  const handleRowEdit = useCallback(
    (editItem: RegisterFlowStep) => setEditItem({ ...editItem }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(() => {
    setEditItem(undefined);
    clearError();
  }, [clearError]);

  const handleRowDelete = useCallback(
    async (deleteItem: RegisterFlowStep) => {
      if (editItem) {
        setSaveInProgress(true);
        try {
          await deleteRegisterFlowStep(
            editItem.id,
            flow.id,
            appContext.serviceConfig
          );
          fetchList();
          setEditItem(undefined);
          notify.actionCompleted();
        } catch (ex) {
          appContext.errorHandler(ex, "delete flow step");
        } finally {
          setSaveInProgress(false);
        }
      }
    },
    [appContext, editItem, fetchList, flow.id]
  );

  const handleFormStateChange = useCallback(
    (fieldName: keyof RegisterFlowStep, value: unknown) => {
      // console.log("ccccccccccccccc", fieldName, value);
      setEditItem((prevState) => ({
        ...prevState!,
        [fieldName]: value,
      }));
    },
    []
  );

  const handleFlowStepAdded = () => {
    fetchList();
  };

  const [copiedObj, , setCopiedObj] = useAsync<
    FlowDetailsCopyObject | undefined
  >(() =>
    navigator.clipboard
      .readText()
      .then((text) => {
        try {
          return JSON.parse(text) as FlowDetailsCopyObject;
        } catch {
          return undefined;
        }
      })
      .catch((e) => {
        console.log("Browser permission denied.", e);
        return undefined;
      })
  );

  const handleCopy = async () => {
    try {
      const objToCopy: FlowDetailsCopyObject = {
        action: "copyFlowSteps",
        registerId: appContext.activeRegister!.id,
        steps: flowSteps,
        flowId: flow.id,
      };

      await navigator.clipboard.writeText(JSON.stringify(objToCopy));
      notify.success(t("FlowDetailsContainer.StepsCopied"));
      setCopiedObj({ data: objToCopy, loading: false, error: undefined });
    } catch (ex) {
      notify.error(ex);
    }
  };

  const handlePaste = async () => {
    try {
      setPasteInProgress(true);
      const jsonStr = await navigator.clipboard.readText();
      const objToPaste: FlowDetailsCopyObject = JSON.parse(jsonStr);
      if (
        objToPaste.registerId === appContext.activeRegister?.id &&
        objToPaste.flowId !== flow.id
      ) {
        const promises = objToPaste.steps.map((step) =>
          createRegisterFlowStep(
            flow.id,
            {
              stepName: step.stepName,
              approveStatusId: step.approveStatusId,
              rejectStatusId: step.rejectStatusId,
              roleCodes: step.roleCodes,
              order: step.order,
              editFields: step.editFields,
              parallelApprove: false,
            },
            appContext.serviceConfig
          )
        );
        await Promise.all(promises);
        await navigator.clipboard.writeText("");
        setPasteInProgress(false);
        setCopiedObj({ data: undefined, loading: false, error: undefined });
        notify.success(t("FlowDetailsContainer.StepsPasted"));
        fetchList();
      }
    } catch (ex) {
      notify.error(ex);
    }
  };

  return (
    <FlowDetailsLayout
      className={className}
      flow={flow}
      statuses={appContext.activeRegister?.statuses || []}
      flowSteps={flowSteps}
      editItem={editItem}
      validationError={validationError}
      saveInProgress={saveInProgress}
      roles={roles.data ? roles.data : []}
      registerCustomForm={registerCustomForm}
      headerActionContent={
        <>
          <NxpButton
            disabled={flowSteps.length === 0 || pasteInProgress}
            onClick={handleCopy}
          >
            {t("FlowDetailsContainer.CopySteps")}
          </NxpButton>
          <NxpButton
            disabled={
              pasteInProgress ||
              copiedObj.data?.registerId !== appContext.activeRegister?.id ||
              copiedObj.data?.flowId === flow.id
            }
            onClick={handlePaste}
          >
            {t("FlowDetailsContainer.PasteSteps")}
          </NxpButton>
          <AddFlowStepButton
            flowId={flow.id}
            flowStepsCount={flowSteps.length}
            formUserSelectFields={extractFormConfigFields(
              registerCustomForm?.definitions || [],
              true,
              false,
              []
            )
              .filter((field) => field.type === "selectUser")
              .map((field) => ({ label: field.label, value: field.key }))}
            onFlowStepAdded={handleFlowStepAdded}
          />
        </>
      }
      onRowDelete={handleRowDelete}
      onRowEdit={handleRowEdit}
      onRowSave={handleRowSave}
      onRowCancel={handleRowCancel}
      onFormStateChange={handleFormStateChange}
    />
  );
};

export default FlowDetailsContainer;
