import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpFormTable,
  NxpFormTableColumnProps,
  NxpHeader,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { useValidate } from "../../../hooks/useValidate";
import {
  RegisterDictionary,
  RegisterDictionaryItemForm,
  updateRegisterDictionary,
} from "../../../services/registerDictionary";
import notify from "../../../utils/notify";
import AddDictionaryItemButton from "./AddDictionaryItem/AddDictionaryItemButton";

interface DictionaryDetailsContainerProps {
  dictionary: RegisterDictionary;
  className: string;
  onDictionaryUpdated: (dict: RegisterDictionary) => void;
}

const DictionaryDetailsContainer: React.FC<DictionaryDetailsContainerProps> = ({
  dictionary,
  className,
  onDictionaryUpdated,
}) => {
  const appContext = useAppContext();

  const [editItem, setEditItem] = useState<
    RegisterDictionaryItemForm & { orignalKey: string }
  >();
  const [saveInProgress, setSaveInProgress] = useState(false);

  const { t } = useTranslation();

  const columns: NxpFormTableColumnProps<
    RegisterDictionaryItemForm & { orignalKey: string }
  >[] = [
    {
      title: t("app.common.Value"),
      dataIndex: "dictKey",
      width: 150,
      sorter: (a, b) => sorterForString(a.dictKey, b.dictKey),
      formItemProps: {
        controlType: "input",
      },
    },
    {
      title: t("app.common.Text"),
      dataIndex: "dictLabel",
      width: 150,
      sorter: (a, b) => sorterForString(a.dictLabel, b.dictLabel),
      formItemProps: {
        controlType: "input",
      },
    },
  ];

  const handleSaveValidated = async () => {
    if (editItem) {
      setSaveInProgress(true);
      try {
        if (editItem.dictKey !== editItem.orignalKey) {
          delete dictionary.dict_pair[editItem.orignalKey];
        }
        const updateDict = await updateRegisterDictionary(
          dictionary.id,
          {
            dict_name: dictionary.dict_name,
            dict_pair: {
              ...dictionary.dict_pair,
              [editItem.dictKey]: editItem.dictLabel,
            },
          },
          appContext.serviceConfig
        );
        onDictionaryUpdated(updateDict);
        setEditItem(undefined);
        notify.actionCompleted();
      } catch (ex) {
        appContext.errorHandler(ex, "update dictionary item");
      } finally {
        setSaveInProgress(false);
      }
    }
  };

  const formSchema = yup.object().shape({
    dictKey: yup
      .string()
      .nullable()
      .required(t("app.common.ValueRequired"))
      .test(
        "duplicate",
        t("app.common.DictionaryValueAlreadyExisted"),
        function (val) {
          return !Object.keys(dictionary.dict_pair).find(
            (key) =>
              key.trim() === val.trim() && key.trim() !== editItem?.orignalKey
          );
        }
      ),
    dictLabel: yup
      .string()
      .nullable()
      .required(t("app.common.TextRequired"))
      .test(
        "duplicate",
        t("app.common.DictionaryTextAlreadyExisted"),
        function (val) {
          return !Object.keys(dictionary.dict_pair).find(
            (key) =>
              dictionary.dict_pair[key].trim() === val.trim() &&
              key.trim() !== editItem?.orignalKey
          );
        }
      ),
  });

  const [validationError, , clearError, saveWithValidate] =
    useValidate<RegisterDictionaryItemForm>(
      editItem,
      formSchema,
      handleSaveValidated
    );

  const handleRowEdit = useCallback(
    (editItem: RegisterDictionaryItemForm) =>
      setEditItem({ ...editItem, orignalKey: editItem.dictKey }),
    []
  );

  const handleRowSave = useCallback(() => {
    saveWithValidate(undefined);
  }, [saveWithValidate]);

  const handleRowCancel = useCallback(() => {
    setEditItem(undefined);
    clearError();
  }, [clearError]);

  const handleRowDelete = useCallback(
    async (deleteItem: RegisterDictionaryItemForm) => {
      if (editItem) {
        setSaveInProgress(true);
        try {
          delete dictionary.dict_pair[editItem.orignalKey];

          const updateDict = await updateRegisterDictionary(
            dictionary.id,
            {
              dict_name: dictionary.dict_name,
              dict_pair: dictionary.dict_pair,
            },
            appContext.serviceConfig
          );
          onDictionaryUpdated(updateDict);
          setEditItem(undefined);
          notify.actionCompleted();
        } catch (ex) {
          appContext.errorHandler(ex, "delete dictionary item");
        } finally {
          setSaveInProgress(false);
        }
      }
    },
    [editItem, dictionary, appContext, onDictionaryUpdated]
  );

  const handleFormStateChange = useCallback(
    (
      fieldName: keyof (RegisterDictionaryItemForm & { orignalKey: string }),
      value: unknown
    ) => {
      // console.log('ccccccccccccccc', fieldName, value);
      setEditItem((prevState) => ({
        ...prevState!,
        [fieldName]: value,
      }));
    },
    []
  );

  return (
    <div className={className}>
      <AppAccess rolesAllowed="adminOnly" showWarning>
        <NxpHeader
          titleContent={`${t("app.common.SetupDictionary")} - ${
            dictionary.dict_name
          }`}
          actionContent={
            <AddDictionaryItemButton
              dictionary={dictionary}
              onDictionaryItemAdded={onDictionaryUpdated}
              disabled={!!editItem}
            />
          }
        />
        <NxpFormTable
          formState={editItem}
          onFormStateChange={handleFormStateChange}
          validationError={validationError}
          useDefaultHeight
          saveInProgress={saveInProgress}
          itemCompareKey="orignalKey"
          onRowDelete={handleRowDelete}
          onRowEdit={handleRowEdit}
          onRowSave={handleRowSave}
          onRowCancel={handleRowCancel}
          columns={columns}
          dataSource={Object.keys(dictionary.dict_pair).map((key) => ({
            dictLabel: dictionary.dict_pair[key],
            dictKey: key,
            orignalKey: key,
          }))}
        />
      </AppAccess>
    </div>
  );
};

export default DictionaryDetailsContainer;
