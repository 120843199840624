declare global {
  namespace Jss {
    export interface Theme extends AppTheme {}
  }
}

export interface AppTheme {
  spacing: (...multipliers: number[]) => string;
  pxToRem: (px: number) => string;
  palette: {
    text: string;
    textInverted: string;
    textLabel: string;
    textLink: string;

    background: string;
    backgroundLight: string;
    backgroundDark: string;
    backgroundDarker: string;
    backgroundPrimaryLight: string;

    border: string;
    borderLight: string;

    primary: string;
    primaryLight: string;
    primaryDark: string;

    warningLight: string;
    warning: string;
    warningDark: string;

    success: string;
    successLight: string;
    successDark: string;

    dim: string;
    dimLight: string;
    dimDark: string;

    marker: string;
    markerLight: string;
    markerDark: string;
  };
  fontSize: {
    smaller: number | string;
    small: number | string;
    main: number;
    h1: number | string;
    h2: number | string;
    h3: number | string;
    h4: number | string;
    h5: number | string;
    h6: number | string;
  };
  fontWeight: { bold: number; bolder: number };
  borderRadius: {
    small: number;
    main: number;
    large: number;
  };
  modalWidth: {
    smaller: number;
    small: number;
    main: number;
    large: number;
  };
  transition: {
    main: string;
    fast: string;
    faster: string;
  };
}

export const appTheme: AppTheme = require("../../theme.config");
