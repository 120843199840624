export const GRID_COLUMN_TOTAL = 24;
export const LAZY_LOAD_PAGE_SIZE = 50;

export const DEFAULT_COLUMN_WIDTH = 100;
export const MIN_COLUMN_WIDTH = 50;
export const DEFAULT_DECIMAL_PLACE = 2;

export const PRINT_FORM_FILE_TYPE = "SYSTEM";

export const FORM_SERVICE_APPLICATION = "POWERFLOW";
export const FORM_SERVICE_IDENTIFIER_COLUMN = "registerId";

export const FORMIO_DEFAULT_OPTIONS = {};

export const STORAGE_X_ACCESS_TOKEN = "xAccessToken";
