import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default function SectionHeaderFormioConfig(...extend: any) {
  return baseEditForm(
    [
      {
        key: "display",
        components: [],
      },
      {
        key: "data",
        components: [],
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
}
