import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { Nxp404 } from "@nexploretechnology/nxp-ui";

import ApprovalDetails from "../../../pages/ApprovalDetails";
import Approvals from "../../../pages/Approvals";
import RegisterFormEntries from "../../../pages/RegisterFormEntries";
import RegisterFormEntryDetails from "../../../pages/RegisterFormEntryDetails/RegisterFormEntryDetails";
import Registers from "../../../pages/Registers";
import SetupAccess from "../../../pages/RegisterSetup/SetupAccess/SetupAccess";
import SetupDictionary from "../../../pages/RegisterSetup/SetupDictionary";
import SetupDocument from "../../../pages/RegisterSetup/SetupDocument";
import SetupFlow from "../../../pages/RegisterSetup/SetupFlow";
import SetupForm from "../../../pages/RegisterSetup/SetupForm";
import SetupGeneral from "../../../pages/RegisterSetup/SetupGeneral";
import SetupListing from "../../../pages/RegisterSetup/SetupListing/SetupListing";
import SetupPrintables from "../../../pages/RegisterSetup/SetupPrintables";
import SetupPrintablesList from "../../../pages/RegisterSetup/SetupPrintables/SetupPrintablesList";
import SetupRule from "../../../pages/RegisterSetup/SetupRule";
import SetupStatus from "../../../pages/RegisterSetup/SetupStatus";
import SetupDelegation from "../../../pages/Settings/Delegation/Delegation";
import RoleAssignments from "../../../pages/Settings/RoleAssignment";
import AppProtectedRoute from "./AppProtectedRoute";

interface AppRouterProps {}

export const getDockerHostRoot = () =>
  window.location.href.toLowerCase().startsWith("http://core.cpcs.localhost")
    ? "/powerflow-ui"
    : "";

const AppRouter: React.FC<AppRouterProps> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/entities/:entityId">
          {({ match }) => (
            <Redirect
              to={`${getDockerHostRoot()}/entities/${
                match?.params.entityId
              }/registers`}
            />
          )}
        </Route>

        {/* approval pages below */}

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/form-entries/:formEntryId/approvals/:approvalId`} // ?register=123 is needed for this path so the page works properly with custom formio controls
        >
          <ApprovalDetails />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/approvals`}
          requireActiveRegister
        >
          <Approvals listType="allApprovals" />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/approvals`}
        >
          <Approvals listType="allApprovals" />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/my-approvals`}
          requireActiveRegister
        >
          <Approvals listType="myApprovals" />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/my-approvals`}
        >
          <Approvals listType="myApprovals" />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/my-requests`}
          requireActiveRegister
        >
          <Approvals listType="myRequests" />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/my-requests`}
        >
          <Approvals listType="myRequests" />
        </AppProtectedRoute>

        {/* register pages below */}

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers`}
        >
          <Registers />
        </AppProtectedRoute>
        <Redirect
          exact
          from={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId`}
          to={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/form-entries`}
        />
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/form-entries/:formEntryId`}
          requireActiveRegister
          requireRegisters
        >
          <RegisterFormEntryDetails />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/form-entries/:formEntryId/printable/:printFormId`}
          requireActiveRegister
          requireRegisters
        >
          <RegisterFormEntryDetails />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/form-entries`}
          requireActiveRegister
          requireRegisters
        >
          <RegisterFormEntries />
        </AppProtectedRoute>

        {/* register setup pages below */}

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/form`}
          requireActiveRegister
        >
          <SetupForm />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/status`}
          requireActiveRegister
        >
          <SetupStatus />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/rule-templates`}
          requireActiveRegister
        >
          <SetupRule />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/documents`}
          requireActiveRegister
        >
          <SetupDocument />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/rule-templates/:ruleTemplateId`}
          requireActiveRegister
        >
          <SetupRule />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/flows`}
          requireActiveRegister
        >
          <SetupFlow />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/flows/:flowId`}
          requireActiveRegister
        >
          <SetupFlow />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/dictionaries`}
          requireActiveRegister
        >
          <SetupDictionary />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/dictionaries/:dictId`}
          requireActiveRegister
        >
          <SetupDictionary />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup`}
          requireActiveRegister
        >
          <SetupGeneral />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/listing`}
          requireActiveRegister
        >
          <SetupListing />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/role-assignments`}
          requireActiveRegister
        >
          <RoleAssignments />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/settings/role-assignments`}
        >
          <RoleAssignments />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/delegation`}
          requireActiveRegister
        >
          <SetupDelegation />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/security`}
          requireActiveRegister
        >
          <SetupAccess />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/settings/delegation`}
        >
          <SetupDelegation />
        </AppProtectedRoute>

        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/print-forms`}
          requireActiveRegister
        >
          <SetupPrintablesList />
        </AppProtectedRoute>
        <AppProtectedRoute
          exact
          path={`${getDockerHostRoot()}/entities/:entityId/registers/:registerId/setup/print-forms/edit-printable`}
          requireActiveRegister
        >
          <SetupPrintables />
        </AppProtectedRoute>
        <AppProtectedRoute>
          <Nxp404 />
        </AppProtectedRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
