import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import {
  NxpAlert,
  NxpHeader,
  NxpTabs,
  TabPane,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../hooks/useAppContext";
import { useAsync } from "../../hooks/useAsync";
import { getFormEntryApproval } from "../../services/registerFormEntryApproval";
import ApprovalChatsContainer from "./ApprovalChats/ApprovalChatsContainer";
import ApprovalOverview from "./ApprovalOverview";

interface ApprovalDetailsProps {}

const ApprovalDetails: React.FC<ApprovalDetailsProps> = () => {
  const appContext = useAppContext();
  const routerHistory = useHistory();
  const { approvalId, formEntryId } = useParams<{
    approvalId: string;
    formEntryId: string;
  }>();

  const fetchApproval = () =>
    getFormEntryApproval(
      Number(approvalId),
      Number(formEntryId),
      appContext.serviceConfig
    );

  const [approvalAsync, setApprovalAsyncRequest] = useAsync(
    () => fetchApproval(),
    undefined,
    "get approval"
  );

  const { t } = useTranslation();

  const handleApprovalUpdated = () => {
    // as requested by - https://nexplore.atlassian.net/browse/CPCS-947
    setTimeout(() => window.location.reload(), 1000);
    // setApprovalAsyncRequest(() => fetchApproval());
  };

  const handleApprovalLockChange = () => {
    setApprovalAsyncRequest(() => fetchApproval());
  };

  const alert = approvalAsync.data?.rejected ? (
    <NxpAlert message={t("ApprovalDetails.ThisRequestWasRejected")} />
  ) : approvalAsync.data?.withdrawn ? (
    <NxpAlert message={t("ApprovalDetails.ThisRequestWasWithdrawn")} />
  ) : null;

  return (
    <>
      <NxpHeader
        titleContent={t("ApprovalDetails.FormApproval")}
        onBackClick={routerHistory.goBack}
      />
      {approvalAsync.data && (
        <NxpTabs defaultActiveKey="1">
          <TabPane tab={t("ApprovalDetails.Overview")} key="1">
            {alert}
            <ApprovalOverview
              approval={approvalAsync.data}
              onApprovalUpdated={handleApprovalUpdated}
            />
            )
          </TabPane>
          <TabPane tab={t("ApprovalDetails.Discussion")} key="2">
            {alert}
            <ApprovalChatsContainer
              approval={approvalAsync.data}
              onApprovalLockChange={handleApprovalLockChange}
            />
          </TabPane>
        </NxpTabs>
      )}
    </>
  );
};

export default ApprovalDetails;
