import React from "react";
import { useTranslation } from "react-i18next";

import { DownloadOutlined } from "@ant-design/icons";
import { notify, NxpSpin } from "@nexploretechnology/nxp-ui";
import clsx from "clsx";

import { UploadFile, useFileService } from "../../services/file";
import { ImagePreviewer } from "./ImagePreviewer";
import { PlaceholderPreviewer } from "./PlaceholderPreviewer";

import "./AppFileUpload.css";

export interface AppFileUploadProps {
  label?: string;
  disabled?: boolean;
  onChange: (files: UploadFile[], ref?: any) => void;
  fileLimit?: number;
  fileRef?: any;
  token: string;
  accept?: string;
  hasError?: boolean;
  uploadedFiles?: UploadFile[];
  registerId: number;
}

const AppFileUploadImpl: React.FC<AppFileUploadProps> = (props) => {
  const {
    label,
    onChange,
    fileLimit,
    fileRef,
    hasError,
    disabled,
    uploadedFiles,
    registerId,
  } = props;
  const [files, setFiles] = React.useState<UploadFile[]>([]);
  const fileService = useFileService(props.token);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const previewList = React.useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    onChange(files, fileRef);
  }, [files, onChange, fileRef]);

  const handleOnFileDelete = React.useCallback(
    (file: UploadFile) => {
      if (uploadedFiles?.length) {
        // controlled mode
        onChange(
          uploadedFiles.filter((item) => item.assetId !== file.assetId),
          fileRef
        );
      } else {
        setFiles((prevFiles) =>
          prevFiles.filter((item) => item.assetId !== file.assetId)
        );
      }
    },
    [fileRef, onChange, uploadedFiles]
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const renderPreviewer = React.useCallback(
    (file: UploadFile, disabled?: boolean) => {
      switch (file.mimeType) {
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
        case "image/gif":
          return (
            <ImagePreviewer
              url={file.url}
              remove={disabled ? undefined : () => handleOnFileDelete(file)}
            />
          );
        default:
          return (
            <PlaceholderPreviewer
              url={file.url}
              remove={disabled ? undefined : () => handleOnFileDelete(file)}
            />
          );
      }
    },
    [handleOnFileDelete]
  );

  const startUploadFile = React.useCallback(() => {
    const fileInput = fileInputRef.current;
    if (!fileInput) return;
    fileInput.click();
  }, [fileInputRef]);

  const uploadFile = React.useCallback(
    async (registerId: number, file: File) => {
      fileService
        .fileUpload(registerId, file)
        .then((presignResult) => {
          const attachment = {
            id: presignResult.assetId,
            assetId: presignResult.assetId,
            fileName: file.name,
            mimeType: file.type,
            url: presignResult.url,
          } as UploadFile;
          if (uploadedFiles && uploadedFiles.length > -1) {
            onChange([attachment, ...uploadedFiles], fileRef);
          } else {
            setFiles((prevFiles) => [...prevFiles, attachment]);
          }
        })
        .catch((e) => {
          notify.error(
            t("notify.ErrorOccurredDuring", { task: `file upload | ${e}` })
          );
        });
    },
    [fileRef, fileService, onChange, uploadedFiles, t]
  );

  const onFileInputChange = React.useCallback(
    async (registerId: number) => {
      const fileInput = fileInputRef.current;
      if (!fileInput || !fileInput.files || fileInput.files.length <= 0) return;
      setIsLoading(true);
      const newFileList = fileInputRef.current!.files!;
      const newFiles = [...(Array(newFileList.length) as any).keys()]
        .map((index) => newFileList.item(index))
        .filter((file): file is File => file !== null)
        .slice(0, 5 - files.length);
      try {
        newFiles.forEach((file) => {
          uploadFile(registerId, file);
        });
      } finally {
        setIsLoading(false);
        fileInput.value = "";
      }
    },
    [files.length, uploadFile]
  );

  return (
    <div className={clsx("file-uploader-container", hasError && "error")}>
      {label && <label className="label">{label}</label>}
      <NxpSpin spinning={isLoading}>
        <div className="preview-list-wrapper">
          <div ref={previewList} className="preview-list">
            {uploadedFiles && uploadedFiles.length > -1
              ? uploadedFiles.map((it) => (
                  // controlled mode
                  <div key={it.assetId} className="preview-list-item">
                    {renderPreviewer(it, disabled)}
                    <div className="preview-list-item__label">
                      {it.fileName}
                    </div>
                  </div>
                ))
              : files.map((it) => (
                  <div key={it.assetId} className="preview-list-item">
                    {renderPreviewer(it, disabled)}
                    <div className="preview-list-item__label">
                      {it.fileName}
                    </div>
                  </div>
                ))}
            {(fileLimit === undefined ||
              (fileLimit &&
                files.length + (uploadedFiles?.length || 0) < fileLimit)) && (
              <div
                className="uploader"
                onClick={disabled ? undefined : startUploadFile}
              >
                <DownloadOutlined />
                <span>{t("app.common.Upload")}</span>
              </div>
            )}
          </div>
        </div>
        <input
          hidden={true}
          type="file"
          ref={fileInputRef}
          multiple={fileLimit && fileLimit > 1 ? true : false}
          onChange={() => onFileInputChange(registerId)}
          accept={props.accept}
        />
      </NxpSpin>
    </div>
  );
};

export const AppFileUpload: React.FC<AppFileUploadProps> = ({
  label,
  disabled,
  hasError,
  uploadedFiles,
  fileLimit,
  onChange,
  ...restProps
}) => {
  const handleOnFileDelete = React.useCallback(
    (file: UploadFile) => {
      onChange(uploadedFiles?.filter((item) => file.url !== item.url) || []);
    },
    [onChange, uploadedFiles]
  );

  const renderPreviewer = React.useCallback(
    (file: UploadFile, disabled?: boolean) => {
      switch (file.mimeType) {
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
        case "image/gif":
          return (
            <ImagePreviewer
              url={file.url}
              remove={disabled ? undefined : () => handleOnFileDelete(file)}
            />
          );
        default:
          return (
            <PlaceholderPreviewer
              url={file.url}
              remove={disabled ? undefined : () => handleOnFileDelete(file)}
            />
          );
      }
    },
    [handleOnFileDelete]
  );

  if (
    uploadedFiles &&
    uploadedFiles.length &&
    (fileLimit === undefined || fileLimit === 1)
  ) {
    return (
      <div className={clsx("file-uploader-container", hasError && "error")}>
        {label && <label className="label">{label}</label>}
        <div className="preview-list-wrapper">
          <div className="preview-list">
            {uploadedFiles.map((it) => (
              <div key={it.assetId} className="preview-list-item">
                {renderPreviewer(it, disabled)}
                <div className="preview-list-item__label">{it.fileName}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <AppFileUploadImpl
      disabled={disabled}
      label={label}
      hasError={hasError}
      onChange={onChange}
      fileLimit={fileLimit}
      uploadedFiles={uploadedFiles}
      {...restProps}
    />
  );
};
