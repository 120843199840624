import React from "react";
import { useParams } from "react-router-dom";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import { getRegister } from "../../../services/register";
import { getRegisterFormEntryList } from "../../../services/registerFormEntry";
import SetupGeneralForm from "./SetupGeneralForm";

interface SetupGeneralContainerProps {}

const SetupGeneralContainer: React.FC<SetupGeneralContainerProps> = () => {
  const registerId = Number(useParams<{ registerId: string }>().registerId);
  const { serviceConfig, activeEntity, activeRegister, registers } =
    useAppContext();

  const [getRegisterResult] = useAsync(
    () => getRegister(registerId, serviceConfig) // get from backend to ensure last sequence number is lastest
  );

  const [getRegisterHasRecordResult] = useAsync(async () => {
    // get from backend to ensure last sequence number is lastest
    const res = await getRegisterFormEntryList(
      serviceConfig,
      { limit: 1, offset: 0, sorters: [], filters: [] },
      [],
      []
    );
    return res.data.length > 0;
  });

  // to ensure form render only after relevant data is loaded in appContext
  if (
    getRegisterResult.data &&
    activeEntity !== undefined &&
    activeRegister !== undefined &&
    registers !== undefined
  ) {
    const initForm = {
      register_name: getRegisterResult.data.register_name,
      description: getRegisterResult.data.description,
      type: getRegisterResult.data.type,
      disabled: getRegisterResult.data.disabled,
      defaultRuleTemplateId: getRegisterResult.data.defaultRuleTemplateId,
      flag_workspace: getRegisterResult.data.flag_workspace,
      flag_consolidated: getRegisterResult.data.flag_seperate_queue,
      flag_seperate_queue: getRegisterResult.data.flag_seperate_queue,
      owner: getRegisterResult.data.owner.id,
      last_sequential_num: getRegisterResult.data.queues?.find(
        (q) => q.registerId === registerId && q.entityId === activeEntity.id
      )?.last_sequential_num,
    };

    return (
      <AppAccess rolesAllowed="adminOnly" showWarning>
        <SetupGeneralForm
          initForm={initForm}
          registerId={registerId}
          queue={getRegisterResult.data.queues?.find(
            (q) => q.registerId === registerId && q.entityId === activeEntity.id
          )}
          registerHasRecord={getRegisterHasRecordResult.data === true}
          isInheritedRegister={
            getRegisterResult.data.entityId !== activeEntity.id
          }
        />
      </AppAccess>
    );
  }
  return null;
};

export default SetupGeneralContainer;
