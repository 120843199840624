import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import {
  createRegisterFlow,
  RegisterFlow,
  RegisterFlowForm,
} from "../../../../services/registerFlow";
import notify from "../../../../utils/notify";

interface CreateFlowModalProps {
  modalVisible: boolean;
  onModalClose: () => void;
  onFlowCreated: (newFlow: RegisterFlow) => void;
}

const CreateFlowModal: React.FC<CreateFlowModalProps> = ({
  modalVisible,
  onFlowCreated,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    flowName: "",
  };

  const [editForm, setEditForm] = useState<RegisterFlowForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const { t } = useTranslation();

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const flow = await createRegisterFlow(editForm, appContext.serviceConfig);
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onFlowCreated(flow);
    } catch (ex) {
      appContext.errorHandler(ex, "create register flow");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    flowName: yup.string().nullable().required(t("app.common.NameRequired")),
  });

  const [validationError, , , saveWithValidate] = useValidate<RegisterFlow>(
    editForm,
    formSchema,
    handleSaveValidated
  );

  const handleFormGridStateChange = (
    fieldName: keyof RegisterFlow,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const formItems: NxpFormGridItemProps<RegisterFlowForm>[] = [
    {
      controlType: "input",
      label: t("CreateFlowModal.FlowName"),
      itemFieldName: "flowName",
      required: true,
      span: 24,
    },
  ];

  return (
    <NxpModal
      width="smaller"
      title={t("CreateFlowModal.CreateFlow")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default CreateFlowModal;
