import { apiRequest, ServiceConfig } from "../utils/backend";
import { UploadFile } from "./file";

export const getChats = async (
  registerId: number,
  serviceConfig: ServiceConfig,
  entryId: number,
  approvalId: number
) => {
  return await apiRequest<ChatMessage[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/form/entries/${entryId}/approvals/${approvalId}/chats`,
    serviceConfig
  );
};

export const getChatFiles = async (
  approvalId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<FileChatPostResponse[]>(
    `/powerflow/approvals/${approvalId}/chats`,
    serviceConfig
  );
};

export const postChat = async (
  registerId: number,
  serviceConfig: ServiceConfig,
  entryId: number,
  approvalId: number,
  form: ChatPost
) => {
  return await apiRequest<ChatMessage>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/form/entries/${entryId}/approvals/${approvalId}/chats`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(form),
    }
  );
};

export const postChatFile = async (
  approvalId: number,
  chatMsgId: number,
  serviceConfig: ServiceConfig,
  form: FileChatPost
) => {
  return await apiRequest<FileChatPostResponse>(
    `/powerflow/approvals/${approvalId}/chats/${chatMsgId}`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(form),
    }
  );
};

export interface ChatPost {
  author: string;
  message: string;
}

export interface FileChatPost {
  isPrintFormFile: boolean;
  file: UploadFile;
  fileType: string;
  description: string;
}

export interface ChatMessage {
  regId: number;
  entityId: number;
  approvalInstanceId: number;
  updatedOn: Date;
  id: number;
  entryId: number;
  createdOn: Date;
  author: UserData;
  message: string;
  attachedFile?: FileChatPostResponse;
}

export interface UserData {
  id: string;
  auth_user_id: string;
  created_at: Date;
  deleted_at: Date;
  email: string;
  emails: string[];
  firstName: string;
  keycloakUserId: string;
  lastName: string;
  locale: string;
  name: string;
  oauth_login: boolean;
  updatedAt: Date;
}

export interface FileChatPostResponse {
  chatId: number;
  approvalInstanceId: number;
  fileType: string;
  updatedOn: Date;
  // chatid: number;
  file: UploadFile;
  id: number;
  createdOn: Date;
  description: string;
  isPrintFormFile: boolean;
}
