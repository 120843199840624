import React, { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
  LazyLoadStatus,
  NxpAlert,
  NxpHeader,
  NxpTableLazy,
  NxpTableOnChange,
} from "@nexploretechnology/nxp-ui";

import AppFilter from "../../components/AppFilter";
import {
  FilterDataType,
  FilterStateItem,
} from "../../components/AppFilter/AppFilter";
import useAppContext from "../../hooks/useAppContext";
import {
  getRegisterFormEntryList,
  RegisterFormEntry,
} from "../../services/registerFormEntry";
import { getRegisterListingConfig } from "../../services/registerListing";
import { QueryParams } from "../../utils/backend/query";
import { LAZY_LOAD_PAGE_SIZE } from "../../utils/const";
import AddFormEntryModalButton from "./AddFormEntry/AddFormEntryButton";
import getAddFormEntryModalWidth from "./AddFormEntry/getAddFormEntryModalWidth";
import registerFormEntriesReducer, {
  RegisterFormEntriesState,
} from "./registerFormEntriesReducer";

interface RegisterFormEntriesProps {}

const RegisterFormEntries: React.FC<RegisterFormEntriesProps> = () => {
  const appContext = useAppContext();
  const routerHistory = useHistory();
  const routerLocation = useLocation<{ from: string }>();
  const { t } = useTranslation();

  const initialState: RegisterFormEntriesState = {
    lazyloadStatus: LazyLoadStatus.ready,
    initializing: true,
    refetchMode: undefined,
    data: [],
    tableColumns: [],
    query: {
      limit: LAZY_LOAD_PAGE_SIZE,
      offset: 0,
      sorters: [],
      filters: [],
    },
    formConfigSpec: [],
    listConfig: undefined,
    translation: t,
  };

  const [registerFormEntriesState, dispatch] = useReducer(
    registerFormEntriesReducer,
    initialState
  );

  // const registerId = appContext.activeRegister?.registerId || 0;

  const fetchData = useCallback(
    (query: QueryParams) => {
      dispatch({ type: "dataLoading" });
      getRegisterFormEntryList(
        appContext.serviceConfig,
        query,
        registerFormEntriesState.formConfigSpec,
        appContext.registerDictionaries || []
      )
        .then((results) =>
          dispatch({
            type: "dataLoaded",
            data: results.data,
            formConfig: results.form_config,
            pageSize: query.limit,
          })
        )
        .catch((ex) => {
          dispatch({
            type: "dataError",
          });
          appContext.errorHandler(ex, "fetch form entries");
        });
    },
    [appContext, registerFormEntriesState.formConfigSpec]
  );

  const fetchListConfig = useCallback(() => {
    getRegisterListingConfig(
      appContext.activeRegister!.id,
      appContext.serviceConfig
    )
      .then((data) =>
        dispatch({
          type: "listConfigLoaded",
          listConfig: appContext.registerAccessList["register-entry:special"]
            ? // disable confidential for users with register-entry:special
              {
                ...data,
                layout: data.layout?.map((configItem) => ({
                  ...configItem,
                  confidential: {
                    ...configItem.confidential,
                    disabled: true,
                  },
                })),
              }
            : data,
        })
      )
      .catch((ex) => {
        dispatch({
          type: "dataError",
        });
        appContext.errorHandler(ex, "fetch listing config");
      });
  }, [appContext]);

  // fetch on start
  useEffect(() => {
    if (!registerFormEntriesState.listConfig) {
      fetchListConfig();
    } else if (
      appContext.activeRegister?.id &&
      registerFormEntriesState.data.length === 0 &&
      registerFormEntriesState.refetchMode === undefined &&
      registerFormEntriesState.lazyloadStatus === LazyLoadStatus.ready
    ) {
      fetchData({
        ...registerFormEntriesState.query,
        sorters: [
          {
            column: "seqNumber",
            order: "descend",
          },
        ],
      });
    }
  }, [
    appContext.activeRegister,
    fetchData,
    fetchListConfig,
    registerFormEntriesState,
  ]);

  // refetch on lazyload, sorting and filtering
  useEffect(() => {
    if (
      registerFormEntriesState.listConfig !== undefined &&
      !registerFormEntriesState.initializing &&
      registerFormEntriesState.lazyloadStatus !== LazyLoadStatus.loading &&
      registerFormEntriesState.refetchMode !== undefined
    ) {
      fetchData(registerFormEntriesState.query);
    }
  }, [
    fetchData,
    registerFormEntriesState.initializing,
    registerFormEntriesState.lazyloadStatus,
    registerFormEntriesState.listConfig,
    registerFormEntriesState.query,
    registerFormEntriesState.refetchMode,
  ]);

  const handleLazyLoad = useCallback(() => {
    dispatch({ type: "lazyload" });
  }, []);

  const handleFormEntryAdded = useCallback(() => {
    dispatch({ type: "dataReload" });
  }, []);

  const renameContentDetailsParam = (fieldString: string) =>
    fieldString.replace(/^contentDetails,/, "contentDetails:/");

  const handleTableChange = useCallback<NxpTableOnChange<RegisterFormEntry>>(
    (_, __, tableSorter) => {
      const sorters: QueryParams["sorters"] = [];
      if (
        Array.isArray(tableSorter) &&
        tableSorter[0].field &&
        tableSorter[0].order
      ) {
        sorters.push({
          column: renameContentDetailsParam(tableSorter[0].field.toString()),
          order: tableSorter[0].order,
        });
      } else if (
        tableSorter &&
        !Array.isArray(tableSorter) &&
        tableSorter.field &&
        tableSorter.order
      ) {
        sorters.push({
          column: renameContentDetailsParam(tableSorter.field.toString()),
          order: tableSorter.order,
        });
      }

      dispatch({ type: "dataSorterChange", sorters });
    },
    []
  );

  const hasDefaultAndCancelStatus = !!(
    appContext.activeRegister?.statuses?.find(
      (status) => status.cancelStatus
    ) &&
    appContext.activeRegister?.statuses?.find((status) => status.defaultStatus)
  );

  const handleFilterChange = useCallback((filterState: FilterStateItem[]) => {
    dispatch({
      type: "dataFilterChange",
      // filters: filterState.map((filterStateItem) => ({
      //   column: filterStateItem.filterColumn.fieldName,
      //   comparer: Object.entries(FilterComparer).find(
      //     (entry) => entry[1] === filterStateItem.filterComparer
      //   )?.[0] as QueryParamsFilterComparer,
      //   value: filterStateItem.filterValue,
      // })),
      filters: filterState,
    });
  }, []);

  const handleTableOnRow = useCallback(
    (record: RegisterFormEntry) => ({
      onClick: () => {
        routerHistory.push({
          pathname: `./form-entries/${record.id.toString()}`,
          state: { from: routerLocation.pathname },
        });
      },
    }),
    [routerHistory, routerLocation.pathname]
  );

  const getFilterColumns = () => {
    return registerFormEntriesState.tableColumns
      .filter((col) => col.filtered !== false)
      .map((col) => {
        const field = Array.isArray(col.dataIndex)
          ? col.dataIndex.join(".")
          : col.dataIndex!.toString();
        return {
          fieldName: field,
          displayName: col.title!.toString(),
          filterDataType:
            field === "updatedOn"
              ? FilterDataType.Date
              : field === "id" || field === "seqNumber"
              ? FilterDataType.Number
              : FilterDataType.String,
          selectOptions: [],
        };
      });
  };

  return (
    <>
      <NxpHeader
        titleContent={t("app.common.RegisterListing")}
        actionContent={
          <>
            <AppFilter
              filterColumns={getFilterColumns()}
              filterState={registerFormEntriesState.query.filters}
              onFilterChange={handleFilterChange}
            />
            {appContext.registerAccessList["register-entry:create"] &&
            appContext.hasAccess("writable") ? (
              <AddFormEntryModalButton
                modalWidth={getAddFormEntryModalWidth(
                  registerFormEntriesState.formConfigSpec
                )}
                disabled={
                  !hasDefaultAndCancelStatus ||
                  registerFormEntriesState.tableColumns.length === 0
                }
                onFormEntryAdded={handleFormEntryAdded}
              />
            ) : undefined}
          </>
        }
        onBackClick={() => {
          if (routerLocation.state?.from) {
            routerHistory.goBack();
          } else {
            routerHistory.push("../../registers");
          }
        }}
      />
      {!registerFormEntriesState.initializing &&
      registerFormEntriesState.tableColumns.length === 0 ? (
        <NxpAlert
          type="warning"
          message={t(
            "RegisterFormEntries.TheFormOfThisRegisterHasNotBeenDefinedYet"
          )}
        />
      ) : !registerFormEntriesState.initializing &&
        !hasDefaultAndCancelStatus ? (
        <NxpAlert
          type="warning"
          message={t(
            "RegisterFormEntries.PleaseSetRegisterDefaultAndCancelStatusBeforeAddFormEntry"
          )}
        />
      ) : null}
      <NxpTableLazy
        rowKey="id"
        lazyLoadStatus={
          !registerFormEntriesState.listConfig
            ? LazyLoadStatus.loading
            : registerFormEntriesState.lazyloadStatus
        }
        onLazyLoad={handleLazyLoad}
        onChange={handleTableChange}
        columns={registerFormEntriesState.tableColumns}
        dataSource={registerFormEntriesState.data}
        useDefaultHeight
        pagination={false}
        onRow={handleTableOnRow}
      />
    </>
  );
};

export default RegisterFormEntries;
