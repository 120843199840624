import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpButtonWithConfirm,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
  NxpPanel,
} from "@nexploretechnology/nxp-ui";

import { RegisterFormEntry } from "../../../services/registerFormEntry";
import { ApprovalUpdateModalForm } from "./ApprovalUpdateModalContainer";

const useStyles = createUseStyles((theme) => ({
  approvalEditFields: {
    marginBottom: theme.spacing(2),
    "& div.ant-collapse-content-box": {
      overflow: "auto",
    },
  },
}));

interface ApprovalUpdateModalLayoutProps {
  modalVisible: boolean;
  saveInProgress: boolean;
  editFields: string[];
  formEntry: RegisterFormEntry | undefined;
  formNodeRef: React.MutableRefObject<React.ReactNode>;
  formItems: NxpFormGridItemProps<ApprovalUpdateModalForm>[];
  editForm: ApprovalUpdateModalForm;
  onFormGridStateChange: (
    fieldName: keyof ApprovalUpdateModalForm,
    value: unknown
  ) => void;
  onSave: (isReject: boolean) => void;
  onModalClose: () => void;
}

const ApprovalUpdateModalLayout: React.FC<ApprovalUpdateModalLayoutProps> = ({
  modalVisible,
  saveInProgress,
  editFields,
  formEntry,
  formNodeRef,
  formItems,
  editForm,
  onFormGridStateChange,
  onSave,
  onModalClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <NxpModal
      title={t("ApprovalUpdateModalLayout.UpdateApprovalRequest")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton onClick={onModalClose} type="text">
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButtonWithConfirm
            confirmLoading={saveInProgress}
            danger
            onConfirm={() => onSave(true)}
            modalContent={
              <>
                {`${t("ApprovalUpdateModalLayout.ProceedTo")} `}
                <span className="ant-typography ant-typography-danger">
                  {t("app.common.reject")}
                </span>
                {` ${t("ApprovalUpdateModalLayout.thisRequest")}`}
              </>
            }
          >
            {t("app.common.Reject")}
          </NxpButtonWithConfirm>
          <NxpButtonWithConfirm
            confirmLoading={saveInProgress}
            onConfirm={() => onSave(false)}
            modalContent={t(
              "ApprovalUpdateModalLayout.ProceedToApproveThisRequest"
            )}
          >
            {t("ApprovalUpdateModalLayout.Approve")}
          </NxpButtonWithConfirm>
        </>
      }
    >
      {editFields.length > 0 && (
        <NxpPanel
          className={classes.approvalEditFields}
          disableCollapse
          titleContent={`${t("app.common.Form")}: ${
            formEntry?.formNumber || ""
          }`}
        >
          {formNodeRef.current}
        </NxpPanel>
      )}
      <NxpFormGrid
        formItems={formItems}
        formState={editForm}
        onFormStateChange={onFormGridStateChange}
      />
    </NxpModal>
  );
};

export default ApprovalUpdateModalLayout;
