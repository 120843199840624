import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import {
  createRegisterDictionary,
  RegisterDictionary,
  RegisterDictionaryForm,
} from "../../../../services/registerDictionary";
import notify from "../../../../utils/notify";

interface AddDictionaryModalProps {
  registerDictionaries: RegisterDictionary[];
  modalVisible: boolean;
  onModalClose: () => void;
  onDictionaryCreated: (newDictionary: RegisterDictionary) => void;
}

const AddDictionaryModal: React.FC<AddDictionaryModalProps> = ({
  registerDictionaries,
  modalVisible,
  onDictionaryCreated,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    dict_name: "",
    dict_pair: {},
  };

  const [editForm, setEditForm] =
    useState<RegisterDictionaryForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const dictionary = await createRegisterDictionary(
        editForm,
        appContext.serviceConfig
      );
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onDictionaryCreated(dictionary);
    } catch (ex) {
      appContext.errorHandler(ex, "create register dictionary");
    } finally {
      setSaveInProgress(false);
    }
  };

  const { t } = useTranslation();

  const formSchema = yup.object({
    dict_name: yup
      .string()
      .nullable()
      .required(t("app.common.NameRequired"))
      .test(
        "duplicate",
        t("AddDictionaryModal.DictionaryAlreadyExisted"),
        function (val) {
          return !registerDictionaries.find(
            (dict) => dict.dict_name.trim() === val.trim()
          );
        }
      ),
    dict_pair: yup.object(),
  });

  const [validationError, , , saveWithValidate] =
    useValidate<RegisterDictionary>(editForm, formSchema, handleSaveValidated);

  const handleFormGridStateChange = (
    fieldName: keyof RegisterDictionary,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const formItems: NxpFormGridItemProps<RegisterDictionaryForm>[] = [
    {
      controlType: "input",
      label: t("AddDictionaryModal.DictionaryName"),
      itemFieldName: "dict_name",
      required: true,
      span: 24,
    },
  ];

  return (
    <NxpModal
      width="smaller"
      title={t("AddDictionaryModal.CreateDictionary")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDictionaryModal;
