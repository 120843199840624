import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import {
  RegisterDictionary,
  RegisterDictionaryItemForm,
  updateRegisterDictionary,
} from "../../../../services/registerDictionary";
import notify from "../../../../utils/notify";

interface AddDictionaryItemModalProps {
  dictionary: RegisterDictionary;
  modalVisible: boolean;
  onModalClose: () => void;
  onDictionaryItemAdded: (dictionary: RegisterDictionary) => void;
}

const AddDictionaryItemModal: React.FC<AddDictionaryItemModalProps> = ({
  dictionary,
  modalVisible,
  onDictionaryItemAdded,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    dictKey: "",
    dictLabel: "",
  };

  const [editForm, setEditForm] =
    useState<RegisterDictionaryItemForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const { t } = useTranslation();

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const updateDict = await updateRegisterDictionary(
        dictionary.id,
        {
          dict_name: dictionary.dict_name,
          dict_pair: {
            ...dictionary.dict_pair,
            [editForm.dictKey]: editForm.dictLabel,
          },
        },
        appContext.serviceConfig
      );
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onDictionaryItemAdded(updateDict);
      onModalClose();
    } catch (ex) {
      appContext.errorHandler(ex, "create flow step");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    dictKey: yup
      .string()
      .nullable()
      .required(t("app.common.ValueRequired"))
      .test(
        "duplicate",
        t("app.common.DictionaryValueAlreadyExisted"),
        function (val) {
          return !Object.keys(dictionary.dict_pair).find(
            (key) => key.trim() === val.trim()
          );
        }
      ),
    dictLabel: yup
      .string()
      .nullable()
      .required(t("app.common.TextRequired"))
      .test(
        "duplicate",
        t("app.common.DictionaryTextAlreadyExisted"),
        function (val) {
          return !Object.keys(dictionary.dict_pair).find(
            (key) => dictionary.dict_pair[key] === val.trim()
          );
        }
      ),
  });

  const [validationError, , , saveWithValidate] =
    useValidate<RegisterDictionaryItemForm>(
      editForm,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof RegisterDictionaryItemForm,
    value: unknown
  ) => {
    // console.log("ccccccccccccccc", fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const formItems: NxpFormGridItemProps<RegisterDictionaryItemForm>[] = [
    {
      controlType: "input",
      label: t("app.common.Value"),
      itemFieldName: "dictKey",
      required: true,
      span: 12,
    },
    {
      controlType: "input",
      label: t("app.common.Text"),
      itemFieldName: "dictLabel",
      required: true,
      span: 12,
    },
  ];

  return (
    <NxpModal
      title={t("AddDictionaryItemModal.AddDictionaryItem")}
      width="small"
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddDictionaryItemModal;
