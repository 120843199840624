import React from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";
import clsx from "clsx";

import {
  RuleCondition,
  RuleConditionGroup,
} from "../../../../services/registerRule";

const useStyles = createUseStyles((theme) => ({
  addConditionButton: {
    marginTop: theme.spacing(1),
    width: 160,
    backgroundColor: theme.palette.backgroundDark,
    color: theme.palette.primary,
    border: `1px solid ${theme.palette.backgroundDark}`,
    "&.ant-btn": {
      marginLeft: 0,
    },
    "&:hover, &:focus": {
      backgroundColor: theme.palette.backgroundDark,
      borderColor: theme.palette.primary,
      color: theme.palette.primary,
    },
    "&::before": {
      content: "'\\A'",
      whiteSpace: "pre",
    },
  },
  dropContainer: {
    "&.isOverCurrent": {
      backgroundColor: theme.palette.backgroundDark,
    },
  },
}));

interface AddConditionButtonProps {
  ruleGroup?: RuleConditionGroup;
  onClick: () => void;
  onRuleDrop: (
    draggingRule: RuleConditionGroup | RuleCondition,
    draggingRuleParent: RuleConditionGroup,
    dropToRule: RuleConditionGroup | RuleCondition | undefined,
    dropToRuleParent: RuleConditionGroup
  ) => void;
}

const AddConditionButton: React.FC<AddConditionButtonProps> = ({
  onClick,
  ruleGroup,
  onRuleDrop,
}) => {
  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: "rule",
      drop(
        item: {
          rule: RuleCondition | RuleConditionGroup;
          parentGroup: RuleConditionGroup;
        },
        monitor
      ) {
        if (monitor.didDrop() || !ruleGroup) {
          return;
        }
        if (
          item.parentGroup.children.indexOf(item.rule) !==
            item.parentGroup.children.length - 1 ||
          item.parentGroup.key !== ruleGroup.key
        ) {
          onRuleDrop(item.rule, item.parentGroup, undefined, ruleGroup);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [ruleGroup]
  );

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div
      ref={drop}
      className={clsx(classes.dropContainer, isOverCurrent && "isOverCurrent")}
    >
      <NxpButton onClick={onClick} className={classes.addConditionButton}>
        {t("AddConditionButton.AddCondition")}
      </NxpButton>
    </div>
  );
};

export default AddConditionButton;
