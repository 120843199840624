import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { RegisterFlow } from "../../../../services/registerFlow";
import CreateFlowModal from "./CreateFlowModal";

const useStyles = createUseStyles((theme) => ({
  addButton: {
    marginBottom: theme.spacing(1),
  },
}));

interface CreateFlowModalButtonProps {
  onFlowCreated: (newFlow: RegisterFlow) => void;
}

const CreateFlowModalButton: React.FC<CreateFlowModalButtonProps> = ({
  onFlowCreated,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleFlowCreated = (newFlow: RegisterFlow) => {
    onFlowCreated(newFlow);
    setModalVisible(false);
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <NxpButton className={classes.addButton} onClick={handleClick}>
        {t("CreateFlowModalButton.AddFlow")}
      </NxpButton>
      <CreateFlowModal
        modalVisible={modalVisible}
        onModalClose={handleModalClose}
        onFlowCreated={handleFlowCreated}
      />
    </>
  );
};

export default CreateFlowModalButton;
