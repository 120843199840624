import React, { ReactNode, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { IdcardFilled, MailFilled } from "@ant-design/icons";
import {
  NxpBackTop,
  NxpLayout,
  NxpNavbarConfigMenu,
  NxpSidebarProps,
} from "@nexploretechnology/nxp-ui";
import { useKeycloak } from "@react-keycloak/web";
import i18n from "i18next";

import useAppContext from "../../../hooks/useAppContext";
import approvalIcon from "../../../images/sidebar/sidebar-approval.svg";
import dashboardIcon from "../../../images/sidebar/sidebar-dashboard.svg";
import registerIcon from "../../../images/sidebar/sidebar-register.svg";
import reportIcon from "../../../images/sidebar/sidebar-report.svg";
import securityIcon from "../../../images/sidebar/sidebar-security.svg";
import settingsIcon from "../../../images/sidebar/sidebar-setup.svg";
import { RegisterAccessList } from "../../../services/access";
import { patchMyUserPreference } from "../../../services/app";
import { getDockerHostRoot } from "../AppRouter/AppRouter";

const useStyles = createUseStyles((theme) => ({
  "@global": {
    // fix outline setting from bootstrap.min.css
    "button:focus": {
      outline: `1px solid ${theme.palette.borderLight}`,
      outlineOffset: -1,
    },
    //fix to override svg verticalAlign of bootstrap.min.css
    ".anticon > svg": {
      verticalAlign: "unset",
    },

    //formio stuffs below
    ".formio-error-wrapper .invalid-feedback": {
      display: "block",
    },
    ".formio-component-sectionHeader label": {
      display: "none",
    },
    ".formio-component-panel .card-header.bg-default": {
      backgroundColor: theme.palette.backgroundDark,
    },
    ".signature-pad-body .signature-pad-refresh i.fa-refresh": {
      display: "none",
    },
    ".signature-pad-body .signature-pad-refresh::after": {
      content: "'clear'",
      margin: 3,
      lineHeight: 1,
      color: theme.palette.primary,
    },
  },
  appLayout: {
    //fix to override svg verticalAlign of bootstrap.min.css
    "& svg": {
      verticalAlign: "unset",
    },
  },
  approval: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${approvalIcon})`,
      transform: "scale(1.25) translateX(0)",
    },
  },
  registerListing: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${registerIcon})`,
      transform: "scale(1.15) translateX(2px)",
    },
  },
  report: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${reportIcon})`,
      transform: "scale(1.15)",
    },
  },
  dashboard: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${dashboardIcon})`,
      transform: "scale(1.1)",
    },
  },
  security: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${securityIcon})`,
    },
  },
  settings: {
    "& i.nxpSidebarIcon": {
      backgroundImage: `url(${settingsIcon})`,
      transform: "scale(1.2)",
    },
  },
  userInfo: {
    "& > span.anticon": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface AppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const {
    activeRegister,
    activeEntity,
    activeUser,
    registers,
    language,
    serviceConfig,
    onAppContextCacheItemUpdate,
    hasAccess,
  } = useAppContext();
  const { keycloak } = useKeycloak();

  const { t } = useTranslation();

  const allowRegisterSetup =
    (activeRegister?.owner.id === activeUser?.id || hasAccess("adminOnly")) &&
    (registers?.find((reg) => reg.register.id === activeRegister?.id)
      ?.permissions || ({} as RegisterAccessList))["register:admin"];

  const getSetupLinks = () => {
    const links =
      activeRegister && allowRegisterSetup
        ? [
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup`,
              title: t("AppLayout.General"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/form`,
              title: t("app.common.Form"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/print-forms`,
              title: t("AppLayout.PrintForms"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/status`,
              title: t("app.common.Status"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/flows`,
              title: t("AppLayout.Flows"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/rule-templates`,
              title: t("AppLayout.RuleTemplates"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/documents`,
              title: t("AppLayout.Documents"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/role-assignments`,
              title: t("AppLayout.RoleAssignments"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/dictionaries`,
              title: t("AppLayout.Dictionaries"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/listing`,
              title: t("app.common.RegisterListing"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/delegation`,
              title: t("AppLayout.Delegation"),
            },
            {
              href: `${getDockerHostRoot()}/entities/${
                activeEntity?.id
              }/registers/${activeRegister.id}/setup/security`,
              title: t("AppLayout.Security"),
            },
          ]
        : [];

    const isInheritedRegister =
      activeRegister?.entityId !== activeEntity?.id.toString();

    return isInheritedRegister
      ? links.filter(
          (link) =>
            link.title === t("AppLayout.General") ||
            link.title === t("AppLayout.RoleAssignments") ||
            link.title === t("AppLayout.Security")
        )
      : links;
  };

  const classes = useStyles();

  const changeLanguage = useCallback(
    async (key: string) => {
      const newUserPreference = await patchMyUserPreference(serviceConfig, {
        application: "entity",
        parameter: "language",
        value: key,
      });
      onAppContextCacheItemUpdate("language", key);
      return newUserPreference;
    },
    [serviceConfig, onAppContextCacheItemUpdate]
  );
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <NxpLayout
      className={classes.appLayout}
      navBarProps={{
        title: `Powerflow${
          activeRegister?.register_name
            ? ` - ${activeRegister?.register_name}`
            : ""
        }`,
        subtitleContent: activeEntity?.name || "",
        username: activeUser?.name || "--",
        userPopoverContent: (
          <>
            <p className={classes.userInfo}>
              <IdcardFilled /> {activeUser?.name} (#{activeUser?.id})
            </p>
            <p className={classes.userInfo}>
              <MailFilled /> {activeUser?.primaryEmail}
            </p>
          </>
        ),
        settingsPopoverContent: (
          <NxpNavbarConfigMenu
            activeLanguageKey={i18n.language}
            onLanguageChange={changeLanguage}
            onLogoutClick={() => keycloak.logout()}
            languageSettings={[
              {
                name: "English",
                key: "en",
              },
              {
                name: "中文",
                key: "zh-HK",
              },
              {
                name: "Deutsch",
                key: "de",
              },
              {
                name: "Español",
                key: "es",
              },
            ]}
          />
        ),
      }}
      sidebarProps={{
        items: activeRegister
          ? // routes with register selection
            ([
              {
                type: "link",
                title: t("app.common.RegisterListing"),
                href: `${getDockerHostRoot()}/entities/${
                  activeEntity?.id
                }/registers/${activeRegister.id}/form-entries`,
                className: classes.registerListing,
              },
              {
                type: "submenu",
                title: t("AppLayout.RegisterApprovals"),
                placement: "right",
                menuLinks: [
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/registers/${activeRegister.id}/my-approvals`,
                    title: t("AppLayout.MyApprovals"),
                  },
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/registers/${activeRegister.id}/my-requests`,
                    title: t("app.common.MyRequests"),
                  },
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/registers/${activeRegister.id}/approvals`,
                    title: t("AppLayout.RegisterApprovals"),
                  },
                ],
                className: classes.approval,
              },
              // report and dashboard css defined above: classes.report, classes.dashboard,
              ...(allowRegisterSetup
                ? [
                    {
                      type: "submenu",
                      title: t("AppLayout.Setup"),
                      placement: "right",
                      menuLinks: getSetupLinks(),
                      className: classes.settings,
                    },
                  ]
                : []),
            ] as NxpSidebarProps["items"])
          : // routes without register selection
            [
              {
                type: "link",
                title: t("AppLayout.MyRegisters"),
                href: `${getDockerHostRoot()}/entities/${
                  activeEntity?.id
                }/registers`,
                className: classes.registerListing,
              },
              {
                type: "submenu",
                title: t("AppLayout.RegisterApprovals"),
                placement: "right",
                menuLinks: [
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/my-approvals`,
                    title: t("AppLayout.MyApprovals"),
                  },
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/my-requests`,
                    title: t("app.common.MyRequests"),
                  },
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/approvals`,
                    title: t("AppLayout.AllApprovals"),
                  },
                ],
                className: classes.approval,
              },
              {
                type: "submenu",
                title: t("AppLayout.Settings"),
                menuLinks: [
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/settings/role-assignments`,
                    title: t("AppLayout.RoleAssignments"),
                  },
                  {
                    href: `${getDockerHostRoot()}/entities/${
                      activeEntity?.id
                    }/settings/delegation`,
                    title: t("AppLayout.Delegation"),
                  },
                ],
                className: classes.settings,
              },
            ],
      }}
    >
      {children}
      <NxpBackTop />
    </NxpLayout>
  );
};

export default AppLayout;
