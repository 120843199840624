import React, { ReactNode, useRef } from "react";
import { Form } from "react-formio";
import { useTranslation } from "react-i18next";

import { NxpModal } from "@nexploretechnology/nxp-ui";

import { RegisterCustomFormField } from "../../../services/registerCustomForm";
import { FORMIO_DEFAULT_OPTIONS } from "../../../utils/const";

interface SetupFormPreviewProps {
  modalVisible: boolean;
  onModalClose: () => void;
  formDefinitions: RegisterCustomFormField[];
}

const SetupFormPreview: React.FC<SetupFormPreviewProps> = ({
  modalVisible,
  onModalClose,
  formDefinitions,
}) => {
  const formNode = useRef<ReactNode>(null);
  if (formNode.current === null && formDefinitions.length) {
    formNode.current = (
      <Form
        form={{
          components: formDefinitions,
        }}
        submission={{
          data: {},
        }}
        options={FORMIO_DEFAULT_OPTIONS}
      />
    );
  }

  const { t } = useTranslation();

  return (
    <NxpModal
      showMandatoryLabel
      title={t("SetupForm.FormPreview")}
      visible={modalVisible}
      onCancel={onModalClose}
    >
      {formNode.current}
    </NxpModal>
  );
};

export default SetupFormPreview;
