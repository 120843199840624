import { FilterStateItem } from "../../components/AppFilter/AppFilter";

export type QueryParamsFilterComparer =
  | "Equal"
  | "NotEqual"
  | "Contains"
  | "IsEmpty"
  | "GreaterThanOrEqual"
  | "LessThanOrEqual"
  | "JsonEqual"
  | "JsonNotEqual"
  | "JsonContains"
  | "JsonIsEmpty"
  | "JsonGreaterThanOrEqual"
  | "JsonLessThanOrEqual";

export interface QueryParams {
  limit: number;
  offset: number;
  sorters: {
    column: string;
    order: "ascend" | "descend";
  }[];
  filters: FilterStateItem[];
}

export function buildQueryString(
  params: {
    [key: string]: string | number | object;
  },
  startWith: string = "?"
): string {
  let query = "";

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      const convertValue = encodeURIComponent(
        typeof value === "object" ? JSON.stringify(value) : value
      );
      const paramsString = `${key}=${convertValue}`;
      query += `${query !== "" ? "&" : ""}${paramsString}`;
    }
  });

  return query && startWith + query; // return empty string if query is empty
}
