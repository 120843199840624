// import { getDockerHostRoot } from '../components/App/AppRouter/AppRouter';
import { apiRequest, ServiceConfig } from "../utils/backend";
import { RegisterAccessList } from "./access";
import { Entity, User } from "./app";
import { RegisterStatus } from "./registerStatus";

// export enum RegisterType {
//   LocalOnly = 1,
//   ForAllProjectsOfThisCountry = 2,
//   ForAllCountries = 3,
//   ForAllProjects = 4,
//   ForAllCountriesAndProjects = 5,
// }

export interface Register {
  id: number;
  register_name: string;
  description?: string;
  entity?: Entity;
  entityId: string;
  owner: User;
  type?: string;
  disabled?: boolean;
  last_sequential_num?: number;
  defaultRuleTemplateId?: string;

  flag_workspace?: boolean;
  flag_consolidated?: boolean;
  flag_seperate_queue?: boolean;

  default_doa_type?: string;
  currentStatus?: string;
  statusId?: number;

  statuses?: RegisterStatus[];
  queues: RegisterQueue[];
}

export interface RegisterListItem {
  register: Pick<
    Register,
    "id" | "register_name" | "description" | "owner" | "entity"
  >;
  permissions: RegisterAccessList;
}

export interface RegisterQueue {
  id: number;
  entityId: string;
  registerId: number;
  last_sequential_num: number;
}

const formatRegisterOwner = <T extends { owner: User }>(register: T) => {
  return {
    ...register,
    owner: {
      ...register.owner,
      name: `${register.owner?.firstName} ${register.owner?.lastName}`,
    },
  };
};

export const getRegisterList = async (serviceConfig: ServiceConfig) => {
  return (
    await apiRequest<RegisterListItem[]>(
      `/powerflow/entities/${serviceConfig.entityId}/register_home`,
      serviceConfig
    )
  ).map((registerItem) => ({
    ...registerItem,
    register: formatRegisterOwner(registerItem.register),
  }));
};
export const getRegister = async (id: number, serviceConfig: ServiceConfig) => {
  const register = await apiRequest<Register>(
    `/powerflow/entities/${serviceConfig.entityId}/register_items/${id}`,
    serviceConfig
  );

  if (register) {
    return formatRegisterOwner(register);
  }
};

export interface RegisterSetupForm
  extends Pick<
    Register,
    | "register_name"
    | "description"
    | "type"
    | "disabled"
    | "last_sequential_num"
    | "defaultRuleTemplateId"
    | "flag_workspace"
    | "flag_consolidated"
    | "flag_seperate_queue"
  > {
  owner?: string;
}

export const createRegister = async (
  registerCreateForm: RegisterSetupForm,
  serviceConfig: ServiceConfig
) => {
  return formatRegisterOwner(
    await apiRequest<Register>(
      `/powerflow/entities/${serviceConfig.entityId}/create_register`,
      serviceConfig,
      {
        method: "POST",
        body: JSON.stringify(registerCreateForm),
      }
    )
  );
};

export const updateRegister = async (
  registerId: number,
  register: RegisterSetupForm,
  serviceConfig: ServiceConfig
) => {
  return formatRegisterOwner(
    await apiRequest<Register>(
      `/powerflow/entities/${serviceConfig.entityId}/register_items/${registerId}`,
      serviceConfig,
      {
        method: "PUT",
        body: JSON.stringify(register),
      }
    )
  );
};

export const updateRegisterQueue = async (
  registerId: number,
  queueId: number,
  last_sequential_num: number,
  serviceConfig: ServiceConfig
) => {
  await apiRequest<Register>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/queue/${queueId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        entityId: serviceConfig.entityId,
        registerId,
        last_sequential_num,
      }),
    }
  );
};
