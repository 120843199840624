import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { NavLink, useHistory, useParams } from "react-router-dom";

import { NxpHeader, NxpMainSplitter } from "@nexploretechnology/nxp-ui";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import {
  getRegisterDictionaryList,
  RegisterDictionary,
} from "../../../services/registerDictionary";
import AddDictionaryButton from "./AddDictionary/AddDictionaryButton";
import DictionaryDetails from "./DictionaryDetails";

const useStyles = createUseStyles((theme) => ({
  setupRuleLayout: {},
  leftSplit: {
    padding: theme.spacing(4),
    "& > ul": {
      listStyle: "none",
      padding: 0,
      margin: theme.spacing(1, 0),
    },
  },
  dictionaryList: {
    "& a": {
      color: theme.palette.text,
      "&.active": {
        color: theme.palette.primary,
        fontWeight: theme.fontWeight.bold,
      },
    },
  },
  dictionaryDetails: {
    margin: theme.spacing(3, 0, 0, 5),
  },
}));

interface SetupDictionaryProps {}

const SetupDictionary: React.FC<SetupDictionaryProps> = () => {
  const appContext = useAppContext();

  const history = useHistory();

  const { dictId: dictIdParam } = useParams<{ dictId: string }>();
  const activeDictId = dictIdParam ? Number(dictIdParam) : undefined;

  const [registerDictionaries, setRegisterDictionaries] = useState<
    RegisterDictionary[]
  >([]);

  useEffect(() => {
    getRegisterDictionaryList(appContext.serviceConfig).then((data) =>
      setRegisterDictionaries(data)
    );
  }, [appContext.serviceConfig]);

  const handleDictionaryCreated = (newDict: RegisterDictionary) => {
    setRegisterDictionaries((prevState) => [newDict, ...prevState]);
    history.push(`${activeDictId ? "" : "dictionaries/"}${newDict.id}`);
  };

  const handleDictionaryUpdated = (updateDict: RegisterDictionary) => {
    setRegisterDictionaries((prevState) =>
      prevState.map((item) => (item.id === updateDict.id ? updateDict : item))
    );
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.setupRuleLayout}>
      <AppAccess rolesAllowed="adminOnly" showWarning>
        <NxpMainSplitter>
          <div className={classes.leftSplit}>
            <AddDictionaryButton
              registerDictionaries={registerDictionaries}
              onDictionaryCreated={handleDictionaryCreated}
            />
            <ul className={classes.dictionaryList}>
              {registerDictionaries.map((registerDictionary) => (
                <li key={registerDictionary.id}>
                  <NavLink
                    to={
                      activeDictId
                        ? `./${registerDictionary.id}`
                        : `./dictionaries/${registerDictionary.id}`
                    }
                  >
                    {registerDictionary.dict_name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          {activeDictId &&
          registerDictionaries.some((item) => item.id === activeDictId) &&
          appContext.activeRegister ? (
            <DictionaryDetails
              onDictionaryUpdated={handleDictionaryUpdated}
              className={classes.dictionaryDetails}
              dictionary={
                registerDictionaries.find((item) => item.id === activeDictId)!
              }
            />
          ) : (
            <div className={classes.dictionaryDetails}>
              <NxpHeader titleContent={t("app.common.SetupDictionary")} />
            </div>
          )}
        </NxpMainSplitter>
      </AppAccess>
    </div>
  );
};

export default SetupDictionary;
