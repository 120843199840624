import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LazyLoadStatus, NxpTableOnChange } from "@nexploretechnology/nxp-ui";

import {
  FilterComparer,
  FilterDataType,
} from "../../components/AppFilter/AppFilter";
import useAppContext from "../../hooks/useAppContext";
import {
  ApprovalListType,
  FormEntryApproval,
  getApprovalList,
} from "../../services/registerFormEntryApproval";
import { QueryParams } from "../../utils/backend/query";
import { LAZY_LOAD_PAGE_SIZE } from "../../utils/const";
import ApprovalsLayout from "./ApprovalsLayout";

const initState = {
  initializing: true,
  lazyloadStatus: LazyLoadStatus.ready,
  data: [],
  query: {
    limit: LAZY_LOAD_PAGE_SIZE,
    offset: 0,
    sorters: [],
    filters: [],
  },
};

export interface ApprovalsContainerState {
  initializing: boolean;
  lazyloadStatus: LazyLoadStatus;
  data: FormEntryApproval[];
  query: QueryParams;
}

export type ApprovalContainerStatusFilter = "pending" | "completed";

interface ApprovalsContainerProps {
  listType: ApprovalListType;
}

const ApprovalsContainer: React.FC<ApprovalsContainerProps> = ({
  listType,
}) => {
  const appContext = useAppContext();
  const routerHistory = useHistory();
  const routeLocation = useLocation();

  const [approvalsState, setApprovalsState] =
    useState<ApprovalsContainerState>(initState);

  const [statusFilter, setStatusFilter] =
    useState<ApprovalContainerStatusFilter>("pending");

  useEffect(() => {
    setStatusFilter("pending");
    setApprovalsState(initState);
  }, [routeLocation]);

  const getQueryFilter = useCallback(
    (status: "pending" | "completed") => {
      const filters: QueryParams["filters"] = [];
      // if (listType === "allApprovals") {
      filters.push(
        {
          filterColumn: {
            fieldName: "entityId",
            displayName: "entityId",
            filterDataType: FilterDataType.String,
          },
          filterComparer: FilterComparer.Equal,
          filterValue: appContext.serviceConfig.entityId!,
        },
        {
          filterColumn: {
            fieldName: "isCompleted",
            displayName: "isCompleted",
            filterDataType: FilterDataType.Boolean,
          },
          filterComparer: FilterComparer.Equal,
          filterValue: (status === "completed").toString(),
        },
        {
          filterColumn: {
            fieldName: "withdrawn",
            displayName: "withdrawn",
            filterDataType: FilterDataType.Boolean,
          },
          filterComparer: FilterComparer.Equal,
          filterValue: (status === "completed").toString(),
        }
      );
      if (appContext.serviceConfig.registerId) {
        filters.push({
          filterColumn: {
            fieldName: "registerId",
            displayName: "registerId",
            filterDataType: FilterDataType.String,
          },
          filterComparer: FilterComparer.Equal,
          filterValue: appContext.serviceConfig.registerId!.toString(),
        });
      }
      // }
      return filters;
    },
    [appContext.serviceConfig.entityId, appContext.serviceConfig.registerId]
  );

  const fetchData = useCallback(
    async (query: QueryParams, status: "pending" | "completed") => {
      setApprovalsState((prevState) => ({
        ...prevState,
        lazyloadStatus: LazyLoadStatus.loading,
      }));
      try {
        const newApprovals = await getApprovalList(
          listType,
          { ...query, filters: getQueryFilter(status) },
          appContext.serviceConfig
        ).then((response) => response.data);
        setApprovalsState((prevState) => ({
          ...prevState,
          initializing: false,
          lazyloadStatus:
            newApprovals.length < query.limit
              ? LazyLoadStatus.noMoreItem
              : LazyLoadStatus.ready,
          data:
            query.offset === 0
              ? newApprovals
              : prevState.data.concat(newApprovals),
          query: {
            ...prevState.query,
            offset: query.offset + newApprovals.length,
          },
        }));
      } catch (ex) {
        appContext.errorHandler(ex, "get approvals");
        setApprovalsState((prevState) => ({
          ...prevState,
          initializing: false,
          lazyloadStatus: LazyLoadStatus.ready,
        }));
      }
    },
    [appContext, getQueryFilter, listType]
  );

  const handleLazyLoad = useCallback(() => {
    fetchData(approvalsState.query, statusFilter);
  }, [approvalsState.query, fetchData, statusFilter]);

  useEffect(() => {
    if (
      approvalsState.initializing &&
      approvalsState.lazyloadStatus !== LazyLoadStatus.loading &&
      appContext.serviceConfig.entityId
    ) {
      fetchData(approvalsState.query, statusFilter);
    }
  }, [
    appContext.serviceConfig,
    approvalsState.initializing,
    approvalsState.lazyloadStatus,
    approvalsState.query,
    fetchData,
    statusFilter,
  ]);

  const handleTableChange = useCallback<NxpTableOnChange<FormEntryApproval>>(
    (_, __, tableSorter) => {
      const sorters: QueryParams["sorters"] = [];
      if (
        Array.isArray(tableSorter) &&
        tableSorter[0].field &&
        tableSorter[0].order
      ) {
        sorters.push({
          column: tableSorter[0].field.toString(),
          order: tableSorter[0].order,
        });
      } else if (
        tableSorter &&
        !Array.isArray(tableSorter) &&
        tableSorter.field &&
        tableSorter.order
      ) {
        sorters.push({
          column: tableSorter.field.toString(),
          order: tableSorter.order,
        });
      }
      setApprovalsState((prevState) => {
        const nextState = {
          ...prevState,
          lazyLoadingStatus: LazyLoadStatus.loading,
          query: {
            ...prevState.query,
            sorters,
            offset: 0,
          },
        };
        fetchData(nextState.query, statusFilter);
        return nextState;
      });
    },
    [fetchData, statusFilter]
  );

  const handleTabChange = useCallback(
    (key: string) => {
      const status = key as "pending" | "completed";
      setStatusFilter(status);
      fetchData({ ...approvalsState.query, offset: 0 }, status);
    },
    [approvalsState.query, fetchData]
  );

  const handleRowClick = (approval: FormEntryApproval) => {
    routerHistory.push(
      `/entities/${appContext.activeEntity?.id}/registers/${approval.registerId}/form-entries/${approval.entryId}/approvals/${approval.id}`
    );
  };

  return (
    <ApprovalsLayout
      registerId={appContext.activeRegister?.id}
      listType={listType}
      statusFilter={statusFilter}
      approvalsState={approvalsState}
      onTabChange={handleTabChange}
      onLazyLoad={handleLazyLoad}
      onTableChange={handleTableChange}
      onRowClick={handleRowClick}
    />
  );
};

export default ApprovalsContainer;
