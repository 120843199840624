import { buildQueryString } from "../utils/backend/query";
import { apiRequest, ServiceConfig } from "../utils/backend";

export interface PrintForm {
  createdOn: Date;
  enabled: boolean;
  id: number;
  printFormCode: string;
  printFormConfig: PrintablePage[];
  printFormName: string;
  publishDate: Date;
  registerId: number;
  status: PrintableStatus;
  updatedOn: Date;
  version: number;
}

export interface FontAppearanceSettings {
  isBold?: boolean;
  isItalic?: boolean;
  fontColor?: string;
}

export interface PrintableComponent {
  subId: string;
  componentId: string;
  x: number;
  y: number;
  sizeHeight: number;
  sizeWidth: number;
  fontSize: number;
  componentType?: string;
  appearance?: FontAppearanceSettings;
}

export interface PrintablePage {
  imageName: string;
  imageWidth: number;
  imageHeight: number;
  components: PrintableComponent[];
}

export interface MakePrintableRequest {
  printFormName: string;
  status: PrintableStatus;
  enabled: boolean;
  printFormConfig: PrintablePage[];
}

export type PrintableComponentValue = Omit<
  PrintableComponent,
  "componentId"
> & {
  component: any;
};

export type PrintablePageValue = Omit<PrintablePage, "components"> & {
  components: PrintableComponentValue[];
};

export function makePrintablePageValue(
  formComponents: any[],
  page: PrintablePage
): PrintablePageValue {
  const formComponentByKey = new Map<string, any>();
  formComponents.forEach((formComponent) => {
    formComponentByKey.set(formComponent.key, formComponent);
  });
  const componentValues: PrintableComponentValue[] = [];
  page.components.forEach((c) => {
    console.log(c);
    const formComponent = formComponentByKey.get(c.componentId);
    if (formComponent == null) {
      console.log(`Cannot find form component of printable component`, c);

      return;
    }

    componentValues.push({
      ...c,
      component: formComponent,
    });
  });

  return {
    ...page,
    components: componentValues,
  };
}

export async function CreatePrintableImage(
  body: MakePrintableRequest,
  registerId: number,
  serviceConfig: ServiceConfig
) {
  return await apiRequest<PrintForm>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/printform_configs`,
    serviceConfig,
    {
      method: "POST",
      body: body ? JSON.stringify(body) : JSON.stringify([]),
    }
  );
}

export async function EditPrintableImage(
  body: MakePrintableRequest,
  registerId: number,
  printFormId: number,
  serviceConfig: ServiceConfig
) {
  return await apiRequest<PrintForm>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/printform_configs/${printFormId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: body ? JSON.stringify(body) : JSON.stringify([]),
    }
  );
}

export function printableComponentValueToComponent(
  value: PrintableComponentValue
): PrintableComponent {
  const { component, ...rest } = value;
  // componentId corresponds to component.key of form
  return {
    ...rest,
    componentId: component.key,
    componentType: component.type,
  };
}

export function printablePageValueToPage(
  value: PrintablePageValue
): PrintablePage {
  const { components, ...rest } = value;

  return {
    ...rest,
    components: components.map((c) => printableComponentValueToComponent(c)),
  };
}

export enum ItemType {
  component = "component",
}

export enum PrintableStatus {
  draft = "draft",
  valid = "valid",
  superseded = "superseded",
}

interface Position {
  x: number;
  y: number;
}

const { v4: uuidv4 } = require("uuid");

export function makePrintableComponent(
  formComponent: any,
  position: Position
): PrintableComponentValue {
  return {
    subId: uuidv4(),
    component: formComponent,
    x: position.x,
    y: position.y,
    sizeHeight: 63,
    sizeWidth: 208,
    fontSize: 12,
  };
}

export const PageBuilderWidth = 794;

export const getPrintablesList = async (
  registerId: number,
  serviceConfig: ServiceConfig,
  query: any //I think Vishwesh's filter objects and Kent's filter objects do not align in format, so setting this to any for now
) => {
  return await apiRequest<{
    data: PrintForm[];
    limit: number;
    page: number;
    total: number;
  }>(
    `/powerflow/entities/${
      serviceConfig.entityId
    }/registers/${registerId}/printform_configs${buildQueryString({
      query: query,
    })}`,
    serviceConfig
  );
};

export const enableDisablePrintable = async (
  registerId: number,
  printFormId: number,
  newEnabledValue: boolean,
  serviceConfig: ServiceConfig
) => {
  //type later
  return await apiRequest<any>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/printform_configs/${printFormId}/state`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({ enabled: newEnabledValue }),
    }
  );
};
