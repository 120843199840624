import deepClone from "lodash/cloneDeep";
// import { getDockerHostRoot } from '../components/App/AppRouter/AppRouter';
import { apiRequest, ServiceConfig } from "../utils/backend";
import { getV6Root } from "./app";

export type RegisterCustomFormField = any; // formio fields, use any as formio has no typing

export interface RegisterCustomForm {
  id: number;
  // user_id: number;
  entityId: string;
  type: string;
  definitions: RegisterCustomFormField[];
  version: number;
  published_at: Date;
  status: string;
  printable_image: any[];
  application: string;
  identifierColumn: string;
  identifierId: number;
  // allow_sections: any[];
  name: string;
  code: string;
  numberFormat: string;
}

export interface RegisterCustomFormForCreate
  extends Pick<
    RegisterCustomForm,
    | "entityId"
    | "type"
    | "name"
    | "code"
    | "numberFormat"
    | "definitions"
    | "application"
    | "identifierColumn"
    | "identifierId"
  > {}

export const prepareFormContentForSubmit = (
  content: any,
  formConfigSpec: RegisterCustomFormField[]
) => {
  const preparedContent = deepClone(content);
  extractFormConfigFields(formConfigSpec, true, false, []).forEach((def) => {
    if (
      def.type === "day" &&
      preparedContent !== undefined &&
      preparedContent[def.key] === "00/00/0000"
    ) {
      preparedContent[def.key] = null;
    }
  });
  return preparedContent;
};

export const checkIsNonReadable = (fieldType: string) => {
  return (
    fieldType === "signature" ||
    fieldType === "datagrid" ||
    fieldType === "survey" ||
    fieldType === "fileUpload"
  );
};

export const extractFormConfigFields = (
  spec: RegisterCustomFormField[] = [],
  excludeNonInput: boolean,
  excludeNonReadable: boolean,
  excludeKeys: string[]
) => {
  const fields = spec
    .filter((field) => field.label && field.key)
    .filter((field) => !excludeNonInput || field.input)
    .filter((field) => {
      return !excludeNonReadable || !checkIsNonReadable(field.type);
    })
    .filter((field) => !excludeKeys.includes(field.key));

  spec.forEach((specChild) => {
    if (
      specChild.components &&
      (!excludeNonReadable || !checkIsNonReadable(specChild.type))
    ) {
      fields.push(
        ...extractFormConfigFields(
          specChild.components,
          excludeNonInput,
          excludeNonReadable,
          excludeKeys
        )
      );
    }
  });

  return fields;
};

export const filterFormConfigInputByKeys = (
  fieldDefinition: RegisterCustomFormField,
  includeInputKeys: string[],
  excludeInputKeys: string[]
) => {
  if (!fieldDefinition.input && fieldDefinition.components?.length) {
    // recursively filter fields inside formio containers eg, panel
    fieldDefinition.components = fieldDefinition.components.filter(
      (def: RegisterCustomFormField) =>
        filterFormConfigInputByKeys(def, includeInputKeys, excludeInputKeys)
    );
  }

  if (
    fieldDefinition.input &&
    includeInputKeys.length &&
    !includeInputKeys.includes(fieldDefinition.key)
  ) {
    // ignore field definition if: 1. input , 2. includeInputKeys specififed, 3, not found in includeInputKeys
    return undefined;
  }

  if (
    fieldDefinition.input &&
    excludeInputKeys.length &&
    excludeInputKeys.includes(fieldDefinition.key)
  ) {
    return undefined;
  }

  if (
    fieldDefinition.type === "panel" &&
    fieldDefinition.components.length === 0
  ) {
    return undefined;
  }

  return fieldDefinition;
};

export const createRegisterCustomForm = async (
  form: RegisterCustomFormForCreate,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterCustomForm>(
    `${getV6Root()}/form/custom`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify({
        ...form,
        entityId: undefined,
        entity_id: form.entityId,
        numberFormat: undefined,
        name: undefined,
        code: undefined,
        type: undefined,
        info: {
          number_format: form.numberFormat,
          name: form.name,
          code: form.code,
          type: form.type,
        },
      }),
    }
  );
};

export interface RegisterCustomFormForUpdate
  extends Omit<
    RegisterCustomFormForCreate,
    "identifierColumn" | "identifierId" | "application"
  > {
  status: string;
}

export const updateRegisterCustomForm = async (
  formId: number,
  form: RegisterCustomFormForUpdate,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterCustomForm>(
    `${getV6Root()}/form/entities/${serviceConfig.entityId}/registers/${
      serviceConfig.registerId
    }/forms/${formId}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        ...form,
        entityId: undefined,
        entity_id: form.entityId,
        numberFormat: undefined,
        name: undefined,
        code: undefined,
        type: undefined,
        info: {
          number_format: form.numberFormat,
          name: form.name,
          code: form.code,
          type: form.type,
        },
      }),
    }
  );
};

// export const getRegisterCustomForm = async (serviceConfig: ServiceConfig) => {
//   return await apiRequest<{ data: RegisterCustomForm[] }>(
//     // `${getV6Root()}/form/custom?application=${FORM_SERVICE_APPLICATION}&identifierColumn=${FORM_SERVICE_IDENTIFIER_COLUMN}&identifierId=${
//     //   serviceConfig.registerId
//     // }&entity_id=${entityId ?? serviceConfig.entityId}&query_status=valid`,
//     `${getV6Root()}/form/custom?entity_id=${
//       serviceConfig.entityId
//     }&register_id=${serviceConfig.registerId}&query_status=valid`,
//     serviceConfig
//   )
//     .then((registerForms) => {
//       if (registerForms.data.length) {
//         const form = registerForms.data.find(
//           (registerForm) =>
//             registerForm.id ===
//             Math.max(...registerForms.data.map((item) => item.id))
//         );
//         return {
//           ...form,
//           spec: JSON.parse(form?.definitions as any),
//         } as RegisterCustomForm;
//       }
//       return undefined;
//     })
//     .catch(() => undefined);
// };

export const getRegisterCustomForm = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<RegisterCustomForm>(
    `${getV6Root()}/form/custom?entity_id=${
      serviceConfig.entityId
    }&register_id=${serviceConfig.registerId}&query_status=valid`,
    serviceConfig
  );
};
