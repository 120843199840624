import baseEditForm from "formiojs/components/_classes/component/Component.form";

// function will be re-created in SetupFormLayout as dictionary key options need to be retrieved in run-time
export default function SelectDictionaryFormioConfig(...extend: any) {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            tooltip: "Select a dictionary defined in Setup - Dictionaries",
            type: "select",
            label: "Dictionary",
            key: "dictionaryId",
            weight: 12,
            data: {
              values: [], // options to be added in run-time
            },
            multiple: false,
            input: true,
            // remove below because not able to get client side validation styling to work
            // validate: {
            //   required: true,
            // },
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
}
