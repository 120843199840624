import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormSurvey,
  NxpFormSurveyItemProps,
  NxpHeader,
  NxpHolder,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import { useValidate } from "../../../hooks/useValidate";
import {
  Delegation,
  DelegationForm,
  getDelegation,
  updateDelegation,
} from "../../../services/delegation";
import notify from "../../../utils/notify";

const SetupDelegation: React.FC = () => {
  const appContext = useAppContext();

  const initFormValues = {
    delegates: [],
  };

  const [delegationAsync, setDelegationAsyncRequest] = useAsync<Delegation>(
    () => getDelegation(appContext.serviceConfig),
    undefined,
    "get delegation"
  );

  const [editForm, setEditForm] = useState<DelegationForm>(initFormValues);

  const initEditForm = useCallback(() => {
    if (!delegationAsync.loading && delegationAsync.data) {
      setEditForm({
        delegates: delegationAsync.data.delegates.map((user) => user.id),
        expiryDate: delegationAsync.data.expiryDate,
      });
    }
  }, [delegationAsync.data, delegationAsync.loading]);

  useEffect(() => {
    initEditForm();
  }, [delegationAsync, initEditForm]);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      await updateDelegation(editForm, appContext.serviceConfig);
      setSaveInProgress(false);
      notify.actionCompleted();
    } catch (ex) {
      appContext.errorHandler(ex, "update delegation");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    expiryDate: yup.date().nullable(),
  });

  const [validationError, , , saveWithValidate] = useValidate<DelegationForm>(
    editForm,
    formSchema,
    handleSaveValidated
  );

  const handleFormGridStateChange = (
    fieldName: keyof Delegation,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  const handleCancel = () => {
    setDelegationAsyncRequest(() => getDelegation(appContext.serviceConfig));
    // initEditForm();
  };

  const { t } = useTranslation();

  const formItems: NxpFormSurveyItemProps<DelegationForm>[] = [
    {
      controlType: "selectMultiple",
      controlProps: {
        options: appContext.users.map((user) => ({
          label: user.name,
          value: user.id,
        })),
      },
      label: t("Delegation.DelegateTo"),
      itemFieldName: "delegates",
      span: 2,
    },
    {
      controlType: "datePicker",
      label: t("Delegation.ExpiryDate"),
      itemFieldName: "expiryDate",
      span: 2,
    },
  ];

  return (
    <>
      <NxpHeader
        titleContent={t("Delegation.SetupDelegation")}
        actionContent={
          <>
            <NxpButton
              disabled={saveInProgress}
              type="text"
              onClick={handleCancel}
            >
              {t("app.common.Cancel")}
            </NxpButton>
            <NxpButton disabled={saveInProgress} onClick={handleSave}>
              {t("app.common.Save")}
            </NxpButton>
          </>
        }
      />
      <NxpHolder>
        <NxpFormSurvey
          validationError={validationError}
          formItems={formItems}
          formState={editForm}
          onFormStateChange={handleFormGridStateChange}
        />
      </NxpHolder>
    </>
  );
};

export default SetupDelegation;
