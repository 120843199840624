import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { NxpButton, NxpHeader, NxpModal } from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { PresignUploadResponse, useFileService } from "../../../services/file";
import {
  getRegisterCustomForm,
  RegisterCustomFormField,
} from "../../../services/registerCustomForm";
import {
  CreatePrintableImage,
  EditPrintableImage,
  makePrintablePageValue,
  PrintablePageValue,
  printablePageValueToPage,
  PrintableStatus,
} from "../../../services/registerPrintables";
import SetupPrintablesLayout from "./SetupPrintablesLayout";

interface SetupPrintablesContainerProps {}

function recursivelyUnpack(spec: any[], unpacked: any[]) {
  let returnList: any[] = [...unpacked];
  spec.forEach((s) => {
    if (s.type === "panel" || s.type === "fieldset") {
      returnList = recursivelyUnpack(s.components, returnList);
    } else if (s.type === "columns") {
      s.columns.forEach((c: any) => {
        returnList = recursivelyUnpack(c.components, returnList);
      });
    } else {
      returnList.push(s);
    }
  });
  return returnList;
}

const SetupPrintablesContainer: React.FC<SetupPrintablesContainerProps> =
  () => {
    const appContext = useAppContext();
    const history = useHistory();
    const fileService = useFileService(
      appContext.serviceConfig.keyCloakToken || ""
    );

    const [editMode, setEditMode] = useState<boolean>(false);
    const [printablePageValues, setPrintablePageValues] = React.useState<
      PrintablePageValue[]
    >([]);
    const [printableName, setPrintableName] = React.useState<string>("");
    const [printableStatus, setPrintableStatus] =
      React.useState<PrintableStatus>(PrintableStatus.draft);
    const [fields, setFields] = useState<RegisterCustomFormField[]>([]);
    const [page, setPage] = React.useState<number>(0);

    //a counter for number of pages added since component was mounted, used only to trigger page turning after adding a new image
    const [addedPages, setAddedPages] = React.useState<number>(0);

    const [imageUrls, setImageUrls] = React.useState<{
      [name: string]: string | undefined;
    }>({});
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    const onModalClose = () => {
      setModalVisible(false);
    };
    const [modalDialogText, setModalDialogText] = React.useState<string>("");
    const [discardModal, setDiscardModal] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const usedFields = React.useMemo<boolean[]>(() => {
      const useList = new Array(fields.length).fill(false);

      fields.forEach((formComponent, index) => {
        if (
          printablePageValues.some((page) => {
            return (
              page.components.findIndex(
                (x) => x.component.key === formComponent.key
              ) !== -1
            );
          })
        ) {
          useList[index] = true;
        }
      });
      return useList;
    }, [fields, printablePageValues]);

    useEffect(() => {
      if (appContext.activeEntity && appContext.activeRegister) {
        getRegisterCustomForm(appContext.serviceConfig).then((response) => {
          if (response) {
            const unpackedFields: any[] = recursivelyUnpack(
              response.definitions,
              []
            );
            // console.log(`unpacked: ${JSON.stringify(unpackedFields[0])}`)
            // added for CPCS-976
            const defaultFields: RegisterCustomFormField[] = [
              {
                label: t("SetupPrintablesContainer.EntityName"),
                key: "entityName",
                type: "entityName",
              },
              {
                label: t("SetupPrintablesContainer.EntityCode"),
                key: "entityCode",
                type: "entityCode",
              },
              {
                label: t("SetupPrintablesContainer.ApprovalRequesterName"),
                key: "approvalAuthor",
                type: "approvalAuthor",
              },
              {
                label: t("SetupPrintablesContainer.EntryAuthor"),
                key: "entryAuthor",
                type: "entryAuthor",
              },
            ];

            setFields([...unpackedFields, ...defaultFields]);
            // end CPCS-976
          }
        });
      }
    }, [
      appContext.activeEntity,
      appContext.activeRegister,
      appContext.serviceConfig,
      t,
    ]);

    useEffect(() => {
      if (appContext.printForm) {
        setEditMode(true);
        setPrintablePageValues(
          appContext.printForm.printFormConfig.map((p: any) =>
            makePrintablePageValue(fields, p)
          )
        );
        setPrintableName(appContext.printForm.printFormName);
        setPrintableStatus(appContext.printForm.status);
      }
    }, [fields, appContext.printForm]);

    React.useEffect(() => {
      if (appContext.serviceConfig.registerId) {
        const assets = printablePageValues.map((page) => page.imageName);
        fileService
          .sign(appContext.serviceConfig.registerId, {
            assetIds: assets,
          })
          .then((c) => {
            const urls: { [name: string]: string } = {};
            c.assets.forEach((asset) => {
              urls[asset.assetId] = asset.url;
            });
            setImageUrls(urls);
          });
      }
    }, [appContext.serviceConfig.registerId, fileService, printablePageValues]);

    const handleReloadImage = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          appContext.serviceConfig.registerId &&
          event.target.files &&
          event.target.files.length === 1
        ) {
          fileService
            .fileUpload(
              appContext.serviceConfig.registerId,
              event.target.files[0]
            )
            .then((image: PresignUploadResponse) => {
              setPrintablePageValues((c) => {
                const component = [...c];
                component[page] = {
                  ...component[page],
                  imageName: image.assetId,
                };
                return component;
              });
            });
        }
        //@ts-ignore
        event.target.value = null;
      },
      [page, setPrintablePageValues, appContext.serviceConfig, fileService]
    );

    const handleUploadImage = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
          Promise.all(
            Array.from(event.target.files).map((file) =>
              fileService.fileUpload(appContext.serviceConfig.registerId!, file)
            )
          )
            .then((images) => {
              setPrintablePageValues((pages) => {
                const printablePages = [...pages];
                printablePages.splice(
                  page + 1,
                  0,
                  ...images.map((image) => {
                    let i = new Image();
                    i.src = image.url;
                    return {
                      imageName: image.assetId,
                      imageWidth: i.width,
                      imageHeight: i.height,
                      components: [],
                    };
                  })
                );
                return printablePages;
              });
              setAddedPages((prevState) => prevState + 1);
            })
            .catch((e) => {
              appContext.errorHandler(e, "Error in file upload");
            });
          //@ts-ignore
          event.target.value = null;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        page,
        setPrintablePageValues,
        appContext.serviceConfig.registerId,
        fileService,
        appContext.errorHandler,
      ]
    );

    useEffect(() => {
      if (addedPages > 1) {
        setPage((prevState) => prevState + 1);
      }
    }, [addedPages]);

    const handlePageChanged = (page: number) => {
      setPage(page);
    };

    const handlePrintablePageChanged = React.useCallback(
      (printablePage: PrintablePageValue) => {
        const newPrintablePages = [...printablePageValues];
        newPrintablePages[page] = printablePage;
        setPrintablePageValues(newPrintablePages);
      },
      [printablePageValues, page, setPrintablePageValues]
    );

    const executeCreateOperation = React.useCallback(async () => {
      try {
        await CreatePrintableImage(
          {
            printFormName: printableName,
            status: printableStatus,
            enabled: true,
            printFormConfig: printablePageValues.map((p) =>
              printablePageValueToPage(p)
            ),
          },
          Number(appContext.activeRegister?.id),
          appContext.serviceConfig
        );
        history.goBack();
      } catch (e) {
        console.log(e);
        //add more robust error handling later
      }
    }, [
      printableName,
      printableStatus,
      printablePageValues,
      appContext.activeRegister?.id,
      appContext.serviceConfig,
      history,
    ]);

    const executeEditOperation = React.useCallback(async () => {
      try {
        await EditPrintableImage(
          {
            printFormName: printableName,
            status: printableStatus,
            enabled: true,
            printFormConfig: printablePageValues.map((p) =>
              printablePageValueToPage(p)
            ),
          },
          Number(appContext.activeRegister?.id),
          Number(appContext.printForm?.id),
          appContext.serviceConfig
        );
        history.goBack();
      } catch (e) {
        console.log(e);
        //add more robust error handling later
      }
    }, [
      appContext.activeRegister?.id,
      appContext.printForm?.id,
      appContext.serviceConfig,
      printableName,
      printableStatus,
      printablePageValues,
      history,
    ]);

    const onClickCreateEdit = React.useCallback(() => {
      const hasUnusedFields = usedFields.filter((used) => !used).length > 0;

      if (hasUnusedFields) {
        setDiscardModal(false);
        setModalDialogText(
          t(
            "SetupPrintablesContainer.YouStillHaveUnusedComponents.AreYouSureYouWishToCreateThisPrintableForm?"
          )
        );
        setModalVisible(true);
      } else {
        editMode ? executeEditOperation() : executeCreateOperation();
      }
    }, [usedFields, t, editMode, executeEditOperation, executeCreateOperation]);

    const onClickDiscardForm = React.useCallback(() => {
      if (!modalVisible) {
        setDiscardModal(true);
        setModalDialogText(
          t(
            "SetupPrintablesContainer.AreYouSureYouWishToDiscardThisPrintableFormTemplate?"
          )
        );
        setModalVisible(true);
      }
    }, [modalVisible, t]);

    const hasImages = printablePageValues.length > 0;

    return (
      <>
        <NxpHeader
          titleContent={
            editMode
              ? t("SetupPrintablesContainer.CreateNewPrintableFormVersion")
              : t("app.common.CreatePrintableForm")
          }
          actionContent={
            <>
              <NxpButton
                onClick={() => {
                  history.goBack();
                }}
              >
                {t("app.common.Back")}
              </NxpButton>
            </>
          }
        />
        {fields ? (
          <SetupPrintablesLayout
            editMode={editMode}
            page={page}
            printablePageValues={printablePageValues}
            handlePageChanged={handlePageChanged}
            fields={fields}
            usedFields={usedFields}
            handleUploadImage={handleUploadImage}
            handleReloadImage={handleReloadImage}
            hasImages={hasImages}
            imageUrls={imageUrls}
            onClickCreateEdit={onClickCreateEdit}
            onClickDiscardForm={onClickDiscardForm}
            handlePrintablePageChanged={handlePrintablePageChanged}
            printableName={printableName}
            setPrintableName={setPrintableName}
            printableStatus={printableStatus}
            setPrintableStatus={setPrintableStatus}
          />
        ) : null}
        <NxpModal
          title={t("SetupPrintablesContainer.AreYouSure?")}
          visible={modalVisible}
          onCancel={onModalClose}
        >
          <span>{modalDialogText}</span>
          <NxpButton
            onClick={() => {
              if (discardModal) {
                setPrintablePageValues([]);
                setImageUrls({});
                setPage(0);
              } else {
                executeCreateOperation();
              }
              setModalVisible(false);
            }}
          >
            {t("SetupPrintablesContainer.Confirm")}
          </NxpButton>
        </NxpModal>
      </>
    );
  };

export default SetupPrintablesContainer;
