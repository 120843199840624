import { apiRequest } from '../utils/backend';
import { ServiceConfig } from '../utils/backend/apiRequest';

export interface Entity {
  id: string;
  name: string;
  code: string; // added for CPCS-976
}

export interface RegisterType {
  value: string;
  label: string;
}

export interface User {
  comments: string;
  createdDate: Date;
  emailAliases: any[];
  firstName: string;
  id: string;
  isDeleted: boolean;
  isSuspended: boolean;
  keycloakUserId: string;
  lastName: string;
  lastUpdatedDate: Date;
  mobile: string;
  primaryEmail: string;
  userEntities: Entity[];
  name: string;
}

export const getV6Root = () => {
  return ""; //process.env.NODE_ENV === "production" ? "/v6" : "/v6";
};

export const getEntityInfo = async (
  entityId: string,
  serviceConfig: ServiceConfig
): Promise<Entity> => {
  return await apiRequest<Entity>(
    `${getV6Root()}/entities/${entityId}`,
    serviceConfig
  ).then((entity) => ({
    ...entity,
    id: entity.id.toString(), //handle case when entityId is sent as integer from backend
  }));
};

export const getUsers = async (
  entityId: string,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<User[]>(
    `${getV6Root()}/entities/${entityId}/cpcs-users`,
    serviceConfig
  )
    .then((users) =>
      users.map((user) => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
      }))
    )
    .then((users) => {
      const uniqueUsers: User[] = [];
      users.forEach((user) => {
        // handle api return duplicated users
        if (!uniqueUsers.find((uniqueUser) => uniqueUser.id === user.id)) {
          uniqueUsers.push(user);
        }
      });
      return uniqueUsers;
    });
};

export const getMe = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<User>(
    `${serviceConfig.qcServiceApiBaseUrl}/user/myself`,
    serviceConfig
  ).then((myself) => ({
    ...myself,
    name: `${myself.firstName} ${myself.lastName}`,
  }));
};

export const getRegisterTypes = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<object>(
    `/powerflow/entities/registertypes`,
    serviceConfig
  ).then((typesObj) =>
    Object.entries(typesObj).map((entry) => ({
      label: entry[1],
      value: entry[0],
    }))
  );
};

export enum CPCSDictionaryDataType {
  TEXT = "Text",
  NUMBER = "Number",
  CONTACT = "Contact",
  GEOLOCATION = "Geolocation",
  BLOB = "BLOB",
}

export interface CPCSDictionaryType {
  id: string;
  name: string;
  description: string;
  dataType: CPCSDictionaryDataType;
  application: string;
  createdDate: Date;
  lastUpdatedDate: Date;
}

export interface CPCSDictionary {
  id: string;
  name: string;
  entityType: string;
  entityId: string;
  members: CPCSDictionaryItem[];
  dictionaryTypeId: string;
}

export interface CPCSDictionaryItem {
  key: string;
  label: string;
  value: string | number;
  members?: CPCSDictionaryItem[];
  isEngaged: boolean;
}

export const getCPCSDictionaryTypes = async (serviceConfig: ServiceConfig) => {
  return (
    await apiRequest<{ data: CPCSDictionaryType[] }>(
      `${serviceConfig.qcServiceApiBaseUrl}/dictionary-type`,
      serviceConfig
    )
  ).data.filter((type) => type.dataType === "Text");
};

export const getCPCSDictionaryData = async (
  dictionaryTypeName: string,
  serviceConfig: ServiceConfig
) => {
  try {
    return (
      await apiRequest<{ data: CPCSDictionary[] }>(
        `${serviceConfig.qcServiceApiBaseUrl}/dictionary-data/pagination?dictionaryTypeName=${dictionaryTypeName}`,
        serviceConfig
      )
    ).data.find((dict) => dict.entityId === serviceConfig.entityId);
  } catch (ex) {
    console.error(ex);
  }
};

export interface UserPreference {
  id: string;
  userId: string;
  application: string;
  parameter: string;
  value: string;
}
export type AddUserPreference = Omit<UserPreference, "id" | "userId">;

export const getMyUserPreference = async (serviceConfig: ServiceConfig) => {
  return apiRequest<UserPreference[]>(
    `${serviceConfig.qcServiceApiBaseUrl}/user-preference/me`,
    serviceConfig
  );
};
export const createMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<UserPreference>(
    `${serviceConfig.qcServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(userPreference),
    }
  );
};
export const patchMyUserPreference = async (
  serviceConfig: ServiceConfig,
  userPreference: AddUserPreference
) => {
  return apiRequest<any>(
    `${serviceConfig.qcServiceApiBaseUrl}/user-preference/me`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(userPreference),
    }
  );
};

// v5 code  below

// export interface CPCSDictionary {
//   id: number;
//   entity_id: number;
//   is_deleted: boolean;
//   name: string;
//   code: string;
//   created_at: Date;
//   updated_at: Date;
//   description: string;
//   type: string;
//   dictionaries: CPCSDictionaryItem[];
// }

// export interface CPCSDictionaryItem {
//   name: string;
//   description: string;
//   code: string;
//   children: CPCSDictionaryItem[];
// }

// export const getCPCSDictionaryList = async (serviceConfig: ServiceConfig) => {
//   return await apiRequest<CPCSDictionary[]>(
//     `${getV6Root()}/entities/${serviceConfig.entityId}/categories/own`,
//     serviceConfig
//   );
// };

// export const getCPCSDictionary = async (
//   dictionaryKey: string,
//   serviceConfig: ServiceConfig
// ) => {
//   try {
//     return await apiRequest<CPCSDictionary>(
//       `${getV6Root()}/entities/${
//         serviceConfig.entityId
//       }/categories/${dictionaryKey}`,
//       serviceConfig
//     );
//   } catch (ex) {
//     console.error(ex);
//   }
// };
