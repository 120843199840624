import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default function FileUploadFormioConfig(...extend: any) {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            type: "number",
            label: "File Limit",
            key: "fileLimit",
            weight: 12,
            defaultValue: 1,
            input: true,
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        components: [],
      },
      {
        key: "api",
        components: [],
      },
      {
        key: "conditional",
        components: [],
      },
      {
        key: "logic",
        components: [],
      },
    ],
    ...extend
  );
}
