// @ts-nocheck

///////////////////////////////////////////////// https://codesandbox.io/s/81qb6?file=/src/Custom/Toggle/index.js:1919-1931

import { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./SelectDictionary.form";

import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
// import { getRegisterDictionary } from "../../../services/registerDictionary";
// import useAppContext from "../../../hooks/useAppContext";
import { getRegisterDictionary } from "../../../services/registerDictionary";
import AppContext from "../../App/AppContext";
import keycloakInstance from "../../App/keycloak";

import { registerIdMatch } from "../registerIdMatch";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const checkDictionaryRemoved = (formEntryDictionary, dictionaryOptions) => {
  // formio sometime pass in empty array for formEntryDictionary in setup page
  if (Array.isArray(formEntryDictionary) && formEntryDictionary.length === 0) {
    return false;
  }

  return !!(
    formEntryDictionary &&
    !dictionaryOptions.find(
      (opt) =>
        opt.label === Object.keys(formEntryDictionary)[0] &&
        opt.value === formEntryDictionary[Object.keys(formEntryDictionary)[0]]
    )
  );
};

const SelectDictionaryInternal = class extends Component {
  constructor(props) {
    super(props);

    if (props.dictionaryId && props.serviceConfig.registerId) {
      getRegisterDictionary(props.dictionaryId, props.serviceConfig).then(
        (dict) =>
          this.setState({
            options: Object.keys(dict.dict_pair).map((key) => ({
              label: dict.dict_pair[key],
              value: key,
            })),
          })
      );
    }

    this.state = {
      value: props.value,
      options: [],
    };
  }

  render() {
    return (
      <Select
        disabled={this.props.disabled}
        style={{ width: "100%" }}
        suffixIcon={<CaretDownOutlined />}
        className="appFormioSelectDictionary"
        options={[
          {
            label: "",
            value: "",
          },
          ...this.state.options,
          // add removed dictionary below
          ...(checkDictionaryRemoved(this.state.value, this.state.options)
            ? [
                {
                  label: `${
                    Object.keys(this.state.value)[0]
                  } (removed from setup)`,
                  value: `Removed_${
                    this.state.value[Object.keys(this.state.value)[0]]
                  }`,
                  disabled: true,
                },
              ]
            : []),
        ]}
        value={
          checkDictionaryRemoved(this.state.value, this.state.options)
            ? `Removed_${this.state.value[Object.keys(this.state.value)[0]]}`
            : this.state.value &&
              this.state.value[Object.keys(this.state.value)[0]]
        }
        onChange={(_, opt) =>
          this.setState(
            { value: opt.value ? { [opt.label]: opt.value } : null },
            () => this.props.onChange(null, { changed: true })
          )
        }
      />
    );
  }
};

// functional component do not work in formio, reason unknown
//
// const SelectDictionaryInternal: React.FC<{
//   value: string | number;
//   dictionaryId: number;
//   onChange: Function;
//   serviceConfig: ServiceConfig; // cannot useAppContext inside formio
// }> = ({ value, dictionaryId, onChange, serviceConfig }) => {
//   const [selectValue, setSelectValue] = useState(() => value);
//   const [options, setOptions] = useState([]);

//   useEffect(() => {
//     console.log("ssssssssssssss", dictionaryId, serviceConfig);

//     if (dictionaryId && serviceConfig.registerId) {
//       getRegisterDictionary(dictionaryId, serviceConfig).then((dict) =>
//         setOptions(
//           Object.keys(dict.dict_pair).map((key) => ({
//             label: dict.dict_pair[key],
//             value: key,
//           }))
//         )
//       );
//     }

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   useEffect(() => {
//     console.log("vvvvvvvvvvvvvv");

//     onChange(null, { changed: true });
//   }, [onChange, selectValue]);

//   console.log("ooooooooooooooo", options);

//   return (
//     <Select
//       style={{ width: "100%" }}
//       suffixIcon={<CaretDownOutlined />}
//       className="appFormioSelectDictionary"
//       options={[
//         {
//           label: "11111",
//           value: "222222",
//         },
//         ...options,
//       ]}
//       value={selectValue}
//       onChange={(value) => setSelectValue(value)}
//     />
//   );
// };

export default class SelectDictionary extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Select Dictionary",
      icon: "list",
      group: "Data",
      documentation: "",
      weight: 10,
      schema: SelectDictionary.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "selectDictionary",
      label: "", //default label, if any
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    const [, entityId] = window.location.href.match(/entities\/([\w-]+)/);

    const registerId = registerIdMatch();

    return ReactDOM.render(
      <SelectDictionaryInternal
        disabled={this.component.disabled}
        dictionaryId={this.component.dictionaryId}
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        serviceConfig={{
          entityId: entityId,
          registerId: Number(registerId),
          keyCloakToken: keycloakInstance.token,
          apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
        }}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  static contextType = AppContext;
}
