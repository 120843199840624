import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";

import {
  LazyLoadStatus,
  NxpButton,
  NxpTableLazy,
} from "@nexploretechnology/nxp-ui";
import clsx from "clsx";
import deepClone from "lodash/cloneDeep";

import useAppContext from "../../../../hooks/useAppContext";
import { getRegisterFormEntryList } from "../../../../services/registerFormEntry";
import { RegisterListingConfig } from "../../../../services/registerListing";
import { QueryParams } from "../../../../utils/backend/query";
import {
  DEFAULT_COLUMN_WIDTH,
  LAZY_LOAD_PAGE_SIZE,
  MIN_COLUMN_WIDTH,
} from "../../../../utils/const";
import registerFormEntriesReducer, {
  RegisterFormEntriesState,
} from "../../../RegisterFormEntries/registerFormEntriesReducer";

const useStyles = createUseStyles((theme) => ({
  saveButtom: {
    position: "absolute",
    right: 0,
    marginTop: -48,
  },
  table: {
    "& th.ant-table-cell": {
      userSelect: "none",
    },
  },
  resizeHandle: {
    position: "absolute",
    width: 5,
    height: "100%",
    bottom: 0,
    right: 0,
    cursor: "col-resize",
    zIndex: 5,
    borderLeft: `2px dotted ${theme.palette.border}`,
    borderRight: `2px dotted ${theme.palette.border}`,
    backgroundColor: theme.palette.background,
    "&.active": {
      borderColor: theme.palette.primary,
    },
  },
}));

interface WebColumnWidthProps {
  listConfig: RegisterListingConfig;
  availableFields: { key: string; name: string; isFormField: boolean }[];
  saveInProgress: boolean;
  onSave: (config: RegisterListingConfig) => void;
}

export interface BuilderItem {
  key: string;
  name: string;
  selected: boolean;
}

const WebColumnWidth: React.FC<WebColumnWidthProps> = ({
  listConfig,
  availableFields,
  saveInProgress,
  onSave,
}) => {
  const appContext = useAppContext();
  const routerHistory = useHistory();
  const routerLocation = useLocation<{ from: string }>();

  const [resizingColumnKey, setResizingColumnKey] = useState<string>();
  const resizingColumnKeyRef = useRef(resizingColumnKey);

  const { t } = useTranslation();
  const initialState: RegisterFormEntriesState = {
    lazyloadStatus: LazyLoadStatus.ready,
    initializing: true,
    refetchMode: undefined,
    data: [],
    tableColumns: [],
    query: {
      limit: LAZY_LOAD_PAGE_SIZE,
      offset: 0,
      sorters: [],
      filters: [],
    },
    formConfigSpec: [],
    listConfig: undefined,
    translation: t,
  };

  const [registerFormEntriesState, dispatch] = useReducer(
    registerFormEntriesReducer,
    initialState
  );

  const fetchData = useCallback(
    (query: QueryParams) => {
      dispatch({ type: "dataLoading" });
      getRegisterFormEntryList(
        appContext.serviceConfig,
        query,
        registerFormEntriesState.formConfigSpec,
        appContext.registerDictionaries || []
      )
        .then((results) =>
          dispatch({
            type: "dataLoaded",
            data: results.data,
            formConfig: results.form_config,
            pageSize: query.limit,
          })
        )
        .catch((ex) => {
          dispatch({
            type: "dataError",
          });
          appContext.errorHandler(ex, "fetch form entries");
        });
    },
    [appContext, registerFormEntriesState.formConfigSpec]
  );

  // fetch on start
  useEffect(() => {
    if (!registerFormEntriesState.listConfig) {
      dispatch({
        type: "listConfigLoaded",
        listConfig: {
          ...listConfig,
          layout: (listConfig.layout || []).filter((configItem) =>
            availableFields.find((field) => field.key === configItem.key)
          ),
        },
      });
    } else if (
      appContext.activeRegister?.id &&
      registerFormEntriesState.data.length === 0 &&
      registerFormEntriesState.lazyloadStatus === LazyLoadStatus.ready
    ) {
      fetchData(registerFormEntriesState.query);
    }
  }, [
    appContext.activeRegister,
    availableFields,
    fetchData,
    listConfig,
    registerFormEntriesState,
  ]);

  // assign column width data
  useEffect(() => {
    if (registerFormEntriesState.tableColumns.length) {
      const widthData = {};
      registerFormEntriesState.tableColumns.forEach((col) => {
        if (col.dataIndex?.toString()) {
          Object.assign(widthData, {
            [col.dataIndex?.toString()]: Number(col.width) || 0,
          });
        }
      });
      setColumnWidthData(widthData);
    }
  }, [registerFormEntriesState.tableColumns]);

  const handleLazyLoad = useCallback(() => {
    fetchData(registerFormEntriesState.query);
  }, [fetchData, registerFormEntriesState.query]);

  const [columnWidthData, setColumnWidthData] = useState<{
    [key: string]: number;
  }>({});

  const handleResizingColumnChange = (columnKey?: string) => {
    resizingColumnKeyRef.current = columnKey;
    setResizingColumnKey(columnKey);
  };

  const resizeListener = (e: MouseEvent) => {
    if (resizingColumnKeyRef.current) {
      handleResize(resizingColumnKeyRef.current, e.movementX);
    }
    window.onmouseup = (e: MouseEvent) => {
      window.removeEventListener("mousemove", resizeListener);
      handleResizingColumnChange(undefined);
    };
  };

  const handleResize = (columnKey: string, delta: number) => {
    setColumnWidthData((prevState) => {
      const width = (prevState[columnKey] || DEFAULT_COLUMN_WIDTH) + delta;

      return {
        ...prevState,
        [columnKey]: width < MIN_COLUMN_WIDTH ? MIN_COLUMN_WIDTH : width,
      };
    });
  };

  const handleSave = () => {
    const nextListConfig = deepClone(listConfig);
    nextListConfig.layout = (nextListConfig.layout || []).map((configItem) => {
      return {
        ...configItem,
        webListing: {
          ...configItem.webListing,
          width:
            columnWidthData[configItem.key] ||
            columnWidthData["contentDetails," + configItem.key] ||
            DEFAULT_COLUMN_WIDTH,
        },
      };
    });
    onSave(nextListConfig);
  };

  const classes = useStyles();

  return (
    <>
      <NxpButton
        className={classes.saveButtom}
        onClick={handleSave}
        disabled={saveInProgress}
      >
        {t("app.common.Save")}
      </NxpButton>
      <NxpTableLazy
        className={classes.table}
        rowKey="id"
        lazyLoadStatus={registerFormEntriesState.lazyloadStatus}
        onLazyLoad={handleLazyLoad}
        columns={registerFormEntriesState.tableColumns.map((col) => ({
          ...col,
          sorter: false,
          width: columnWidthData[col.dataIndex?.toString() || ""],
          title: (
            <>
              {col.title}
              <div
                className={clsx(
                  classes.resizeHandle,
                  resizingColumnKey === col.dataIndex?.toString() && "active"
                )}
                onMouseDown={() => {
                  handleResizingColumnChange(col.dataIndex?.toString());
                  window.addEventListener("mousemove", resizeListener, {
                    passive: true,
                  });
                }}
              />
            </>
          ),
        }))}
        dataSource={registerFormEntriesState.data}
        useDefaultHeight
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            routerHistory.push({
              pathname: `./form-entries/${record.id.toString()}`,
              state: { from: routerLocation.pathname },
            });
          },
        })}
      />
    </>
  );
};

export default WebColumnWidth;
