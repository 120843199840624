import React from "react";
import { RegisterCustomFormField } from "../../../services/registerCustomForm";
import { useDrag } from "react-dnd";
import { useTranslation } from "react-i18next";

interface FieldListProps {
  fields: RegisterCustomFormField[];
  useList: boolean[];
}

const FieldList: React.FC<FieldListProps> = ({ fields, useList }) => {
  const { t } = useTranslation();

  return (
    <div className="form-printable-left-panel">
      <div className="form-printable-drag-header">
        <div className="form-printable-drag-title">
          {t("FieldList.DragAndDrop")}
        </div>
      </div>
      <div className="form-printable-left-panel-inner">
        {fields.map((field, index) => {
          return (
            <PrintableComponent
              key={field["key"]}
              field={field}
              isUsed={useList[index]}
            />
          );
        })}
      </div>
    </div>
  );
};

export enum ItemType {
  component = "component",
}

interface PrintableComponentProps {
  field: RegisterCustomFormField;
  isUsed: boolean;
}

export const PrintableComponent: React.FC<PrintableComponentProps> = ({
  field,
  isUsed,
}) => {
  const [, drag] = useDrag({
    type: ItemType.component,
    item: { id: field.key },
  });

  return (
    <div
      ref={drag}
      key={field.key}
      className="form-printable-component"
      style={{
        opacity: isUsed ? 0.5 : 1,
      }}
    >
      <span className="form-printable-component-name">{field.key}</span>
      <span className="form-printable-component-type">{field.type}</span>
    </div>
  );
};

export default FieldList;
