import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import {
  getRegisterFlowList,
  RegisterFlow,
} from "../../../services/registerFlow";
import SetupFlowLayout from "./SetupFlowLayout";

interface SetupFlowContainerProps {}

const SetupFlowContainer: React.FC<SetupFlowContainerProps> = () => {
  const appContext = useAppContext();

  const history = useHistory();

  const { flowId: flowIdParam } = useParams<{ flowId: string }>();
  const activeFlowId = flowIdParam ? Number(flowIdParam) : undefined;

  const [registerFlows, setRegisterFlows] = useState<RegisterFlow[]>();

  useEffect(() => {
    getRegisterFlowList(appContext.serviceConfig).then((data) => {
      setRegisterFlows(data);
      if (data.length && !activeFlowId) {
        history.push(`flows/${data[0].id}`);
      }
    });
  }, [activeFlowId, appContext.serviceConfig, history]);

  const handleFlowCreated = (newFlow: RegisterFlow) => {
    setRegisterFlows((prevState) => [newFlow, ...(prevState || [])]);
    history.push(`${activeFlowId ? "" : "flows/"}${newFlow.id}`);
  };

  return (
    <AppAccess rolesAllowed="adminOnly" showWarning>
      <SetupFlowLayout
        activeFlowId={activeFlowId}
        registerFlows={registerFlows}
        onFlowCreated={handleFlowCreated}
      />
    </AppAccess>
  );
};

export default SetupFlowContainer;
