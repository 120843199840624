import { useTranslation } from "react-i18next";

import {
  NxpFormTable,
  NxpFormTableColumnProps,
  NxpHeader,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";

import { ValidationResult } from "../../../hooks/useValidate";
import { User } from "../../../services/app";
import { RoleAssignmentForm } from "../../../services/roleAssignment";
import AddRoleAssignmentButton from "./AddRoleAssignment/AddRoleAssignmentButton";

interface RoleAssignmentsLayoutProps {
  registerId?: number;
  roleAssignments: RoleAssignmentForm[];
  editItem?: RoleAssignmentForm;
  validationError: ValidationResult<RoleAssignmentForm>;
  saveInProgress: boolean;
  userList: User[];
  onRoleAssignmentAdded: (roleAssignment: RoleAssignmentForm) => void;
  onFormStateChange: (field: keyof RoleAssignmentForm, value: unknown) => void;
  onRowDelete: (deleteItem: RoleAssignmentForm) => void;
  onRowEdit: (editItem: RoleAssignmentForm) => void;
  onRowSave: () => void;
  onRowCancel: () => void;
}

const RoleAssignmentsLayout: React.FC<RoleAssignmentsLayoutProps> = ({
  registerId,
  roleAssignments,
  editItem,
  validationError,
  saveInProgress,
  userList,
  onRoleAssignmentAdded,
  onFormStateChange,
  onRowDelete,
  onRowEdit,
  onRowSave,
  onRowCancel,
}) => {
  const { t } = useTranslation();

  const getColumns = (
    userList: User[]
  ): NxpFormTableColumnProps<RoleAssignmentForm>[] => [
    {
      title: t("app.common.RoleCode"),
      dataIndex: "rolecode",
      width: 100,
      sorter: (a, b) => sorterForString(a.rolecode, b.rolecode),
      formItemProps: {
        controlType: "input",
      },
    },
    {
      title: t("app.common.Assignee"),
      dataIndex: "userids",
      width: 150,
      sorter: false,
      formItemProps: {
        controlType: "selectMultiple",
        controlProps: {
          allowClear: true,
          options: userList.map((user) => ({
            label: user.name,
            value: user.id.toString(),
          })),
        },
      },
    },
    {
      title: t("RoleAssignmentsLayout.UpdateOn"),
      dataIndex: "updatedOn",
      width: 100,
      sorter: (a, b) => sorterForDate(a.updated, b.updated),
      formItemProps: {
        controlType: "datePicker",
        editing: false,
      },
    },
  ];

  return (
    <div>
      <NxpHeader
        titleContent={
          registerId
            ? t("RoleAssignmentsLayout.RegisterRoleAssignment")
            : t("RoleAssignmentsLayout.EntityRoleAssignment")
        }
        actionContent={
          <AddRoleAssignmentButton
            disabled={!!editItem}
            registerId={registerId}
            existingRoleAssignments={roleAssignments}
            onRoleAssignmentAdded={onRoleAssignmentAdded}
          />
        }
      />
      <NxpFormTable
        rowKey="id"
        formState={editItem}
        onFormStateChange={onFormStateChange}
        validationError={validationError}
        useDefaultHeight
        saveInProgress={saveInProgress}
        itemCompareKey="id"
        onRowDelete={onRowDelete}
        onRowEdit={onRowEdit}
        onRowSave={onRowSave}
        onRowCancel={onRowCancel}
        columns={getColumns(userList)}
        dataSource={roleAssignments}
      />
    </div>
  );
};

export default RoleAssignmentsLayout;
