import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpFormTable,
  NxpFormTableColumnProps,
  NxpHeader,
  sorterForBoolean,
  sorterForNumber,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { Input } from "antd";
import Select, { LabeledValue } from "antd/lib/select";

import { ValidationResult } from "../../../hooks/useValidate";
import {
  RegisterDocument,
  RegisterDocumentForm,
} from "../../../services/registerDocument";
import { PRINT_FORM_FILE_TYPE } from "../../../utils/const";
import AddRegisterDocumentButton from "./AddRegisterDocument/AddRegisterDocumentButton";

const useStyles = createUseStyles((theme) => ({
  nameError: {
    color: theme.palette.warning,
    margin: 0,
  },
  nameSelect: {
    width: "100%",
  },
}));

interface SetupDocumentLayoutProps {
  registerDocuments: RegisterDocument[];
  editItem?: RegisterDocument;
  saveInProgress: boolean;
  validationError: ValidationResult<RegisterDocumentForm>;
  printFormOptions: LabeledValue[];
  fileExtensionOptions: LabeledValue[];
  onRowDelete: (doc: RegisterDocument) => void;
  onRowEdit: (doc: RegisterDocument) => void;
  onRowSave: () => void;
  onRowCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onRegisterDocumentAdded: () => void;
  onFormStateChange: (
    fieldName: keyof RegisterDocument,
    value: unknown
  ) => void;
}

const SetupDocumentLayout: React.FC<SetupDocumentLayoutProps> = ({
  editItem,
  saveInProgress,
  validationError,
  printFormOptions,
  fileExtensionOptions,
  registerDocuments,
  onRowDelete,
  onRowEdit,
  onRowSave,
  onRowCancel,
  onRegisterDocumentAdded,
  onFormStateChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns: NxpFormTableColumnProps<RegisterDocument>[] = [
    // {
    //   title: "id",
    //   dataIndex: "doc_id",
    //   width: 100,
    //   fixed: "left",
    //   sorter: (a, b) => sorterForNumber(a.doc_id, b.doc_id),
    // },
    {
      title: t("app.common.Name"),
      dataIndex: "doc_name",
      width: 150,
      sorter: (a, b) => sorterForString(a.doc_name, b.doc_name),
      formItemProps: {
        controlType: "custom",
        customContent: undefined,
      },
      render: (_, rec) =>
        editItem && editItem?.id === rec.id ? (
          <>
            {editItem.file_ext === PRINT_FORM_FILE_TYPE ? (
              <Select
                value={editItem.doc_name}
                options={printFormOptions || []}
                onChange={(val) => onFormStateChange("doc_name", val)}
                className={classes.nameSelect}
              />
            ) : (
              <Input
                value={editItem.doc_name}
                onChange={(e) => onFormStateChange("doc_name", e.target.value)}
              />
            )}
            {validationError.doc_name && (
              <p className={classes.nameError}>{validationError.doc_name}</p>
            )}
          </>
        ) : rec.file_ext === PRINT_FORM_FILE_TYPE ? (
          (printFormOptions || []).find((opt) => opt.value === rec.doc_name)
            ?.label
        ) : (
          rec.doc_name
        ),
    },
    {
      title: t("app.common.AllowFileExtension"),
      dataIndex: "file_ext",
      width: 100,
      sorter: (a, b) => sorterForString(a.file_ext, b.file_ext),
      formItemProps: {
        controlType: "select",
        controlProps: {
          allowClear: true,
          options: fileExtensionOptions,
        },
      },
    },
    {
      title: t("app.common.Mandatory"),
      dataIndex: "mandatory_flag",
      width: 100,
      sorter: (a, b) => sorterForBoolean(a.mandatory_flag, b.mandatory_flag),
      formItemProps: {
        controlType: "radioGroup",
        controlProps: {
          options: [
            {
              label: t("app.common.Yes"),
              value: true,
            },
            {
              label: t("app.common.No"),
              value: false,
            },
          ],
        },
      },
    },
    {
      title: t("app.common.FileOrder"),
      dataIndex: "file_ord",
      width: 100,
      sorter: (a, b) => sorterForNumber(a.file_ord, b.file_ord),
      formItemProps: {
        controlType: "select",
        controlProps: {
          options: Array(registerDocuments.length)
            .fill(null)
            .map((_, idx) => ({ value: idx + 1, label: idx + 1 })),
        },
      },
    },
  ];

  return (
    <div>
      <NxpHeader
        titleContent={t("SetupDocumentLayout.SetupDocument")}
        actionContent={
          <AddRegisterDocumentButton
            printFormOptions={printFormOptions}
            fileExtensionOptions={fileExtensionOptions}
            registerDocuments={registerDocuments}
            onDocumentAdded={onRegisterDocumentAdded}
          />
        }
      />
      <NxpFormTable
        formState={editItem}
        onFormStateChange={onFormStateChange}
        validationError={validationError}
        useDefaultHeight
        saveInProgress={saveInProgress}
        itemCompareKey="id"
        onRowDelete={onRowDelete}
        onRowEdit={onRowEdit}
        onRowSave={onRowSave}
        onRowCancel={onRowCancel}
        columns={columns}
        dataSource={registerDocuments}
      />
    </div>
  );
};

export default SetupDocumentLayout;
