import { Button, ButtonProps } from "antd";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { useState } from "react";

const useStyles = createUseStyles((theme) => ({
  nxpButton: {
    width: "40px",
    height: "40px",
    visibility: "visible",
    color: "#000",
    "&.active-selected": {
      backgroundColor: "#0078d4",
      fontWeight: "bolder",
      color: "#fff",
    },
  },
}));

export interface NxpToggleButtonProps extends ButtonProps {
  label: string;
  active: boolean;
  className?: string;
  onButtonClick: (state: boolean) => void;
}

const NxpToggleButton: React.FC<NxpToggleButtonProps> = ({
  active = false,
  label,
  className,
  onButtonClick,
}) => {
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState<boolean>(active);

  const handleButtonClick = (event: any) => {
    console.log(`Toogle Button: clicked!`);
    const value: boolean = !isSelected;
    console.log(`Toogle Button->isSelected: ${isSelected}`);
    console.log(`Toogle Button->isSelected (After): ${value}`);
    setIsSelected(value);
    onButtonClick(value);
  };

  return (
    <Button
      onClick={handleButtonClick}
      className={clsx(
        "nxpButton",
        classes.nxpButton,
        isSelected ? "active-selected" : undefined,
        className
      )}
    >
      {label}
    </Button>
  );
};

export default NxpToggleButton;
