import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import {
  createRegisterRuleTemplate,
  RegisterRuleTemplate,
  RegisterRuleTemplateForm,
} from "../../../../services/registerRule";
import notify from "../../../../utils/notify";

interface CreateTemplateModalProps {
  modalVisible: boolean;
  onModalClose: () => void;
  onTemplateCreated: (newTemplate: RegisterRuleTemplate) => void;
}

const CreateTemplateModal: React.FC<CreateTemplateModalProps> = ({
  modalVisible,
  onTemplateCreated,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    ruleTemplateName: "",
  };

  const [editForm, setEditForm] =
    useState<RegisterRuleTemplateForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const template = await createRegisterRuleTemplate(
        editForm,
        appContext.serviceConfig
      );
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onTemplateCreated(template);
    } catch (ex) {
      appContext.errorHandler(ex, "create rule template");
    } finally {
      setSaveInProgress(false);
    }
  };

  const { t } = useTranslation();

  const formSchema = yup.object({
    ruleTemplateName: yup
      .string()
      .nullable()
      .required(t("app.common.NameRequired")),
  });

  const [validationError, , , saveWithValidate] =
    useValidate<RegisterRuleTemplate>(
      editForm,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof RegisterRuleTemplate,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const formItems: NxpFormGridItemProps<RegisterRuleTemplateForm>[] = [
    {
      controlType: "input",
      label: t("CreateTemplateModal.TemplateName"),
      itemFieldName: "ruleTemplateName",
      required: true,
      span: 24,
    },
  ];

  return (
    <NxpModal
      width="smaller"
      title={t("CreateTemplateModal.CreateTemplate")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default CreateTemplateModal;
