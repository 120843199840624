import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FileOutlined } from "@ant-design/icons";
import { NxpFormGridItemProps } from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import { AppFileUpload } from "../../../components/AppFileUpload";
import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import { useValidate } from "../../../hooks/useValidate";
import { UploadFile } from "../../../services/file";
import {
  getRegisterDocumentList,
  RegisterDocument,
} from "../../../services/registerDocument";
import { RegisterFlowStep } from "../../../services/registerFlow";
import {
  createFormEntryApproval,
  FormEntryApproval,
  FormEntryApprovalForm,
  genApprovalDocPreview,
} from "../../../services/registerFormEntryApproval";
import notify from "../../../utils/notify";
import AddApprovalModalLayout from "./AddApprovalModalLayout";

interface AddApprovalModalContainerProps {
  formEntryId: number;
  formApprovalFlows: RegisterFlowStep[];
  modalVisible: boolean;
  onApprovalCreated: (approval: FormEntryApproval) => void;
  onModalClose: () => void;
}

const AddApprovalModalContainer: React.FC<AddApprovalModalContainerProps> = ({
  formEntryId,
  formApprovalFlows,
  modalVisible,
  onApprovalCreated,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    remarks: "",
    notifyUsers: [],
    approvalDocuments: [],
  };

  const [editForm, setEditForm] =
    useState<Partial<FormEntryApprovalForm>>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const [registerDocs] = useAsync(
    () =>
      getRegisterDocumentList(
        appContext.activeRegister!.id,
        appContext.serviceConfig
      ),
    [],
    "get documents setup for approval"
  );

  const [approvalDocPreview] = useAsync(
    () => genApprovalDocPreview(formEntryId, appContext.serviceConfig),
    [],
    "generate approval document preview"
  );

  // add print forms to input state here
  useEffect(() => {
    if (approvalDocPreview.data?.length && registerDocs.data?.length) {
      setEditForm((prevState) => {
        const approvalDocuments = [...prevState.approvalDocuments!];
        approvalDocPreview.data
          ?.filter((preview) => preview.previewFile?.url)
          .forEach((preview) => {
            approvalDocuments.push({
              file: { ...preview.previewFile, fileName: preview.doc_name },
              isPrintFormFile: true,
              fileCategory: preview.doc_name,
              registerDocumentId: preview.id,
            });
          });
        return { ...prevState, approvalDocuments };
      });
    }
  }, [approvalDocPreview, registerDocs]);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const approval = await createFormEntryApproval(
        formEntryId,
        editForm as FormEntryApprovalForm,
        appContext.serviceConfig
      );
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      onApprovalCreated(approval);
    } catch (ex) {
      appContext.errorHandler(ex, "create approval");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    remarks: yup.string().nullable(),
    notifyUsers: yup.array(yup.string()),
    approvalDocuments: yup.array(yup.object()).test({
      name: "mandatory documents",
      test: function (value) {
        const docIds: number[] = (registerDocs.data || [])
          .filter(
            (regDoc) =>
              regDoc.mandatory_flag &&
              !editForm.approvalDocuments?.find(
                (approvalDoc) => approvalDoc.registerDocumentId === regDoc.id
              )
          )
          .map((regDoc) => regDoc.id);

        return docIds.length
          ? this.createError({
              message: docIds.join("_"),
              path: "approvalDocuments", // Fieldname
            })
          : true;
      },
    }),
  });

  const [validationError, , , saveWithValidate] =
    useValidate<FormEntryApprovalForm>(
      editForm,
      formSchema,
      handleSaveValidated
    );

  const handleFormGridStateChange = (
    fieldName: keyof FormEntryApprovalForm,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const handleFileChange = (
    registerDoc: RegisterDocument,
    files: UploadFile[]
  ) => {
    setEditForm((prevState) => {
      let approvalDocuments = [...prevState.approvalDocuments!];

      const approvalDoc = approvalDocuments?.find(
        (item) => item.registerDocumentId === registerDoc.id
      );

      if (approvalDoc) {
        if (files.length) {
          approvalDoc.file = { ...files[0] };
        } else {
          approvalDocuments = approvalDocuments.filter(
            (item) => item.registerDocumentId !== registerDoc.id
          );
        }
      } else if (!approvalDoc && files.length) {
        approvalDocuments = [
          ...approvalDocuments,
          {
            registerDocumentId: registerDoc.id,
            isPrintFormFile: false,
            fileCategory: registerDoc.doc_name,
            file: { ...files[0] },
          },
        ];
      }
      return { ...prevState, approvalDocuments };
    });
  };

  const { t } = useTranslation();

  const step1FormItems: NxpFormGridItemProps<Partial<FormEntryApprovalForm>>[] =
    [
      {
        controlType: "textarea",
        label: "Remarks",
        itemFieldName: "remarks",
        span: 24,
      },
      {
        controlType: "selectMultiple",
        controlProps: {
          options: appContext.users.map((user) => ({
            label: user.name,
            value: user.id,
          })),
        },
        label: "Send Notification To",
        itemFieldName: "notifyUsers",
        span: 24,
      },
    ];

  // memorizing to avoid re-render loop
  const step2FormItems: NxpFormGridItemProps<object>[] = useMemo(
    () =>
      registerDocs.data?.map((doc) => {
        const printForm = approvalDocPreview.data?.find(
          (preview) => preview.id === doc.id && preview.previewFile?.url
        )?.previewFile;

        const error = validationError.approvalDocuments
          ?.split("_")
          .includes(doc.id.toString())
          ? `${doc.doc_name} ${t("AddApprovalModalContainer.required")}.`
          : undefined;

        return {
          controlType: "custom",
          itemFieldName: "custom",
          required: doc.mandatory_flag,
          customContent: printForm ? (
            <a href={printForm.url} target="_blank" rel="noreferrer">
              <FileOutlined /> {t("AddApprovalModalContainer.PrintFromFile")}
            </a>
          ) : appContext.activeRegister ? (
            <AppFileUpload
              registerId={appContext.activeRegister.id}
              hasError={!!error}
              token={appContext.serviceConfig.keyCloakToken!}
              onChange={(files) => handleFileChange(doc, files)}
              fileLimit={1}
              accept={
                doc.file_ext ? `.${doc.file_ext.toLowerCase()}` : undefined
              }
            />
          ) : null,
          error,
          label: doc.doc_name,
          span: 12,
        };
      }) || [],
    [
      appContext.activeRegister,
      appContext.serviceConfig.keyCloakToken,
      approvalDocPreview.data,
      registerDocs.data,
      t,
      validationError.approvalDocuments,
    ]
  );

  return appContext.activeRegister && appContext.activeUser ? (
    <AddApprovalModalLayout
      formEntryId={formEntryId}
      registerId={appContext.activeRegister.id}
      modalVisible={modalVisible}
      currentStep={currentStep}
      saveInProgress={saveInProgress}
      activeUserName={appContext.activeUser.name}
      validationError={{
        ...validationError,
        notifyUsers:
          validationError.notifyUsers &&
          Object.keys(validationError.notifyUsers).includes("0") // Yup is return object like { 0: "...", 1: "..."} for array validation, perhaps a new feature
            ? validationError.notifyUsers[0]
            : validationError.notifyUsers,
      }}
      step1FormItems={step1FormItems}
      step2FormItems={step2FormItems}
      editForm={editForm}
      formApprovalFlows={formApprovalFlows}
      onCurrentStepChange={setCurrentStep}
      onFormGridStateChange={handleFormGridStateChange}
      onModalSave={handleModalSave}
      onModalClose={onModalClose}
    />
  ) : null;
};

export default AddApprovalModalContainer;
