import React from "react";
import { useTranslation } from "react-i18next";

import { NxpButton, NxpFormItem, NxpModal } from "@nexploretechnology/nxp-ui";
import { Col, Form, Row } from "antd";
import { LabeledValue } from "antd/lib/select";

import { AppFileUpload } from "../../../components/AppFileUpload";
import useAppContext from "../../../hooks/useAppContext";
import { postChat, postChatFile } from "../../../services/approvalsChat"; //2nd file call should come from here
import { UploadFile } from "../../../services/file";
import { getRegisterDocumentList } from "../../../services/registerDocument";
import { RegisterFormEntry } from "../../../services/registerFormEntry";
import { FormEntryApproval } from "../../../services/registerFormEntryApproval";

interface ApprovalChatFileModalProps {
  userId: string;
  modalVisible: boolean;
  approval: FormEntryApproval;
  formEntry: RegisterFormEntry;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  callGetChats: () => void;
}

const ApprovalChatFileModal: React.FC<ApprovalChatFileModalProps> = (props) => {
  const {
    userId,
    modalVisible,
    setModalVisible,
    approval,
    callGetChats,
    formEntry,
  } = props;

  const appContext = useAppContext();

  const [docOptions, setDocOptions] = React.useState<LabeledValue[]>([]);

  const fetchDocuments = React.useCallback(() => {
    getRegisterDocumentList(
      approval.registerId,
      appContext.serviceConfig,
      appContext.activeUser?.keycloakUserId
    ).then((data) => {
      const docOptions: LabeledValue[] = [];
      data.forEach((d) => {
        if (d.file_ext !== "SYSTEM") {
          docOptions.push({
            label: d.doc_name,
            value: d.id, //there is a null doc_code floating around right now so using id for now...
          });
        }
      });
      setDocOptions(docOptions);
    });
  }, [appContext, approval.registerId]);

  React.useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const [fileTypeLabel, setFileTypeLabel] = React.useState<string>();
  const [setFileTypeValue] = React.useState<number>(); //using id for now, switch to doc_code? (use later to make send to approval docs table API)
  const [signedFile, setSignedFile] = React.useState<UploadFile>();
  const [fileDescription, setFileDescription] = React.useState<string>("");

  const { t } = useTranslation();

  return appContext.activeRegister ? (
    <NxpModal
      title={t("app.common.UploadFiles")}
      visible={modalVisible}
      onCancel={() => setModalVisible(false)}
      footer={
        <NxpButton
          onClick={() => {
            if (signedFile) {
              postChat(
                //@ts-ignore
                approval.registerId,
                appContext.serviceConfig,
                formEntry.id,
                approval.id,
                {
                  author: userId,
                  message: signedFile.fileName || "",
                }
              ).then((response) => {
                postChatFile(
                  approval.id,
                  response.id,
                  appContext.serviceConfig,
                  {
                    isPrintFormFile: false,
                    file: signedFile,
                    fileType: signedFile.mimeType ? signedFile.mimeType : "",
                    description: fileDescription,
                  }
                ).then(() => {
                  callGetChats();
                  setModalVisible(false);
                });
              });
            }
          }}
        >
          {t("app.common.Upload")}
        </NxpButton>
      }
    >
      <Form>
        <Row gutter={24}>
          <Col span={12}>
            <NxpFormItem
              controlType="select"
              controlProps={{
                showSearch: false,
                value: fileTypeLabel,
                options: docOptions,
                onChange: (e, v) => {
                  //@ts-ignore
                  setFileTypeLabel(v.label);
                  //@ts-ignore
                  setFileTypeValue(v.value);
                },
              }}
              label={t("app.common.fileType")}
            />
          </Col>
          <Col span={12}>
            <div className="chats-post-file-wrapper">
              <span className="chats-post-file-label">
                {t("app.common.FILE")}:
              </span>
              <AppFileUpload
                registerId={appContext.activeRegister.id}
                fileLimit={1}
                onChange={(file) => {
                  setSignedFile(file[0]);
                }}
                token={appContext.serviceConfig.keyCloakToken || ""}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <NxpFormItem
              controlType="input"
              label={t("app.common.Description")}
              controlProps={{
                value: fileDescription,
                onChange: (e) => {
                  setFileDescription(e.target.value);
                },
              }}
            />
          </Col>
        </Row>
      </Form>
    </NxpModal>
  ) : null;
};

export default ApprovalChatFileModal;
