import { CloseCircleFilled } from "@ant-design/icons";
import * as React from "react";
import "./Previewer.css";

interface ImagePreviewerProps {
  url: string;
  remove?: () => void;
}

const ImagePreviewerImpl: React.FC<ImagePreviewerProps> = ({ url, remove }) => {
  const handleThumbnailClick = React.useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  return (
    <div className="previewer-container">
      <div
        onClick={handleThumbnailClick}
        className="image-container"
        style={{ backgroundImage: `url('${url}')` }}
      />
      {remove && (
        <div className="previewer__close-button" onClick={remove}>
          <CloseCircleFilled />
        </div>
      )}
    </div>
  );
};

export const ImagePreviewer = ImagePreviewerImpl;
