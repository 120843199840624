import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { NxpButton } from "@nexploretechnology/nxp-ui";

import { AppListBuilderActiveItem } from "./AppListBuilder";

import "./AppListBuilderBase.css";

export interface AppListBuilderListItem {
  key: string;
  label: ReactNode;
}

interface AppListBuilderBaseProps<T> {
  leftHeader: ReactNode;
  leftItems: T[];
  rightHeader: ReactNode;
  rightItems: T[];
  listBuilderActiveItem?: AppListBuilderActiveItem;
  saveInProgress?: boolean;
  disableReorder?: boolean;
  onMoveToRight: (item: T, fromIndex: number, toIndex?: number) => void;
  onMoveToLeft: (item: T, fromIndex: number, toIndex?: number) => void;
  onReorder: (item: T, fromIndex: number, toIndex?: number) => void;
  onItemPropsRender: (item: T) => AppListBuilderListItem;
  onSortAscending: (a: T, b: T) => number;
  onSortDescending: (a: T, b: T) => number;
  onSave: () => void;
  onCancel: () => void;
}

function AppListBuilderBase<T>(props: AppListBuilderBaseProps<T>) {
  const {
    leftHeader,
    leftItems,
    rightHeader,
    rightItems,
    listBuilderActiveItem,
    saveInProgress,
    disableReorder,
    onMoveToRight,
    onMoveToLeft,
    onReorder,
    onSortAscending,
    onSortDescending,
    onItemPropsRender,
    onSave,
    onCancel,
  } = props;

  // for adding scroll bottom behavior after add click
  const rightListRef = useRef<HTMLUListElement>(null);

  const [sorting, setSorting] = useState<{
    type: "default" | "a-z" | "z-a";
    sortedList: T[];
  }>({
    type: "default",
    sortedList: leftItems,
  });

  const [activeItem, setActiveItem] = useState<{
    isLeftItem: boolean;
    itemIndex: number;
  }>();

  useEffect(() => {
    setActiveItem(listBuilderActiveItem);
  }, [listBuilderActiveItem]);

  useEffect(() => {
    switch (sorting.type) {
      case "default":
        setSorting((prevState) => ({
          ...prevState,
          sortedList: leftItems,
        }));
        break;
      case "a-z":
        setSorting((prevState) => ({
          ...prevState,
          sortedList: [...leftItems].sort(onSortAscending),
        }));
        break;
      case "z-a":
        setSorting((prevState) => ({
          ...prevState,
          sortedList: [...leftItems].sort(onSortDescending),
        }));
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting.type, leftItems]);

  const handleSortClick = () => {
    if (activeItem?.isLeftItem) {
      setActiveItem(undefined);
    }
    switch (sorting.type) {
      case "default":
        setSorting((prevState) => ({
          ...prevState,
          type: "a-z",
        }));
        break;
      case "a-z":
        setSorting((prevState) => ({
          ...prevState,
          type: "z-a",
        }));
        break;
      case "z-a":
        setSorting((prevState) => ({
          ...prevState,
          type: "default",
        }));
        break;
    }
  };

  const handleAddClick = () => {
    if (activeItem) {
      setActiveItem(undefined);
      onMoveToRight(leftItems[activeItem.itemIndex], activeItem.itemIndex);
      // run in next tick to ensure reaching bottom
      setTimeout(() =>
        rightListRef.current!.scrollTo(0, rightListRef.current!.scrollHeight)
      );
    }
  };

  const handleRemoveClick = () => {
    if (activeItem) {
      setActiveItem(undefined);
      onMoveToLeft(rightItems[activeItem.itemIndex], activeItem.itemIndex);
    }
  };

  const handleRemoveDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const isFromLeft = e.dataTransfer.getData("isFromLeft") === true.toString();
    if (!isFromLeft && activeItem) {
      setActiveItem(undefined);
      onMoveToLeft(rightItems[activeItem.itemIndex], activeItem.itemIndex);
      e.currentTarget.className = e.currentTarget.className.replace(
        / drag-over/g,
        ""
      );
    }
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement | HTMLLIElement>
  ) => {
    e.preventDefault();
    if (!e.currentTarget.className.includes(" drag-over")) {
      e.currentTarget.className += " drag-over";
    }
  };

  const handleDragLeave = (
    e: React.DragEvent<HTMLDivElement | HTMLLIElement>
  ) => {
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );
  };

  const handleItemDragStart = (
    e: React.DragEvent<HTMLLIElement>,
    fromIndex: number,
    isFromLeft: boolean
  ) => {
    if (isFromLeft && sorting.type !== "default") {
      // reset to index of original array instead of sorted one
      const findFromIndex = leftItems.indexOf(sorting.sortedList[fromIndex]);
      e.dataTransfer.setData("fromIndex", findFromIndex.toString());
    } else {
      e.dataTransfer.setData("fromIndex", fromIndex.toString());
    }

    e.dataTransfer.setData("isFromLeft", isFromLeft.toString());
  };

  const handleItemDrop = (
    e: React.DragEvent<HTMLLIElement | HTMLDivElement>,
    toIndex?: number
  ) => {
    const fromIndex = Number(e.dataTransfer.getData("fromIndex"));
    const isFromLeft = e.dataTransfer.getData("isFromLeft") === true.toString();
    e.currentTarget.className = e.currentTarget.className.replace(
      / drag-over/g,
      ""
    );
    if (disableReorder) {
      e.currentTarget.parentElement!.parentElement!.className =
        e.currentTarget.parentElement!.parentElement!.className.replace(
          / drag-over/g,
          ""
        );
    }

    if (isFromLeft) {
      onMoveToRight(leftItems[fromIndex], fromIndex, toIndex);
    } else {
      onReorder(rightItems[fromIndex], fromIndex, toIndex);
    }
  };

  const handleCancel = () => {
    setActiveItem(undefined);
    onCancel();
  };
  const { t } = useTranslation();
  return (
    <div className="app-list-builder-base">
      <div
        className="builder-list-wrapper"
        onDrop={handleRemoveDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <h3>
          {leftHeader}
          <NxpButton
            disabled={saveInProgress}
            onClick={handleSortClick}
            type="text"
          >
            {t("AppListBuilderBase.sort")}: {sorting.type.toUpperCase()}
          </NxpButton>
        </h3>
        <ul>
          {sorting.sortedList.map((item, idx) => {
            const itemProps = onItemPropsRender(item);
            return (
              <li
                draggable={true}
                onDragStart={(e: React.DragEvent<HTMLLIElement>) =>
                  handleItemDragStart(e, idx, true)
                }
                className={
                  activeItem?.isLeftItem === true &&
                  activeItem?.itemIndex === idx
                    ? "selected"
                    : undefined
                }
                onMouseDown={() =>
                  setActiveItem({ isLeftItem: true, itemIndex: idx })
                }
                key={itemProps.key}
              >
                {itemProps.label}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="builder-buttons">
        <NxpButton
          onClick={handleAddClick}
          disabled={activeItem?.isLeftItem !== true || saveInProgress}
        >
          {t("app.common.Add")}
        </NxpButton>
        <NxpButton
          onClick={handleRemoveClick}
          disabled={activeItem?.isLeftItem !== false || saveInProgress}
        >
          {t("AppListBuilderBase.Remove")}
        </NxpButton>
      </div>
      <div
        className="builder-list-wrapper"
        onDragOver={disableReorder ? handleDragOver : undefined}
        onDragLeave={disableReorder ? handleDragLeave : undefined}
        onDrop={
          disableReorder
            ? (e: React.DragEvent<HTMLDivElement>) =>
                handleItemDrop(e, rightItems.length)
            : undefined
        }
      >
        <h3>{rightHeader}</h3>
        <ul className="with-list-buttons" ref={rightListRef}>
          {rightItems.map((item, idx) => {
            const itemProps = onItemPropsRender(item);
            return (
              <li
                draggable={true}
                onDrop={
                  disableReorder
                    ? undefined
                    : (e: React.DragEvent<HTMLLIElement>) =>
                        handleItemDrop(e, idx)
                }
                onDragStart={(e: React.DragEvent<HTMLLIElement>) =>
                  handleItemDragStart(e, idx, false)
                }
                onDragLeave={handleDragLeave}
                onDragOver={disableReorder ? undefined : handleDragOver}
                className={
                  activeItem?.isLeftItem === false &&
                  activeItem?.itemIndex === idx
                    ? "selected"
                    : undefined
                }
                onMouseDown={() => {
                  setActiveItem({ isLeftItem: false, itemIndex: idx });
                }}
                key={itemProps.key}
              >
                {itemProps.label}
              </li>
            );
          })}
          {!disableReorder && (
            <li
              className="drop-placeholder"
              onDrop={(e: React.DragEvent<HTMLLIElement>) =>
                handleItemDrop(e, undefined)
              }
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
            ></li>
          )}
        </ul>
        <div className="list-buttons">
          {disableReorder ? null : (
            <>
              <NxpButton
                onClick={() =>
                  onReorder(
                    rightItems[activeItem!.itemIndex],
                    activeItem!.itemIndex,
                    activeItem!.itemIndex - 1
                  )
                }
                disabled={
                  activeItem?.isLeftItem !== false ||
                  activeItem.itemIndex === 0 ||
                  saveInProgress
                }
              >
                ▲
              </NxpButton>
              <NxpButton
                onClick={() =>
                  onReorder(
                    rightItems[activeItem!.itemIndex],
                    activeItem!.itemIndex,
                    activeItem!.itemIndex + 2
                  )
                }
                disabled={
                  activeItem?.isLeftItem !== false ||
                  activeItem.itemIndex === rightItems.length - 1 ||
                  saveInProgress
                }
              >
                ▼
              </NxpButton>
            </>
          )}

          <div className="right">
            <NxpButton
              disabled={saveInProgress}
              onClick={handleCancel}
              type="default"
            >
              {t("app.common.Reset")}
            </NxpButton>
            <NxpButton disabled={saveInProgress} onClick={onSave}>
              {t("app.common.Save")}
            </NxpButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppListBuilderBase;
