// @ts-nocheck

///////////////////////////////////////////////// https://codesandbox.io/s/81qb6?file=/src/Custom/Toggle/index.js:1919-1931

import { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./SelectUser.form";

import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { getUsers } from "../../../services/app";
import AppContext from "../../App/AppContext";
import keycloakInstance from "../../App/keycloak";

import { registerIdMatch } from "../registerIdMatch";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */

const checkUserRemoved = (formEntryUser, userOptions) => {
  // formio sometime pass in empty array for formEntryUser in setup page
  if (
    (Array.isArray(formEntryUser) && formEntryUser.length === 0) ||
    !formEntryUser ||
    !formEntryUser.id
  ) {
    return false;
  }

  return !!(
    formEntryUser && !userOptions.find((opt) => opt.value === formEntryUser.id)
  );
};

const SelectUserInternal = class extends Component {
  constructor(props) {
    super(props);

    console.log(`SelectUserInternal(): Enter`);
    getUsers(props.serviceConfig.entityId, props.serviceConfig).then((users) =>
      this.setState({
        options: users
          .sort((a, b) => {
            let comparison: int = 0;
            if (a.name > b.name) {
              comparison = 1;
            } else if (a.name < b.name) {
              comparison = -1;
            }
            return comparison;
          })
          .map((user) => ({
            label: `${user.name} (${user.primaryEmail})`,
            value: user.id,
          })),
      })
    );

    this.state = {
      value: props.value,
      options: [],
    };
  }

  render() {
    return (
      <Select
        disabled={this.props.disabled}
        style={{ width: "100%" }}
        suffixIcon={<CaretDownOutlined />}
        className="appFormioSelectUser"
        options={[
          {
            label: "",
            value: "",
          },
          ...this.state.options,
          // add removed user below
          ...(checkUserRemoved(this.state.value, this.state.options)
            ? [
                {
                  label: `${this.state.value.name} (user no longer exists)`,
                  value: `Removed_${this.state.value.id}`,
                  disabled: true,
                },
              ]
            : []),
        ]}
        value={
          checkUserRemoved(this.state.value, this.state.options)
            ? `Removed_${this.state.value.id}`
            : this.state.value && this.state.value.id
        }
        onChange={(_, opt) =>
          this.setState(
            {
              value: opt.value
                ? { id: opt.value, name: opt.label.split("(")[0].trim() }
                : null,
            },
            () => this.props.onChange(null, { changed: true })
          )
        }
      />
    );
  }
};

export default class SelectUser extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Select User",
      icon: "user",
      group: "Data",
      documentation: "",
      weight: 10,
      schema: SelectUser.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "selectUser",
      label: "", //default label, if any
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    const [, entityId] = window.location.href.match(/entities\/([\w-]+)/);

    const registerId = registerIdMatch();

    return ReactDOM.render(
      <SelectUserInternal
        disabled={this.component.disabled}
        userId={this.component.userId}
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        serviceConfig={{
          entityId: entityId,
          registerId: Number(registerId),
          keyCloakToken: keycloakInstance.token,
          apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
        }}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  static contextType = AppContext;
}
