import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  NxpButton,
  NxpDescriptionList,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import { Steps } from "antd";

import { ValidationResult } from "../../../hooks/useValidate";
import { RegisterFlowStep } from "../../../services/registerFlow";
import { FormEntryApprovalForm } from "../../../services/registerFormEntryApproval";
import FormEntryKeyInformation from "../FormEntryKeyInformation";

const useStyles = createUseStyles((theme) => ({
  steps: {
    "&.ant-steps": {
      margin: `0 auto ${theme.spacing(3)}`,
      width: "70%",
      "& div.ant-steps-item": {
        "& div.ant-steps-item-content > div.ant-steps-item-title": {
          fontSize: theme.fontSize.main,
          fontWeight: theme.fontWeight.bold,
          color: theme.palette.primary,
        },
        "&.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after":
          {
            backgroundColor: theme.palette.borderLight,
          },
        "& .ant-steps-item-icon": {
          lineHeight: "31px",
        },
        "&.ant-steps-item-wait": {
          "& .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after":
            {
              backgroundColor: theme.palette.borderLight,
            },
          "& .ant-steps-item-icon": {
            borderColor: theme.palette.dim,
          },
          "& .ant-steps-icon": {
            color: theme.palette.dim,
          },
          "& div.ant-steps-item-content > div.ant-steps-item-title": {
            color: theme.palette.dim,
          },
        },
        "&.ant-steps-item-finish": {
          "& .ant-steps-item-icon": {
            borderColor: theme.palette.success,
          },
          "& .ant-steps-icon": {
            color: theme.palette.success,
          },
          "& div.ant-steps-item-content > div.ant-steps-item-title": {
            color: theme.palette.success,
          },
        },
      },
    },
  },
  keyInfo: {
    padding: `${theme.spacing(2)} 2px`,
    borderBottom: `1px solid ${theme.palette.borderLight}`,
    borderTop: `1px solid ${theme.palette.borderLight}`,
    marginBottom: theme.spacing(2),
  },
  docHeader: {
    marginBottom: theme.spacing(2),
    fontSize: theme.fontSize.h6,
  },
  flowHeader: {
    margin: theme.spacing(3, 0, 2),
    fontSize: theme.fontSize.h6,
  },
  backButton: {
    float: "left",
  },
}));

interface AddApprovalModalLayoutProps {
  formEntryId: number;
  registerId: number;
  modalVisible: boolean;
  currentStep: number;
  saveInProgress: boolean;
  formApprovalFlows: RegisterFlowStep[];
  activeUserName: string;
  step1FormItems: NxpFormGridItemProps<Partial<FormEntryApprovalForm>>[];
  step2FormItems: NxpFormGridItemProps<object>[];
  editForm: Partial<FormEntryApprovalForm>;
  validationError: ValidationResult<FormEntryApprovalForm>;
  onCurrentStepChange: (step: number) => void;
  onFormGridStateChange: (
    fieldName: keyof FormEntryApprovalForm,
    value: unknown
  ) => void;
  onModalSave: () => void;
  onModalClose: () => void;
}

const AddApprovalModalLayout: React.FC<AddApprovalModalLayoutProps> = ({
  formEntryId,
  registerId,
  modalVisible,
  currentStep,
  saveInProgress,
  activeUserName,
  step1FormItems,
  step2FormItems,
  editForm,
  validationError,
  formApprovalFlows,
  onCurrentStepChange,
  onFormGridStateChange,
  onModalSave,
  onModalClose,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <NxpModal
      title={t("app.common.RequestApproval")}
      width="large"
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        currentStep === 0 ? (
          <NxpButton onClick={() => onCurrentStepChange(1)}>
            {t("AddApprovalModalLayout.Next")}
          </NxpButton>
        ) : (
          <>
            <NxpButton
              disabled={saveInProgress}
              className={classes.backButton}
              onClick={() => onCurrentStepChange(0)}
              type="default"
            >
              {t("app.common.Back")}
            </NxpButton>
            <NxpButton loading={saveInProgress} onClick={onModalSave}>
              {t("app.common.Save")}
            </NxpButton>
          </>
        )
      }
    >
      <Steps className={classes.steps} current={currentStep}>
        <Steps.Step key={0} title={t("app.common.KeyInformation")} />
        <Steps.Step
          key={1}
          title={t("AddApprovalModalLayout.ApprovalDocument")}
        />
      </Steps>

      {/* step one */}
      <div hidden={currentStep === 1}>
        <div className={classes.keyInfo}>
          <FormEntryKeyInformation
            formEntryId={formEntryId}
            registerId={registerId}
          />
        </div>
        <NxpFormGrid
          validationError={validationError}
          formItems={step1FormItems}
          formState={editForm}
          onFormStateChange={onFormGridStateChange}
        />
      </div>

      {/* step two */}
      <div hidden={currentStep === 0}>
        {step2FormItems.length > 0 && (
          <>
            <h4 className={classes.docHeader}>
              {t("AddApprovalModalLayout.ApprovalDocuments")}
            </h4>
            <NxpFormGrid formItems={step2FormItems} />
          </>
        )}
        <h4 className={classes.flowHeader}>{t("app.common.ApprovalFlow")}</h4>
        <NxpDescriptionList headerWidth={175}>
          {/* added due to CPCS-986 */}
          {formApprovalFlows.map((step, index) => {
            return (
              <React.Fragment key={step.id}>
                <dt>{step.roleCodes[0]}</dt>
                {index === 0 ? (
                  <dd>{activeUserName}</dd>
                ) : (
                  <dd>
                    {step.approvingUsers &&
                      step.approvingUsers
                        .map((user) => `${user.firstName} ${user.lastName}`)
                        .join(", ")}
                  </dd>
                )}
              </React.Fragment>
            );
          })}
        </NxpDescriptionList>
      </div>
    </NxpModal>
  );
};

export default AddApprovalModalLayout;
