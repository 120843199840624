import NxpToggleButton from "./NxpToggleButton";
import NxpColorPicker from "./NxpColorPicker";
import { useEffect, useState } from "react";

export interface NxpFontFormatterProps {
  isBold: boolean;
  isItalic: boolean;
  fontColor: string;
  onFontElementsChanged: (
    bold: boolean,
    italic: boolean,
    color: string
  ) => void;
}

const NxpFontFormatter: React.FC<NxpFontFormatterProps> = ({
  isBold = false,
  isItalic = false,
  onFontElementsChanged,
  fontColor,
}) => {
  const [isElementBold, setElementBold] = useState<boolean>(isBold);
  const [isElementItalic, setElementItalic] = useState<boolean>(isItalic);
  const [color, setColor] = useState<string>(fontColor);

  useEffect(() => {
    onFontElementsChanged(isElementBold, isElementItalic, color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElementBold, isElementItalic, color]);

  const handleElementBoldChange = (state: boolean) => setElementBold(state);

  const handleElementItalicHcnage = (state: boolean) => setElementItalic(state);

  const handleElementColorChange = (color: string) => setColor(color);

  return (
    <>
      <NxpToggleButton
        label="B"
        active={isElementBold}
        onButtonClick={handleElementBoldChange}
      />
      <NxpToggleButton
        label="I"
        active={isElementItalic}
        onButtonClick={handleElementItalicHcnage}
      />
      <NxpColorPicker color={color} onColorChange={handleElementColorChange} />
    </>
  );
};

export default NxpFontFormatter;
