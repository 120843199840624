import { apiRequest, ServiceConfig } from "../utils/backend";
import { RegisterCustomForm } from "./registerCustomForm";

export interface RegisterListingAvailableField {
  key: string;
  label: string;
}

export interface RegisterListingConfigItem {
  key: string;
  webListing: {
    disabled: boolean;
    order: number;
    width?: number;
  };
  approvedRequest: {
    disabled: boolean;
    order: number;
  };
  appListing: {
    disabled: boolean;
    order: number;
  };
  confidential: { disabled: boolean };
}

export interface RegisterListingConfig {
  layout?: RegisterListingConfigItem[];
}

export const getRegisterListingAvailableFields = async (
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<{ form_cpcs: RegisterCustomForm }[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/setup/app_config_available_to_select`,
    serviceConfig
  );
};

export const getRegisterListingConfig = async (
  registerId: number,
  serviceConfig: ServiceConfig,
  override?: string
) => {
  return await apiRequest<RegisterListingConfig>(
    `/powerflow/entities/${
      serviceConfig.entityId
    }/registers/${registerId}/setup/app_config_selected${
      override ? "?o=" + override : ""
    }`,
    serviceConfig
  ).then((data) => {
    // handle null return from v6 api
    return data || {};
  });
};

export const createRegisterListingConfig = async (
  registerListingConfig: RegisterListingConfig,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterListingConfig>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/setup/app_config_selected`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(registerListingConfig),
    }
  );
};

export const updateRegisterListing = async (
  registerListingConfig: RegisterListingConfig,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterListingConfig>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/setup/app_config_selected`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(registerListingConfig),
    }
  );
};
