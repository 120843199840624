import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormItem,
  NxpHeader,
  NxpTable,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { TableColumnProps } from "antd";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { useAsync } from "../../../hooks/useAsync";
import {
  getRegisterRoleAccess,
  RegisterRoleAccess,
  RegisterRoleAccessFormItem,
  RoleCode,
  updateRegisterRoleAccess,
} from "../../../services/access";
import notify from "../../../utils/notify";

interface SetupAccessProps {}

const SetupAccess: React.FC<SetupAccessProps> = () => {
  const appContext = useAppContext();

  const [isSaving, setIsSaving] = useState(false);

  const [registerAccessListAsync] = useAsync(
    () => getRegisterRoleAccess(appContext.serviceConfig),
    [],
    "get register access list"
  );

  const [registerAccessFormItems, setRegisterAccessFormItems] = useState<
    RegisterRoleAccessFormItem[]
  >([]);

  const onRoleMembersChange = (roleCode: RoleCode, members: string[]) => {
    setRegisterAccessFormItems((prevState) =>
      prevState.map((item) =>
        item.roleCode === roleCode ? { ...item, members } : item
      )
    );
  };

  const handleReset = useCallback(
    () =>
      setRegisterAccessFormItems(
        registerAccessListAsync.data?.map((access) => ({
          roleCode: access.roleCode,
          members: access.members.map((member) => member.id),
        })) || []
      ),
    [registerAccessListAsync.data]
  );

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateRegisterRoleAccess(
        registerAccessFormItems,
        appContext.serviceConfig
      );
      notify.actionCompleted();
    } catch (ex) {
      appContext.errorHandler(ex, "update register access");
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (!registerAccessListAsync.loading) {
      handleReset();
    }
  }, [handleReset, registerAccessListAsync]);

  const { t } = useTranslation();

  const columns: TableColumnProps<RegisterRoleAccess>[] = [
    {
      title: t("SetupAccess.Role"),
      dataIndex: "name",
      width: 100,
      sorter: (a, b) => sorterForString(a.name, b.name),
    },
    {
      title: t("SetupAccess.Users"),
      dataIndex: "members",
      width: 100,
      sorter: false,
      render: (_, rec) => (
        <NxpFormItem
          editing
          controlType="selectMultiple"
          controlProps={{
            value:
              registerAccessFormItems.find(
                (item) => item.roleCode === rec.roleCode
              )?.members || [],
            options: appContext.users.map((user) => ({
              label: user.name,
              value: user.id,
            })),
            onChange: (members) =>
              onRoleMembersChange(rec.roleCode, members as string[]),
          }}
        />
      ),
    },
  ];

  return (
    <div>
      <AppAccess rolesAllowed="adminOnly" showWarning>
        <NxpHeader
          titleContent={t("SetupAccess.SetupAccess")}
          actionContent={
            <>
              <NxpButton disabled={isSaving} type="text" onClick={handleReset}>
                {t("app.common.Reset")}
              </NxpButton>
              <NxpButton loading={isSaving} onClick={handleSave}>
                {t("app.common.Save")}
              </NxpButton>
            </>
          }
        />
        <NxpTable
          dataSource={registerAccessListAsync.data}
          columns={columns}
          rowKey="id"
          useDefaultHeight
        />
      </AppAccess>
    </div>
  );
};

export default SetupAccess;
