import { apiRequest, ServiceConfig } from "../utils/backend";

export interface RoleAssignment {
  users?: [
    {
      id: string;
      username: string;
    }
  ];
  id: number;
  rolecode: string;
  createdOn: Date;
  updatedOn: Date;
}
export interface RoleAssignmentForm
  extends Omit<RoleAssignment, "id" | "createdOn" | "updatedOn" | "users"> {
  id?: number;
  userids: string[];
  updated?: Date;
}

export const getRoleAssignmentList = async (
  serviceConfig: ServiceConfig,
  registerId?: number
) => {
  return await apiRequest<RoleAssignment[]>(
    registerId
      ? `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/reg_roles`
      : `/powerflow/entities/${serviceConfig.entityId}/module_roles`,
    serviceConfig
  );
};

export const createRoleAssignment = async (
  roleAssignmentForm: RoleAssignmentForm,
  serviceConfig: ServiceConfig,
  registerId?: number
) => {
  return await apiRequest<RoleAssignmentForm>(
    registerId
      ? `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/reg_roles`
      : `/powerflow/entities/${serviceConfig.entityId}/module_roles`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(roleAssignmentForm),
    }
  );
};

export const updateRoleAssignment = async (
  roleId: number,
  roleAssignmentForm: RoleAssignmentForm,
  serviceConfig: ServiceConfig,
  registerId?: number
) => {
  return await apiRequest<RoleAssignment>(
    registerId
      ? `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/reg_roles/${roleId}`
      : `/powerflow/entities/${serviceConfig.entityId}/module_roles/${roleId}`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(roleAssignmentForm),
    }
  );
};

export const deleteRoleAssignment = async (
  roleId: number,
  serviceConfig: ServiceConfig,
  registerId?: number
) => {
  return await apiRequest(
    registerId
      ? `/powerflow/entities/${serviceConfig.entityId}/registers/${registerId}/reg_roles/${roleId}`
      : `/powerflow/entities/${serviceConfig.entityId}/module_roles/${roleId}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
