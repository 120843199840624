import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../hooks/useAppContext";
import { useValidate } from "../../hooks/useValidate";
import {
  createRegister,
  Register,
  RegisterSetupForm,
} from "../../services/register";
import notify from "../../utils/notify";

interface AddRegisterModalProps {
  modalVisible: boolean;
  onModalClose: () => void;
}

const AddRegisterModal: React.FC<AddRegisterModalProps> = ({
  modalVisible,
  onModalClose,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    register_name: "",
    last_sequential_num: 0,
  };

  const [editForm, setEditForm] = useState<RegisterSetupForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const register = await createRegister(editForm, appContext.serviceConfig);
      setSaveInProgress(false);
      notify.actionCompleted();
      setEditForm(initFormValues);
      appContext.onAppContextCacheItemUpdate("registers", [
        ...(appContext.registers || []),
        {
          register: {
            id: register.id,
            register_name: register.register_name,
            owner: { ...register.owner },
          },
          permissions: {
            "register-entry:create": true,
            "register-entry:delete": true,
            "register-entry:read": true,
            "register-entry:special": true,
            "register-entry:update": true,
            "register:admin": true,
            "register:setup": true,
          },
        },
      ]);
      onModalClose();
    } catch (ex) {
      appContext.errorHandler(ex, "create register");
    } finally {
      setSaveInProgress(false);
    }
  };

  const formSchema = yup.object({
    register_name: yup.string().nullable().required("Name required."),
    owner: yup.string().nullable().required("Owner required."),
    last_sequential_num: yup
      .number()
      .nullable()
      .required("Last Sequential Number required."),
    type: yup.string().nullable().required("Register Type required."),
    flag_workspace: yup.boolean().nullable().required("Option required."),
  });

  const [validationError, , , saveWithValidate] =
    useValidate<RegisterSetupForm>(editForm, formSchema, handleSaveValidated);

  const handleFormGridStateChange = (
    fieldName: keyof Register,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    saveWithValidate(undefined);
  };

  const { t } = useTranslation();

  const formItems: NxpFormGridItemProps<RegisterSetupForm>[] = [
    {
      controlType: "input",
      label: t("app.common.Name"),
      itemFieldName: "register_name",
      required: true,
      span: 24,
    },
    {
      controlType: "textarea",
      label: t("app.common.Description"),
      itemFieldName: "description",
      span: 24,
    },
    {
      controlType: "select",
      controlProps: {
        options: appContext.users.map((opt) => ({
          label: opt.name,
          value: opt.id,
        })),
      },
      label: t("app.common.Owner"),
      itemFieldName: "owner",
      span: 12,
      required: true,
    },
    {
      controlType: "select",
      controlProps: {
        options: appContext.registerTypes,
      },
      label: t("app.common.RegisterType"),
      itemFieldName: "type",
      span: 12,
      required: true,
    },
    {
      controlType: "radioGroup",
      controlProps: {
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      label: t("app.common.Disabled"),
      itemFieldName: "disabled",
      span: 12,
    },
    {
      controlType: "inputNumber",
      controlProps: {
        decimalPlace: 0,
      },
      label: t("app.common.LastSequentialNumber"),
      itemFieldName: "last_sequential_num",
      span: 12,
      required: true,
    },
    {
      controlType: "radioGroup",
      label: t("app.common.AddToWorkspace"),
      itemFieldName: "flag_workspace",
      required: true,
      controlProps: {
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      span: 12,
    },
    {
      controlType: "radioGroup",
      controlProps: {
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      label: t("AddRegisterModal.ConsolidatedView"),
      itemFieldName: "flag_consolidated",
      span: 12,
    },
    {
      controlType: "radioGroup",
      controlProps: {
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      label: t("app.common.SeparateQueue"),
      itemFieldName: "flag_seperate_queue",
      span: 12,
    },
  ];

  return (
    <NxpModal
      title={t("AddRegisterModal.AddRegister")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddRegisterModal;
