import React, { ReactNode } from "react";

import useAppContext from "../../hooks/useAppContext";
import { AccessType, RoleCode } from "../../services/access";

interface AppAccessProps {
  rolesAllowed: RoleCode[] | AccessType;
  showWarning?: boolean;
  children: ReactNode;
}

const AppAccess: React.FC<AppAccessProps> = ({
  rolesAllowed,
  showWarning,
  children,
}) => {
  const { registerRolesOfCurrentUser, hasAccess } = useAppContext();

  return (
    <>
      {(!Array.isArray(rolesAllowed) && hasAccess(rolesAllowed)) ||
      (Array.isArray(rolesAllowed) &&
        rolesAllowed.some((r) => registerRolesOfCurrentUser?.includes(r))) ? (
        children
      ) : showWarning ? (
        <h2>Access Denied</h2>
      ) : null}
    </>
  );
};

export default AppAccess;
