import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButtonWithConfirm,
  NxpDateDisplay,
  NxpPanel,
  NxpTable,
  sorterForBoolean,
  sorterForDate,
  sorterForString,
} from "@nexploretechnology/nxp-ui";
import { ColumnProps } from "antd/lib/table";
import mime from "mime-types";

import useAppContext from "../../hooks/useAppContext";
import {
  FormEntryApproval,
  FormEntryApprovalStep,
  widthdrawFormEntryApproval,
} from "../../services/registerFormEntryApproval";
import { DateFormatPattern } from "../../utils/date/formatDate";
import notify from "../../utils/notify";
import FormEntryKeyInformation from "../RegisterFormEntryDetails/FormEntryKeyInformation";
import ApprovalUpdateButton from "./ApprovalUpdateModal/ApprovalUpdatelButton";

import styles from "./ApprovalOverview.module.css";

interface ApprovalOverviewProps {
  approval: FormEntryApproval;
  onApprovalUpdated: () => void;
}

const ApprovalOverview: React.FC<ApprovalOverviewProps> = ({
  approval,
  onApprovalUpdated,
}) => {
  const appContext = useAppContext();
  const { t } = useTranslation();

  const flowColumns: ColumnProps<FormEntryApprovalStep>[] = [
    {
      title: t("ApprovalOverview.Step"),
      dataIndex: ["stepName"],
      width: 100,
      render: (val) => {
        return val;
      },
    },
    {
      title: t("ApprovalOverview.Approver"),
      dataIndex: ["approvers"],
      width: 100,
      render: (_, rec) => {
        return getApproverNode(rec);
      },
    },
    {
      title: t("app.common.Status"),
      dataIndex: ["stepApproved"],
      width: 100,
      sorter: (a, b) => sorterForBoolean(a.stepApproved, b.stepApproved),
      render: (stepApproved, rec) => {
        // console.log(
        //   "AAAAAAAAAAAA",
        //   approval.pendingApprovers.find(
        //     (approver) => approver.id === appContext.activeUser?.id
        //   ) !== undefined
        // );

        // console.log(
        //   "BBBBBBBBBBBBB",
        //   (rec.delegates?.length > 0 &&
        //     rec.delegates.find(
        //       (delegate) => delegate.id === appContext.activeUser?.id
        //     )) !== undefined
        // );

        // console.log("DDDDDDDDDDDD", rec.id === approval.currentStepId);

        // console.log(
        //   "============",
        //   (approval.pendingApprovers.find(
        //     (approver) => approver.id === appContext.activeUser?.id
        //   ) !== undefined ||
        //     (rec.delegates?.length > 0 &&
        //       rec.delegates.find(
        //         (delegate) => delegate.id === appContext.activeUser?.id
        //       )) !== undefined) &&
        //     rec.id === approval.currentStepId,
        //   stepApproved,
        //   rec.stepStatus?.statusName
        // );

        if (stepApproved) {
          return rec.stepStatusId && rec.order === 1
            ? rec.stepStatus.statusName
            : t("ApprovalOverview.Approved");
        } else {
          if (rec.stepRejected) {
            return t("ApprovalOverview.Rejected");
          } else if (rec.stepWithdrawn) {
            return t("ApprovalOverview.Withdrawn");
          } else if (rec.stepReleased) {
            return t("ApprovalOverview.Released");
          } else if (
            // check current user can approval in this step
            rec.id === approval.currentStepId &&
            (approval.pendingApprovers.find(
              (approver) => approver.id === appContext.activeUser?.id
            ) !== undefined ||
              // or delegate
              rec.delegates?.find(
                (delegate) => delegate.id === appContext.activeUser?.id
              ) !== undefined)
          ) {
            return (
              <ApprovalUpdateButton
                approval={approval}
                onApprovalUpdated={onApprovalUpdated}
              />
            );
          } else {
            return t("app.common.Waiting");
          }
        }
      },
    },
    {
      title: t("ApprovalOverview.StartOn"),
      dataIndex: "startedOn",
      width: 100,
      sorter: (a, b) => sorterForDate(a.startedOn, b.startedOn),
      render: (val) => (
        <NxpDateDisplay date={val} format={DateFormatPattern.dateWithTime} />
      ),
    },
    {
      title: t("ApprovalOverview.RespondOn"),
      dataIndex: "completedOn",
      width: 100,
      sorter: (a, b) => sorterForDate(a.completedOn, b.completedOn),
      render: (val) => (
        <NxpDateDisplay date={val} format={DateFormatPattern.dateWithTime} />
      ),
    },
    {
      title: t("app.common.Remarks"),
      dataIndex: "remarks",
      width: 150,
      sorter: (a, b) => sorterForString(a.remarks, b.remarks),
    },
  ];

  const attachmentColumns: ColumnProps<
    FormEntryApproval["approvalDocuments"][0]
  >[] = [
    {
      title: t("app.common.FileName"),
      dataIndex: ["file", "fileName"],
      width: 150,
      fixed: "left",
      sorter: (a, b) => sorterForString(a.file?.fileName, b.file?.fileName),
      render: (_, record) => (
        <a href={record.file?.url} target="_blank" rel="noreferrer">
          {record.file?.fileName}
        </a>
      ),
    },
    {
      title: t("app.common.FileType"),
      dataIndex: ["file", "mimeType"],
      width: 100,
      sorter: (a, b) =>
        sorterForString(
          mime.extension(a.file?.mimeType || "") || "",
          mime.extension(b.file?.mimeType || "") || ""
        ),
      render: (val) => (mime.extension(val) || "").toString().toUpperCase(),
    },
    {
      title: t("ApprovalOverview.UploadDate"),
      dataIndex: "updatedOn",
      width: 100,
      sorter: (a, b) => sorterForDate(a.updatedOn, b.updatedOn),
      render: (val) => <NxpDateDisplay date={val} />,
    },
  ];

  const [withdrawLoading, setWithdrawLoading] = useState(false);

  const handleConfirmWithdraw = async () => {
    try {
      setWithdrawLoading(true);
      await widthdrawFormEntryApproval(
        approval.id,
        approval.entryId,
        "",
        appContext.serviceConfig
      );
      onApprovalUpdated();
      notify.actionCompleted();
    } catch (ex) {
      appContext.errorHandler(ex, "withdraw approval");
    } finally {
      setWithdrawLoading(false);
    }
  };

  function getApproverNode(step: FormEntryApprovalStep) {
    if (step.approvingUsers !== null) {
      return (
        <>
          <div>
            {step.approvingUsers
              .map((user) => {
                return user.firstName + " " + user.lastName;
              })
              .join(", ")}
          </div>
          {step.delegates?.length > 0 ? (
            <div className={styles["tiny"]}>
              (delegated:{" "}
              {step.delegates
                .map((user) => {
                  return user.firstName + " " + user.lastName;
                })
                .join(", ")}
              )
            </div>
          ) : undefined}
        </>
      );
    } else {
      return <div>N/A (To be assigned)</div>;
    }
  }

  return (
    <>
      <FormEntryKeyInformation
        formEntryId={approval.entryId}
        registerId={approval.registerId}
        isApprovalForm={true}
      />

      <NxpPanel
        titleContent={t("app.common.ApprovalFlow")}
        extra={
          !approval.deleted &&
          !approval.withdrawn &&
          !approval.isCompleted &&
          approval.submitUser.id === appContext.activeUser?.id ? (
            <NxpButtonWithConfirm
              modalContent={t(
                "ApprovalOverview.ProceedToWithdrawThisApplication?"
              )}
              danger
              type="text"
              onConfirm={handleConfirmWithdraw}
              confirmLoading={withdrawLoading}
            >
              {t("ApprovalOverview.WithdrawApplication")}
            </NxpButtonWithConfirm>
          ) : undefined
        }
      >
        <NxpTable
          useBackgroundLight
          columns={flowColumns}
          dataSource={approval.approvalSteps || []}
          scroll={{ y: 380 }}
        />
      </NxpPanel>
      <NxpPanel titleContent={t("app.common.Attachments")}>
        <NxpTable
          rowKey="id"
          useBackgroundLight
          columns={attachmentColumns}
          dataSource={approval.approvalDocuments}
          scroll={{ y: 380 }}
        />
      </NxpPanel>
    </>
  );
};

export default ApprovalOverview;
