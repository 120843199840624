import moment from "moment";

export enum DateFormatPattern {
  date = "YYYY-MM-DD",
  dateWithWeekday = "ddd YYYY-MM-DD",
  dateWithTime = "YYYY-MM-DD hh:mm:ss",
  time = "hh:mm",
  timeToSecond = "hh:mm:ss",
}

export default function formatDate(
  date: Date | null | undefined,
  pattern: string = DateFormatPattern.date, // set type as string for flexibility
  upperCase = true
): string {
  return date
    ? upperCase
      ? moment(date).format(pattern).toUpperCase()
      : moment(date).format(pattern)
    : "";
}
