import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { NavLink } from "react-router-dom";

import {
  NxpHeader,
  NxpMainSplitter,
  NxpSpin,
} from "@nexploretechnology/nxp-ui";

import useAppContext from "../../../hooks/useAppContext";
import { RegisterFlow } from "../../../services/registerFlow";
import CreateFlowModalButton from "./CreateFlowModal/CreateFlowModalButton";
import FlowDetails from "./FlowDetails";

const useStyles = createUseStyles((theme) => ({
  setupRuleLayout: {},
  selectRegisterFlows: {
    padding: theme.spacing(4),
    "& > ul": {
      listStyle: "none",
      padding: 0,
      margin: theme.spacing(1, 0),
    },
  },
  registerFlowList: {
    "& a": {
      color: theme.palette.text,
      "&.active": {
        color: theme.palette.primary,
        fontWeight: theme.fontWeight.bold,
      },
    },
  },
  registerFlowDetails: {
    margin: theme.spacing(3, 0, 0, 5),
  },
}));

interface SetupFlowLayoutProps {
  activeFlowId?: number;
  registerFlows?: RegisterFlow[];
  onFlowCreated: (newRegisterFlow: RegisterFlow) => void;
}

const SetupFlowLayout: React.FC<SetupFlowLayoutProps> = ({
  activeFlowId,
  registerFlows,
  onFlowCreated,
}) => {
  const appContext = useAppContext();

  const classes = useStyles();

  const { t } = useTranslation();

  const definedRegisterFlows = registerFlows || [];

  return (
    <div className={classes.setupRuleLayout}>
      <NxpMainSplitter>
        <div className={classes.selectRegisterFlows}>
          <CreateFlowModalButton onFlowCreated={onFlowCreated} />
          <ul className={classes.registerFlowList}>
            {registerFlows ? (
              registerFlows.map((registerFlow) => (
                <li key={registerFlow.id}>
                  <NavLink
                    to={
                      activeFlowId
                        ? `./${registerFlow.id}`
                        : `./flows/${registerFlow.id}`
                    }
                  >
                    {registerFlow.flowName}
                  </NavLink>
                </li>
              ))
            ) : (
              <NxpSpin size="large" />
            )}
          </ul>
        </div>
        {activeFlowId &&
        definedRegisterFlows.some((item) => item.id === activeFlowId) &&
        appContext.activeRegister ? (
          <FlowDetails
            className={classes.registerFlowDetails}
            flow={
              definedRegisterFlows.find((item) => item.id === activeFlowId)!
            }
          />
        ) : (
          <div className={classes.registerFlowDetails}>
            <NxpHeader titleContent={t("app.common.SetupFlow")} />
          </div>
        )}
      </NxpMainSplitter>
    </div>
  );
};

export default SetupFlowLayout;
