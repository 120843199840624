import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { NxpButton, NxpModal } from "@nexploretechnology/nxp-ui";
import Form from "antd/lib/form/Form";

import {
  FilterColumn,
  FilterComparer,
  FilterDataType,
  FilterStateItem,
} from "./AppFilter";
import AppFilterRow from "./AppFilterRow";

const useStyles = createUseStyles((theme) => ({
  appFilterModalForm: {
    "& .error-message": {
      color: theme.palette.warning,
      marginTop: theme.spacing(0.5),
    },
  },
  addButton: {
    "&.ant-btn": {
      backgroundColor: theme.palette.backgroundDark,
      border: `1px solid ${theme.palette.backgroundDark}`,
      color: theme.palette.primary,
      display: "block",
      width: `100%`,
      marginLeft: 0,

      "&:hover, &:focus": {
        borderColor: theme.palette.primary,
      },
    },
  },
}));

interface AppFilterModalProps {
  filterState: FilterStateItem[];
  filterColumns: FilterColumn[];
  onFilterChange: (filterState: FilterStateItem[]) => void;
  showFilterModal: boolean;
  onModalClose: () => void;
}

const AppFilterModal: React.FC<AppFilterModalProps> = ({
  filterState,
  filterColumns,
  onFilterChange,
  showFilterModal,
  onModalClose,
}) => {
  const [formFilterItems, setFormFilterItems] = useState<
    Partial<FilterStateItem>[]
  >(() => {
    if (filterState.length) {
      return [...filterState].map((option) => ({ ...option }));
    } else return [{}]; // show an empty row
  });

  const [isFormVaild, setIsFormValid] = useState(true);

  const findColumn = (fieldName: string): FilterColumn => {
    return filterColumns.find((column) => column.fieldName === fieldName)!;
  };

  const handleClearFilter = () => {
    onModalClose();
    onFilterChange([]);
  };

  const handleSaveFilter = () => {
    let isValid = true;
    if (
      formFilterItems.length === 1 &&
      !formFilterItems[0].filterColumn &&
      !formFilterItems[0].filterComparer &&
      !formFilterItems[0].filterValue?.toString()?.trim()
    ) {
      isValid = true; // pass validation when only one blank row
    } else if (
      formFilterItems.find(
        (option) =>
          !option.filterColumn ||
          !option.filterComparer ||
          (!option.filterValue?.toString()?.trim() &&
            option.filterComparer !== FilterComparer.IsEmpty)
      )
    ) {
      isValid = false;
    }

    if (isValid) {
      const options = formFilterItems.filter(
        (option) => option.filterColumn && option.filterComparer
      );
      onFilterChange(options as FilterStateItem[]);
      onModalClose();
      return;
    }

    setIsFormValid(isValid);
  };

  const handleAddFilter = () => {
    setFormFilterItems((prevState) => [...prevState, {}]);
    setIsFormValid(true);
  };

  const handleFormFilterChange = (
    orignalFormFilterItem: Partial<FilterStateItem>,
    updatedFormFilterItem?: Partial<FilterStateItem>
  ) => {
    if (!updatedFormFilterItem) {
      // delete
      setFormFilterItems((prev) =>
        prev.filter((item) => item !== orignalFormFilterItem)
      );
      return;
    }

    if (!Object.keys(updatedFormFilterItem).length) {
      // clear
      setFormFilterItems((prevState) => {
        prevState[prevState.indexOf(orignalFormFilterItem)] =
          updatedFormFilterItem;
        return [...prevState];
      });
      return;
    }

    if (
      orignalFormFilterItem.filterColumn?.fieldName !==
      updatedFormFilterItem.filterColumn?.fieldName
    ) {
      // update filter field
      if (
        orignalFormFilterItem.filterColumn?.filterDataType ===
          FilterDataType.Boolean ||
        orignalFormFilterItem.filterColumn?.filterDataType ===
          FilterDataType.Date ||
        orignalFormFilterItem.filterColumn?.filterDataType ===
          FilterDataType.Select
      ) {
        updatedFormFilterItem.filterValue = undefined;
      }

      if (updatedFormFilterItem.filterColumn) {
        const column = findColumn(updatedFormFilterItem.filterColumn.fieldName);
        if (
          column.filterDataType === FilterDataType.Boolean ||
          column.filterDataType === FilterDataType.Date ||
          column.filterDataType === FilterDataType.Select
        ) {
          updatedFormFilterItem.filterValue = undefined;
        }
        updatedFormFilterItem.filterColumn = column;
      } else {
        updatedFormFilterItem.filterColumn = undefined;
      }

      if (
        orignalFormFilterItem.filterColumn?.filterDataType !==
        updatedFormFilterItem.filterColumn?.filterDataType
      ) {
        updatedFormFilterItem.filterComparer = undefined;
      }
    }

    setFormFilterItems((prevState) => {
      prevState[prevState.indexOf(orignalFormFilterItem)] =
        updatedFormFilterItem;
      return [...prevState];
    });
  };

  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <NxpModal
      showMandatoryLabel={false}
      title={t("AppFilterModal.Filters")}
      onCancel={onModalClose}
      visible={showFilterModal}
      footer={
        <>
          <NxpButton
            className="app-filter-form-clear-all-button"
            onClick={handleClearFilter}
            type="default"
          >
            {t("AppFilterModal.ClearFilters")}
          </NxpButton>
          <NxpButton
            className="app-filter-form-save-button"
            onClick={handleSaveFilter}
          >
            {t("AppFilterModal.Apply")}
          </NxpButton>
        </>
      }
    >
      <Form layout="vertical" className={classes.appFilterModalForm}>
        {formFilterItems.map((formFilterItem, idx) => (
          // use index for key as form filter option has unique identifier
          <AppFilterRow
            key={idx}
            rowIndex={idx}
            isFormValid={isFormVaild}
            formFilterItem={formFilterItem}
            showClearButton={formFilterItems.length === 1}
            filterColumns={filterColumns}
            onFormFilterChange={handleFormFilterChange}
          />
        ))}
        {!isFormVaild && (
          <p className="error-message">
            {t("AppFilterModal.PleaseEnterValueForTheEmptyFields.")}
          </p>
        )}{" "}
        <NxpButton className={classes.addButton} onClick={handleAddFilter}>
          {t("AppFilterModal.AddFilter")}
        </NxpButton>
      </Form>
    </NxpModal>
  );
};

export default AppFilterModal;
