import React from 'react';

import {
  AccessType,
  RoleCode,
} from '../../../services/access';
import {
  Entity,
  RegisterType,
  User,
} from '../../../services/app';
import {
  Register,
  RegisterListItem,
} from '../../../services/register';
import { RegisterDictionary } from '../../../services/registerDictionary';
import { PrintForm } from '../../../services/registerPrintables';

export interface AppContextCache {
  users: User[];
  activeEntity?: Entity;
  activeUser?: User;
  registers?: RegisterListItem[];
  activeRegister?: Register;
  registerDictionaries?: RegisterDictionary[];
  registerTypes: RegisterType[];
  registerRolesOfCurrentUser?: RoleCode[];
  printForm?: PrintForm;
  language?: string;
}

type AppContextType = AppContextCache & {
  hasAccess: (accessType: AccessType) => boolean;
  onAppContextCacheUpdate: React.Dispatch<
    React.SetStateAction<AppContextCache>
  >;
  onAppContextCacheItemUpdate: <K extends keyof AppContextCache>(
    stateItem: K,
    itemValue: AppContextCache[K]
  ) => void;
};

const AppContext = React.createContext<AppContextType>({} as AppContextType);

export default AppContext;
