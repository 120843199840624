import React, { useState } from "react";
import { createUseStyles } from "react-jss";

import { FilterFilled } from "@ant-design/icons";
import { NxpButton } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";
import clsx from "clsx";

import AppFilterModal from "./AppFilterModal";

const useStyles = createUseStyles((theme) => ({
  appFilter: {
    color: theme.palette.primary,
    fontWeight: theme.fontWeight.bolder,
    "& .anticon": {
      color: theme.palette.backgroundDarker,
    },
    "&.active  .anticon": {
      color: theme.palette.primary,
    },
  },
  filterCount: {},
}));

export enum FilterComparer {
  Equal = "Equal",
  // NotEqual = "Not Equal",
  IsEmpty = "Is Empty",
  // Contains = "Contains",
  GreaterThan = "Greater Than",
  LessThan = "Less Than",
  // Between = "Between",
}

export enum FilterDataType {
  String,
  Number,
  Date,
  Boolean,
  Select,
}

export interface FilterStateItem {
  filterColumn: FilterColumn;
  filterComparer: FilterComparer;
  filterValue: string;
}

export interface FilterColumn {
  fieldName: string;
  displayName: string; // display nam of the field defined in the table column settings
  filterDataType: FilterDataType;
  selectOptions?: LabeledValue[];
}

interface AppFilterProps {
  filterState: FilterStateItem[];
  filterColumns: FilterColumn[];
  className?: string;
  onFilterChange: (filterState: FilterStateItem[]) => void;
}

const AppFilter: React.FC<AppFilterProps> = ({
  filterState,
  filterColumns,
  className,
  onFilterChange,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const handleFilterButtonClick = () => {
    setShowFilterModal(true);
  };

  const handleModalClose = () => {
    setShowFilterModal(false);
  };

  const classes = useStyles();

  return (
    <>
      <NxpButton
        type="default"
        className={clsx(
          className,
          classes.appFilter,
          filterState.length && " active"
        )}
        onClick={handleFilterButtonClick}
      >
        <FilterFilled />
        {filterState.length > 0 && (
          <span className={classes.filterCount}>
            {
              new Set(filterState.map((item) => item.filterColumn!.fieldName))
                .size
            }
          </span>
        )}
      </NxpButton>
      {showFilterModal && (
        <AppFilterModal
          showFilterModal={showFilterModal}
          onFilterChange={onFilterChange}
          filterState={filterState}
          filterColumns={filterColumns}
          onModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AppFilter;
