import React, { useEffect, useState } from "react";
import useAppContext from "../../../hooks/useAppContext";
import {
  FormEntryLog,
  getFormEntryLogs,
} from "../../../services/registerFormEntry";

import FormEntryLogsLayout from "./FormEntryLogsLayout";

interface FormEntryLogsContainerProps {
  formEntryId: number;
}

const FormEntryLogsContainer: React.FC<FormEntryLogsContainerProps> = ({
  formEntryId,
}) => {
  const appContext = useAppContext();
  const [logs, setLogs] = useState<FormEntryLog[]>([]);

  useEffect(() => {
    getFormEntryLogs(formEntryId, appContext.serviceConfig).then((data) => {
      setLogs(data);
    });
  }, [appContext.activeRegister, appContext.serviceConfig, formEntryId]);
  return <FormEntryLogsLayout logs={logs} />;
};

export default FormEntryLogsContainer;
