import { apiRequest, ServiceConfig } from "../utils/backend";
import { User } from "./app";

export interface RegisterAccessList {
  "register-entry:create": boolean;
  "register-entry:delete": boolean;
  "register-entry:read": boolean;
  "register-entry:special": boolean;
  "register-entry:update": boolean;
  "register:admin": boolean;
  "register:setup": boolean;
}

// export interface Access {
// id: number;
// name: string;
// }
// export interface AccessForm extends Pick<Access, 'name'> {}

// export const getAccessList = async (serviceConfig: ServiceConfig) => {
// return await apiRequest<Access[]>(
// `/entities/${serviceConfig.entityId}/accesss`,
// serviceConfig
// );
// };
// export const getAccess = async (
// id: number,
// serviceConfig: ServiceConfig
// ) => {
// return await apiRequest<Access>(
// `/entities/${serviceConfig.entityId}/accesss/${id}`,
// serviceConfig
// );
// };

// export const createAccess = async (
// accessForm: AccessForm,
// serviceConfig: ServiceConfig
// ) => {
// return await apiRequest<Access>(
// `/entities/${serviceConfig.entityId}/accesss`,
// serviceConfig,
// {
// method: 'POST',
// body: JSON.stringify(accessForm),
// }
// );
// };

// export const updateAccess = async (
// id: number,
// accessForm: AccessForm,
// serviceConfig: ServiceConfig
// ) => {
// return await apiRequest<Access>(
// `/entities/${serviceConfig.entityId}/accesss/${id}`,
// serviceConfig,
// {
// method: 'PATCH',
// body: JSON.stringify(accessForm),
// }
// );
// };

export const deleteAccess = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/entities/${serviceConfig.entityId}/accesss/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export type RoleCode =
  | "RES_OWNER"
  | "RES_ADMIN"
  | "RES_READER"
  | "RES_CONTRIBUTOR"
  | "RES_PRIV_READER";

export type AccessType = "adminOnly" | "writable" | "readable";

export interface RegisterRoleAccess {
  id: number;
  name: string;
  roleCode: RoleCode;
  members: User[];
}

export const getRegisterRoleAccess = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<RegisterRoleAccess[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/role-members`,
    serviceConfig
  );
};

export const getRegisterRolesOfCurrentUser = async (
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterRoleAccess[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/role-members/me`,
    serviceConfig
  );
};

export interface RegisterRoleAccessFormItem {
  roleCode: RoleCode;
  members: string[];
}

export const updateRegisterRoleAccess = async (
  registerAccessForm: RegisterRoleAccessFormItem[],
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<{ roles: RegisterRoleAccessFormItem[] }>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/role-members`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify({
        roles: registerAccessForm.map((access) => ({
          code: access.roleCode,
          members: access.members,
        })),
      }),
    }
  );
};
