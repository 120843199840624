import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import * as yup from "yup";

import AppAccess from "../../../components/AppAccess";
import useAppContext from "../../../hooks/useAppContext";
import { useValidate } from "../../../hooks/useValidate";
import {
  deleteRoleAssignment,
  getRoleAssignmentList,
  RoleAssignmentForm,
  updateRoleAssignment,
} from "../../../services/roleAssignment";
import notify from "../../../utils/notify";
import RoleAssignmentsLayout from "./RoleAssignmentsLayout";

interface RoleAssignmentsContainerProps {}

const RoleAssignmentsContainer: React.FC<RoleAssignmentsContainerProps> =
  () => {
    const appContext = useAppContext();
    const [roleAssignments, setRoleAssignments] = useState<
      RoleAssignmentForm[]
    >([]);
    const { registerId: registerIdParam } = useParams<{ registerId: string }>();
    const registerId = registerIdParam ? Number(registerIdParam) : undefined;

    useEffect(() => {
      if (appContext.activeEntity) {
        getRoleAssignmentList(appContext.serviceConfig, registerId)
          .then((data) =>
            setRoleAssignments(
              data.map((item) => ({
                id: item.id,
                rolecode: item.rolecode,
                userids: (item.users || []).map((user) => user.id),
                updatedOn: item.updatedOn,
              }))
            )
          )
          .catch((ex) => appContext.errorHandler(ex, "get role assignments"));
      }
    }, [appContext, registerId]);

    const [editItem, setEditItem] = useState<RoleAssignmentForm>();
    const [saveInProgress, setSaveInProgress] = useState(false);

    const { t } = useTranslation();

    const formSchema = yup.object().shape({
      userId: yup.number().nullable(),
      rolecode: yup
        .string()
        .nullable()
        .required(t("app.common.RoleCodeRequired"))
        .test(
          "duplicate",
          t("app.common.RoleCodeAlreadyExisted"),
          function (val) {
            return !roleAssignments.find(
              (item) =>
                item.rolecode.trim() === val.trim() && item.id !== editItem?.id
            );
          }
        ),
      updateOn: yup.date(),
    });

    const handleSaveValidated = async () => {
      if (editItem) {
        setSaveInProgress(true);
        try {
          const roleAssignment = await updateRoleAssignment(
            editItem?.id!,
            { rolecode: editItem.rolecode, userids: editItem.userids },
            appContext.serviceConfig,
            registerId
          );

          setRoleAssignments((prevState) =>
            prevState.map((item) =>
              item.id === roleAssignment.id
                ? {
                    ...roleAssignment,
                    userids: editItem.userids,
                  }
                : item
            )
          );
          setEditItem(undefined);
          notify.actionCompleted();
        } catch (ex) {
          appContext.errorHandler(ex, "update role assignment");
        } finally {
          setSaveInProgress(false);
        }
      }
    };

    const [validationError, , clearError, saveWithValidate] =
      useValidate<RoleAssignmentForm>(
        editItem,
        formSchema,
        handleSaveValidated
      );

    const handleRowEdit = useCallback(
      (editItem: RoleAssignmentForm) => setEditItem({ ...editItem }),
      []
    );

    const handleRowSave = useCallback(() => {
      saveWithValidate(undefined);
    }, [saveWithValidate]);

    const handleRowCancel = useCallback(() => {
      setEditItem(undefined);
      clearError();
    }, [clearError]);

    const handleRowDelete = useCallback(
      async (deleteItem: RoleAssignmentForm) => {
        setSaveInProgress(true);
        try {
          await deleteRoleAssignment(
            deleteItem.id!,
            appContext.serviceConfig,
            registerId
          );
          setRoleAssignments((prevState) =>
            prevState.filter((item) => item.id !== deleteItem.id)
          );
          setEditItem(undefined);
          notify.actionCompleted();
        } catch (ex) {
          appContext.errorHandler(ex, "update role assignment");
        } finally {
          setSaveInProgress(false);
        }
      },
      [appContext, registerId]
    );

    const handleFormStateChange = useCallback(
      (fieldName: keyof RoleAssignmentForm, value: unknown) => {
        setEditItem((prevState) => ({
          ...prevState!,
          [fieldName]: value,
        }));
      },
      []
    );

    const handleRoleAssignmentAdded = (
      roleAssignmentAdded: RoleAssignmentForm
    ) => {
      setRoleAssignments((prevState) => [
        {
          ...roleAssignmentAdded,
        },
        ...prevState,
      ]);
    };

    const layout = (
      <RoleAssignmentsLayout
        registerId={registerId}
        roleAssignments={roleAssignments}
        editItem={editItem}
        validationError={validationError}
        saveInProgress={saveInProgress}
        userList={appContext.users}
        onRoleAssignmentAdded={handleRoleAssignmentAdded}
        onFormStateChange={handleFormStateChange}
        onRowDelete={handleRowDelete}
        onRowEdit={handleRowEdit}
        onRowSave={handleRowSave}
        onRowCancel={handleRowCancel}
      />
    );

    return (
      // remove access check temporarily as requested by for CPCS-1536
      registerId === undefined ? (
        layout
      ) : (
        <AppAccess rolesAllowed="adminOnly" showWarning>
          {layout}
        </AppAccess>
      )
    );
  };

export default RoleAssignmentsContainer;
