import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  NxpButton,
  NxpFormGrid,
  NxpFormGridItemProps,
  NxpModal,
} from "@nexploretechnology/nxp-ui";
import * as yup from "yup";

import useAppContext from "../../../../hooks/useAppContext";
import { useValidate } from "../../../../hooks/useValidate";
import {
  createRoleAssignment,
  RoleAssignmentForm,
} from "../../../../services/roleAssignment";
import notify from "../../../../utils/notify";

interface AddRoleAssignmentModalProps {
  registerId?: number;
  existingRoleAssignments: RoleAssignmentForm[];
  modalVisible: boolean;
  onModalClose: () => void;
  onRoleAssignmentAdded: (roleAssignment: RoleAssignmentForm) => void;
}

const AddRoleAssignmentModal: React.FC<AddRoleAssignmentModalProps> = ({
  registerId,
  existingRoleAssignments,
  modalVisible,
  onModalClose,
  onRoleAssignmentAdded,
}) => {
  const appContext = useAppContext();

  const initFormValues = {
    rolecode: "",
    userids: [],
  };

  const [editForm, setEditForm] = useState<RoleAssignmentForm>(initFormValues);

  const [saveInProgress, setSaveInProgress] = useState(false);

  const handleSaveValidated = async () => {
    setSaveInProgress(true);

    try {
      const roleAssignment = await createRoleAssignment(
        editForm,
        appContext.serviceConfig,
        registerId
      );

      setSaveInProgress(false);
      notify.actionCompleted();
      onRoleAssignmentAdded({
        ...roleAssignment,
      });
      setEditForm(initFormValues);
    } catch (ex) {
      appContext.errorHandler(ex, "create role assignment");
    } finally {
      setSaveInProgress(false);
    }
  };

  const { t } = useTranslation();

  const formSchema = yup.object({
    rolecode: yup
      .string()
      .nullable()
      .required(t("app.common.RoleCodeRequired"))
      .test(
        "duplicate",
        t("app.common.RoleCodeAlreadyExisted"),
        function (val) {
          return !existingRoleAssignments.find(
            (item) => item.rolecode.trim() === val.trim()
          );
        }
      ),
  });

  const [validationError, , , saveWithValidate] =
    useValidate<RoleAssignmentForm>(editForm, formSchema, handleSaveValidated);

  const handleFormGridStateChange = (
    fieldName: keyof RoleAssignmentForm,
    value: unknown
  ) => {
    // console.log('ccccccccccccccc', fieldName, value);

    setEditForm((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleModalSave = () => {
    saveWithValidate(undefined);
  };

  const formItems: NxpFormGridItemProps<RoleAssignmentForm>[] = [
    {
      controlType: "input",
      label: t("app.common.RoleCode"),
      itemFieldName: "rolecode",
      required: true,
      span: 12,
    },
    {
      controlType: "selectMultiple",
      controlProps: {
        allowClear: true,
        options: appContext.users.map((user) => ({
          label: user.name,
          value: user.id.toString(),
        })),
      },
      label: t("app.common.Assignee"),
      itemFieldName: "userids",
      span: 12,
    },
  ];

  return (
    <NxpModal
      title={t("AddRoleAssignModal.AddRoleAssignment")}
      visible={modalVisible}
      onCancel={onModalClose}
      footer={
        <>
          <NxpButton
            disabled={saveInProgress}
            type="text"
            onClick={onModalClose}
          >
            {t("app.common.Cancel")}
          </NxpButton>
          <NxpButton loading={saveInProgress} onClick={handleModalSave}>
            {t("app.common.Save")}
          </NxpButton>
        </>
      }
    >
      <NxpFormGrid
        validationError={validationError}
        formItems={formItems}
        formState={editForm}
        onFormStateChange={handleFormGridStateChange}
      />
    </NxpModal>
  );
};

export default AddRoleAssignmentModal;
