import { apiRequest, ServiceConfig } from "../utils/backend";
import { RegisterFlow } from "./registerFlow";

export enum RuleOperator {
  Equal = "==",
  NotEqual = "!=",
  GreaterThan = ">",
  GreaterThanEqual = ">=",
  LessThan = "<",
  LessThanEqual = "<=",
}

export enum RuleConjunctive {
  And = "and",
  Or = "or",
}

export type RuleCompareDataValue = string | number | Date;

export interface RuleCondition {
  key: number;
  field: string;
  fieldType?: "date" | "number" | "string";
  operator: RuleOperator;
  value: RuleCompareDataValue;
}

export interface RuleConditionGroup {
  key: number;
  conjunctive: RuleConjunctive;
  children: (RuleConditionGroup | RuleCondition)[];
}

export interface RuleCompareDataObject {
  [key: string]: RuleCompareDataValue;
}

export interface RegisterRule {
  id: number;
  ruleName: string;
  order: number;
  workflow: RegisterFlow;
  workflowId: number;
  rule: RuleConditionGroup | RuleCondition | undefined | null;
}

export interface RegisterRuleForm extends Omit<RegisterRule, "id"> {}

export const getRegisterRuleList = async (
  ruleTemplateId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterRule[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates/${ruleTemplateId}/rules`,
    serviceConfig
  );
};

export const getRegisterRule = async (
  id: number,
  ruleTemplateId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterRule>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates/${ruleTemplateId}/rules/${id}`,
    serviceConfig
  );
};

export const createRegisterRule = async (
  ruleTemplateId: number,
  registerRuleForm: RegisterRuleForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterRule>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates/${ruleTemplateId}/rules`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(registerRuleForm),
    }
  );
};

export const updateRegisterRule = async (
  id: number,
  ruleTemplateId: number,
  registerRule: RegisterRuleForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterRule>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates/${ruleTemplateId}/rules/${id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(registerRule),
    }
  );
};

export const deleteRegisterRule = async (
  id: number,
  ruleTemplateId: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates/${ruleTemplateId}/rules/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};

export const ruleConditionMatch = (
  ruleCondition: RuleCondition,
  data: RuleCompareDataObject
) => {
  const dataVal = data[ruleCondition.field];
  switch (ruleCondition.operator) {
    case RuleOperator.NotEqual:
      // to widen match between string and number
      // eslint-disable-next-line eqeqeq
      return dataVal != ruleCondition.value;
    case RuleOperator.Equal:
      // to widen match between string and number
      // eslint-disable-next-line eqeqeq
      return dataVal == ruleCondition.value;
    case RuleOperator.LessThan:
      return dataVal < ruleCondition.value;
    case RuleOperator.LessThanEqual:
      return dataVal <= ruleCondition.value;
    case RuleOperator.GreaterThan:
      return dataVal > ruleCondition.value;
    case RuleOperator.GreaterThanEqual:
      return dataVal >= ruleCondition.value;
  }
};

export const ruleMatch = (
  rule: RuleCondition | RuleConditionGroup,
  data: RuleCompareDataObject
): boolean => {
  if ("conjunctive" in rule) {
    if (rule.conjunctive === "and") {
      // true only if every condition and childRule is true
      return rule.children.every((childRule) => {
        if ("conjunctive" in childRule) {
          // recursive processing of child RuleGroup
          return ruleMatch(childRule, data);
        } else {
          return ruleConditionMatch(childRule, data);
        }
      });
    } else if (rule.conjunctive === "or") {
      // true if any one condition or childRule is true
      return rule.children.some((childRule) => {
        if ("conjunctive" in childRule) {
          // recursive processing of child RuleGroup
          return ruleMatch(childRule, data);
        } else {
          return ruleConditionMatch(childRule, data);
        }
      });
    }
    // shouldn't happen as conjundtive can only be 'and' or 'or'
    throw new Error("Rule group conjunctive not found.");
  } else {
    // when the rule is one ruleCondition and has no ruleGroup
    return ruleConditionMatch(rule, data);
  }
};

export const ruleConditionStringify = (ruleCondition: RuleCondition) => {
  return ` ${ruleCondition.field} ${ruleCondition.operator} ${ruleCondition.value} `;
};

export const ruleStringify = (rule: RuleConditionGroup | RuleCondition) => {
  let ruleStr = " ( ";
  if ("conjunctive" in rule) {
    ruleStr += rule.children
      .map((childRule) => {
        if ("conjunctive" in childRule) {
          return ruleStringify(childRule);
        }
        return ruleConditionStringify(childRule);
      })
      .join(rule.conjunctive.toUpperCase());
  } else {
    ruleStr += ruleConditionStringify(rule);
  }
  return `${ruleStr} ) `;
};

export interface RegisterRuleTemplate {
  id: number;
  ruleTemplateName: string;
  rules: RegisterRule[];
  updatedOn: Date;
  createdOn: Date;
}

export interface RegisterRuleTemplateForm
  extends Pick<RegisterRuleTemplate, "ruleTemplateName"> {}

export const getRuleTemplateList = async (serviceConfig: ServiceConfig) => {
  return await apiRequest<RegisterRuleTemplate[]>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates`,
    serviceConfig
  );
};

export const createRegisterRuleTemplate = async (
  registerRuleTemplateForm: RegisterRuleTemplateForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterRuleTemplate>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(registerRuleTemplateForm),
    }
  );
};

export const updateRegisterRuleTemplate = async (
  id: number,
  registerRuleTemplateForm: RegisterRuleTemplateForm,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterRuleTemplate>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates/${id}`,
    serviceConfig,
    {
      method: "PATCH",
      body: JSON.stringify(registerRuleTemplateForm),
    }
  );
};

export const deleteRegisterRuleTemplate = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/rule_templates/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
