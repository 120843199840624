import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Components } from "react-formio";
import { ThemeProvider } from "react-jss";

import "../../i18n/i18nSetup";
import "./App.less";

import { ReactKeycloakProvider } from "@react-keycloak/web";

import appFormioComponents from "../AppFormio";
import AppContextProvider from "./AppContext/AppContextProvider";
// import {useTranslation} from 'react-i18next';
import AppRouter from "./AppRouter";
import { appTheme } from "./appTheme";
import keycloakInstance from "./keycloak";

import "formiojs/dist/formio.full.css";

Components.setComponents(appFormioComponents);
export interface UserInfo {
  username: string;
  email: string;
  token: string;
}

function App() {
  // const {t} = useTranslation();
  // for tracking idle time and token expiry

  const tokenLogger = (tokens: unknown) => {
    console.log("onKeycloakTokens", tokens);
  };

  // skip to prevent early logout issue reported by user
  // useAppTimer(keycloakInstance);

  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      // onEvent={eventLogger}
      onTokens={tokenLogger}
      initOptions={{
        onLoad: "login-required",
        flow: "implicit",
      }}
    >
      <ThemeProvider theme={appTheme}>
        <DndProvider backend={HTML5Backend}>
          <AppContextProvider>
            <AppRouter />
          </AppContextProvider>
        </DndProvider>
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
