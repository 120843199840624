import { useContext, useMemo } from "react";
import AppContext from "../components/App/AppContext";
import { RegisterAccessList } from "../services/access";
import useErrorHandler from "./useErrorHandler";
import useServiceConfig from "./useServiceConfig";

const useAppContext = () => {
  const appContext = useContext(AppContext);
  const serviceConfig = useServiceConfig();
  const errorHandler = useErrorHandler();

  return useMemo(
    () => ({
      ...appContext,
      serviceConfig,
      errorHandler,
      registerAccessList:
        (appContext.registers || []).find(
          (reg) => reg.register.id === appContext.activeRegister?.id
        )?.permissions || ({} as RegisterAccessList),
      // {
      //   ...appContext.registers.find(
      //     (reg) =>
      //       reg.register.id === appContext.activeRegister?.id
      //   )?.permissions,
      // "register-entry:special": false, // code for permission testing
      //} || ({} as RegisterAccessList),
    }),
    [appContext, serviceConfig, errorHandler]
  );
};

export default useAppContext;
