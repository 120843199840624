import { apiRequest, ServiceConfig } from "../utils/backend";

export interface RegisterDocument {
  file_ext: string;
  file_ord: number;
  entityId: number;
  mandatory_flag: boolean;
  id: number;
  doc_code: string;
  doc_name: string;
}

export interface RegisterDocumentForm
  extends Pick<
    RegisterDocument,
    "doc_name" | "file_ord" | "mandatory_flag" | "file_ext"
  > {}

export const getRegisterDocumentList = async (
  registerId: number,
  serviceConfig: ServiceConfig,
  override?: string
) => {
  return await apiRequest<RegisterDocument[]>(
    `/powerflow/entities/${
      serviceConfig.entityId
    }/registers/${registerId}/docs${override ? "?o=" + override : ""}`,
    serviceConfig
  );
};

export const getRegisterDocument = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest<RegisterDocument>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/docs/${id}`,
    serviceConfig
  );
};

const formatExtension = (ext: string) => {
  return ext ? ext.replace(".", "").toUpperCase() : "";
};

export const createRegisterDocument = async (
  registerDocumentForm: RegisterDocumentForm,
  serviceConfig: ServiceConfig
) => {
  registerDocumentForm.file_ext = formatExtension(
    registerDocumentForm.file_ext
  );
  return await apiRequest<RegisterDocument>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/docs`,
    serviceConfig,
    {
      method: "POST",
      body: JSON.stringify(registerDocumentForm),
    }
  );
};

export const updateRegisterDocument = async (
  id: number,
  registerDocumentForm: RegisterDocumentForm,
  serviceConfig: ServiceConfig
) => {
  registerDocumentForm.file_ext = formatExtension(
    registerDocumentForm.file_ext
  );
  return await apiRequest<RegisterDocument>(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/docs/${id}`,
    serviceConfig,
    {
      method: "PUT",
      body: JSON.stringify(registerDocumentForm),
    }
  );
};

export const deleteRegisterDocument = async (
  id: number,
  serviceConfig: ServiceConfig
) => {
  return await apiRequest(
    `/powerflow/entities/${serviceConfig.entityId}/registers/${serviceConfig.registerId}/docs/${id}`,
    serviceConfig,
    {
      method: "DELETE",
    }
  );
};
