import React from "react";
import { useTranslation } from "react-i18next";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { NxpFormItem } from "@nexploretechnology/nxp-ui";
import { LabeledValue } from "antd/lib/select";

export interface PaginatorProps {
  page: number;
  totalCount: number;
  onPageChange?: (page: number) => void;
  onPageClick?: (page: number) => void;
  disableTitle?: boolean;
  disableDropdown?: boolean;
}

let pageOptions: LabeledValue[] = [
  {
    value: 0,
    label: "1",
  },
];

export const Paginator: React.FC<PaginatorProps> = ({
  page,
  totalCount,
  onPageChange,
  onPageClick,
}) => {
  //   const i18n = useI18n();

  const handlePerPageChange = (option: any) => {
    // console.log(option);
    if (!onPageChange) {
      return;
    }

    if (option == null) {
      throw new Error("option should not be empty");
    }

    onPageChange(option);
  };

  const handlePrevClick = () => {
    if (!onPageClick) {
      return;
    }

    if (page === 0) {
      return;
    }

    onPageClick(page - 1);
  };

  const handleNextClick = () => {
    if (!onPageClick) {
      return;
    }
    // console.log(
    //   "logging from paginator, page: " + page + " totalCount: " + totalCount
    // );

    if (page >= totalCount - 1) {
      return;
    }

    onPageClick(page + 1);
  };

  pageOptions = [];
  for (let i = 0; i < totalCount; i++) {
    pageOptions.push({
      value: i,
      label: String(i + 1),
    });
  }

  const { t } = useTranslation();

  return (
    <div className="form-printable-list-pagination">
      <NxpFormItem
        controlType="select"
        controlProps={{
          value: page,
          options: pageOptions,
          onChange: handlePerPageChange,
        }}
        label={t("app.common.Page")}
      />
      <div className="form-printable-page-control">
        <LeftOutlined
          className={
            "form-printable-page-control-prev" +
            (page === 0 ? " is-disabled" : "")
          }
          onClick={handlePrevClick}
        />
        <div className="form-printable-page-control-sep" />
        <RightOutlined
          className={
            "form-printable-page-control-next" +
            (page >= totalCount - 1 ? " is-disabled" : "")
          }
          onClick={handleNextClick}
        />
      </div>
    </div>
  );
};

export default Paginator;
