import notify from "../../utils/notify";

export const registerIdMatch = () => {
  const registerIdMatch = window.location.href.match(/registers\/(\d+)/);

  // below match is need for the approval page which cannot use the /registers/ path because of access right restriction
  const registerIdQueryMatch = window.location.href.match(/\?register=(\d+)/);

  if (!registerIdMatch && !registerIdQueryMatch) {
    const error = Error("Register ID undefined");
    notify.error(error);
    throw error;
  } else if (registerIdMatch) {
    return registerIdMatch[1];
  } else if (registerIdQueryMatch) {
    return registerIdQueryMatch[1];
  }
};
